import React from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import filter1 from "../../assets/svg/Health Article/filtericon.svg";
import sort1 from "../../assets/svg/Health Article/sortbyicon.svg";
import search from "../../assets/images/searchIcon.png";
import buttonadd from "../../assets/images/plus.png";
import "./index.scss";
import { useState } from "react";
import { FaAngleRight } from "react-icons/fa";
import Autocomplete from "react-google-autocomplete";

const AddNewClinic = () => {
  const [, setSort] = useState("newest");
  const [sortName, setSortName] = useState("Sort by");
  // const [filter, setFilter] = useState({});
  // const [records, setRecords] = useState([]);

  const [sortshow, setSortShow] = useState(false);
  const [textcolor, setTextColor] = useState(0);
  const [filtershow, setFilterShow] = useState(false);
  const [, setSubmitFilter] = useState(false);
  const [spldata, setSplData] = useState([]);
  const [statusdata, setStatusData] = useState([]);
  const [isChecked,] = useState(true);
  const [location, setLocation] = useState("");

  const primaryspl = [
    {
      text: "Andrology",
    },
    {
      text: "Ayurveda",
    },
    {
      text: "Allergist",
    },
    {
      text: "Bariatrics",
    },
    {
      text: "Cardiology",
    },
    {
      text: "Cosmetology",
    },
    {
      text: "Dentistry",
    },
  ];

  const status = [
    {
      text: "Active",
    },
    {
      text: "Inactive",
    },
  ];

  const navigate = useNavigate();

  const handleNewClinicForm = () => {
    navigate("/add-new-clinic-form");
  };

  const handleEdit = () => {
    navigate("/edit-clinic-form");
  };

  const data = [
    {
      sno: "1",
      name: "Rk General Clinic",
      docname: ["Anand K", "Girish M", "Manoj Kumar L"],
      location: "Ganapathy",
      pincode: "641 006",
      photos: "",
      status: "Verified",
      spl: "Genereal Physician",
      license: "TN69241219",
      logo: "",
      time: [
        "09:30 AM - 11:30 AM",
        "12:00 PM - 02:30 PM",
        "03:00 PM - 06:00 PM",
        "06:30 PM - 12:00 AM",
        "12:00 AM - 07:30 AM",
      ],
      map: "http://www.khnishivs.vcn",
    },
  ];

  return (
    <>
      {/* <Home> */}
      <div className="healtharticle">
        <div className="healtharticle-container">
          <div className="healtharticle-title carrer-job-title ms-4">
            <Link
              to="/add-new-doctors"
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "15px",
              }}
            >
              <span style={{ paddingLeft: "5%" }}>Dashboard</span>
            </Link>
            <span style={{ paddingLeft: "1%", paddingRight: "0.4%" }}>{`>`}</span>
            <Link
              to=""
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "15px",
              }}
            >
              <span>Clinic & Hospital</span>
            </Link>
            <Link
              to=""
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "15px",
              }}
            >
              <span style={{ paddingLeft: "0%", paddingRight: "0%" }}>{`>`}</span>
              <span>List of Active Clinic & Hospitals</span>
            </Link>
          </div>

          <div className="healtharticle-addnew">
            <div
              id="addnewpost"
              onClick={handleNewClinicForm}
              className="addnewhealth"
              style={{ width: "310px" }}
            >
              <div>
                {/* <BsFillPlusCircleFill /> */}
                <img src={buttonadd} alt="" />
              </div>
              <div>
                <button>Add New Clinic / Hospital Profile</button>
              </div>
            </div>
          </div>
          <div className="careers-tab" style={{ marginLeft: "2%" }}>
            <div className="master-doctor">Active Clinic & Hospitals</div>
            <div
              className="career-tab-jobpost"
              onClick={() => navigate("/doctor-inclinic-appointment")}
            >
              Doctors in In-Clinic Appointments
            </div>
          </div>

          <div className="healtharticle-show mt-5">
            <div>Show</div>
            <div>
              <select
                // value={limit}
                // onChange={(e) => setLimit(e.target.value)}
                className="me-2"
              >
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
                <option value="0">All</option>
              </select>
            </div>
            <div>entries</div>
            <div>
              <div>
                {/* <FaSearch /> */}
                <img src={search} alt="" />
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Search"
                  className="form-control"
                  // onChange={(e) => filter_records(e.target.value)}
                />
              </div>
            </div>
            <div className="sortandfilter-new">
              <div className="sortByPosition">
                <div>
                  <div>
                    <div
                      onClick={() => {
                        setSortShow(!sortshow);
                      }}
                    >
                      {sortName}
                      {/* Sort by */}
                    </div>
                  </div>
                  <div>
                    <img src={sort1} alt="" />
                  </div>
                </div>
              </div>
              <div className="sortByDepartment">
                <div>
                  <div>
                    <div
                      onClick={() => {
                        setFilterShow(true);
                      }}
                    >
                      Filter
                    </div>
                  </div>
                  <div>
                    <img src={filter1} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="healtharticle-table add-new-doctors-table">
            <table>
              <thead>
                <tr>
                  <td>S.No</td>
                  <td>Name of Clinic / Hospital</td>
                  <td>Concerned Doctor's Name</td>
                  <td>Location of Clinic / Hospital</td>
                  <td>Pincode of Clinic / Hospital</td>
                  <td>Clinic / Hospital Photos</td>
                  <td>Status</td>
                  <td>Specialisation</td>
                  <td>License / Registration Number</td>
                  <td>Logo of Clinic / Hopital</td>
                  <td>Timings</td>
                  <td>Google Map Location Mark</td>
                  <td>Actions</td>
                </tr>
              </thead>
              <tbody>
                {data.map((x, i) => {
                  return (
                    <tr>
                      <td>{x.sno}</td>
                      <td>{x.name}</td>
                      <td>{x.docname}</td>
                      <td>{x.location}</td>
                      <td>{x.pincode}</td>
                      <td>{}</td>
                      <td>{x.status}</td>
                      <td>{x.spl}</td>
                      <td>{x.license}</td>
                      <td>{}</td>
                      <td>{x.time}</td>
                      <td>{x.map}</td>
                      <td>
                        <span
                          className="doctor-edit"
                          onClick={() => {
                            handleEdit();
                          }}
                        >
                          Edit
                        </span>
                        <span className="doctor-edit-logo"></span>
                        <span className="doctor-delete">Cancel</span>
                        <span className="doctor-delete-logo"></span>
                        <span
                          className="doctor-view"
                          onClick={() => {
                            navigate("/view-clinic-form");
                          }}
                        >
                          View
                        </span>
                        <span className="doctor-view-logo"></span>
                        <span
                          className="doctor-activate"
                          onClick={() => {
                            navigate("/clinic-track");
                          }}
                        >
                          Track
                        </span>
                        <span className="doctor-activate-logo"></span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* </Home> */}
      {sortshow ? (
        <div className="sortby-Common-career-main sortby-master-common">
          <div
            onClick={() => {
              setTextColor(11);
              setSortShow(false);
              setSort("oldest");
              setSortName("Oldest");
            }}
            style={{
              color: textcolor === 11 ? "#CB1B5B" : "",
              fontWeight: textcolor === 11 ? "bold" : "",
            }}
          >
            Oldest
          </div>
          <div
            onClick={() => {
              setTextColor(12);
              setSortShow(false);
              setSort("newest");
              setSortName("Newest");
            }}
            style={{
              color: textcolor === 12 ? "#CB1B5B" : "",
              fontWeight: textcolor === 12 ? "bold" : "",
            }}
          >
            Newest
          </div>
          <div
            onClick={() => {
              setTextColor(13);
              setSortShow(false);
              setSort("ascending");
              setSortName("Ascending");
            }}
            style={{
              color: textcolor === 13 ? "#CB1B5B" : "",
              fontWeight: textcolor === 13 ? "bold" : "",
            }}
          >
            Alphabetical ↑
          </div>
          <div
            onClick={() => {
              setTextColor(14);
              setSortShow(false);
              setSort("descending");
              setSortName("Descending");
            }}
            style={{
              color: textcolor === 14 ? "#CB1B5B" : "",
              fontWeight: textcolor === 14 ? "bold" : "",
            }}
          >
            Alphabetical ↓
          </div>
        </div>
      ) : null}

      {/*---------Filter Portion--------*/}
      {filtershow ? (
        <div className="filter-container-career">
          <div className="filter-header">Filter</div>
          <div className="filter-header-hr"></div>
          <div className="filter-content-doctor">
            <div
              className="filter-content-card-doctor"
              onClick={() => {
                setTextColor(1);
              }}
            >
              <div
                style={{
                  color: textcolor === 1 ? "#CB1B5B" : "",
                  fontWeight: textcolor === 1 ? "bold" : "",
                }}
              >
                Primary Specialisation
              </div>
              <div>
                <FaAngleRight className="rightarrow" />
              </div>
            </div>
            <div
              className="filter-content-card-doctor"
              onClick={() => {
                setTextColor(2);
              }}
            >
              <div
                style={{
                  color: textcolor === 2 ? "#CB1B5B" : "",
                  fontWeight: textcolor === 2 ? "bold" : "",
                }}
              >
                Status
              </div>
              <div>
                <FaAngleRight className="rightarrow" />
              </div>
            </div>
            <div
              className="filter-content-card-doctor"
              onClick={() => {
                setTextColor(3);
              }}
            >
              <div
                style={{
                  color: textcolor === 3 ? "#CB1B5B" : "",
                  fontWeight: textcolor === 3 ? "bold" : "",
                }}
              >
                City / Town
              </div>
              <div>
                <FaAngleRight className="rightarrow" />
              </div>
            </div>
          </div>
          <div className="filter-foot-btn">
            <div>
              <button
                onClick={() => {
                  setFilterShow(false);
                }}
              >
                Clear
              </button>
            </div>
            <div>
              <button
                onClick={() => {
                  setFilterShow(false);
                  setSubmitFilter(true);
                }}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {/*-------Primary Specialisation---------*/}
      {textcolor === 1 && filtershow === true ? (
        <div className="filter-doctor-fee-career">
          <div className="filter-doctor-fee-header">Primary Specialisation</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            {primaryspl.map((el, i) => {
              return (
                <div className="filter-doctor-fee-box">
                  <div>{el.text}</div>
                  <div className="checkbox-wrapper">
                    <input
                      type="checkbox"
                      className={isChecked ? "checked" : ""}
                      name={el.text}
                      id=""
                      onChange={(e) => {
                        setSplData([...spldata, e.target.name]);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}

      {/*-------City / Town--------*/}
      {textcolor === 3 && filtershow === true ? (
        <div className="filter-doctor-fee-career">
          <div className="filter-doctor-fee-header">City / Town</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            <div className="filter-doctor-search">
              <div>
                <div>
                  <img src={search} alt="" />
                </div>
                <div>
                  {/* <input 
                    type="text" 
                    name="" 
                    id="filter-search" 
                    placeholder="Search"
                    onChange={(e)=>setLocation(e.target.value)}
                  /> */}
                  <Autocomplete
                    apiKey={"AIzaSyBJUZFe3zt4HNVzwdTlmJYMWtXhZ7mfclk"}
                    onPlaceSelected={(place) => {
                      setLocation(place.formatted_address);
                    }}
                    types={["address"]}
                    className="form-control newlocation"
                    defaultValue={location}
                    placeholder=""
                    componentRestrictions={{ country: "us" }}
                    preventDefault
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {/*-------Status---------*/}
      {textcolor === 2 && filtershow === true ? (
        <div className="filter-doctor-fee-career">
          <div className="filter-doctor-fee-header">Type of Job</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            {status.map((el, i) => {
              return (
                <div className="filter-doctor-fee-box">
                  <div>{el.text}</div>
                  <div className="checkbox-wrapper">
                    <input
                      type="checkbox"
                      className={isChecked ? "checked" : ""}
                      name={el.text}
                      id=""
                      onChange={(e) => {
                        setStatusData([...statusdata, e.target.name]);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default AddNewClinic;
