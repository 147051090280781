
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const ExcelDownload = (records, columnOrder, fileName="records.xlsx") => {
  const worksheet = XLSX.utils.json_to_sheet(records, {
        header: columnOrder,
      });

      // Create a workbook and append the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Records");

      // Convert the workbook to a binary array
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Create a Blob and download the file
      const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
      saveAs(blob, fileName);

}

export default ExcelDownload