import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import SucessModal from "../../modal/SucessModal";
import "./index.scss";

const MetaTags = () => {
  const {
    register,
    getValues,
    setValue,
    // formState: { errors },
    handleSubmit,
  } = useForm();

  const [success, setSuccess] = useState(false);
  const [editSuccess, setEditSuccess] = useState(false);
  // const navigate = useNavigate();

  // const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [count, setCount] = useState(0);

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_URL}meta-tags/list`)
      .then((res) => {
        console.log(res);
        setId(res.data.rows[0]?._id);
        setCount(res.data.count);
        res.data?.rows?.length && Object.keys(res.data.rows[0]).forEach((x) => {
          setValue(x, res.data.rows[0][x]);
        });
      });
  }, [count, setValue]);

  const onSubmit = (fields) => {
    if (count === 0) {
      axios
        .post(`${process.env.REACT_APP_API_URL}meta-tags/create`, fields)
        .then((response) => {
          if (response.data?._id) {
            setSuccess(true);
          } else {
            alert("Fail");
            console.log("failed...");
          }
        });
    } else if (count === 1) {
      let fields = getValues();
      axios
        .patch(
          `${process.env.REACT_APP_API_URL}meta-tags/update/${id}`,
          fields
        )
        .then((response) => {
          if (response.data?._id) {
            setEditSuccess(true);
          } else {
            alert("Fail");
          }
        });
    }
  };

  const metaTagFields = [
    {
      pageName: 'Custom page',
      titleName: 'common_page.metaTitle',
      descriptionName: 'common_page.metaDescription',
      keyWordsName: 'common_page.metaKeywords',
      othersName: 'common_page.others',
    },
    {
      pageName: 'Home Page',
      titleName: 'home_page.metaTitle',
      descriptionName: 'home_page.metaDescription',
      keyWordsName: 'home_page.metaKeywords',
      othersName: 'home_page.others',
    },
    {
      pageName: 'Doctor Demo Page',
      titleName: 'doctor_demo.metaTitle',
      descriptionName: 'doctor_demo.metaDescription',
      keyWordsName: 'doctor_demo.metaKeywords',
      othersName: 'doctor_demo.others',
    },
    {
      pageName: 'Other Registration Page',
      titleName: 'others.metaTitle',
      descriptionName: 'others.metaDescription',
      keyWordsName: 'others.metaKeywords',
      othersName: 'others.registration_others',
    },
    {
      pageName: 'Career Page',
      titleName: 'career_page.metaTitle',
      descriptionName: 'career_page.metaDescription',
      keyWordsName: 'career_page.metaKeywords',
      othersName: 'career_page.others',
    },
    {
      pageName: 'Career Detail Page',
      titleName: 'career_detail_page.metaTitle',
      descriptionName: 'career_detail_page.metaDescription',
      keyWordsName: 'career_detail_page.metaKeywords',
      othersName: 'career_detail_page.others',
    },
    {
      pageName: 'Health Article Page',
      titleName: 'blog_page.metaTitle',
      descriptionName: 'blog_page.metaDescription',
      keyWordsName: 'blog_page.metaKeywords',
      othersName: 'blog_page.others',
    },
    {
      pageName: 'Blog Detail Page',
      titleName: 'blog_detail_page.metaTitle',
      descriptionName: 'blog_detail_page.metaDescription',
      keyWordsName: 'blog_detail_page.metaKeywords',
      othersName: 'blog_detail_page.others',
    },
    {
      pageName: 'Contact Us',
      titleName: 'contact_us.metaTitle',
      descriptionName: 'contact_us.metaDescription',
      keyWordsName: 'contact_us.metaKeywords',
      othersName: 'contact_us.others',
    },
    {
      pageName: 'About Us',
      titleName: 'about_us.metaTitle',
      descriptionName: 'about_us.metaDescription',
      keyWordsName: 'about_us.metaKeywords',
      othersName: 'about_us.others',
    },
    {
      pageName: 'HealthSy Life',
      titleName: 'healthSy_life.metaTitle',
      descriptionName: 'healthSy_life.metaDescription',
      keyWordsName: 'healthSy_life.metaKeywords',
      othersName: 'healthSy_life.others',
    },
    {
      pageName: 'Online Consultation',
      titleName: 'online_consultation.metaTitle',
      descriptionName: 'online_consultation.metaDescription',
      keyWordsName: 'online_consultation.metaKeywords',
      othersName: 'online_consultation.others',
    },
    {
      pageName: 'In-Clinic Appoinments',
      titleName: 'in_Clinic_appoinments.metaTitle',
      descriptionName: 'in_Clinic_appoinments.metaDescription',
      keyWordsName: 'in_Clinic_appoinments.metaKeywords',
      othersName: 'in_Clinic_appoinments.others',
    },
    {
      pageName: 'Order Medicines',
      titleName: 'order_medicines.metaTitle',
      descriptionName: 'order_medicines.metaDescription',
      keyWordsName: 'order_medicines.metaKeywords',
      othersName: 'order_medicines.others',
    },
    {
      pageName: 'Home Healthcare Service',
      titleName: 'home_healthcare_service.metaTitle',
      descriptionName: 'home_healthcare_service.metaDescription',
      keyWordsName: 'home_healthcare_service.metaKeywords',
      othersName: 'home_healthcare_service.others',
    },
    {
      pageName: 'HealthSy Plus Membership',
      titleName: 'healthsy_plus_membership.metaTitle',
      descriptionName: 'healthsy_plus_membership.metaDescription',
      keyWordsName: 'healthsy_plus_membership.metaKeywords',
      othersName: 'healthsy_plus_membership.others',
    },
    {
      pageName: 'Healthcare Products ',
      titleName: 'healthcare_products.metaTitle',
      descriptionName: 'healthcare_products.metaDescription',
      keyWordsName: 'healthcare_products.metaKeywords',
      othersName: 'healthcare_products.others',
    },
    {
      pageName: 'Physiotherapy',
      titleName: 'physiotherapy.metaTitle',
      descriptionName: 'physiotherapy.metaDescription',
      keyWordsName: 'physiotherapy.metaKeywords',
      othersName: 'physiotherapy.others',
    },
    {
      pageName: 'InstaDoc ',
      titleName: 'insta_doc.metaTitle',
      descriptionName: 'insta_doc.metaDescription',
      keyWordsName: 'insta_doc.metaKeywords',
      othersName: 'insta_doc.others',
    },
    {
      pageName: 'InstaDoc QR Code ',
      titleName: 'rp_qr_instadoc.metaTitle',
      descriptionName: 'rp_qr_instadoc.metaDescription',
      keyWordsName: 'rp_qr_instadoc.metaKeywords',
      othersName: 'rp_qr_instadoc.others',
    },
    {
      pageName: 'Healthsy Advantage Subscription ',
      titleName: 'healthsy_advantage_subscription.metaTitle',
      descriptionName: 'healthsy_advantage_subscription.metaDescription',
      keyWordsName: 'healthsy_advantage_subscription.metaKeywords',
      othersName: 'healthsy_advantage_subscription.others',
    },
    {
      pageName: 'Sustainability Policy',
      titleName: 'sustainability_policy.metaTitle',
      descriptionName: 'sustainability_policy.metaDescription',
      keyWordsName: 'sustainability_policy.metaKeywords',
      othersName: 'sustainability_policy.others',
    },
    {
      pageName: 'Franchise Programme',
      titleName: 'franchise_programme.metaTitle',
      descriptionName: 'franchise_programme.metaDescription',
      keyWordsName: 'franchise_programme.metaKeywords',
      othersName: 'franchise_programme.others',
    },
    {
      pageName: 'Doctor Listing',
      titleName: 'doctor_listing.metaTitle',
      descriptionName: 'doctor_listing.metaDescription',
      keyWordsName: 'doctor_listing.metaKeywords',
      othersName: 'doctor_listing.others',
    },
    {
      pageName: 'Doctor Details',
      titleName: 'doctor_details.metaTitle',
      descriptionName: 'doctor_details.metaDescription',
      keyWordsName: 'doctor_details.metaKeywords',
      othersName: 'doctor_details.others',
    },
    {
      pageName: 'InstaDoc page',
      titleName: 'instaDoc_page.metaTitle',
      descriptionName: 'instaDoc_page.metaDescription',
      keyWordsName: 'instaDoc_page.metaKeywords',
      othersName: 'instaDoc_page.others',
    },
    {
      pageName: 'InstaDoc Form StepOne',
      titleName: 'instaDoc_form_stepOne.metaTitle',
      descriptionName: 'instaDoc_form_stepOne.metaDescription',
      keyWordsName: 'instaDoc_form_stepOne.metaKeywords',
      othersName: 'instaDoc_form_stepOne.others',
    },
    {
      pageName: 'InstaDoc Form StepTwo',
      titleName: 'instaDoc_form_stepTwo.metaTitle',
      descriptionName: 'instaDoc_form_stepTwo.metaDescription',
      keyWordsName: 'instaDoc_form_stepTwo.metaKeywords',
      othersName: 'instaDoc_form_stepTwo.others',
    },
    {
      pageName: 'InstaDoc Terms',
      titleName: 'instaDoc_terms.metaTitle',
      descriptionName: 'instaDoc_terms.metaDescription',
      keyWordsName: 'instaDoc_terms.metaKeywords',
      othersName: 'instaDoc_terms.others',
    },
    {
      pageName: 'InstaDoc Privacy',
      titleName: 'instaDoc_privacy.metaTitle',
      descriptionName: 'instaDoc_privacy.metaDescription',
      keyWordsName: 'instaDoc_privacy.metaKeywords',
      othersName: 'instaDoc_privacy.others',
    },
    {
      pageName: 'Doctor Page',
      titleName: 'doctor_page.metaTitle',
      descriptionName: 'doctor_page.metaDescription',
      keyWordsName: 'doctor_page.metaKeywords',
      othersName: 'doctor_page.others',
    },
    {
      pageName: 'Doctor Registration StepOne',
      titleName: 'doctor_form_stepOne.metaTitle',
      descriptionName: 'doctor_form_stepOne.metaDescription',
      keyWordsName: 'doctor_form_stepOne.metaKeywords',
      othersName: 'doctor_form_stepOne.others',
    },
    {
      pageName: 'Doctor Registration StepTwo',
      titleName: 'doctor_form_stepTwo.metaTitle',
      descriptionName: 'doctor_form_stepTwo.metaDescription',
      keyWordsName: 'doctor_form_stepTwo.metaKeywords',
      othersName: 'doctor_form_stepTwo.others',
    },
    {
      pageName: 'Doctor Registration StepThree',
      titleName: 'doctor_form_stepThree.metaTitle',
      descriptionName: 'doctor_form_stepThree.metaDescription',
      keyWordsName: 'doctor_form_stepThree.metaKeywords',
      othersName: 'doctor_form_stepThree.others',
    },
    {
      pageName: 'Doctor Terms',
      titleName: 'doctor_terms.metaTitle',
      descriptionName: 'doctor_terms.metaDescription',
      keyWordsName: 'doctor_terms.metaKeywords',
      othersName: 'doctor_terms.others',
    },
    {
      pageName: 'Doctor Privacy',
      titleName: 'doctor_privacy.metaTitle',
      descriptionName: 'doctor_privacy.metaDescription',
      keyWordsName: 'doctor_privacy.metaKeywords',
      othersName: 'doctor_privacy.others',
    },
    {
      pageName: 'Retail Pharmacy Page',
      titleName: 'retail_pharmacy_page.metaTitle',
      descriptionName: 'retail_pharmacy_page.metaDescription',
      keyWordsName: 'retail_pharmacy_page.metaKeywords',
      othersName: 'retail_pharmacy_page.others',
    },
    {
      pageName: 'Retail Pharmacy Step One',
      titleName: 'retail_pharmacy_step_one.metaTitle',
      descriptionName: 'retail_pharmacy_step_one.metaDescription',
      keyWordsName: 'retail_pharmacy_step_one.metaKeywords',
      othersName: 'retail_pharmacy_step_one.others',
    },
    {
      pageName: 'Retail Pharmacy Step Two',
      titleName: 'retail_pharmacy_step_two.metaTitle',
      descriptionName: 'retail_pharmacy_step_two.metaDescription',
      keyWordsName: 'retail_pharmacy_step_two.metaKeywords',
      othersName: 'retail_pharmacy_step_two.others',
    },
    {
      pageName: 'Retail Pharmacy Step Three',
      titleName: 'retail_pharmacy_step_three.metaTitle',
      descriptionName: 'retail_pharmacy_step_three.metaDescription',
      keyWordsName: 'retail_pharmacy_step_three.metaKeywords',
      othersName: 'retail_pharmacy_step_three.others',
    },
    {
      pageName: 'Retail Pharmacy Terms',
      titleName: 'retail_pharmacy_terms.metaTitle',
      descriptionName: 'retail_pharmacy_terms.metaDescription',
      keyWordsName: 'retail_pharmacy_terms.metaKeywords',
      othersName: 'retail_pharmacy_terms.others',
    },
    {
      pageName: 'Retail Pharmacy Privacy',
      titleName: 'retail_pharmacy_privacy.metaTitle',
      descriptionName: 'retail_pharmacy_privacy.metaDescription',
      keyWordsName: 'retail_pharmacy_privacy.metaKeywords',
      othersName: 'retail_pharmacy_privacy.others',
    },
    {
      pageName: 'Home Healthcare Page',
      titleName: 'home_healthcare_page.metaTitle',
      descriptionName: 'home_healthcare_page.metaDescription',
      keyWordsName: 'home_healthcare_page.metaKeywords',
      othersName: 'home_healthcare_page.others',
    },
    {
      pageName: 'Home Healthcare Step One',
      titleName: 'home_healthcare_step_one.metaTitle',
      descriptionName: 'home_healthcare_step_one.metaDescription',
      keyWordsName: 'home_healthcare_step_one.metaKeywords',
      othersName: 'home_healthcare_step_one.others',
    },
    {
      pageName: 'Home Healthcare Step Two',
      titleName: 'home_healthcare_step_two.metaTitle',
      descriptionName: 'home_healthcare_step_two.metaDescription',
      keyWordsName: 'home_healthcare_step_two.metaKeywords',
      othersName: 'home_healthcare_step_two.others',
    },
    {
      pageName: 'Home Healthcare Step Three',
      titleName: 'home_healthcare_step_three.metaTitle',
      descriptionName: 'home_healthcare_step_three.metaDescription',
      keyWordsName: 'home_healthcare_step_three.metaKeywords',
      othersName: 'home_healthcare_step_three.others',
    },
    {
      pageName: 'Home Healthcare Terms',
      titleName: 'home_healthcare_terms.metaTitle',
      descriptionName: 'home_healthcare_terms.metaDescription',
      keyWordsName: 'home_healthcare_terms.metaKeywords',
      othersName: 'home_healthcare_terms.others',
    },
    {
      pageName: 'Home Healthcare Privacy',
      titleName: 'home_healthcare_privacy.metaTitle',
      descriptionName: 'home_healthcare_privacy.metaDescription',
      keyWordsName: 'home_healthcare_privacy.metaKeywords',
      othersName: 'home_healthcare_privacy.others',
    },
    {
      pageName: 'Other Registration Page',
      titleName: 'other_registration_form.metaTitle',
      descriptionName: 'other_registration_form.metaDescription',
      keyWordsName: 'other_registration_form.metaKeywords',
      othersName: 'other_registration_form.others',
    },
    {
      pageName: 'Other Registration Terms',
      titleName: 'others_terms.metaTitle',
      descriptionName: 'others_terms.metaDescription',
      keyWordsName: 'others_terms.metaKeywords',
      othersName: 'others_terms.others',
    },
    {
      pageName: 'Others Registration Privacy',
      titleName: 'others_privacy.metaTitle',
      descriptionName: 'others_privacy.metaDescription',
      keyWordsName: 'others_privacy.metaKeywords',
      othersName: 'others_privacy.others',
    },
    {
      pageName: 'Franchise Programme Page',
      titleName: 'franchise_programme_page.metaTitle',
      descriptionName: 'franchise_programme_page.metaDescription',
      keyWordsName: 'franchise_programme_page.metaKeywords',
      othersName: 'franchise_programme_page.others',
    },
    {
      pageName: 'Franchise Programme Terms',
      titleName: 'franchise_programme_terms.metaTitle',
      descriptionName: 'franchise_programme_terms.metaDescription',
      keyWordsName: 'franchise_programme_terms.metaKeywords',
      othersName: 'franchise_programme_terms.others',
    },
    {
      pageName: 'Franchise Programme Privacy',
      titleName: 'franchise_programme_privacy.metaTitle',
      descriptionName: 'franchise_programme_privacy.metaDescription',
      keyWordsName: 'franchise_programme_privacy.metaKeywords',
      othersName: 'franchise_programme_privacy.others',
    },
  ]

  return (
    <>
      <div className="healtharticle">
        <div className="healtharticle-title ms-4">
          <Link
            to="/settings-social-media-link"
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: "15px",
            }}
          >
            <span style={{ paddingLeft: "5%" }}>Settings</span>
          </Link>
          <span style={{ fontSize: "15px", paddingLeft: "1%" }}>&#62;</span>
          <Link
            to=""
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: "15px",
              paddingLeft: "0.2%",
              width: "20%",
            }}
          >
            <span style={{ paddingLeft: "6%" }}>Meta Tags</span>
          </Link>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="carr-dep-form">
          <div className="meta-tags-scroll">
            {
              metaTagFields.map((data, idx) => {
                return (
                  <Fragment key={idx}>
                    <div
                      className="mt-3"
                      style={{ fontWeight: "bold", color: "#cb1b5b" }}
                    >
                      {data.pageName}
                    </div>
                    <div className="row car-dep-form mt-2">
                      <div className="col-6">
                        <label
                          className="car-dep-form-txt mb-2"
                          style={{ color: "#4B4853" }}
                          htmlFor=""
                        >
                          Meta Title
                        </label>
                        <input
                          className="form-control w-75"
                          type="text"
                          name=""
                          id="type"
                          {...register(data.titleName, { required: false })}
                          placeholder="Type here..."
                        />
                      </div>

                      <div className="col-6">
                        <label
                          className="car-dep-form-txt mb-2"
                          style={{ color: "#4B4853" }}
                          htmlFor=""
                        >
                          Meta Description
                        </label>
                        <input
                          className="form-control w-75"
                          type="text"
                          name=""
                          id="type"
                          {...register(data.descriptionName, { required: false })}
                          placeholder="Type here..."
                        />
                      </div>

                      <div className="col-6 mt-4">
                        <label
                          className="car-dep-form-txt mb-2"
                          style={{ color: "#4B4853" }}
                          htmlFor=""
                        >
                          Meta Keyword
                        </label>
                        <input
                          className="form-control w-75"
                          type="text"
                          name=""
                          id="type"
                          {...register(data.keyWordsName, { required: false })}
                          placeholder="Type here..."
                        />
                      </div>

                      <div className="col-6 mt-4">
                        <label
                          className="car-dep-form-txt mb-2"
                          style={{ color: "#4B4853" }}
                          htmlFor=""
                        >
                          Others
                        </label>
                        <textarea
                          className="form-control w-75"
                          style={{ height: "150px" }}
                          type="text"
                          name=""
                          id="type"
                          {...register(data.othersName, { required: false })}
                          placeholder="Type here..."
                        />
                      </div>
                    </div>
                  </Fragment>
                )
              })
            }
            <div className="car-dep-form-button mb-5 mt-5 submit-btn">
              <button type="submit">SUBMIT</button>
            </div>
          </div>
        </form>
      </div>
      {success && (
        <SucessModal
          page={"/meta-tags"}
          name="Meta tags"
          post="posted"
        />
      )}

      {editSuccess && (
        <SucessModal
          page={"/meta-tags"}
          name="Meta tags"
          post="updated"
        />
      )}
    </>
  );
};

export default MetaTags;
