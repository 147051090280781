import React from "react";

import Logout from "../Account/Logout/Logout";
import Header from "../Layout/Header/Header";
import "./home.scss";
import SideBarMenu from "./SidebarMenu";

const Home = ({ children }) => {

  return (
    <div className="home">
      <Header />
      <SideBarMenu />
      <main className="main-container">
        <div>{children}</div>
      </main>
      <Logout />
    </div>
  );
};

export default Home;
