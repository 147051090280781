import axios from "axios";
import { debounce } from 'lodash';
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router";
import apiCall from "../../../api";
import "./healthsy.scss";

import { FaAngleDown } from "react-icons/fa";
import { Link } from "react-router-dom";

// actions icons
import Moment from "moment";
import contact from "../../../assets/images/HealthSy/contact.png";
import newdelete from "../../../assets/images/HealthSy/delete.png";
import Deletepopup from "../../modal/Deletepopup";

import { AiOutlineBackward, AiOutlineForward } from "react-icons/ai";
import { instaDocFilterOptions } from "../../../constants";
import FilterOptions from "../../Common/doctorsFilterOptions";
import ExcelDownload from "../../Common/excelDownload";
import FilterModal from "../../Common/filterModal";
import FilterRootModal from "../../Common/filterRootModal";
import ExcelDownloadModal from "../../masters/Common/excelDownloadAccess";
import AssignedTo from "../../modal/AssignedTo";
import LeadStatus from "../../modal/LeadStatus";
import LeadStatusConfirm from "../../modal/LeadStatusConfirm";
import SucessModal from "../../modal/SucessModal";

const columnOrder = [
  "S.No",
  "Time of Registration",
  "Date of Registration",
  "Name of the Doctor",
  "Contact Number",
  "Email ID",
  "Degree",
  "Other Education",
  "Gender",
  "Primary Specialisation",
  "MCI / SMC Number",
  "Year of Registration with Medical Council",
  "Are you okay to receive online consultations from 9 PM - 9 AM ( Night - Day )",
  "State",
  "City",
  "Location",
  "Pincode",
  "Language",
  "BD Name",
  "App Download Android Click",
  "Brochure Icon Click Status (1.0)",
  "Assigned To",
  "Lead Status"
]

const InstaDocRegistration = () => {

  const navigate = useNavigate();

  //filter portion
  const [filtershow, setFilterShow] = useState(false);
  const [textcolor, setTextColor] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState("newest");
  const [sortshow, setSortShow] = useState(false);
  const [sortName, setSortName] = useState("Sort by");
  const [pages, setPages] = useState(0);

  const [filter,] = useState({ name: "" });
  const [filtered_records, setFilteredRecords] = useState([]);
  const [assignModal, setAssignModal] = useState({ id: "", status: false })
  const [leadStatusModal, setLeadStatusModal] = useState({ id: "", status: false })
  const [success, setSuccess] = useState(false);
  const [leadStatussuccess, setLeadStatusSuccess] = useState(false);
  const [records, setRecords] = useState([]);
  const [leadStatusConfirmModal, setLeadStatusConfirmModal] = useState({ leadType: "", status: false, id: "" })
  const [leadStatus, setLeadStatus] = useState([]);
  const [filterLeadStatus, setFilterLeadStatus] = useState([]);
  const [bdModal, setBdModal] = useState([]);
  const [filterBdModal, setFilterBdModal] = useState([]);
  const [skip, setSkip] = useState(0);
  const [pageOffset, setPageOffset] = useState(0);
  const [search, setSearch] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);


  useEffect(() => {
    // getInstaDocList();
    getLeadStatus();
    getBdModal();
  }, []);

  useEffect(() => {
    list_api_call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, sort, skip, filter, search]);

  const handleDoctor = () => {
    navigate("/doctor-registration");
  };
  const handleBookDemo = () => {
    navigate("/doctor-demo");
  };
  const handleService = () => {
    navigate("/healthcare-registration");
  };
  const handleRetail = () => {
    navigate("/pharmacy-registration");
  };
  const handleOthers = () => {
    navigate("/other-registration");
  };

  const getLeadStatus = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}lead-status/list`)
      .then((res) => {
        setLeadStatus(res.data.rows.map((lead) => lead?.type));
      });
  };

  const getBdModal = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}bd/list`)
      .then((res) => {
        setBdModal(res.data.rows.map((data) => data?.bd_name));
      });
  };

  const filter_call = async () => {
    const result = await apiCall(`insta-doc-registration/list`, "POST",
      {
        values: {
          lead_status: filterLeadStatus,
          assigned_to: filterBdModal
        },
        skip: skip,
        limit: limit,
        sort: sort,
        searchText: search,
      })
    setRecords(result.data.rows);
    setPages(Math.ceil(result.data.count / limit));
  };

  const list_api_call = async (clearFilter = false) => {
    const result = await apiCall(`insta-doc-registration/list`, "POST",
      {
        skip: skip,
        limit: limit,
        sort: sort,
        searchText: search,
        values: clearFilter ? {} : {
          lead_status: filterLeadStatus,
          assigned_to: filterBdModal
        },
      })
    setFilteredRecords([]);
    setRecords(result.data?.rows);
    // setRowCount(result.data.count);
    setPages(Math.ceil(result.data.count / limit));
  };

  const onClickDownload = async () => {
    try {
      const response = await axios
        .post(`${process.env.REACT_APP_API_URL}insta-doc-registration/list`, {
          skip: 0,
          limit: 3000,
          searchText: search,
          values: {
            lead_status: filterLeadStatus,
            assigned_to: filterBdModal,
          },
          sort: sort
        })
      if (!response?.data?.rows?.length) {
        throw new Error("No records available to download.");
      }
      const records = response.data.rows
      const formattedRecords = records.map((record, index) => ({
        "S.No": index + 1,
        "Time of Registration": record.createdAt ? new Date(record.createdAt).toLocaleTimeString() : "",
        "Date of Registration": record.createdAt ? new Date(record.createdAt).toLocaleDateString() : "",
        "Name of the Doctor": record.name || "",
        "Contact Number": record.mobile || "",
        "Email ID": record.email || "",
        "Degree": record.degree || "",
        "Other Education": record.other_course || "",
        "Gender": record.gender || "",
        "Primary Specialisation": record.specialization || "",
        "MCI / SMC Number": record.mci || "",
        "Year of Registration with Medical Council": record.registered_year || "",
        "Are you okay to receive online consultations from 9 PM - 9 AM ( Night - Day )": record.receive_online_consultations || "",
        "State": record.state || "",
        "City": record.city || "",
        "Location": record.location || "",
        "Pincode": record.pincode || "",
        "Language": record.languages_known[0] || "",
        "BD Name": "",
        "App Download Android Click": record.android_click || 0,
        "Brochure Icon Click Status (1.0)": record.brochure_click || 0,
        "Assigned To": record.assigned_to || "",
        "Lead Status": record.lead_status || ""
        // Actions: "",
      }));

      ExcelDownload(formattedRecords, columnOrder, "instaDoc-registration.xlsx")
      console.log("File downloaded successfully.");

    } catch (err) {
      console.error("Error downloading the file while getting all Doctor registration records", err, "err.message", err?.message)
      // console.error("Error downloading the file:", err.message);
      alert(err?.message || "An error occurred while downloading the file.");
    }
  }

  //Actions

  //Delete Functionality
  const [del, setDel] = useState(false);
  const [id, setId] = useState("");

  const handleDelete = (id) => {
    fetch(`${process.env.REACT_APP_API_URL}insta-doc-registration/delete/${id}`, {
      method: "DELETE",
    }).then((result) => {
      result.json().then((res) => {
        list_api_call();
        // getInstaDocList();
        setDel(false);
      });
    });
  };

  const successModalClose = () => {
    setSuccess(false)
    setLeadStatusSuccess(false)
    // getInstaDocList()
    list_api_call();
  }

  // const filter_records = (filter) => {
  //   let rows = records.filter((el) => {
  //     // return JSON.stringify(el).indexOf(filter) > -1;
  //     return JSON.stringify(el).toLowerCase().indexOf(filter.toLowerCase()) > -1;
  //   });
  //   setFilteredRecords(rows);
  // };

  const handlePageChange = (event) => {
    setSkip(event.selected * limit)
    setPageOffset(event.selected);
  };

  const debouncedSearch = debounce((value) => {
    handlePageChange({ selected: 0 })
    setSearch(value); // Update the state after debounce
  }, 500);

  const onChangeSearch = (value) => {
    debouncedSearch(value); // Trigger the debounced function
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <div className="healtharticle ps-3">
        <div className="healtharticle-container ">
          <div className="healtharticle-title ms-0">
            <span>
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to="/dashboard"
              >
                Dashboard
              </Link>
            </span>
            <span>&#62;</span>
            <span>
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to="/doctor-registration"
              >
                HealthSynergy
              </Link>
            </span>
            <span style={{ paddingLeft: "0.4%" }}>&#62;</span>
            <span style={{ paddingLeft: "0.4%" }}>
              InstaDoc
            </span>
          </div>

          <div className="careers-tab  ">
            <div className="career-tab-jobpost" onClick={handleDoctor}>Doctors</div>
            <div className="career-tab-jobpost" onClick={handleRetail}>
              Retail Pharmacy
            </div>
            <div className="career-post-department" onClick={handleService}>
              Home Healthcare Service Providers
            </div>
            <div className="career-post-department pink-line">
              InstaDoc
            </div>
            <div className="career-post-position other " onClick={handleOthers}>
              Others
            </div>
            <div
              className="career-post-position "
              style={{ color: "#65626C" }}
              onClick={handleBookDemo}
            >
              Book a Demo
            </div>
          </div>
        </div>
        <FilterOptions
          limit={limit}
          sortshow={sortshow}
          sortName={sortName}
          search={search}
          setFilterShow={setFilterShow}
          setSortShow={setSortShow}
          onChange={onChangeSearch}
          setLimit={setLimit}
          downloadExcel={true}
          onClickDownload={showModal}
        />
        <div className="healtharticle-table instadoc-table ms-2">
          <table>
            <thead className="">
              <tr>
                <td className="sno ">S.No</td>
                <td className="sno ">Time of Registration</td>
                <td className="sno ">Date of Registration</td>
                <td className="align-middle">Name of the Doctor</td>
                <td className="align-middle">Contact Number</td>
                <td className="align-middle">Email ID</td>
                <td className="align-middle">Degree</td>
                <td className="align-middle">Other Education</td>
                <td className="align-middle">Gender</td>
                <td className="align-middle">Primary Specialisation</td>
                <td className="align-middle meddical-reg-no"> MCI / SMC Number</td>
                <td className="align-middle">Year of Registration with Medical Council</td>
                <td className="align-middle">Are you okay to receive online consultations from 9 PM - 9 AM ( Night - Day )</td>
                <td className="align-middle">State</td>
                <td className="align-middle">City</td>
                <td className="align-middle">Location</td>
                <td className="align-middle">Pincode</td>
                <td className="align-middle">Language</td>
                <td className="align-middle">BD Name</td>
                <td className="align-middle">App Download Android Click</td>
                <td className="align-middle">Brochure Icon Click Status (1.0)</td>
                <td className="align-middle">Assigned To</td>
                <td className="align-middle">Lead Status</td>
                <td className="align-middle">Actions</td>
              </tr>
            </thead>
            <tbody>
              {(filtered_records.length > 0 ? filtered_records : records).map(
                (x, i) => {
                  if (x.show === undefined || x.show === true) {
                    return (
                      <tr key={x._id}>
                        <td>{skip + (i + 1)}</td>
                        <td>{Moment(x.createdAt).format("LT")}</td>
                        <td>{Moment(x.createdAt).format("DD-MM-YYYY")}</td>
                        <td>{x.name}</td>
                        <td>{x.mobile}</td>
                        <td style={{ width: "150px" }}>{x.email}</td>
                        <td>{x.degree ? x.degree : "-"}</td>
                        <td>{x.other_course ? x.other_course : "-"}</td>
                        <td>{x.gender}</td>
                        <td>{x.specialization ? x.specialization : "-"}</td>
                        <td>{x.mci}</td>
                        <td>{x.registered_year}</td>
                        <td>{x.receive_online_consultations}</td>
                        <td>{x.state ? x.state : "-"}</td>
                        <td>{x.city}</td>
                        <td>{x.location}</td>
                        <td>{x.pincode}</td>
                        <td>{x?.languages_known?.join(", ")}</td>
                        <td>{"-"}</td>
                        <td>{x.android_click}</td>
                        <td>{x.brochure_click}</td>
                        <td>{x.assigned_to ? x.assigned_to : "-"}</td>
                        <td>{x.lead_status ? x.lead_status : "-"}</td>
                        <td>
                          <span
                            className="pe-1 primaryColor ps-2"
                            onClick={() => setAssignModal({ id: x._id, status: true })}
                          >
                            Assigned
                          </span>
                          <span className="pe-1">
                            <FaAngleDown className="drop-down-arrow" />
                          </span>
                          <span
                            className="pe-1 primaryColor ps-2"
                            onClick={() => setLeadStatusModal({ id: x._id, status: true })}
                          >
                            Lead Status
                          </span>
                          <span className="pe-1">
                            <FaAngleDown className="drop-down-arrow" />
                          </span>
                          <span
                            className="text-primary cursorPointer ms-2"
                            onClick={() => { navigate(`/insta-doc-registration-edit/${x._id}`) }}
                          >
                            Edit
                          </span>
                          <span className="contact-logo">
                            <img src={contact} alt="" />
                          </span>
                          <span
                            className="newdelete"
                            onClick={() => {
                              setId(x._id);
                              setDel(true);
                            }}
                          >
                            Delete
                          </span>
                          <span className="newdelete-logo">
                            <img src={newdelete} alt="" />
                          </span>
                        </td>
                      </tr>
                    );
                  } else {
                    return <></>
                  }
                }
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <div className="mb-5 col d-flex pagination justify-content-end admin-pagination">
          <button
            className="page-link"
            onClick={() => handlePageChange({ selected: 0 })}

            disabled={skip === 0}
          >
            <AiOutlineBackward size={20} />
          </button>
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            pageCount={pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName="pagination my-0"
            activeClassName="active"
            forcePage={pageOffset}
          />
          <button
            className="page-link"
            onClick={() => handlePageChange({ selected: pages - 1 })}

            disabled={(skip / limit) === (pages - 1)}
          >
            <AiOutlineForward size={20} />
          </button>
        </div>
      </div>

      {del === true && <Deletepopup handleDelete={handleDelete} id={id} />}

      {filtershow ? (
        <FilterRootModal
          filterOptions={instaDocFilterOptions}
          onClickClear={() => {
            setFilterShow(false);
            setFilterLeadStatus([]);
            setFilterBdModal([]);
            list_api_call(true);
          }}
          onClickApply={() => {
            setFilterShow(false);
            filter_call()
          }}
          selectOption={textcolor}
          setSelectOption={setTextColor}
        />
      ) : null}

      {textcolor === 1 && filtershow === true ? (
        <FilterModal
          title={"Lead Status"}
          data={leadStatus}
          className="filterModelNestedLevel"
          activeFilters={filterLeadStatus}
          onChange={(e) => {
            if (filterLeadStatus.includes(e.target.name)) {
              setFilterLeadStatus(filterLeadStatus.filter((ele) => ele !== e.target.name))
            } else {
              setFilterLeadStatus((pre) => [...pre, e.target.name])

            }
          }}
        />
      ) : null}
      {/* -------BD-------- */}
      {textcolor === 2 && filtershow === true ? (
        <FilterModal
          title={"BD"}
          data={bdModal}
          className="filterModelNestedLevel"
          activeFilters={filterBdModal}
          onChange={(e) => {
            if (filterBdModal.includes(e.target.name)) {
              setFilterBdModal(filterBdModal.filter((ele) => ele !== e.target.name))
            } else {
              setFilterBdModal((pre) => [...pre, e.target.name])
            }
          }}
        />
      ) : null}

      {sortshow ? (
        <div className="sortby-Common">
          <div
            onClick={() => {
              setTextColor(11);
              setSortShow(false);
              setSort("oldest");
              setSortName("Oldest");
            }}
            style={{
              color: textcolor === 11 ? "#CB1B5B" : "",
              fontWeight: textcolor === 11 ? "bold" : "",
            }}
          >
            Oldest
          </div>
          <div
            onClick={() => {
              setTextColor(12);
              setSortShow(false);
              setSort("newest");
              setSortName("Newest");
            }}
            style={{
              color: textcolor === 12 ? "#CB1B5B" : "",
              fontWeight: textcolor === 12 ? "bold" : "",
            }}
          >
            Newest
          </div>
          <div
            onClick={() => {
              setTextColor(13);
              setSortShow(false);
              setSort("ascending");
              setSortName("Ascending");
            }}
            style={{
              color: textcolor === 13 ? "#CB1B5B" : "",
              fontWeight: textcolor === 13 ? "bold" : "",
            }}
          >
            Alphabetical ↑
          </div>
          <div
            onClick={() => {
              setTextColor(14);
              setSortShow(false);
              setSort("descending");
              setSortName("Descending");
            }}
            style={{
              color: textcolor === 14 ? "#CB1B5B" : "",
              fontWeight: textcolor === 14 ? "bold" : "",
            }}
          >
            Alphabetical ↓
          </div>
        </div>
      ) : null}

      {assignModal.status === true && (
        <AssignedTo
          id={assignModal?.id}
          setAssignModal={setAssignModal}
          setSuccess={setSuccess}
          pathName="/insta-doc"
        />
      )}

      {success && (
        <SucessModal
          name="BD"
          post="Assigned"
          onClick={() => successModalClose()}
        />
      )}

      {leadStatusModal.status === true && (
        <LeadStatus
          id={leadStatusModal?.id}
          setLeadStatusModal={setLeadStatusModal}
          setLeadStatusSuccess={setLeadStatusSuccess}
          setLeadStatusConfirmModal={setLeadStatusConfirmModal}
        />
      )}
      {leadStatusConfirmModal.status === true && (
        <LeadStatusConfirm
          id={leadStatusConfirmModal?.leadType}
          setLeadStatusConfirmModal={setLeadStatusConfirmModal}
          setLeadStatusSuccess={setLeadStatusSuccess}
          leadStatusConfirmModal={leadStatusConfirmModal}
          formType="insta-doc-registration"
        />
      )}
      {leadStatussuccess && (
        <SucessModal
          name="Lead Status"
          post="Updated"
          onClick={() => successModalClose()}
        />
      )}
      <ExcelDownloadModal onClickDownload={onClickDownload} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </>
  );
};

export default InstaDocRegistration;
