import axios from "axios";
import { useEffect, useState } from "react";

import search from "../../assets/images/searchIcon.png";
import filter1 from "../../assets/svg/Health Article/filtericon.svg";
import sort1 from "../../assets/svg/Health Article/sortbyicon.svg";
import Breadcrumbs from "../Common/Breadcrumbs/Breadcrumbs";
import Deletepopup from "../modal/Deletepopup";

const SustainabilityPolicy = () => {
    const [feedBackList, setFeedBackList] = useState([]);
    const [pages,] = useState(0);
    const [current_page,] = useState(1);

    const [deleteModal, setDeleteModal] = useState({ id: "", status: false });

    useEffect(() => {
        getFeedBackList()
    }, []);

    const getFeedBackList = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}sustainability-feedback/sustainability-feedback-list`)
            .then((response) => {
                setFeedBackList(response?.data);
            });
    }

    const handleDelete = (id) => {
        axios
            .delete(`${process.env.REACT_APP_API_URL}sustainability-feedback/sustainability-feedback-delete/${id}`)
            .then(() => {
                getFeedBackList()
                setDeleteModal({ id: "", status: false });
            });
    };

    const handleClose = () => {
        setDeleteModal({ id: "", status: false });
    };

    return (
        <>
            <div className="mt-4 p-4 bg-white">
                <Breadcrumbs
                    breadcrumbText="Sustainability Policy"
                    breadcrumbLink="/sustainability-policy"
                />
                <div className="healtharticle-show m-0 ps-4">
                    <div>Show</div>
                    <div>
                        <select
                            className="me-2"
                        >
                            <option>10</option>
                            <option>25</option>
                            <option>50</option>
                            <option>100</option>
                            <option value="0">All</option>
                        </select>
                    </div>
                    <div>entries</div>
                    <div>
                        <div>
                            <img src={search} alt="" />
                        </div>
                        <div>
                            <input
                                type="text"
                                placeholder="Search"
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="sortandfilter-new">
                        <div className="sortByPositionNew">
                            <div>
                                <div>Sort by</div>
                                <div>
                                    <img src={sort1} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="sortByDepartment">
                            <div>
                                <div>
                                    <div>
                                        Filter
                                    </div>
                                </div>
                                <div>
                                    <img src={filter1} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white">
                    {feedBackList?.length ? (
                        <div className="adLandingFormsTable ms-4">
                            <table className="text-center">
                                <thead>
                                    <tr>
                                        <td className="">S.No</td>
                                        <td className="">Name</td>
                                        <td className="">Email ID</td>
                                        <td className="">Mobile No</td>
                                        <td className="">Feedback</td>
                                        <td className="">Actions</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {feedBackList?.map((el, i) => {
                                        return (
                                            <tr key={el._id}>
                                                <td>{i + 1}</td>
                                                <td>{el.name}</td>
                                                <td>{el.email}</td>
                                                <td>{el.mobile}</td>
                                                <td>{el.feedback}</td>
                                                <td>
                                                    <span
                                                        className="pe-1"
                                                        onClick={() => setDeleteModal({ id: el._id, status: true })}
                                                    >
                                                        Delete
                                                    </span>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div className="mt-4">No Feedback Forms are registered </div>
                    )}
                </div>
                <div className="col d-flex pagination admin-pagination justify-content-end">
                    <input
                        type="button"
                        className="page-item page-link"
                        value="<<"
                        // onClick={(e) => setCurrentPage(1)}
                        disabled={current_page === 1}
                    />
                    <input
                        type="button"
                        className="page-item page-link"
                        value="<"
                        // onClick={(e) => setCurrentPage(current_page - 1)}
                        disabled={current_page === 1}
                    />

                    {current_page > 1 && (
                        <input
                            type="button"
                            value={current_page - 1}
                            onClick={(e) => {
                                // setCurrentPage(current_page - 1);
                            }}
                        />
                    )}
                    <input
                        className="page-item page-link"
                        type="button"
                        value={current_page}
                    />
                    {current_page < pages && (
                        <input
                            type="button"
                            value={current_page + 1}
                            onClick={(e) => {
                                // setCurrentPage(current_page + 1);
                            }}
                        />
                    )}
                    <input
                        type="button"
                        value=">"
                        className="page-item page-link"
                        // onClick={(e) => setCurrentPage(current_page + 1)}
                        disabled={current_page === pages}
                    />
                    <input
                        type="button"
                        value=">>"
                        className="page-link"
                        // onClick={(e) => setCurrentPage(pages)}
                        disabled={current_page === pages}
                    />
                </div>
            </div>
            {deleteModal.status === true && (
                <Deletepopup
                    handleDelete={handleDelete}
                    id={deleteModal?.id}
                    isCloseModalEnable={true}
                    handleClose={handleClose}
                />
            )}
        </>
    );
};

export default SustainabilityPolicy;