import axios from "axios";
import { debounce } from 'lodash';
import { default as Moment, default as moment } from "moment";
import React, { useEffect, useState } from "react";
import Autocomplete from "react-google-autocomplete";
import { AiOutlineBackward, AiOutlineForward } from "react-icons/ai";
import { FaAngleDown } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import apiCall from "../../../api";
import contact from "../../../assets/images/HealthSy/contact.png";
import newdelete from "../../../assets/images/HealthSy/delete.png";
import searchImg from "../../../assets/images/searchIcon.png";
import { businessType, Inventory, pharmacyFilterOptions, sizeofPharmacy } from "../../../constants";
import FilterOptions from "../../Common/doctorsFilterOptions";
import ExcelDownload from "../../Common/excelDownload";
import FilterModal from "../../Common/filterModal";
import FilterRootModal from "../../Common/filterRootModal";
import ExcelDownloadModal from "../../masters/Common/excelDownloadAccess";
import AssignedTo from "../../modal/AssignedTo";
import Deletepopup from "../../modal/Deletepopup";
import LeadStatus from "../../modal/LeadStatus";
import LeadStatusConfirm from "../../modal/LeadStatusConfirm";
import SucessModal from "../../modal/SucessModal";

const columnOrder = [
  "S.No",
  "Time of Registration",
  "Date of Registration",
  "First Name",
  "Last Name",
  "Pharmacy Name",
  "Mobile Number",
  "Email ID",
  "Pharmacy Business Type",
  "Company Name",
  "Pharmacy GST Number",
  "Drug License Number",
  "FSSAI Number",
  "Pharmacist Name",
  "Your pharmacist's license number",
  "State",
  "City",
  "Pharmacy Location",
  "Address",
  "Pincode",
  "Medicines Listing Fee (%)",
  "OTC Listing Fee (%)",
  "What is the approximate size of your pharmacy store?",
  "What is the value of inventory you have in your pharmacy store?",
  "How many pharmacists are working in your pharmacy?",
  "Do you have a delivery person / staff to deliver orders to your customers?",
  "Do you have Poc for your retail pharmacy store",
  "Poc Name",
  "Poc Number",
  "Are you currently part of any online pharmacy / health-tech platforms?",
  "Do you have a wholesale license?",
  "Do you currently use any billing / sales / ERP software?",
  "Pharmacy Available",
  "Pharmacy Start Time",
  "Pharmacy End Time",
  "BD Name",
  "App Download Android Click",
  "Brochure Icon Click Status (1.0)",
  "Assigned To",
  "Lead Status",
  // "Actions"
]

const RetailPharmacy = () => {

  const [records, setRecords] = useState([]);
  const [filtered_records, setFilteredRecords] = useState([]);
  const [pages, setPages] = useState(0);
  const navigate = useNavigate();
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState("newest");
  const [sortName, setSortName] = useState("Sort by");

  //filter portion
  const [filtershow, setFilterShow] = useState(false);
  const [sortshow, setSortShow] = useState(false);
  const [textcolor, setTextColor] = useState(0);

  const [inventorydata, setInventoryData] = useState([]);
  const [sizepharmdata, setSizePharmData] = useState([]);
  const [businessdata, setBusinessData] = useState([]);
  const [location, setLocation] = useState("");

  const [assignModal, setAssignModal] = useState({ id: "", status: false })
  const [leadStatusModal, setLeadStatusModal] = useState({ id: "", status: false })
  const [success, setSuccess] = useState(false);
  const [leadStatussuccess, setLeadStatusSuccess] = useState(false);
  const [leadStatusConfirmModal, setLeadStatusConfirmModal] = useState({ leadType: "", status: false, id: "" })
  const [leadStatus, setLeadStatus] = useState([]);
  const [filterLeadStatus, setFilterLeadStatus] = useState([]);
  const [bdModal, setBdModal] = useState([]);
  const [filterBdModal, setFilterBdModal] = useState([]);
  const [pageOffset, setPageOffset] = useState(0);
  const [del, setDel] = useState(false);
  const [id, setId] = useState("");
  const [search, setSearch] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    getLeadStatus()
    getBdModal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    list_api_call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, sort, skip, search]);


  const handleDoctor = () => {
    navigate("/doctor-registration");
  };
  const handleService = () => {
    navigate("/healthcare-registration");
  };
  const handleOthers = () => {
    navigate("/other-registration");
  };
  const handleBookDemo = () => {
    navigate("/doctor-demo");
  };
  const handleInstaDoc = () => {
    navigate("/insta-doc");
  };

  const getLeadStatus = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}lead-status/list`)
      .then((res) => {
        setLeadStatus(res.data.rows.map((lead) => lead?.type));
      });
  };

  const getBdModal = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}bd/list`)
      .then((res) => {
        setBdModal(res.data.rows.map((data) => data?.bd_name));
      });
  };

  const filter_Location_Search_call = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}filters/location`, {
        key: "insta_doc",
        values: {
          location: location
        }
      })
      .then((res) => {
        setRecords(res.data)
      })
  }

  useEffect(() => {
    if (location) {
      filter_Location_Search_call()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const filter_call = async () => {
    const result = await apiCall(`pharmacy-registration/list`, "POST",
      {
        values: {
          value_of_inventory: inventorydata,
          business_type: businessdata,
          size: sizepharmdata,
          pharmacy_town: location,
          lead_status: filterLeadStatus,
          assigned_to: filterBdModal
        },
        skip: skip,
        limit: limit,
        sort: sort,
        searchText: search
      })
    setRecords(result.data.rows);
    setPages(Math.ceil(result.data.count / limit));
  };

  const list_api_call = async (clearFilter = false) => {
    const result = await apiCall(`pharmacy-registration/list`, "POST",
      {
        // ...filter,
        skip: skip,
        limit: limit,
        // key: "retail_pharmacy",
        sort: sort,
        searchText: search,
        values: clearFilter ? {} : {
          value_of_inventory: inventorydata,
          business_type: businessdata,
          size: sizepharmdata,
          pharmacy_town: location,
          lead_status: filterLeadStatus,
          assigned_to: filterBdModal
        },
      })
    setFilteredRecords([]);
    setRecords(result.data?.rows);
    // setRowCount(result.data.count);
    setPages(Math.ceil(result.data.count / limit));
  };

  const handlePageChange = (event) => {
    setSkip(event.selected * limit)
    setPageOffset(event.selected);
  };

  const debouncedSearch = debounce((value) => {
    handlePageChange({ selected: 0 })
    setSearch(value);
  }, 500);

  const onChangeSearch = (value) => {
    debouncedSearch(value)
  }

  //Actions

  //Delete Functionality

  const handleDelete = (id) => {
    fetch(
      `${process.env.REACT_APP_API_URL}pharmacy-registration/delete/${id}`,
      {
        method: "DELETE",
      }
    ).then((result) => {
      result.json().then((res) => {
        list_api_call();
        setDel(false);
      });
    });
  };

  const successModalClose = () => {
    setSuccess(false)
    setLeadStatusSuccess(false)
    list_api_call();
  }
  const onClickDownload = async () => {
    try {
      const response = await axios
        .post(`${process.env.REACT_APP_API_URL}pharmacy-registration/list`, {
          skip: 0,
          limit: 3000,
          searchText: search,
          values: {
            value_of_inventory: inventorydata,
            business_type: businessdata,
            size: sizepharmdata,
            pharmacy_town: location,
            lead_status: filterLeadStatus,
            assigned_to: filterBdModal,
          },
          sort: sort,
        })
      if (!response?.data?.rows?.length) {
        throw new Error("No records available to download.");
      }
      const records = response.data.rows
      const formattedRecords = records.map((record, index) => ({
        "S.No": index + 1,
        "Time of Registration": record.createdAt ? new Date(record.createdAt).toLocaleTimeString() : "",
        "Date of Registration": record.createdAt ? new Date(record.createdAt).toLocaleDateString() : "",
        "First Name": record.first_name || "",
        "Last Name": record.last_name || "",
        "Pharmacy Name": record.pharmacy_name || "",
        "Mobile Number": record.mobile || "",
        "Email ID": record.email || "",
        "Pharmacy Business Type": record.business_type || "",
        "Company Name": record.company_name || "",
        "Pharmacy GST Number": record.gst_no || "",
        "Drug License Number": record.pharmacy_registration_number || "",
        "FSSAI Number": record.fssai_license_no || "",
        "Pharmacist Name": "Unknown", // Replace with actual pharmacist name if available
        "Your pharmacist's license number": record.pharmacist_license_number || "",
        "State": record.state || "",
        "City": record.pharmacy_town || "",
        "Pharmacy Location": record.pharmacy_address || "",
        "Address": record.pharmacy_address || "",
        "Pincode": record.pincode || "",
        "Medicines Listing Fee (%)": "N/A", // Add logic if available
        "OTC Listing Fee (%)": record.discount_on_OTC || "",
        "What is the approximate size of your pharmacy store?": record.size || "",
        "What is the value of inventory you have in your pharmacy store?": record.value_of_inventory || "",
        "How many pharmacists are working in your pharmacy?": record.pharmacists_count || "",
        "Do you have a delivery person / staff to deliver orders to your customers?": record.have_delivery_person || "",
        "Do you have Poc for your retail pharmacy store": "No", // Replace with actual PoC details if available
        "Poc Name": "",
        "Poc Number": "",
        "Are you currently part of any online pharmacy / health-tech platforms?": record.online_pharmacy || "",
        "Do you have a wholesale license?": record.have_wholesale_license || "",
        "Do you currently use any billing / sales / ERP software?": record.billing_software || "",
        "Pharmacy Available": "N/A", // Add logic if available
        "Pharmacy Start Time": "N/A", // Add logic if available
        "Pharmacy End Time": "N/A", // Add logic if available
        "BD Name": "N/A", // Replace with actual BD Name if available
        "App Download Android Click": record.android_click || 0,
        "Brochure Icon Click Status (1.0)": record.brochure_click || 0,
        "Assigned To": record.assigned_to || "",
        "Lead Status": record.lead_status || "",
        // "Actions": "", // Uncomment if action logic needs to be added
      }));


      ExcelDownload(formattedRecords, columnOrder, "pharmacy-registrations.xlsx")
      console.log("File downloaded successfully.");

    } catch (err) {
      console.error("Error downloading the file while getting all Doctor registration records", err, "err.message", err?.message)
      // console.error("Error downloading the file:", err.message);
      alert(err?.message || "An error occurred while downloading the file.");
    }
  }

  const showModal = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      {/* <Home> */}
      <div className="healtharticle">
        <div className="healtharticle-container">
          <div className="top-container ps-3 ">
            <div className="row">
              <div className="healtharticle-title ms-0">
                <span>
                  <Link
                    style={{ textDecoration: "none", color: "black" }}
                    to="/dashboard"
                  >
                    Dashboard
                  </Link>
                </span>
                <span className="bread-crumb" style={{ paddingLeft: "0.4%" }}>
                  &#62;
                </span>
                <span>
                  <Link
                    style={{ textDecoration: "none", color: "black" }}
                    to="/doctor-registration"
                  >
                    HealthSynergy
                  </Link>
                </span>
                <span className="bread-crumb1" style={{ paddingLeft: "0.4%" }}>
                  &#62;
                </span>
                <span style={{ paddingLeft: "0.4%" }}>
                  HealthSy Partnered Pharmacy Network Programme
                </span>
              </div>
            </div>

            <div className="careers-tab  ">
              <div className="career-tab-career " onClick={handleDoctor}>
                Doctors
              </div>
              <div className="career-tab-jobpost  pink-line">Retail Pharmacy</div>
              <div className="career-post-department" onClick={handleService}>
                Home Healthcare Service Providers
              </div>
              <div className="career-post-department" onClick={handleInstaDoc}>
                InstaDoc
              </div>
              <div
                className="career-post-position  other"
                onClick={handleOthers}
              >
                Others
              </div>
              <div
                className="career-post-position"
                onClick={handleBookDemo}
                style={{ color: "#65626C" }}
              >
                Book a Demo
              </div>
            </div>
            <FilterOptions
              limit={limit}
              sortshow={sortshow}
              sortName={sortName}
              search={search}
              setFilterShow={setFilterShow}
              setSortShow={setSortShow}
              onChange={onChangeSearch}
              setLimit={setLimit}
              downloadExcel={true}
              onClickDownload={showModal}
            />
          </div>
          <div className="Pharmacy-Reg-Table ps-3 healtharticle-table ms-2">
            <table className="">
              <thead className="">
                <tr>
                  <td>S.No</td>
                  <td className="align-middle">Time of Registration</td>
                  <td className="align-middle">Date of Registration</td>
                  <td className="name">First Name</td>
                  <td>Last Name</td>
                  <td>Pharmacy Name</td>
                  <td>Mobile Number</td>
                  <td>Email ID</td>
                  <td>Pharmacy Business Type</td>
                  <td>Company Name <br />(If Pharmacy name & Company name is different)</td>
                  <td>Pharmacy GST Number</td>
                  <td>Drug License Number </td>
                  <td>FSSAI Number</td>
                  <td>Pharmacist Name</td>
                  <td>Your pharmacist's license number</td>
                  <td>State</td>
                  <td>City</td>
                  <td>Pharmacy Location</td>
                  <td>Address</td>
                  <td>Pincode</td>
                  <td>Medicines Listing Fee (%)</td>
                  <td>OTC Listing Fee (%)</td>
                  <td>What is the approximate size of your pharmacy store?</td>
                  <td>What is the value of inventory you have in your pharmacy store?</td>
                  <td>How many pharmacists are working in your pharmacy?</td>
                  <td>Do you have a delivery person / staff to deliver orders to your customers?</td>
                  <td>Do you have Poc for your retail pharmact store </td>
                  <td>Poc Name</td>
                  <td>Poc Number</td>
                  <td>Are you currently part of any online pharmacy / health-tech platforms?</td>
                  <td>Do you have a wholesale license?</td>
                  <td>Do you currently use any billing / sales / ERP software?</td>
                  <td>Pharmacy Available</td>
                  <td>Pharmacy Start Time</td>
                  <td>Pharmacy End Time</td>
                  {/* <td></td> */}
                  <td>BD Name</td>
                  <td>App Download Android Click</td>
                  <td>Brochure Icon Click Status (1.0)</td>
                  <td className="">Assigned To</td>
                  <td className="">Lead Status</td>
                  <td>Actions</td>
                </tr>
              </thead>
              <tbody>
                {(filtered_records.length > 0 ? filtered_records : records).map(
                  (x, i) => {
                    if (x.show === undefined || x.show === true) {
                      return (
                        <tr key={x._id}>
                          <td>{skip + (i + 1)}</td>
                          <td>{Moment(x.createdAt).format("LT")}</td>
                          <td>{Moment(x.createdAt).format("DD-MM-YYYY")}</td>
                          <td>{x.first_name ? x.first_name : x.name}</td>
                          <td>{x.last_name ? x.last_name : "-"}</td>
                          <td>{x.pharmacy_owner_name ? x.pharmacy_owner_name : x.pharmacy_name}</td>
                          <td>{x.mobile}</td>
                          <td>{x.email}</td>
                          <td>{x.business_type}</td>
                          <td>{x.company_name ? x.company_name : "-"}</td>
                          <td>{x.gst_no}</td>
                          <td>{x.pharmacy_registration_number ? x.pharmacy_registration_number : "-"}</td>
                          <td>{x.fssai_license_no ? x.fssai_license_no : "-"}</td>
                          <td>{x.pharmacist_name}</td>
                          <td>{x.pharmacist_license_number}</td>
                          <td>{x.state ? x.state : "-"}</td>
                          <td>{x.pharmacy_town}</td>
                          <td>{x.pharmacy_location}</td>
                          <td>{x.pharmacy_address}</td>
                          <td>{x.pincode}</td>
                          <td>{x.discounts_on_products}</td>
                          <td>{x.discount_on_OTC}</td>
                          <td>{x.size}</td>
                          <td>{x.value_of_inventory}</td>
                          <td>{x.pharmacists_count}</td>
                          <td>{x.have_delivery_person}</td>
                          <td>{x.poc_retail_pharmacy_store ? x.poc_retail_pharmacy_store : "-"}</td>
                          <td>{x.poc_name ? x.poc_name : "-"}</td>
                          <td>{x.poc_number ? x.poc_number : "-"}</td>
                          <td>{x.online_pharmacy}</td>
                          <td>{x.have_wholesale_license}</td>
                          <td>{x.billing_software}</td>
                          <td>{x.pharmacy_available}</td>
                          <td>{x.pharmacy_start_timing ? moment(x.pharmacy_start_timing).format("h:mm A") : ""}</td>
                          <td>{x.pharmacy_end_timing ? moment(x.pharmacy_end_timing).format("h:mm A") : ""}</td>
                          <td>{"-"}</td>
                          <td>{x.android_click ? x.android_click : "-"}</td>
                          <td>{x.brochure_click ? x.brochure_click : "-"}</td>
                          <td>{x.assigned_to ? x.assigned_to : "-"}</td>
                          <td>{x.lead_status ? x.lead_status : "-"}</td>
                          <td>
                            <span
                              className="pe-1 primaryColor ps-2"
                              onClick={() => setAssignModal({ id: x._id, status: true })}
                            >
                              Assigned
                            </span>
                            <span className="pe-1">
                              <FaAngleDown className="drop-down-arrow" />
                            </span>
                            <span
                              className="pe-1 primaryColor ps-2"
                              onClick={() => setLeadStatusModal({ id: x._id, status: true })}
                            >
                              Lead Status
                            </span>
                            <span className="pe-1">
                              <FaAngleDown className="drop-down-arrow" />
                            </span>
                            <span
                              className="text-primary cursorPointer ps-2"
                              onClick={() => { navigate(`/pharmacy-registration-edit/${x._id}`) }}
                            >
                              Edit
                            </span>
                            <span className="contact-logo">
                              <img src={contact} alt="" />
                            </span>
                            <span
                              className="newdelete"
                              onClick={() => {
                                setId(x._id);
                                setDel(true);
                              }}
                            >
                              Delete
                            </span>
                            <span className="newdelete-logo">
                              <img src={newdelete} alt="" />
                            </span>
                          </td>
                        </tr>
                      );
                    } else {
                      return <></>
                    }
                  }
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div>
        <div className="mb-5 col d-flex pagination justify-content-end admin-pagination">
          <button
            className="page-link"
            onClick={() => handlePageChange({ selected: 0 })}

            disabled={skip === 0}
          >
            <AiOutlineBackward size={20} />
          </button>
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            pageCount={pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName="pagination my-0"
            activeClassName="active"
            forcePage={pageOffset}
          />
          <button
            className="page-link"
            onClick={() => handlePageChange({ selected: pages - 1 })}

            disabled={(skip / limit) === (pages - 1)}
          >
            <AiOutlineForward size={20} />
          </button>
        </div>
      </div>
      {/* </Home> */}
      {del === true && <Deletepopup handleDelete={handleDelete} id={id} />}
      {/*---------Filter Portion--------*/}
      {filtershow ? (
        <FilterRootModal
          filterOptions={pharmacyFilterOptions}
          onClickClear={() => {
            list_api_call(true)
            setFilterShow(false);
            setInventoryData([]);
            setLocation("");
            setBusinessData([]);
            setFilterLeadStatus([]);
            setFilterBdModal([]);
          }}
          onClickApply={() => {
            setFilterShow(false);
            filter_call()
          }}
          selectOption={textcolor}
          setSelectOption={setTextColor}
        />
      ) : null}

      {/*---------Inventory Portion--------*/}
      {textcolor === 1 && filtershow === true ? (
        <FilterModal
          title={"Inventory"}
          data={Inventory}
          className="filterModelNestedLevel"
          activeFilters={inventorydata}
          onChange={(e) => {
            if (inventorydata.includes(e.target.name)) {
              setInventoryData(inventorydata.filter((ele) => ele !== e.target.name))
            } else {
              setInventoryData((pre) => [...pre, e.target.name])
            }
          }}
        />
      ) : null}
      {/*---------City / Town / Location Portion--------*/}
      {textcolor === 2 && filtershow === true ? (
        <div className="filter-doctor-fee">
          <div className="filter-doctor-fee-header">City / Town / Location</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            <div className="filter-doctor-search">
              <div>
                <div>
                  <img src={searchImg} alt="" />
                </div>
                <div>
                  <Autocomplete
                    apiKey={"AIzaSyBJUZFe3zt4HNVzwdTlmJYMWtXhZ7mfclk"}
                    onPlaceSelected={(place) => {
                      setLocation(place.formatted_address);
                    }}
                    types={["address"]}
                    className="form-control newlocation"
                    defaultValue={location}
                    placeholder=""
                    componentRestrictions={{ country: "us" }}
                    preventDefault
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {/*---------Size of Pharmacy Portion--------*/}
      {textcolor === 3 && filtershow === true ? (
        <FilterModal
          title={"Size of Pharmacy"}
          data={sizeofPharmacy}
          className="filterModelNestedLevel"
          activeFilters={sizepharmdata}
          onChange={(e) => {
            if (sizepharmdata.includes(e.target.name)) {
              setSizePharmData(sizepharmdata.filter((ele) => ele !== e.target.name))
            } else {
              setSizePharmData((pre) => [...pre, e.target.name])
            }
          }}
        />
      ) : null}
      {/*---------Business Type Portion--------*/}
      {textcolor === 4 && filtershow === true ? (
        <FilterModal
          title={"Business Type"}
          data={businessType}
          className="filterModelNestedLevel"
          activeFilters={businessdata}
          onChange={(e) => {
            if (businessdata.includes(e.target.name)) {
              setBusinessData(businessdata.filter((ele) => ele !== e.target.name))
            } else {
              setBusinessData((pre) => [...pre, e.target.name])
            }
          }}
        />
      ) : null}
      {/*-------Lead Status--------*/}
      {textcolor === 5 && filtershow === true ? (
        <FilterModal
          title={"Lead Status"}
          data={leadStatus}
          className="filterModelNestedLevel"
          activeFilters={filterLeadStatus}
          onChange={(e) => {
            if (filterLeadStatus.includes(e.target.name)) {
              setFilterLeadStatus(filterLeadStatus.filter((ele) => ele !== e.target.name))
            } else {
              setFilterLeadStatus((pre) => [...pre, e.target.name])

            }
          }}
        />
      ) : null}
      {/* -------BD-------- */}
      {textcolor === 6 && filtershow === true ? (
        <FilterModal
          title={"BD"}
          data={bdModal}
          className="filterModelNestedLevel"
          activeFilters={filterBdModal}
          onChange={(e) => {
            if (filterBdModal.includes(e.target.name)) {
              setFilterBdModal(filterBdModal.filter((ele) => ele !== e.target.name))
            } else {
              setFilterBdModal((pre) => [...pre, e.target.name])
            }
          }}
        />
      ) : null}

      {/*--------Sort By Portion---------*/}
      {sortshow ? (
        <div className="sortby-Common">
          <div
            onClick={() => {
              setTextColor(11);
              setSortShow(false);
              setSort("oldest");
              setSortName("Oldest");
            }}
            style={{
              color: textcolor === 11 ? "#CB1B5B" : "",
              fontWeight: textcolor === 11 ? "bold" : "",
            }}
          >
            Oldest
          </div>
          <div
            onClick={() => {
              setTextColor(12);
              setSortShow(false);
              setSort("newest");
              setSortName("Newest");
            }}
            style={{
              color: textcolor === 12 ? "#CB1B5B" : "",
              fontWeight: textcolor === 12 ? "bold" : "",
            }}
          >
            Newest
          </div>
          <div
            onClick={() => {
              setTextColor(13);
              setSortShow(false);
              setSort("ascending");
              setSortName("Ascending");
            }}
            style={{
              color: textcolor === 13 ? "#CB1B5B" : "",
              fontWeight: textcolor === 13 ? "bold" : "",
            }}
          >
            Alphabetical ↑
          </div>
          <div
            onClick={() => {
              setTextColor(14);
              setSortShow(false);
              setSort("descending");
              setSortName("Descending");
            }}
            style={{
              color: textcolor === 14 ? "#CB1B5B" : "",
              fontWeight: textcolor === 14 ? "bold" : "",
            }}
          >
            Alphabetical ↓
          </div>
        </div>
      ) : null}

      {assignModal.status === true && (
        <AssignedTo
          id={assignModal?.id}
          setAssignModal={setAssignModal}
          setSuccess={setSuccess}
          pathName="partnered-doctor-for-inclinic"
        />
      )}

      {success && (
        <SucessModal
          name="BD"
          post="Assigned"
          onClick={() => successModalClose()}
        />
      )}

      {leadStatusModal.status === true && (
        <LeadStatus
          id={leadStatusModal?.id}
          setLeadStatusModal={setLeadStatusModal}
          setLeadStatusSuccess={setLeadStatusSuccess}
          setLeadStatusConfirmModal={setLeadStatusConfirmModal}
        />
      )}

      {leadStatusConfirmModal.status === true && (
        <LeadStatusConfirm
          id={leadStatusConfirmModal?.leadType}
          setLeadStatusConfirmModal={setLeadStatusConfirmModal}
          setLeadStatusSuccess={setLeadStatusSuccess}
          leadStatusConfirmModal={leadStatusConfirmModal}
          formType="pharmacy-registration"
        />
      )}

      {leadStatussuccess && (
        <SucessModal
          name="Lead Status"
          post="Updated"
          onClick={() => successModalClose()}
        />
      )}
      <ExcelDownloadModal onClickDownload={onClickDownload} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </>
  );
};

export default RetailPharmacy;
