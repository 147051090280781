import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, 
  timeout: 10000, // Timeout after 10 seconds
});

// Request Interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Add custom headers or tokens if needed
    // config.headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response Interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // You can process the response globally here
    return response;
  },
  (error) => {
    // Handle errors globally
    if (error.response?.status === 401) {
      // Example: Redirect to login if unauthorized
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
