import React, { useState } from "react";
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import search from "../../../assets/images/searchIcon.png";
import backRed from "../../../assets/svg/back-rose-icon.svg";
import filter1 from "../../../assets/svg/Health Article/filtericon.svg";
import sort1 from "../../../assets/svg/Health Article/sortbyicon.svg";

const RetailPharmacyTrack = () => {
  const navigate = useNavigate();
  const [arrow1, setArrow1] = useState(false);
  const [arrow2, setArrow2] = useState(false);
  const [sortName, setSortName] = useState("Sort by");
  const [sortshow, setSortShow] = useState(false);
  const [textcolor, setTextColor] = useState(0);

  const data = [
    {
      sno: "1",
      orderid: "622412",
      name: "Prakash R",
      mobile: "+91 72964 45263",
      items: "Dolo 650 Mg Telekast L 25 Rediase..",
      received: "10 Mins Ago",
      status: "Accepted",
      fulfilment: "Packed Billed Ready for dispatch",
      ordervalue: "716.16",
      healthsy: "100.61",
    },
  ];

  return (
    <>
      {/* <Home> */}
      <div
        onClick={() => navigate("/add-new-retail-pharmacy")}
        className="rose back-button"
      >
        <img src={backRed} alt="" />
        <span className="rose-back">Back</span>
      </div>
      <div className="healtharticle">
        <div className="healtharticle-container ps-3">
          <div className="healtharticle-title ms-0">
            <Link
              to="/dashboard"
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "15px",
              }}
            >
              <span style={{ paddingLeft: "5%" }}>Dashboard</span>
            </Link>
            <span style={{ paddingLeft: "1%" }}>{`>`}</span>
            <span style={{ paddingLeft: "0.4%" }}>
              <Link
                to="/add-new-retail-pharmacy"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                }}
              >
                Partnered Retail Pharmacies
              </Link>
            </span>
            {/* <span style={{ paddingLeft: "0.4%" }}>{`>`}</span>
              <span style={{ paddingLeft: "0.4%" }}>
                List of Doctors for Online Consultation
              </span> */}
            <span style={{ paddingLeft: "0.4%" }}>{`>`}</span>
            <span style={{ paddingLeft: "0.4%" }}>Track Retail Pharmacy</span>
          </div>

          <div className="doctor-track-header">
            <div>
              <img src="" alt="" />
            </div>
            <div>
              <div>Orbit Pharmacy</div>
              {/* <div>Nursing Support Service</div> */}
            </div>
            <div>Resend Login Credentials</div>
          </div>

          <div className="doctor-track-data">
            <div>
              <div>HealthSy ID</div>
              <div>KOSL95612DF</div>
            </div>
            <div>
              <div>Registered Mobile Number</div>
              <div>+91 92226 25629</div>
            </div>
            <div>
              <div>Registered Email ID</div>
              <div>ananth@gmail.com</div>
            </div>
          </div>

          <div className="doctor-track-data">
            <div>
              <div>Password</div>
              <div>Xjrxxxxxxxxxxx92</div>
            </div>
            <div>
              <div>Active Since</div>
              <div>27-01-2022</div>
            </div>
            <div>
              <div>Total Orders Fulfilled</div>
              <div>29</div>
            </div>
          </div>

          <div className="doctor-track-data">
            <div>
              <div>Total Revenue Clocked (₹INR)</div>
              <div>₹ 5,771</div>
            </div>
          </div>
        </div>
      </div>

      {/*----------table portion-----------*/}
      <div className="doctor-track-second-portion">
        <div className="healtharticle-show mt-5">
          <div>Show</div>
          <div>
            <select
              // value={limit}
              // onChange={(e) => setLimit(e.target.value)}
              className="me-2"
            >
              <option>10</option>
              <option>25</option>
              <option>50</option>
              <option>100</option>
              <option value="0">All</option>
            </select>
          </div>
          <div>entries</div>
          <div>
            <div>
              <img src={search} alt="" />
            </div>
            <div>
              <input
                type="text"
                placeholder="Search"
                className="form-control"
              // onChange={(e) => filter_records(e.target.value)}
              />
            </div>
          </div>
          <div className="sortandfilter-new">
            <div className="sortByPosition">
              <div>
                <div>
                  <div
                    onClick={() => {
                      setSortShow(!sortshow);
                    }}
                  >
                    {sortName}
                    {/* Sort by */}
                  </div>
                </div>
                <div>
                  <img src={sort1} alt="" />
                </div>
              </div>
            </div>
            <div className="sortByDepartment">
              <div>
                <div>
                  <div
                    onClick={() => {
                      // setFilterShow(true)
                    }}
                  >
                    Filter
                  </div>
                </div>
                <div>
                  <img src={filter1} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="healtharticle-table retail-pharmacy-track-table">
          <table>
            <thead>
              <tr>
                <td>S.No</td>
                <td>Order ID</td>
                <td>Customer Name</td>
                <td>Mobile Number</td>
                <td>Item(s) Ordered</td>
                <td>Received</td>
                <td>Order Status</td>
                <td>Fulfilment Status</td>
                <td>Order Value</td>
                <td>HealthSy %</td>
                <td>Actions</td>
              </tr>
            </thead>
            <tbody>
              {data.map((x, i) => {
                return (
                  <tr>
                    <td>{x.sno}</td>
                    <td>{x.orderid}</td>
                    <td>{x.name}</td>
                    <td>{x.mobile}</td>
                    <td>{x.items}</td>
                    <td>{x.received}</td>
                    <td>{x.status}</td>
                    <td>{x.fulfilment}</td>
                    <td>{x.ordervalue}</td>
                    <td>{x.healthsy}</td>
                    <td>
                      <span
                        className="doctor-track-view"
                        onClick={() => {
                          navigate("/retail-pharmacy-track-view");
                        }}
                      >
                        View Order
                      </span>
                      <span className="doctor-track-view-logo"></span>
                      <span className="doctor-track-cancel">Cancel Order</span>
                      <span className="doctor-track-cancel-logo"></span>
                      <span
                        className="doctor-track-reschedule"
                        onClick={() => {
                          navigate("/retail-pharmacy-track-redirect");
                        }}
                      >
                        Redirect Order
                      </span>
                      <span className="doctor-track-reschedule-logo"></span>
                      <span
                        className="doctor-track-redirect"
                        onClick={() => {
                          navigate("/retail-pharmacy-track-edit");
                        }}
                      >
                        Edit Order
                      </span>
                      <span className="doctor-track-redirect-logo"></span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="doctor-revenue-section">
          <div>
            <img src="" alt="" />
            <div>Total Revenue (INR)</div>
            <div>₹ 13,07,619.18</div>
            {arrow1 === true ? (
              <BsFillCaretUpFill onClick={() => setArrow1(!arrow1)} />
            ) : (
              <BsFillCaretDownFill onClick={() => setArrow1(!arrow2)} />
            )}
            {arrow1 === true ? (
              <div className="doctor-arrow1">
                <div>Daily</div>
                <div>Weekly</div>
                <div>Monthly</div>
                <div>Quaterly</div>
                <div>Custom Date</div>
              </div>
            ) : null}
          </div>
          <div>
            <img src="" alt="" />
            <div>Total HealthSy % (INR)</div>
            <div>₹ 2,35,371.42</div>
            {arrow2 === true ? (
              <BsFillCaretUpFill onClick={() => setArrow2(!arrow2)} />
            ) : (
              <BsFillCaretDownFill onClick={() => setArrow2(!arrow2)} />
            )}
            {arrow2 === true ? (
              <div className="doctor-arrow1">
                <div>Daily</div>
                <div>Weekly</div>
                <div>Monthly</div>
                <div>Quaterly</div>
                <div>Custom Date</div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {/* </Home> */}

      {sortshow ? (
        <div className="sortby-Common-career-main sortby-doctor-track">
          <div
            onClick={() => {
              setTextColor(11);
              setSortShow(false);
              setSortName("Oldest");
            }}
            style={{
              color: textcolor === 11 ? "#CB1B5B" : "",
              fontWeight: textcolor === 11 ? "bold" : "",
            }}
          >
            Oldest
          </div>
          <div
            onClick={() => {
              setTextColor(12);
              setSortShow(false);
              setSortName("Newest");
            }}
            style={{
              color: textcolor === 12 ? "#CB1B5B" : "",
              fontWeight: textcolor === 12 ? "bold" : "",
            }}
          >
            Newest
          </div>
          <div
            onClick={() => {
              setTextColor(13);
              setSortShow(false);
              setSortName("Ascending");
            }}
            style={{
              color: textcolor === 13 ? "#CB1B5B" : "",
              fontWeight: textcolor === 13 ? "bold" : "",
            }}
          >
            Alphabetical ↑
          </div>
          <div
            onClick={() => {
              setTextColor(14);
              setSortShow(false);
              setSortName("Descending");
            }}
            style={{
              color: textcolor === 14 ? "#CB1B5B" : "",
              fontWeight: textcolor === 14 ? "bold" : "",
            }}
          >
            Alphabetical ↓
          </div>
        </div>
      ) : null}
    </>
  );
};

export default RetailPharmacyTrack;
