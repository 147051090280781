import axios from "axios";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate, useParams } from "react-router";

import { AiOutlineBackward, AiOutlineForward } from "react-icons/ai";
import apiCall from "../../../api";
import contact from "../../../assets/images/HealthSy/contact.png";
import newdelete from "../../../assets/images/HealthSy/delete.png";
import AddButton from "../../Common/addButton";
import Breadcrumbs from "../../Common/Breadcrumbs/Breadcrumbs";
import DoctorsFilterOptions from "../../Common/doctorsFilterOptions";
import FiltersRootModal, { SortByModel } from "../../Common/filtersRootModals";
import SitemapNavHeader from "../../Common/sitemapNavHeader";
import Deletepopup from "../../modal/Deletepopup";

const Sitemap = () => {
    const navigate = useNavigate();
    const { category } = useParams();

    const [skip, setSkip] = useState(0);
    const [textColor, setTextColor] = useState([]);
    const [pages, setPages] = useState(0);
    const [limit, setLimit] = useState(10);
    const [filter,] = useState({});
    const [records, setRecords] = useState([]);
    const [sort, setSort] = useState("newest");
    const [sortshow, setSortShow] = useState(false);
    const [filtershow, setFilterShow] = useState(false);
    const [selectedSort, setSelectedSort] = useState([]);
    const [sitemapHeader, setSitemapHeader] = useState([]);
    const [deleteModal, setDeleteModal] = useState({ id: "", status: false });

    useEffect(() => {
        getSitemapHeader()
    }, [])

    useEffect(() => {
        list_api_call();
    }, [filter, limit, skip, category]);

    const handleAddSitemap = () => {
        navigate(`/add-sitemap-form/${category}`)
    }

    const handleEdit = (id) => {
        console.log("handleEdit-->", category, id);
        navigate(`/edit-sitemap-form/${category}/${id}`)
    }

    const handleClose = () => {
        setDeleteModal({ id: "", status: false });
    };

    const handleFilterApply = (records) => {
        setRecords(records);
        setSkip(0);
        setPages(Math.ceil(records.length / limit));
    };

    const handlePageChange = (event) => {
        const selectedPage = event.selected;
        setSkip(selectedPage * limit);
    };

    const handleSortClick = (sortOption) => {
        setSelectedSort(sortOption);
    };

    const handleApplyClick = () => {
        if (selectedSort) {
            setSort(selectedSort);
            setSortShow(false);
        }
    };

    const handleDelete = async (id) => {
        try {
            const response = await apiCall(`sitemap/delete/${id}`, "DELETE");

            if (response) {
                list_api_call();
                setDeleteModal({ id: "", status: false });
            }
        } catch (err) {
            console.error(err);
        }
    };

    const handleActive = (id, status) => {
        axios
            .patch(
                `${process.env.REACT_APP_API_URL}sitemap/update/active/${id}`,
                {
                    active: !status,
                }
            )
            .then((result) => {
                list_api_call();
            });
    };

    const getSitemapHeader = async () => {
        try {
            const result = await apiCall(`sitemap-category/list`, "POST", { active: true });
            if (result.data?.rows) {
                console.log("result.data.rows--->", result?.data?.rows);
                setSitemapHeader(result.data?.rows);
            }
        } catch (err) {
            console.error(err);
        }
    }

    const list_api_call = async () => {
        try {
            const response = await apiCall(`sitemap/list`, "POST", {
                ...filter,
                skip: skip,
                limit: Number(limit),
                sort: sort,
                sitemap_category: category
            });

            const totalCount = response.data.count;
            setRecords(response.data?.rows);
            setPages(Math.ceil(totalCount / limit));
        } catch (err) {
            console.error(err);
            alert("Some error occurred while fetching filter options");
        }
    }

    // const [data, setData] = useState({
    //     content: 'Initial record data',
    //     lastUpdated: new Date().toLocaleString(),
    //     previousUpdated: '',
    // });

    return (
        <>
            <div className="healtharticle">
                <div className="healtharticle-container">
                    <div className="healtharticle-title carrer-job-title ms-4">
                        <Breadcrumbs
                            breadcrumbText="Sitemap"
                            breadcrumbLink="/services"
                            nestedText="Sitemap Analysis"
                            nestedLink="#"
                        />
                    </div>
                    <div className="healtharticle-addnew">
                        <AddButton
                            onClick={handleAddSitemap}
                            btnName="Add Sitemap"
                        />
                    </div>
                    <SitemapNavHeader
                        titles={sitemapHeader}
                        className="common-nav-tab"
                        scrollable={true}
                    />
                    <DoctorsFilterOptions
                        limit={limit}
                        sortshow={sortshow}
                        sortName={"sort By"}
                        setFilterShow={setFilterShow}
                        setSortShow={setSortShow}
                        setLimit={setLimit}
                        onFilterApply={handleFilterApply}
                    />
                    <div className="healtharticle-table master-doctor-table">
                        <table>
                            <thead>
                                <tr>
                                    <td className="career-jobpost-td1 fs18fwb">S.No</td>
                                    <td className="career-jobpost-td2 fs18fwb">Sitemap Name</td>
                                    <td>Total URL's in sitemap</td>
                                    <td className="career-jobpost-td4">Sitemap Live URL</td>
                                    <td className="career-jobpost-td5">Last Update On</td>
                                    {/* <td className="career-jobpost-td5">Previously Updated Sitemap Folder</td> */}
                                    <td className="career-jobpost-td5">Previously Updated On</td>
                                    <td className="career-jobpost-td5">Sitemap Updated Status</td>
                                    <td className="career-jobpost-td5">Sitemap Status</td>
                                </tr>
                            </thead>
                            <tbody>
                                {records.slice(skip, skip + limit).map((el, i) => (
                                    <tr key={el._id}>
                                        <td>{skip + i + 1}</td>
                                        <td>{el.sitemap_name}</td>
                                        <td>{el.total_url_sitemap}</td>
                                        <td>{el.live_sitemap_url}</td>
                                        <td>{new Date(el.updatedAt).toLocaleString()}</td>
                                        {/* <td>{"Download"}</td> */}
                                        <td>{el?.previousUpdate ? new Date(el.previousUpdate).toLocaleString() : '-'}</td>
                                        <td>{el.active ? "Success" : "Failed"}</td>
                                        <td>
                                            <span
                                                className="newdelete text-success px-0"
                                                onClick={() => {
                                                    handleEdit(el._id);
                                                }}>
                                                Edit
                                            </span>
                                            <span className="newdelete-logo">
                                                <img src={contact} alt="" />
                                            </span>
                                            <span
                                                className="newdelete py-3"
                                                onClick={() => setDeleteModal({ id: el._id, status: true })}>
                                                Delete
                                            </span>
                                            <span className="newdelete-logo p-3">
                                                <img src={newdelete} alt="" />
                                            </span>
                                            <span
                                                className={`cursorPointer ${el.active ? "failed" : 'success'}`}
                                                onClick={() => handleActive(el._id, el.active)}>
                                                {el.active ? "Inactive" : "Active"}
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="mb-5 col d-flex pagination justify-content-end admin-pagination">
                <button
                    className="page-link"
                    onClick={() => handlePageChange({ selected: 0 })}
                    disabled={skip === 0}
                >
                    <AiOutlineBackward size={20} />
                </button>
                <ReactPaginate
                    previousLabel="Previous"
                    nextLabel="Next"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    pageCount={pages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageChange}
                    containerClassName="pagination my-0"
                    activeClassName="active"
                />
                <button
                    className="page-link"
                    onClick={() => handlePageChange({ selected: pages - 1 })}
                    disabled={(skip / limit) >= (pages - 1)}
                >
                    <AiOutlineForward size={20} />
                </button>
            </div>

            {filtershow && (
                <FiltersRootModal
                    onClickClear={() => {
                        setFilterShow(false);
                        setRecords([]);
                        setSkip(0);
                        list_api_call();
                    }}
                    onClickApply={() => {
                        setFilterShow(false);
                        list_api_call();
                    }}
                    selectOption={textColor}
                    setSelectOption={setTextColor}
                    className="filter-container-partnered-doctor"
                />
            )}
            {sortshow && (
                <SortByModel
                    latest="Latest"
                    oldest="Oldest"
                    handleSortClick={handleSortClick}
                    selectedSort={selectedSort}
                    textColor={textColor}
                    handleApplyClick={handleApplyClick}
                />
            )}
            {deleteModal.status && (
                <Deletepopup
                    handleDelete={handleDelete}
                    id={deleteModal?.id}
                    isCloseModalEnable={true}
                    handleClose={handleClose}
                />
            )}
        </>
    )
}

export default Sitemap