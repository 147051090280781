import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { IoCloseCircleSharp } from "react-icons/io5";
import { useLocation } from "react-router";

import "./SucessModel.scss";

const AssignedTo = ({
  id,
  setAssignModal = () => { },
  setSuccess = () => { },
  pathName = ""
}) => {

  const router = useLocation()

  const [inputValue, setInputValue] = useState('');

  const {
    handleSubmit,
  } = useForm();

  const onSubmit = (fields) => {
    fields.assigned_to = inputValue;
      if(router.pathname === "/ad-landing-forms/partnered-doctors"){
        axios
          .patch(
            `${process.env.REACT_APP_API_URL}doctors-ad/doctors-ad-update/${id}`,
            fields
          )
          .then((response) => {
            setSuccess(true);
            setAssignModal({ id: "", status: false });
          });
    }else if(router.pathname === "/insta-doc-qr-code"){
        axios
          .patch(
            `${process.env.REACT_APP_API_URL}insta-doc-qr-code/insta-doc-qr-code-update/${id}`,
            fields
          )
          .then((response) => {
            setSuccess(true);
            setAssignModal({ id: "", status: false });
          });
    }else if(router.pathname === "/web-bookings/home-healthcare-bookings") {
        axios
          .patch(
            `${process.env.REACT_APP_API_URL}web-bookings/update/${id}`,
            fields
          )
          .then((response) => {
            setSuccess(true);
            setAssignModal({ id: "", status: false });
          });
    }else if(router.pathname === "/pharmacy-registration") {
        axios
          .patch(
            `${process.env.REACT_APP_API_URL}pharmacy-registration/update/${id}`,
            fields
          )
          .then((response) => {
            setSuccess(true);
            setAssignModal({ id: "", status: false });
          });
    } else if(router.pathname === "/doctor-registration"){
        axios
          .patch(
            `${process.env.REACT_APP_API_URL}doctor-registration/update/${id}`,
            fields
          )
          .then((response) => {
            setSuccess(true);
            setAssignModal({ id: "", status: false });
          });
    } else if(router.pathname === "/healthcare-registration"){
        axios
          .patch(
            `${process.env.REACT_APP_API_URL}healthcare-registration/update/${id}`,
            fields
          )
          .then((response) => {
            setSuccess(true);
            setAssignModal({ id: "", status: false });
          });
    } else if(router.pathname === "/insta-doc"){
        axios
          .patch(
            `${process.env.REACT_APP_API_URL}insta-doc-registration/update/${id}`,
            fields
          )
          .then((response) => {
            setSuccess(true);
            setAssignModal({ id: "", status: false });
          });
    } else if(router.pathname === "/other-registration"){
        axios
          .patch(
            `${process.env.REACT_APP_API_URL}other-registration/update/${id}`,
            fields
          )
          .then((response) => {
            setSuccess(true);
            setAssignModal({ id: "", status: false });
          });
    } else if(router.pathname === "/doctor-demo"){
        axios
          .patch(
            `${process.env.REACT_APP_API_URL}doctor-book-a-demo/update/${id}`,
            fields
          )
          .then((response) => {
            setSuccess(true);
            setAssignModal({ id: "", status: false });
          });
    } else if(router.pathname === "/ad-landing-forms/users-general"){
        axios
          .patch(
            `${process.env.REACT_APP_API_URL}user-general/user-general-update/${id}`,
            fields
          )
          .then((response) => {
            setSuccess(true);
            setAssignModal({ id: "", status: false });
          });
    } else if(router.pathname === "/rp-qr-ratings/pharmacy-rating"){
        axios
          .patch(
            `${process.env.REACT_APP_API_URL}rating-form/rating-form-update/${id}`,
            fields
          )
          .then((response) => {
            setSuccess(true);
            setAssignModal({ id: "", status: false });
          });
    }
  }

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const submitButtonStyle = {
    backgroundColor: inputValue.trim() ? 'green' : 'gray',
  };

  const [assignedBD, setAssignedBD] = useState([]);

  const getAssignedBD = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}bd/list`, {
        active: true
      })
      .then((response) => {
        setAssignedBD(response.data?.rows);
      })
      .catch((err) => {
        console.error(err);
        alert("Some error");
      });
  };

  useEffect(() => {
    getAssignedBD();
  }, [])

  return (
    <>
      <div className="assignModal">
        <div className="modal-backdrop fade show success-model"></div>
        <div
          style={{ display: "block" }}
          className="modal fade show modal-sm"
          id="exampleModal2"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className=" modal-content p-4">
              <div className="d-flex justify-content-between w-100">
                <p className="assignTitle">Assign to BD</p>
                <IoCloseCircleSharp size={30} color={"gray"} className="cursorPointer" onClick={() => setAssignModal({ id: "", status: false })} />
              </div>
              <p className="assignName">Name of BD</p>
              <form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column align-items-center">
                {pathName ?
                  <select
                    placeholder="Select Name"
                    className="form-control"
                    value={inputValue}
                    onChange={handleInputChange}
                    default=""
                  >
                    <option value="" disabled pri-specialization="true">
                      Select
                    </option>
                    {assignedBD.map((data) => {
                      return (
                        <option>{data.bd_name}</option>
                      )
                    })}
                  </select> :
                  <input
                    type="text"
                    placeholder="Enter name"
                    className="form-control"
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                }
                <button
                  className="assignSubmit"
                  type="submit"
                  style={submitButtonStyle}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AssignedTo;