import React from "react";
import { Link, useNavigate } from "react-router-dom";
import backRed from "../../../../assets/svg/back-rose-icon.svg";
import { useForm } from "react-hook-form";
import { useState } from "react";
import "./index.scss";
import greenround from "../../../../assets/images/greenround.PNG";
import greentick from "../../../../assets/images/greentick.PNG";
import graytick from "../../../../assets/images/graytick.PNG";
import { ImageUploadAndPreview } from "../../../Pages/HealthArticle/AddNew/AddNew";
import next from "../../../../assets/svg/next-icon.svg";
import back from "../../../../assets/svg/back-icon.svg";
import searchh from "../../../../assets/images/searchIcon.png";
import SucessModal from "../../../modal/SucessModal";
import { BsXCircle } from "react-icons/bs";

const AddDoctorInclinicform = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);

  const {
    // handleSubmit,
    formState: { errors },
  } = useForm();

  const handleNext = () => {
    setPage(2);
    setShow(true);
  };

  const handleBack = () => {
    setPage(1);
    setShow(false);
  };

  const searchData = [
    {
      name: "Dr. Anand Krishnan",
      logo: "",
      job: "General Physician",
      exp: "4 Years Exp, (MBBS, MD)",
      languages: ["English", "Tamil"],
    },
    {
      name: "Dr. Anand",
      logo: "",
      job: "General Physician",
      exp: "4 Years Exp, (MBBS, MD, Urology)",
      languages: ["English", "Telugu"],
    },
  ];

  const [search, setSearch] = useState("");
  const [isChecked,] = useState(true);
  const [searchCheck, setSearchCheck] = useState(false);

  return (
    <>
      {/* <Home> */}

      {page === 1 ? (
        <div
          onClick={() => navigate("/doctor-inclinic-appointment")}
          className="rose back-button"
        >
          <img src={backRed} alt="" />
          <span className="rose-back">Back</span>
        </div>
      ) : page === 2 ? (
        <div onClick={handleBack} className="rose back-button">
          <img src={backRed} alt="" />
          <span className="rose-back">Back</span>
        </div>
      ) : null}

      <div className="healtharticle">
        <div className="healtharticle-container">
          <div className="healtharticle-title">
            <Link
              to="/add-new-doctors"
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "15px",
              }}
            >
              <span style={{ paddingLeft: "5%" }}>Doctor</span>
            </Link>
            <span style={{ fontSize: "15px", paddingLeft: "1%" }}>{`>`}</span>
            <Link
              to="/doctor-inclinic-appointmentb"
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "15px",
                paddingLeft: "0.4%",
              }}
            >
              <span>Clinic & Hospital</span>
            </Link>
            <span style={{ paddingLeft: "1%", fontSize: "15px" }}>{`>`}</span>
            <span style={{ paddingLeft: "0.7%", fontSize: "15px" }}>
              Add New Clinic & Hospital
            </span>
          </div>

          {page === 1 ? (
            <div className="doctor-page-tick">
              <div>
                <img src={greenround} alt="" />
                <div>Page 1</div>
              </div>
              <div>
                <img src={graytick} alt="" />
                <div>Page 2</div>
              </div>
            </div>
          ) : page === 2 ? (
            <div className="doctor-page2-tick">
              <div>
                <img src={greentick} alt="" />
                <div>Page 1</div>
              </div>
              <div>
                <img src={greentick} alt="" />
                <div>Page 2</div>
              </div>
            </div>
          ) : null}

          <form className="career-jobpost-form">
            {show === false ? (
              <div className="doctor-page1">
                {/* one */}
                <div className="addnew-form1-autor-detail mt-3 d-flex">
                  <div className="col-md-4">
                    <label for="inputAddress" className="form-label title">
                      Name of Clinic / Hospital <span className="star">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control addnew-input"
                      id="authordetails"
                      style={{ paddingLeft: "10px" }}
                    />
                    {errors.authordetails && (
                      <div className={`invalid-feedback d-block`}>
                        Please provide a Author Name
                      </div>
                    )}
                  </div>

                  <div className="row col-md-4">
                    <div className="col">
                      <label className=" small mb-2" htmlFor="">
                        Type of Organisation <span className="star">*</span>
                      </label>
                      <div className="d-flex">
                        <div className="form-check form-check-inline me-3 mt-1">
                          <input
                            className="form-check-input me-3"
                            type="radio"
                            name="organisation"
                            id="organisation"
                            value="Active"
                            checked
                          />
                          <label htmlFor="option-1" className="text-dark mt-1">
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline mt-1">
                          <input
                            className="form-check-input  me-3"
                            type="radio"
                            name="organisation"
                            id="organisation"
                            value="Inactive"
                          />
                          <label htmlFor="option-2" className="text-dark mt-1">
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                    {errors.status && (
                      <div className={`invalid-feedback d-block`}>
                        Please Select an Option
                      </div>
                    )}
                  </div>

                  <div className="col-md-4">
                    <label for="inputAddress" className="form-label title">
                      Location of Clinic / Hospital{" "}
                      <span className="star">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control addnew-input"
                      id="authordetails"
                      style={{ paddingLeft: "10px" }}
                    />
                    {errors.authordetails && (
                      <div className={`invalid-feedback d-block`}>
                        Please provide a Author Name
                      </div>
                    )}
                  </div>
                </div>

                {/* two */}
                <div className="addnew-form1-autor-detail mt-5 d-flex">
                  <div className="col-md-4">
                    <label for="inputAddress" className="form-label title">
                      City / Town <span className="star">*</span>
                    </label>
                    <select className="form-select w-75 car-job-select">
                      <option value="" hidden selected>
                        Select
                      </option>
                      <option value="">Allopathy</option>
                      <option value="">Allopathy</option>
                      <option value="">Allopathy</option>
                    </select>
                    {errors.authordetails && (
                      <div className={`invalid-feedback d-block`}>
                        Please provide a Author Name
                      </div>
                    )}
                  </div>

                  <div className="col-md-4">
                    <label for="inputAddress" className="form-label title">
                      State <span className="star">*</span>
                    </label>
                    <select className="form-select w-75 car-job-select">
                      <option value="" hidden selected>
                        Select State
                      </option>
                      <option value="">Allopathy</option>
                      <option value="">Allopathy</option>
                      <option value="">Allopathy</option>
                    </select>
                    {errors.authordetails && (
                      <div className={`invalid-feedback d-block`}>
                        Please provide a Author Name
                      </div>
                    )}
                  </div>

                  <div className="col-md-4">
                    <label for="inputAddress" className="form-label title">
                      Pincode of Clinic / Hospital{" "}
                      <span className="star">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control addnew-input"
                      id="authordetails"
                      style={{ paddingLeft: "10px" }}
                    />
                    {errors.authordetails && (
                      <div className={`invalid-feedback d-block`}>
                        Please provide a Author Name
                      </div>
                    )}
                  </div>
                </div>

                {/* three */}
                <div className="addnew-form1-autor-detail mt-5 d-flex">
                  <div className="col-md-4">
                    <label for="inputAddress" className="form-label title">
                      Clinic / Hospital Contract <span className="star">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control addnew-input"
                      id="authordetails"
                      style={{ paddingLeft: "10px" }}
                    />
                    {errors.authordetails && (
                      <div className={`invalid-feedback d-block`}>
                        Please provide a Author Name
                      </div>
                    )}
                  </div>

                  <div className="col-md-4 ms-3">
                    <label for="inputAddress" className="form-label title">
                      Select Specialisation (upto 4){" "}
                      <span className="star">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control addnew-input"
                      id="authordetails"
                      style={{ paddingLeft: "10px" }}
                    />
                    {errors.authordetails && (
                      <div className={`invalid-feedback d-block`}>
                        Please provide a Author Name
                      </div>
                    )}
                  </div>
                </div>

                {/* four */}
                <div className="addnew-form1-autor-detail mt-5 d-flex">
                  <div className="col-md-4">
                    <label for="inputAddress" className="form-label title">
                      Clinic / Hospital License of Registration Number{" "}
                      <span className="star">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control addnew-input"
                      id="authordetails"
                      style={{ paddingLeft: "10px" }}
                    />
                    {errors.authordetails && (
                      <div className={`invalid-feedback d-block`}>
                        Please provide a Author Name
                      </div>
                    )}
                  </div>

                  <div className="authorUpload">
                    <label className="title fo-tit fo-tit-top">
                      Upload Logo of Clinic / Hospital{" "}
                      <span className="star">*</span>
                    </label>
                    <div id="img-preview-two"></div>
                    <div>
                      <ImageUploadAndPreview
                      //  onChange={files => setAuthorImage(files.length ? files[0] : null)}
                      />
                      {errors.authorImage && (
                        <div className={`invalid-feedback d-block`}>
                          Please Select Author Image
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="authorUpload">
                    <label className="title fo-tit fo-tit-top">
                      Upload Photos of Clinic / Hospital{" "}
                      <span className="star">*</span>
                      <div style={{ color: "#cb1c5c", fontSize: "13px" }}>
                        (Interior / Exterior) max 8 photos
                      </div>
                    </label>
                    <div id="img-preview-two"></div>
                    <div>
                      <ImageUploadAndPreview
                      //  onChange={files => setAuthorImage(files.length ? files[0] : null)}
                      />
                      {errors.authorImage && (
                        <div className={`invalid-feedback d-block`}>
                          Please Select Author Image
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* five */}
                <div className="addnew-form1-autor-detail mt-5 d-flex">
                  <div className="col-md-12">
                    <label for="inputAddress" className="form-label title">
                      Google Map location Link <span className="star">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="authordetails"
                      style={{ paddingLeft: "10px", width: "100%" }}
                    />
                    {errors.authordetails && (
                      <div className={`invalid-feedback d-block`}>
                        Please provide a Author Name
                      </div>
                    )}
                  </div>
                </div>

                <div className="addnew-form1-next">
                  <div onClick={handleNext}>
                    <div>
                      <button>NEXT</button>
                    </div>
                    <div>
                      <img src={next} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="doctor-page2">
                {/* one */}
                <div className="addnew-form1-autor-detail mt-3 d-flex">
                  <div className="add-new-clinic-search">
                    <div>
                      <img src={searchh} alt="" />
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="Search for Doctors"
                        className="form-control"
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                    <div>
                      {search !== "" ? (
                        <BsXCircle
                          className="search-cancel"
                          onClick={() => {
                            setSearch("");
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>

                {/* Two */}
                {search !== "" ? (
                  <div className="search-show-data">
                    {searchData
                      .filter((ele) => ele.name.toLowerCase().includes(search))
                      .map((el, i) => {
                        return (
                          <div className="search-show-box" key={i}>
                            <div>
                              <img src={el.logo} alt="" />
                            </div>
                            <div>
                              <div>{el.name}</div>
                              <div>{el.job}</div>
                              <div>{el.exp}</div>
                              <div>{el.languages}</div>
                            </div>
                            <div>
                              <div
                                style={{
                                  border:
                                    searchCheck === true
                                      ? "1px solid #CB1B5B"
                                      : "",
                                }}
                              >
                                <div className="checkbox-wrapper">
                                  <input
                                    type="checkbox"
                                    className={isChecked ? "checked" : ""}
                                    id=""
                                    onClick={() => {
                                      setSearchCheck(true);
                                    }}
                                  />
                                </div>
                                <div
                                  style={{
                                    color:
                                      searchCheck === true ? "#CB1B5B" : "",
                                  }}
                                >
                                  Select
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                ) : null}

                <div className="addnew1-form1-next">
                  <div className="addnew1-back-next">
                    <div className="addnew1-back">
                      <div onClick={handleBack}>
                        <div>
                          <img src={back} alt="" />
                        </div>
                        <div>BACK</div>
                      </div>
                    </div>
                    <div className="addnew1-next">
                      <div>
                        <button
                          type="button"
                          onClick={() => setSuccess(true)}
                          className="bg-transparent border-0 text-white"
                        >
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
      {/* </Home> */}
      {success && (
        <SucessModal
          page={"/doctor-inclinic-appointment"}
          name="New Inclinic"
          post="posted"
        />
      )}
    </>
  );
};

export default AddDoctorInclinicform;
