import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import SucessModal from "../../modal/SucessModal";
import "./index.scss";

const BusinessSettings = () => {
  const {
    register,
    getValues,
    setValue,
    // formState: { errors },
    handleSubmit,
  } = useForm();

  const [success, setSuccess] = useState(false);
  const [editSuccess, setEditSuccess] = useState(false);

  const [id, setId] = useState("");
  const [count, setCount] = useState(0);
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_URL}business-settings/list`)
      .then((res) => {
        setLogo(res.data.rows[0].logo)
        setId(res.data.rows[0]._id);
        setCount(res.data.count);
        Object.keys(res.data.rows[0]).forEach((x) => {
          setValue(x, res.data.rows[0][x]);
        });
      });
  }, [count, setValue]);

  const getsrcValue = (value) => {
    if (typeof value === "string" && value.length)
      return process.env.REACT_APP_API_URL + "images/logo/" + value;
    return typeof value === "object" ? URL.createObjectURL(value) : " ";
  };

  const onSubmit = (fields) => {
    if (count === 0) {
      // delete fields.job_post_image;
      fields["logo"] = logo;
      axios
        .postForm(
          `${process.env.REACT_APP_API_URL}business-settings/create`,
          fields
        )
        .then((response) => {
          if (response.data?._id) {
            setSuccess(true);
          } else {
            alert("Fail");
            console.log("failed...");
          }
        });
    } else if (count === 1) {
      let fields = getValues();
      fields["logo"] = logo;
      axios
        .postForm(
          `${process.env.REACT_APP_API_URL}business-settings/update/${id}`,
          fields
        )
        .then((response) => {
          if (response.data?._id) {
            setEditSuccess(true);
          } else {
            alert("Fail");
          }
        });
    }
  };

  return (
    <>
      {/* <Home> */}
      <div className="healtharticle">
        <div className="healtharticle-title">
          <Link
            to="/settings-social-media-link"
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: "15px",
            }}
          >
            <span style={{ paddingLeft: "5%" }}>Settings</span>
          </Link>
          <span style={{ fontSize: "15px", paddingLeft: "1%" }}>{'>'}</span>
          <Link
            to=""
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: "15px",
              paddingLeft: "0.2%",
              width: "20%",
            }}
          >
            <span style={{ paddingLeft: "6%" }}>Business Settings</span>
          </Link>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="carr-dep-form">
          <div className="row car-dep-form">
            <div className="col-6">
              <label
                className="car-dep-form-txt mb-2"
                style={{ color: "#4B4853" }}
                htmlFor=""
              >
                Email{" "}
              </label>
              <input
                className="form-control w-75"
                type="text"
                name=""
                id="email"
                {...register("email", { required: false })}
                placeholder="Enter email..."
              />
            </div>

            <div className="col-6">
              <label
                className="car-dep-form-txt mb-2"
                style={{ color: "#4B4853" }}
                htmlFor=""
              >
                Mobile Number{" "}
              </label>
              <input
                className="form-control w-75"
                type="text"
                name=""
                id="mobile"
                {...register("mobile", { required: false })}
                placeholder="Enter mobile number..."
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </div>

            <div className="col-6 mt-5">
              <label
                className="car-dep-form-txt mb-2"
                style={{ color: "#4B4853" }}
                htmlFor=""
              >
                Logo{" "}
              </label>
              {/* <ImageUploadAndPreview
                onChange={(files) => setLogo(files.length ? files[0] : null)}
              />  */}
              <div>
                {logo ? (
                  <img
                    src={getsrcValue(logo)}
                    style={{
                      width: "200px",
                      height: "50px",
                      marginTop: "2%",
                      marginBottom: "5%",
                    }}
                    alt="img"
                  />
                ) : null}
              </div>
              <input
                className="form-control-file custom-file-input logoFileChoose"
                type="file"
                onChange={(e) => setLogo(e.target.files[0])}
                required={false}
              />
              {logo &&
                <button
                  className="btn btn-danger ms-2"
                  onClick={() => setLogo(null)}
                >Remove</button>}
            </div>
          </div>
          <div className="car-dep-form-button business-button">
            <button type="submit" className="mb-5">
              SUBMIT
            </button>
          </div>
        </form>
      </div>
      {/* </Home> */}
      {success && (
        <SucessModal
          page={"/business-settings"}
          name="Business Settings"
          post="posted"
        />
      )}

      {editSuccess && (
        <SucessModal
          page={"/business-settings"}
          name="Business Settings"
          post="updated"
        />
      )}
    </>
  );
};

export default BusinessSettings;
