import React from 'react'
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import filter1 from "../../../../assets/svg/Health Article/filtericon.svg";
import sort1 from "../../../../assets/svg/Health Article/sortbyicon.svg";
import search from "../../../../assets/images/searchIcon.png";
import buttonadd from "../../../../assets/images/plus.png";
import "./index.scss"
import { useState } from 'react';
import { FaAngleRight } from "react-icons/fa";
import Autocomplete from "react-google-autocomplete";

const DoctorInstaDoc = () => {
  
  const [, setSort] = useState("newest");
  const [sortName, setSortName] = useState("Sort by");
  // const [filter, setFilter] = useState({});
  // const [records, setRecords] = useState([]);

  const [sortshow, setSortShow] = useState(false);
  const [textcolor, setTextColor] = useState(0);
  const [filtershow, setFilterShow] = useState(false);
  const [, setSubmitFilter] = useState(false);
  const [feedata, setFeeData]=useState([])
  const [expdata, setExpData]=useState([])
  const [genderdata, setGenderData]=useState([])
  const [languagedata, setLanguageData]=useState([])
  const [spldata, setSplData]=useState([])
  const [location, setLocation]=useState('')
  const [isChecked,] = useState(true);

  const fee = [
    {
      text:"Less than INR 99"
    },
    {
      text:"INR 100 to INR 299"
    },
    {
      text:"INR 300 to INR 499"
    },
    {
      text:"INR 500 to INR 799"
    },
    {
      text:"INR 800 to INR 999"
    },
    {
      text:"More than INR 1000"
    }
  ]

  const experience = [
    {
      text:"Less than 1 Years"
    },
    {
      text:"1 - 2 Years"
    },
    {
      text:"2 - 3 Years"
    },
    {
      text:"3 - 5 Years"
    },
    {
      text:"5+ Years"
    }
  ]

  const gender = [
    {
      text:"Male"
    },
    {
      text:"Female"
    },
    {
      text:"Others"
    }
  ]

  const language = [
    {
      text:"Tamil"
    },
    {
      text:"Telugu"
    },
    {
      text:"Hindi"
    },
    {
      text:"English"
    },
    {
      text:"Malayalam"
    },
    {
      text:"Kannada"
    },
    {
      text:"Bengali"
    },
    {
      text:"Marathi"
    },
    {
      text:"Punjabi"
    },
    {
      text:"Others"
    },
  ]

  const primaryspl = [
    {
      text:"Andrology"
    },
    {
      text:"Ayurveda"
    },
    {
      text:"Allergist"
    },
    {
      text:"Bariatrics"
    },
    {
      text:"Cardiology"
    },
    {
      text:"Cosmetology"
    },
    {
      text:"Dentistry"
    },
  ]

    const navigate = useNavigate();

    const handlenewDoctorForm=()=>{
      navigate("/doctor-insta-doc-add-form")
    }

    const handleEditDoctorForm=()=>{
      navigate("/doctor-insta-doc-add-edit")
    }
    
    const data=[
        {
            sno:"1",
            name:"rajesh L",
            spl1:"General Physician",
            spl2:"-",
            date:"27-01-2022",
            gender:"Male",
            age:"29",
            fee:"₹ 199",
            mobile:"+91 92226 25629",
            email:"ananth@gmail.com",
            city:"Ranipet",
            medical:"PJ2J2611",
            exp:"2",
            edu:"MBBS, MD",
            hos:"Mathus Hospital",
            total:"5",
            langauges:"Hindi, English, Punjabi",
            modes:"Chat, Audio, Video",
            status:"Track"
        },
        {
            sno:"2",
            name:"Jai Kishor M",
            spl1:"Dermatologist",
            spl2:"-",
            date:"24-01-2022",
            gender:"Male",
            age:"45",
            fee:"₹ 299",
            mobile:"+91 92226 25629",
            email:"arunjohn@gmail.com",
            city:"Chennai",
            medical:"TN26R2J",
            exp:"14",
            edu:"MBBS, MD",
            hos:"Orbits Hospital",
            total:"16",
            langauges:"Tamil, English",
            modes:"Chat, Audio, Video",
            status:"Track"
        }
    ]

  return (
    <>
       {/* <Home> */}
        <div className="healtharticle">
          <div className="healtharticle-container">
            <div className="healtharticle-title carrer-job-title ms-4">
              <Link
                to="/add-new-doctors"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                }}
              >
                <span style={{ paddingLeft: "5%" }}>Doctors</span>
              </Link>
              <span style={{ paddingLeft: "1%", paddingRight: "0.4%" }}>{`>`}</span>
              <Link
                to=""
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                }}
              >
                <span>List of Doctors for 24/7</span>
              </Link>
            </div>

            <div className="healtharticle-addnew">
              <div
                id="addnewpost"
                onClick={handlenewDoctorForm}
                className="addnewhealth"
                style={{ width: "240px" }}
              >
                <div>
                  {/* <BsFillPlusCircleFill /> */}
                  <img src={buttonadd} alt="" />
                </div>
                <div>
                  <button>Add New Doctor in 24/7</button>
                </div>
              </div>
            </div>
            <div className="careers-tab" style={{ marginLeft: "2%" }}>
              <div
                className=""
                onClick={() => navigate("/add-new-doctors")}
              >
                List of Doctors for Online Consultation
              </div>
              <div
                className="doctor-insta-doc"
                onClick={() => navigate("/doctor-insta-doc")}
              >
                Doctors in 24/7 (InstaDoc)
              </div>
            </div>

            <div className="healtharticle-show mt-5">
              <div>Show</div>
              <div>
                <select
                    // value={limit}
                    // onChange={(e) => setLimit(e.target.value)}
                  className="me-2"
                >
                  <option>10</option>
                  <option>25</option>
                  <option>50</option>
                  <option>100</option>
                  <option value="0">All</option>
                </select>
              </div>
              <div>entries</div>
              <div>
                <div>
                  {/* <FaSearch /> */}
                  <img src={search} alt="" />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control"
                    // onChange={(e) => filter_records(e.target.value)}
                  />
                </div>
              </div>
              <div className="sortandfilter-new">
                <div className="sortByPosition">
                  <div>
                    <div>
                    <div
                        onClick={()=>{
                          setSortShow(!sortshow)
                        }}
                      >
                        {sortName}
                        {/* Sort by */}
                      </div>
                      {/* <select
                        // onChange={(e) => setSort(e.target.value)}
                      >
                        <option>Sort by</option>
                        <option value="id-desc">
                          <div style={{ color: "red" }}>Oldest</div>
                        </option>
                        <option value="name-asc">
                          <div>Newest</div>
                        </option>
                        <option value="id-asc">
                          <div>Alphabetical ↑</div>
                        </option>
                        <option value="id-asc">
                          <div>Alphabetical ↓</div>
                          <span>
                            <img src="" alt="" />
                          </span>
                        </option>
                      </select> */}
                    </div>
                    <div>
                      <img src={sort1} alt="" />
                    </div>
                  </div>
                </div>
                <div className="sortByDepartment">
                  <div>
                    <div>
                    <div
                        onClick={()=>{
                        setFilterShow(true)
                        }}
                    >Filter</div>
                      {/* <select
                        name=""
                        id=""
                        // onChange={handleSort}
                      >
                        <option value="">Filter</option>
                        <option value="Asc to Des">Asc to Des</option>
                        <option value="Des to Asc">Des to Asc</option>
                      </select> */}
                    </div>
                    <div>
                      <img src={filter1} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="healtharticle-table add-new-doctors-table">
              <table>
                <thead>
                  <tr>
                    <td>S.No</td>
                    <td>Name of Doctor</td>
                    <td>Specialisation (Primary)</td>
                    <td>Specialisation (Secondary)</td>
                    <td>Date of Active On-boarding since</td>
                    <td>Gender</td>
                    <td>Age</td>
                    <td>Online Consultation Fee (INR) 24/7</td>
                    <td>Registered Mobile Number</td>
                    <td>Registered Email</td>
                    <td>City / Town</td>
                    <td>Medical Registration Details</td>
                    <td>Experience in (Yrs)</td>
                    <td>Education Qualification</td>
                    <td>Name of Hospital / Clinic Employed at or Owned</td>
                    <td>Total Online Consultations Completed</td>
                    <td>Language Known</td>
                    <td>Available Modes</td>
                    <td>Actions</td>
                  </tr>
                </thead>
                <tbody>
                  {
                    data.map((x, i)=>{
                      return(
                        <tr>
                          <td>{x.sno}</td>
                          <td>{x.name}</td>
                          <td>{x.spl1}</td>
                          <td>{x.spl2}</td>
                          <td>{x.date}</td>
                          <td>{x.gender}</td>
                          <td>{x.age}</td>
                          <td>{x.fee}</td>
                          <td>{x.mobile}</td>
                          <td>{x.email}</td>
                          <td>{x.city}</td>
                          <td>{x.medical}</td>
                          <td>{x.exp}</td>
                          <td>{x.edu}</td>
                          <td>{x.hos}</td>
                          <td>{x.total}</td>
                          <td>{x.languages}</td>
                          <td>{x.modes}</td>
                          <td>
                            <span 
                              className='doctor-edit'
                              onClick={()=>{
                                handleEditDoctorForm()
                              }}
                            >Edit</span>
                            <span className='doctor-edit-logo'></span>
                            <span className='doctor-delete'>Delete</span>
                            <span className='doctor-delete-logo'></span>
                            <span 
                              className='doctor-view'
                              onClick={()=>{
                                navigate("/doctor-insta-doc-add-view")
                              }}
                            >View</span>
                            <span className='doctor-view-logo'></span>
                            <span 
                              className='doctor-activate'
                              onClick={()=>{
                                if(x.status === "Activate"){
                                  navigate("/doctor-activate")
                                }
                                if(x.status === "Track"){
                                  navigate("/insta-doc-track")
                                }
                              }}
                            >{x.status}</span>
                            <span className='doctor-activate-logo'></span>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      {/* </Home> */}
      {sortshow ? (
        <div className="sortby-Common-career-main sortby-master-common">
          <div
            onClick={() => {
              setTextColor(11);
              setSortShow(false);
              setSort("oldest");
              setSortName("Oldest");
            }}
            style={{
              color: textcolor === 11 ? "#CB1B5B" : "",
              fontWeight: textcolor === 11 ? "bold" : "",
            }}
          >
            Oldest
          </div>
          <div
            onClick={() => {
              setTextColor(12);
              setSortShow(false);
              setSort("newest");
              setSortName("Newest");
            }}
            style={{
              color: textcolor === 12 ? "#CB1B5B" : "",
              fontWeight: textcolor === 12 ? "bold" : "",
            }}
          >
            Newest
          </div>
          <div
            onClick={() => {
              setTextColor(13);
              setSortShow(false);
              setSort("ascending");
              setSortName("Ascending");
            }}
            style={{
              color: textcolor === 13 ? "#CB1B5B" : "",
              fontWeight: textcolor === 13 ? "bold" : "",
            }}
          >
            Alphabetical ↑
          </div>
          <div
            onClick={() => {
              setTextColor(14);
              setSortShow(false);
              setSort("descending");
              setSortName("Descending");
            }}
            style={{
              color: textcolor === 14 ? "#CB1B5B" : "",
              fontWeight: textcolor === 14 ? "bold" : "",
            }}
          >
            Alphabetical ↓
          </div>
        </div>
      ) : null}

      {/*---------Filter Portion--------*/}
      {
        filtershow
        ?
        <div className="filter-container">
          <div className="filter-header">Filter</div>
          <div className="filter-header-hr"></div>
          <div className="filter-content-doctor">
            <div 
              className="filter-content-card-doctor"
              onClick={()=>{
                setTextColor(1)
              }}
            >
              <div style={{color:textcolor===1?"#CB1B5B":"", fontWeight:textcolor===1?"bold":""}}>Fee</div>
              <div><FaAngleRight className="rightarrow"/></div>
            </div>
            <div 
              className="filter-content-card-doctor"
              onClick={()=>{
                setTextColor(2)
              }}
            >
              <div style={{color:textcolor===2?"#CB1B5B":"", fontWeight:textcolor===2?"bold":""}}>Experience</div>
              <div><FaAngleRight className="rightarrow"/></div>
            </div>
            <div 
              className="filter-content-card-doctor"
              onClick={()=>{
                setTextColor(3)
              }}
            >
              <div style={{color:textcolor===3?"#CB1B5B":"", fontWeight:textcolor===3?"bold":""}}>Gender</div>
              <div><FaAngleRight className="rightarrow"/></div>
            </div>
            <div 
              className="filter-content-card-doctor"
              onClick={()=>{
                setTextColor(4)
              }}
            >
              <div style={{color:textcolor===4?"#CB1B5B":"", fontWeight:textcolor===4?"bold":""}}>Language</div>
              <div><FaAngleRight className="rightarrow"/></div>
            </div>
            <div 
              className="filter-content-card-doctor"
              onClick={()=>{
                setTextColor(5)
              }}
            >
              <div style={{color:textcolor===5?"#CB1B5B":"", fontWeight:textcolor===5?"bold":""}}>City / Town</div>
              <div><FaAngleRight className="rightarrow"/></div>
            </div>
            <div 
              className="filter-content-card-doctor"
              onClick={()=>{
                setTextColor(6)
              }}
            >
              <div style={{color:textcolor===6?"#CB1B5B":"", fontWeight:textcolor===6?"bold":""}}>Primary Specialisation</div>
              <div><FaAngleRight className="rightarrow"/></div>
            </div>
          </div>
          <div className="filter-foot-btn">
            <div>
              <button
                onClick={()=>{
                  setFilterShow(false)
                  setFeeData([])
                  setExpData([])
                  setGenderData([])
                  setLanguageData([])
                  setSplData([])
                  setLocation('') 
                }}
              >Clear</button>
            </div>
            <div>
              <button 
                onClick={()=>{
                  setFilterShow(false)
                  setSubmitFilter(true)
                }}
              >Apply</button>
            </div>
          </div>
        </div>
        : null
      }

{/*-------Fee---------*/}
      {
        textcolor === 1 && filtershow === true
        ? 
        <div className="filter-doctor-fee">
          <div className="filter-doctor-fee-header">Fee</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            {
              fee.map((el, i)=>{
                return(
                  <div className='filter-doctor-fee-box'>
                    <div>{el.text}</div>
                    <div className="checkbox-wrapper">
                      <input 
                        type="checkbox" 
                        className={isChecked ? "checked" : ""}
                        name={el.text} 
                        id="" 
                        onChange={(e)=>{
                          setFeeData([...feedata, e.target.name])
                        }}
                      />
                    </div>
                  </div>
                )
              })
            }
           </div>
        </div>
        : null
      }

{/*-------Experience--------*/}
      {
        textcolor === 2 && filtershow === true
        ? 
        <div className="filter-doctor-fee">
          <div className="filter-doctor-fee-header">Experience</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            {
              experience.map((el, i)=>{
                return(
                  <div className='filter-doctor-fee-box'>
                    <div>{el.text}</div>
                    <div className="checkbox-wrapper">
                      <input 
                        type="checkbox" 
                        className={isChecked ? "checked" : ""}
                        name={el.text} 
                        id="" 
                        onChange={(e)=>{
                          setExpData([...expdata, e.target.name])
                        }}
                      />
                    </div>
                  </div>
                )
              })
            }
           </div>
        </div>
        : null
      }


      {/*-------Gender--------*/}
      {
        textcolor === 3 && filtershow === true
        ? 
        <div className="filter-doctor-fee">
          <div className="filter-doctor-fee-header">Gender</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            {
              gender.map((el, i)=>{
                return(
                  <div className='filter-doctor-fee-box'>
                    <div>{el.text}</div>
                    <div className="checkbox-wrapper">
                      <input 
                        type="checkbox" 
                        className={isChecked ? "checked" : ""}
                        name={el.text} 
                        id="" 
                        onChange={(e)=>{
                          setGenderData([...genderdata, e.target.name])
                        }}
                      />
                    </div>
                  </div>
                )
              })
            }
           </div>
        </div>
        : null
      }

      {/*-------Language--------*/}
      {
        textcolor === 4 && filtershow === true
        ? 
        <div className="filter-doctor-fee">
          <div className="filter-doctor-fee-header">Language</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            {
              language.map((el, i)=>{
                return(
                  <div className='filter-doctor-fee-box'>
                    <div>{el.text}</div>
                    <div className="checkbox-wrapper">
                      <input 
                        type="checkbox" 
                        className={isChecked ? "checked" : ""}
                        name={el.text} 
                        id="" 
                        onChange={(e)=>{
                          setLanguageData([...languagedata, e.target.name])
                        }}
                      />
                    </div>
                  </div>
                )
              })
            }
           </div>
        </div>
        : null
      }

      {/*-------City / Town--------*/}
      {
        textcolor === 5 && filtershow === true
        ? 
        <div className="filter-doctor-fee">
          <div className="filter-doctor-fee-header">City / Town</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            <div className="filter-doctor-search">
              <div>
                <div><img src={search} alt="" /></div>
                <div>
                  {/* <input 
                    type="text" 
                    name="" 
                    id="filter-search" 
                    placeholder="Search"
                    onChange={(e)=>setLocation(e.target.value)}
                  /> */}
                   <Autocomplete
                    apiKey={"AIzaSyBJUZFe3zt4HNVzwdTlmJYMWtXhZ7mfclk"}
                    onPlaceSelected={(place) => {
                      setLocation(place.formatted_address);
                    }}
                    types={["address"]}
                    className="form-control newlocation"
                    defaultValue={location}
                    placeholder=""
                    componentRestrictions={{ country: "us" }}
                    preventDefault
                  />
                </div>
              </div>
            </div>
           </div>
        </div>
        : null
      }

      {/*-------Primary Specialisation--------*/}
      {
        textcolor === 6 && filtershow === true
        ? 
        <div className="filter-doctor-fee">
          <div className="filter-doctor-fee-header">Primary Specialisation</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            {
              primaryspl.map((el, i)=>{
                return(
                  <div className='filter-doctor-fee-box'>
                    <div>{el.text}</div>
                    <div className="checkbox-wrapper">
                      <input 
                        type="checkbox" 
                        className={isChecked ? "checked" : ""}
                        name={el.text} 
                        id="" 
                        onChange={(e)=>{
                          setSplData([...spldata, e.target.name])
                        }}
                      />
                    </div>
                  </div>
                )
              })
            }
           </div>
        </div>
        : null
      }
    </>
  )
}

export default DoctorInstaDoc