import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";

import apiCall from "../../../api";
import BackBtn from "../../Common/backBtn";
import Breadcrumbs from "../../Common/Breadcrumbs/Breadcrumbs";
import InputTextField from "../../Common/inputTextField";
import SucessModal from "../../modal/SucessModal";

const AddSitemap = ({
	edit = false
}) => {
	// const { id } = useParams();
	const { id, category } = useParams();
	const [success, setSuccess] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);

	const {
		register,
		setValue,
		getValues,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const handleFileChange = (event) => {
		setSelectedFile(event.target.files[0]);
	};

	useEffect(() => {
		edit && getMenuData();
	}, [edit]);

	useEffect(() => {
		if (selectedFile?.length && getValues()?.sitemap_folder) {
			setValue('sitemap_folder', getValues()?.sitemap_folder);
		}
	}, [selectedFile, setValue, getValues])

	const getMenuData = async () => {
		try {
			const response = await apiCall(`sitemap/get/${id}`, "GET",
				{})
			console.log("response111213--->", response.data);
			setValue('sitemap_name', response.data.sitemap_name);
			setValue('total_url_sitemap', response.data.total_url_sitemap);
			setValue('sitemap_folder', response.data.sitemap_folder);
			setValue('previousUpdate', response.data.updatedAt);
			// setValue('sitemap_folder', response.data.sitemap_folder);
			setValue('live_sitemap_url', response.data.live_sitemap_url);
		} catch (err) {
			console.error(err);
		}
	}

	const handleSitemapSubmit = async (data) => {
		const formData = new FormData();
		if (selectedFile) {
			formData.append('siteMap', selectedFile);
		}

		formData.append('liveSitemapUrl', data.live_sitemap_url);
		const response = await fetch(`${process.env.REACT_APP_USER_SITE_URL}api/upload`, {
			method: 'POST',
			body: formData,
		});

		const result = await response.json();

		if (response.ok) {
			const fields = {
				live_sitemap_url: data.live_sitemap_url,
				sitemap_name: data.sitemap_name,
				total_url_sitemap: data.total_url_sitemap,
			};
			onSubmit(fields);
			setSuccess(true);
		} else {
			console.error(result.message);
		}
	};

	const onSubmit = (fields) => {
		if (edit) {
			axios
				.patch(
					`${process.env.REACT_APP_API_URL}sitemap/update/${id}`,
					{ ...fields, previousUpdate: getValues()?.previousUpdate }
				)
				.then((response) => {
					if (response.data?._id) {
						setSuccess(true);
					} else {
						alert("Fail");
						console.log("failed...");
					}
				})
				.catch((error) => {
					console.error("Error during update API call:", error);
				});
		} else {
			axios
				.post(
					`${process.env.REACT_APP_API_URL}sitemap/create`,
					{ ...fields, sitemap_category: category }
				)
				.then((response) => {
					if (response.data?._id) {
						setSuccess(true);
					} else {
						alert("Fail");
						console.log("failed...");
					}
				})
				.catch((error) => {
					console.error("Error during create API call:", error);
				});
		}
	};

	return (
		<>
			<BackBtn
				navigatePath={`/sitemap/${category}`}
			/>
			<div className="healtharticle mt-5">
				<div className="mt-4 pt-5 ms-5">
					<Breadcrumbs
						breadcrumbText="Sitemap"
						breadcrumbLink="#"
						nestedText="Sitemap Analysis"
						nestedLink="/parent-sitemap"
						nestedText2={edit ? `Edit Sitemap` : `Add Sitemap`}
						nestedLink2="#"
					/>
				</div>
				<form className="carr-dep-form ms-5" onSubmit={handleSubmit(handleSitemapSubmit)}>
					<div className="addnew-form1-autor-detail mt-5 pb-5 d-flex">
						<div className="col-md-6">
							<InputTextField
								labelName="Sitemap Name"
								name="sitemap_name"
								register={register}
								type="text"
								errors={errors}
								className="mt-2 h-100"
								placeholder="Type here"
								errorText={"Please provide a Sitemap Name"}
							/>
						</div>
						<div className="col-md-6">
							<InputTextField
								labelName="Total url's in Sitemap"
								name="total_url_sitemap"
								register={register}
								type="number"
								errors={errors}
								className="mt-2 h-100"
								placeholder="Type here"
								errorText={"Please provide a Sitemap Name"}
							/>
						</div>
					</div>
					<div className="addnew-form1-autor-detail mt-5 d-flex">
						<div className="col-md-6">
							<label className="form-label title mb-2">Sitemap File</label>
							<div className="meta-tag-file w-75 mt-2 d-flex align-items-center">
								<input
									type="file"
									className="hidden custom-file-input bg-white w-75 mt-2"
									{...register("sitemap_folder", { required: true })}
									onChange={handleFileChange}
								/>
								<label>{selectedFile ? selectedFile.name : "No file chosen"}</label>
							</div>
						</div>
						{/* <div className="col-md-6">
                            <label className="form-label title mb-2">Sitemap Folder (ZIP)</label>
                            <div className="meta-tag-file w-75 mt-2 d-flex align-items-center">
                                <input
                                    type="file"
                                    className="hidden custom-file-input bg-white w-75 mt-2"
                                    accept=".zip"
                                    {...register("siteMap_zip_file", { required: true })}
                                    onChange={handleZipFileChange}
                                />
                                <label>{selectedFileZip ? selectedFileZip.name : "No file chosen"}</label>
                            </div>
                        </div> */}
						<div className="col-md-6">
							<InputTextField
								labelName="Live Sitemap URL"
								name="live_sitemap_url"
								register={register}
								errors={errors}
								className="mt-2 h-75"
								placeholder="Type here"
								errorText={"Please provide a Live Sitemap URL"}
							/>
						</div>
					</div>
					<div className="car-dep-form-button mb-5 mt-5 pb-5">
						<button type="submit">SUBMIT</button>
					</div>
				</form>
			</div>
			{success && (
				<SucessModal
					page={`/sitemap/${category}`}
					name="Sitemap"
					post='posted'
				/>
			)}
		</>
	);
};

export default AddSitemap;