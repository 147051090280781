import axios from 'axios';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { FaSearch } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import filter1 from "../../../assets/svg/Health Article/filtericon.svg";
import sort1 from "../../../assets/svg/Health Article/sortbyicon.svg";
import Deletepopup from '../../modal/Deletepopup';
import "./comment.scss";

const Comments = () => {

  const [sortName, setSortName] = useState("Sort by");

  const [sortshow, setSortShow] = useState(false);
  const [textcolor, setTextColor] = useState(0);

  const navigate = useNavigate()

  // const handleMenu = () => {
  //     navigate("/addNewMenuForm");
  //   };

  //   const handleCategory = () => {
  //     navigate("/addNewCategoryForm");
  //   };

  //   const handleComment = ()=>{
  //     navigate("/comment")
  //   }

  //   const handleClick = () => {
  //     navigate("/Category");
  //   };

  const [data, setData] = useState([])
  const [blogdata, setBlogData] = useState([])

  useEffect(() => {
    getData()
    getBlogData()
  }, [])

  const getData = () => {
    axios.post(`${process.env.REACT_APP_API_URL}post-a-comment/list`)
      .then((res) => {
        setData(res.data)
      })
  }

  const getBlogData = () => {
    axios.post(`${process.env.REACT_APP_API_URL}health-article/list`)
      .then((res) => {
        setBlogData(res.data.rows)
      })
  }

  const [del, setDel] = useState(false)
  const [id, setId] = useState("")

  const handleDelete = (id) => {
    fetch(`${process.env.REACT_APP_API_URL}post-a-comment/delete/${id}`, {
      method: "DELETE",
    }).then((result) => {
      result.json().then((res) => {
        getData()
        getBlogData()
        setDel(false)
      });
    });
  };

  const handleCommentView = (id) => {
    navigate(`/view-comment/${id}`)
  }

  return (
    <>
      {/* <Home> */}
      <div className="healtharticle">
        <div className="healtharticle-container ps-3">
          <div className="healtharticle-title ms-2">
            <Link
              to="/dashboard"
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "15px",
              }}
            >
              <span>Pop-up Data</span>
            </Link>
            <span>{`>`}</span>
            <span>Post a Comment</span>
          </div>
          {/* <div className="add-btn mt-3">
              <button onClick={handleMenu} className=" new-menu-btn me-3">
                <img className="add-img" src={add} alt="" /> Add New Menu
              </button>
              <button onClick={handleCategory} className="new-category-btn">
                <img className="add-img" src={add} alt="" />
                Add New Category
              </button>
            </div> */}
          {/* <div className="careers-tab mt-5">
              <div 
                 className="career-tab-career" 
                 onClick={() => {
                  navigate("/healtharticlemenu");
                }}>Menu</div>
              <div
                className="career-tab-jobpost text-muted"
                onClick={handleClick}
              >
                Category
              </div>
              <div className="career-tab-comment" onClick={handleComment}>Comments</div>
            </div> */}

          <div className="healtharticle-show mt-5 ms-2">
            <div>Show</div>
            <div>
              <select
                // value={limit}
                // onChange={(e) => setLimit(e.target.value)}
                className="me-2"
              >
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
                <option value="0">All</option>
              </select>
            </div>
            <div>entries</div>
            <div>
              <div>
                <FaSearch />
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Search"
                  className="form-control"
                // onChange={(e) => filter_records(e.target.value)}
                />
              </div>
            </div>
            <div className="sortandfilter-new">
              <div className="sortByPosition">
                <div>
                  <div>
                    <div
                      onClick={() => {
                        setSortShow(!sortshow);
                      }}
                    >
                      {/* Sort By */}
                      {sortName}
                    </div>
                    {/* <select 
                          // onChange={(e) => setSort(e.target.value)}
                        >
                          <option>Sort by</option>
                          <option value="id-desc"><div style={{color:"red"}}>Oldest</div></option>
                          <option value="name-asc"><div>Newest</div></option>
                          <option value="id-asc"><div>Alphabetical ↑</div></option>
                          <option value="id-asc"><div>Alphabetical ↓</div><span><img src="" alt="" /></span></option>
                        </select> */}
                  </div>
                  <div>
                    <img src={sort1} alt="" />
                  </div>
                </div>
              </div>
              <div className="sortByDepartment">
                <div>
                  <div>
                    <div
                    // onClick={() => {
                    //   setFilterShow(true);
                    // }}
                    >
                      Filter
                    </div>
                    {/* <select
                        name=""
                        id=""
                        // onChange={(e) => {
                        //   setFilter((prevFilter) => {
                        //     prevFilter.active = e.target.value;
                        //     return prevFilter;
                        //   });
                        //   list_api_call();
                        // }}
                      >
                        <option value="">Filter</option>
                        <option value="Asc to Des">Asc to Des</option>
                        <option value="Des to Asc">Des to Asc</option>
                      </select> */}
                  </div>
                  <div>
                    <img src={filter1} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="healtharticle-table menu-table table-responsive mt-3 ms-1 post-a-commet-table">
            <table className="table table-bordered ">
              <thead>
                <tr>
                  <th className="t-head">S.No</th>
                  <th className='t-head'>Blog Name</th>
                  <th className="t-head">Name</th>
                  <th className="t-head">Email</th>
                  <th className="t-head">Comments</th>
                  <th className="t-head">Date & Time</th>
                  <th className="t-head">Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.map((el, i) => {
                  return (
                    <tr key={el._id}>
                      <td>{i + 1}</td>
                      <td>{blogdata.map((ele) => {
                        if (ele._id === el.id) {
                          return (
                            <span>{ele.title}</span>
                          )
                        } else {
                          return <></>
                        }
                      })}</td>
                      <td>{el.name}</td>
                      <td>{el.email}</td>
                      <td>{el.comment}</td>
                      <td>{`${moment(el.createdAt).format('DD-MM-YYYY')} & ${moment(el.createdAt).format('LT')}`}</td>
                      <td>
                        <span
                          className='post-comment-delete'
                          onClick={() => {
                            setId(el._id)
                            setDel(true);
                          }}
                        >Delete</span>
                        <span className='post-comment-delete-logo'></span>
                        <span
                          className='post-comment-view'
                          onClick={() => {
                            handleCommentView(el._id)
                          }}
                        >View</span>
                        <span className='post-comment-view-logo'></span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* </Home> */}
      {
        del === true
        &&
        <Deletepopup
          handleDelete={handleDelete}
          id={id}
        />
      }

      {sortshow ? (
        <div className="sortby-Common-career-main sortby-popup-comments">
          <div
            onClick={() => {
              setTextColor(11);
              setSortShow(false);
              setSortName("Oldest");
            }}
            style={{
              color: textcolor === 11 ? "#CB1B5B" : "",
              fontWeight: textcolor === 11 ? "bold" : "",
            }}
          >
            Oldest
          </div>
          <div
            onClick={() => {
              setTextColor(12);
              setSortShow(false);
              setSortName("Newest");
            }}
            style={{
              color: textcolor === 12 ? "#CB1B5B" : "",
              fontWeight: textcolor === 12 ? "bold" : "",
            }}
          >
            Newest
          </div>
          <div
            onClick={() => {
              setTextColor(13);
              setSortShow(false);
              setSortName("Ascending");
            }}
            style={{
              color: textcolor === 13 ? "#CB1B5B" : "",
              fontWeight: textcolor === 13 ? "bold" : "",
            }}
          >
            Alphabetical ↑
          </div>
          <div
            onClick={() => {
              setTextColor(14);
              setSortShow(false);
              setSortName("Descending");
            }}
            style={{
              color: textcolor === 14 ? "#CB1B5B" : "",
              fontWeight: textcolor === 14 ? "bold" : "",
            }}
          >
            Alphabetical ↓
          </div>
        </div>
      ) : null}
    </>
  )
}

export default Comments