import filterIcon from "../../assets/svg/filter-img.svg"
import sortIcon from "../../assets/svg/sort_icon.svg";
import excel from "../../assets/svg/excel.svg";
import search from "../../assets/images/searchIcon.png";
import { Button } from "antd";

const DoctorsFilterOptions = ({
	setLimit = () => { },
	onChange = () => { },
	setSortShow = () => { },
	setFilterShow = () => { },
	limit,
	sortName,
	sortshow,
	// search,
	filterBy = true,
	showOption = false,
	downloadExcel = false,
	onClickDownload = () => {}
}) => {
	const filterSortBtns = () => {
		return (
			<div className="rightContent">
				{filterBy ? (
					<Button className="filterByBtn" onClick={() => setFilterShow(true)}>
						<span>
							Filter{" "}By
						</span>
						<img src={filterIcon} alt="" />
					</Button>
				) : <></>}
				<Button
					onClick={() => {
						setSortShow(!sortshow);
					}}
					className="sortBtn"
				>
					{sortName}
					<img src={sortIcon} alt="" />
				</Button>
			</div>
		)
	}
	return (
		<div className={`filterSortOptions my-3 ${downloadExcel ? '':'justify-content-between'}`}>
			<div className="leftContent">
				<div>Show</div>
				<div>
					<select
						value={limit}
						onChange={(e) => setLimit(e.target.value)}
						className="me-2"
					>
						<option>10</option>
						<option>25</option>
						<option>50</option>
						<option>100</option>
						{showOption ?
							null : <option value="0">All</option>
						}
					</select>
				</div>
				<div>Entries</div>
				<div>
					<div>
						{/* <FaSearch /> */}
						<img src={search} alt="" />
					</div>
					<div>
						<input
							type="text"
							placeholder="Search"
							className="form-control"
							// onChange={onChange}
							onChange={(e) => onChange(e.target.value)}

						/>
					</div>
				</div>
			</div>
			{downloadExcel ?
				<div className={`d-flex ${downloadExcel ? 'w-100 justify-content-between':''}`}>
					{filterSortBtns()}
					<Button
						onClick={onClickDownload}
						className="downloadExcel"
					>
						Download
						<img src={excel} alt="" />
					</Button>
				</div> : filterSortBtns()}
		</div>
	)
}

export default DoctorsFilterOptions