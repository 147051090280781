import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import profileIcon from "../../../assets/svg/avatar.svg";
import headerLogo from "../../../assets/svg/logo.svg";
import logoutIcon from "../../../assets/svg/logout_icon.svg";
import pipeIcon from "../../../assets/svg/pipe-icon.svg";
import "./header.scss";

const formatAMPM = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours}:${minutes} ${ampm}`;
};

const TimeDisplay = () => {
  const [time, setTime] = useState(() => formatAMPM(new Date()));

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(formatAMPM(new Date()));
    }, 60000); // Update every 60 seconds

    return () => clearInterval(interval);
  }, []);

  return <div className="time text-muted mt-1 me-1">{time}</div>;
};

const Header = () => {
  const navigate = useNavigate();

  const currentDate = (() => {
    const date = new Date();
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  })();

  const handleHome = () => navigate("/");

  return (
    <header className="header">
      <div className="container-fluid">
        <div className="row  pt-3">
          <div className="left-container col d-flex align-items-center ms-3">
            <img
              src={headerLogo}
              alt="Logo"
              className="header-logo"
              onClick={handleHome}
            />
          </div>
          <div className="right-container col d-flex justify-content-between  align-items-center col-4 me-3 mt-1">
            <div className="time-date d-flex ">
              {/* <div className="time text-muted mt-1  me-1">{time}</div> */}
              <TimeDisplay />
              <div className="date text-muted mt-1  me-1">|</div>
              <div className="curdate text-muted mt-1 ms-1 me-1">
                {currentDate}
              </div>
            </div>
            <div className="img-profile">
              <img src={profileIcon} alt="icon" />
            </div>
            <div className="adminName fw-bold">Admin</div>
            <img src={pipeIcon} alt="" />
            <div className="logout d-flex">
              <div className="logout-icon me-2 text-center">
                <img src={logoutIcon} alt="" />
              </div>
              <div
                className="header-logout"
                data-bs-toggle="modal"
                data-bs-target="#logoutModal"
              >
                Log out
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
