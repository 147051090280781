import React from "react";
import { Modal, Button, Form, Input, message } from "antd";

const ExcelDownloadModal = ({isModalOpen, setIsModalOpen,onClickDownload, showModal}) => {
  const [form] = Form.useForm();
  const handleCancel = () => {
    setIsModalOpen(false);


    form.resetFields();
  };

  const handleDownload = () => {
    form
      .validateFields()
      .then((values) => {
        const { username, password } = values;
        if (username === "HealthSy" && password === "7TcvKsL9Ug%3A4TF") {
          onClickDownload()
          message.success("Access granted! Starting download...");
          // Logic to download Excel file goes here
          setIsModalOpen(false)
        } else {
          message.error("Invalid username or password.");
        }
      })
      .catch((info) => {
        console.log("Validation Failed:", info);
      });
  };
  console.log("isModalOpen-->", isModalOpen);
  
  return (
    // <div>
    //   <Button type="primary" onClick={showModal}>
    //     Download Excel
    //   </Button>
    <div className="exportDwnWrapper">
      <Modal
        title="Bulk Download Confirmation"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="download" type="primary" onClick={handleDownload}>
            Download
          </Button>,
        ]}
      >
         <p className="exportDwnContent m-0 mb-3">To proceed with the bulk download, please enter your username and password below</p>
        <Form form={form} layout="vertical" name="download_form">
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: "Please enter your username!" }]}
          >
            <Input placeholder="Enter username" />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please enter your password!" }]}
          >
            <Input.Password placeholder="Enter password" />
          </Form.Item>
        </Form>
      </Modal>
      </div>
    // </div>
  );
};

export default ExcelDownloadModal;
