import { useEffect, useState } from "react";
import { useParams } from "react-router";

import Breadcrumbs from "../../../../Common/Breadcrumbs/Breadcrumbs";
import BackBtn from "../../../../Common/backBtn";
import InputRadioField from "../../../../Common/inputRadioField";
import apiCall from "../../../../../api";

const DoctorClinicSpecialityView = () => {

  const [data, setData] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    getData();
  }, []);

  const getData = async (fields) => {
    const result = await apiCall(`clinic-speciality/get/${id}`, "GET", fields)
    if (result.data?._id) {
      setData(result.data);
    }
  };

  return (
    <>
      <BackBtn
        navigatePath="/doctors-clinic-speciality"
      />
      <div className="healtharticle ms-0 pb-4 bg-white mt-5 ps-4 pt-5">
        <Breadcrumbs
          breadcrumbText="Doctors"
          breadcrumbLink="/add-new-doctors"
          nestedText="Common"
          nestedLink=""
          nestedText2="View Clinic Speciality"
          nestedLink2=""
        />
        <div className="row mt-5 ms-2 new-menu ">
          <div className="menu-field col-md-3 me-0">
            <p className="form-label " htmlFor="">
              Clinic Speciality
            </p>
            <div className="blog-view-text-half-bor">{data.clinic_speciality}</div>
          </div>

          <div className="col">
            <InputRadioField
              viewOnly={true}
              checked={data.active === true ? "checked" : null}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default DoctorClinicSpecialityView
