import React from "react";
import { Link, useNavigate } from "react-router-dom";
import backRed from "../../../assets/svg/back-rose-icon.svg";
import { useState } from "react";
import SucessModal from "../../modal/SucessModal";

const DoctorInclinicTrackEdit = () => {
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);

  return (
    <>
      {/* <Home> */}
      <div
        onClick={() => navigate("/clinic-track")}
        className="rose back-button"
      >
        <img src={backRed} alt="" />
        <span className="rose-back">Back</span>
      </div>

      <div className="healtharticle">
        <div className="healtharticle-container ps-3">
          <div className="healtharticle-title ms-0">
            <Link
              to="/dashboard"
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "15px",
              }}
            >
              <span style={{ paddingLeft: "5%" }}>Dashboard</span>
            </Link>
            <span style={{ paddingLeft: "1%" }}>{`>`}</span>
            <span style={{ paddingLeft: "0.4%" }}>
              <Link
                to="/add-new-doctors"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                }}
              >
                Partnered Retail Pharmacies
              </Link>
            </span>
            {/* <span style={{ paddingLeft: "0.4%" }}>></span>
                <span style={{ paddingLeft: "0.4%" }}>
                  List of Doctors for Online Consultation
                </span> */}
            <span style={{ paddingLeft: "0.4%" }}>{`>`}</span>
            <span style={{ paddingLeft: "0.4%" }}>Track Retail Pharmacy</span>
            <span style={{ paddingLeft: "0.4%" }}>{`>`}</span>
            <span style={{ paddingLeft: "0.4%" }}>Edit Retail Pharmacy</span>
          </div>

          <div className="addnew-form1-autor-detail ms-2 mt-2 d-flex">
            <div className="col-md-4">
              <label for="inputAddress" className="form-label title">
                Order ID
                <span className="star">*</span>
              </label>
              <input
                type="text"
                className="form-control addnew-input"
                id="orderid"
                placeholder="Type here"
                style={{ paddingLeft: "10px" }}
              />
            </div>

            <div className="col-md-4">
              <label for="inputAddress" className="form-label title">
                Customer Name <span className="star">*</span>
              </label>
              <input
                type="text"
                className="form-control addnew-input"
                id="name"
                placeholder="Type here"
                style={{ paddingLeft: "10px" }}
              />
            </div>

            <div className="col-md-4">
              <label for="inputAddress" className="form-label title">
                Mobile Number <span className="star">*</span>
              </label>
              <input
                type="text"
                className="form-control addnew-input"
                id="mobile"
                placeholder="Type here"
                style={{ paddingLeft: "10px" }}
              />
            </div>
          </div>

          <div className="addnew-form1-autor-detail ms-2 mt-3 d-flex">
            <div className="col-md-4">
              <label for="inputAddress" className="form-label title">
                Item(s) Ordered
                <span className="star">*</span>
              </label>
              <input
                type="text"
                className="form-control addnew-input"
                id="orderid"
                placeholder="Type here"
                style={{ paddingLeft: "10px" }}
              />
            </div>

            <div className="col-md-4">
              <label for="inputAddress" className="form-label title">
                Received <span className="star">*</span>
              </label>
              <input
                type="text"
                className="form-control addnew-input"
                id="name"
                placeholder="Type here"
                style={{ paddingLeft: "10px" }}
              />
            </div>

            <div className="col-md-4">
              <label for="inputAddress" className="form-label title">
                Order Status <span className="star">*</span>
              </label>
              <input
                type="text"
                className="form-control addnew-input"
                id="mobile"
                placeholder="Type here"
                style={{ paddingLeft: "10px" }}
              />
            </div>
          </div>

          <div className="addnew-form1-autor-detail ms-2 mt-3 d-flex">
            <div className="col-md-4">
              <label for="inputAddress" className="form-label title">
                Fulfilment Status
                <span className="star">*</span>
              </label>
              <input
                type="text"
                className="form-control addnew-input"
                id="orderid"
                placeholder="Type here"
                style={{ paddingLeft: "10px" }}
              />
            </div>

            <div className="col-md-4">
              <label for="inputAddress" className="form-label title">
                Order Value <span className="star">*</span>
              </label>
              <input
                type="text"
                className="form-control addnew-input"
                id="name"
                placeholder="Type here"
                style={{ paddingLeft: "10px" }}
              />
            </div>

            <div className="col-md-4">
              <label for="inputAddress" className="form-label title">
                HealthSy % <span className="star">*</span>
              </label>
              <input
                type="text"
                className="form-control addnew-input"
                id="mobile"
                placeholder="Type here"
                style={{ paddingLeft: "10px" }}
              />
            </div>
          </div>

          <div className="car-pos-form-button mb-5">
            <button type="button" onClick={() => setSuccess(true)}>
              UPDATE
            </button>
          </div>
        </div>
      </div>
      {/* </Home> */}
      {success && (
        <SucessModal
          page={"/clinic-track"}
          name="Clinic/Hospital"
          post="updated"
        />
      )}
    </>
  );
};

export default DoctorInclinicTrackEdit;
