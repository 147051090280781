import Moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import apiCall from "../../../api";
import AddButton from "../../Common/addButton";
import Breadcrumbs from "../../Common/Breadcrumbs/Breadcrumbs";
import FilterOptions from "../../Common/filterOptions";
import Pagination from "../../Common/pagination";
import Deletepopup from "../../modal/Deletepopup";

import contact from "../../../assets/images/HealthSy/contact.png";
import newdelete from "../../../assets/images/HealthSy/delete.png";
import search from "../../../assets/images/searchIcon.png";

const SitemapCategory = () => {
	const navigate = useNavigate();
	const [id, setId] = useState("")
	const [skip, setSkip] = useState(0);
	const [del, setDel] = useState(false);
	const [pages,] = useState(0);
	const [limit, setLimit] = useState(10);
	const [filter,] = useState({});
	const [records, setRecords] = useState([]);
	const [sort,] = useState("newest");
	const [sortshow, setSortShow] = useState(false);
	const [current_page, setCurrentPage] = useState(1);
	const [sortName,] = useState("Sort by");
	// const [filtershow, setFilterShow] = useState(false);
	const [filtered_records, setFilteredRecords] = useState([]);

	useEffect(() => {
		list_api_call();
	}, [filter, limit, skip]);

	useEffect(() => {
		if (current_page > 1) {
			setSkip((current_page - 1) * limit);
		} else {
			setSkip(0);
		}
	}, [current_page]);

	const handleServices = () => {
		navigate("/add-sitemap-category-form")
	}

	const handleEdit = (id) => {
		navigate(`/edit-sitemap-category-form/${id}`)
	}

	const handleDelete = async (id) => {
		try {
			const response = await apiCall(`sitemap-category/delete/${id}`, "DELETE");

			if (response) {
				list_api_call();
				setDel({ id: "", status: false });
			}
		} catch (err) {
			console.error(err);
		}
	};

	const handleActive = async (id, status) => {
		try {
			const response = await apiCall(`sitemap-category/update/active/${id}`, "PATCH", {
				active: !status,
			});

			if (response) {
				list_api_call();
			}
		} catch (err) {
			console.error(err);
		}
	};

	const list_api_call = async () => {
		try {
			const response = await apiCall(`sitemap-category/list`, "POST", {
				...filter,
				skip: skip,
				limit: limit,
				sort: sort,
			});

			setFilteredRecords([]);
			setRecords(response.data?.rows);
		} catch (err) {
			console.error(err);
			alert("Some error occurred while fetching filter options");
		}
	}

	const filter_records = (filter) => {
		let rows = records.filter((x) => {
			return JSON.stringify(x).toLowerCase().indexOf(filter.toLowerCase()) > -1;
		});
		setFilteredRecords(rows); <span className="master-view-icon"></span>
	};

	return (
		<>
			<div className="healtharticle">
				<div className="healtharticle-container">
					<div className="healtharticle-title carrer-job-title ms-4">
						<Breadcrumbs
							breadcrumbText="Sitemap"
							breadcrumbLink="/sitemap-category"
							nestedText="Common Sitemap Category"
							nestedLink="#"
						/>
					</div>
					<div className="healtharticle-addnew">
						<AddButton
							onClick={handleServices}
							btnName="Add Sitemap Header Name"
						/>
					</div>
					<FilterOptions
						limit={limit}
						sortshow={sortshow}
						sortName={sortName}
						search={search}
						// setFilterShow={setFilterShow}
						setSortShow={setSortShow}
						filter_records={filter_records}
						setLimit={setLimit}
					/>
					<div className="healtharticle-table master-doctor-table">
						<table>
							<thead>
								<tr>
									<td className="career-jobpost-td1">S.No</td>
									<td className="career-jobpost-td2">Sitemap Category</td>
									<td>Added On</td>
									<td className="career-jobpost-td4">Status</td>
									<td className="career-jobpost-td5">Actions</td>
								</tr>
							</thead>
							<tbody>
								{(filtered_records.length > 0
									? filtered_records
									: records
								).map((el, i) => {
									return (
										<tr key={el._id}>
											<td>{(current_page - 1) * limit + (i + 1)}</td>
											<td className="">{el.title}</td>
											<td>{Moment(el.createdAt).format("DD-MM-YYYY | hh:mm A")}</td>
											<td>{el.active ? "Success" : "Failed"}</td>
											<td>
												<span className="master-edit" onClick={() => handleEdit(el._id)}>Edit</span>
												<span className="contact-logo">
													<img src={contact} alt="" />
												</span>
												<span className="master-delete" onClick={() => {
													setId(el._id)
													setDel(true);
												}}>Delete</span>
												<span className="newdelete-logo">
													<img src={newdelete} alt="" />
												</span>
												<span
													className={`cursorPointer ms-3 ${el.active ? "failed" : 'success'}`}
													onClick={() => handleActive(el._id, el.active)}>
													{el.active ? "Inactive" : "Active"}
												</span>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<Pagination
				setCurrentPage={setCurrentPage}
				current_page={current_page}
				pages={pages}
			/>
			{
				del === true
				&&
				<Deletepopup
					handleDelete={handleDelete}
					id={id}
				/>
			}
		</>
	)
}

export default SitemapCategory