import axios from "axios"
import { useEffect, useState } from "react"
import { FaAngleRight } from "react-icons/fa"
import { useNavigate } from "react-router"

import contact from "../../../../assets/images/HealthSy/contact.png";
import newdelete from "../../../../assets/images/HealthSy/delete.png";
import inactive from "../../../../assets/svg/inactive.svg";
import search from "../../../../assets/images/searchIcon.png"
import Breadcrumbs from "../../../Common/Breadcrumbs/Breadcrumbs"
import AddButton from "../../../Common/addButton"
import FilterOptions from "../../../Common/filterOptions"
import Pagination from "../../../Common/pagination"
import Deletepopup from "../../../modal/Deletepopup"
import NavBarTitles from "../../../Common/navTabTitles";

const PGDegree = () => {

    const navigate = useNavigate()

    const handleDegreeForm = () => {
        navigate("/master-pg-degree-create")
    }

    const handleEdit = (id) => {
        navigate(`/master-pg-degree-edit/${id}`)
    }

    const handleView = (id) => {
        navigate(`/master-pg-degree-view/${id}`)
    }

    const [del, setDel] = useState(false)
    const [id, setId] = useState("")

    const handleDelete = (id) => {
        fetch(`${process.env.REACT_APP_API_URL}pg-degree/delete/${id}`,
            {
                method: "DELETE",
            }
        ).then((result) => {
            result.json().then((res) => {
                list_api_call();
                setDel(false)
            });
        });
    };

    const [filtered_records, setFilteredRecords] = useState([]);
    const [, setRowCount] = useState(0);
    const [pages, setPages] = useState(0);
    const [current_page, setCurrentPage] = useState(1);
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(10);
    const [sort, setSort] = useState("newest");
    const [sortName, setSortName] = useState("Sort by");
    const [filter,] = useState({});
    const [records, setRecords] = useState([]);

    const [sortshow, setSortShow] = useState(false);
    const [textcolor, setTextColor] = useState(0);
    const [filtershow, setFilterShow] = useState(false);
    const [, setSubmitFilter] = useState(false);
    const [expriencedata, setExperienceData] = useState([]);
    const [statusdata, setStatusData] = useState([]);
    const [isChecked,] = useState(true);

    const experience = [
        {
            text: "Less than 2 Years"
        },
        {
            text: "1-2 Years"
        },
        {
            text: "2-3 Years"
        },
        {
            text: "3-5 Years"
        },
        {
            text: "5+ Years"
        }
    ]

    const status = [
        {
            text: "Active",
        },
        {
            text: "Inactive",
        },
    ];

    useEffect(() => {
        list_api_call();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, limit, sort, skip]);

    useEffect(() => {
        if (current_page > 1) {
            setSkip((current_page - 1) * limit);
        } else {
            setSkip(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current_page]);

    const list_api_call = () => {
        axios
            .post(`${process.env.REACT_APP_API_URL}pg-degree/list`, {
                ...filter,
                skip: skip,
                limit: limit,
                sort: sort,
            })
            .then((response) => {
                setFilteredRecords([]);
                setRecords(response.data.rows);
                setRowCount(response.data.count);
                setPages(Math.ceil(response.data.count / limit));
            })
            .catch((err) => {
                console.error(err);
                alert("Some error");
            });
    };

    const filter_records = (filter) => {
        let rows = records.filter((x) => {
            return JSON.stringify(x).toLowerCase().indexOf(filter.toLowerCase()) > -1;
        });
        setFilteredRecords(rows);
    };

    return (
        <>
            <div className="healtharticle">
                <div className="healtharticle-container">
                    <div className="healtharticle-title carrer-job-title ms-4">
                        <Breadcrumbs
                            breadcrumbText="Master"
                            breadcrumbLink=""
                            nestedText="Doctors"
                            nestedLink=""
                            nestedText2="PG Course"
                            nestedLink2=""
                        />
                    </div>

                    <div className="healtharticle-addnew">
                        <AddButton
                            onClick={handleDegreeForm}
                            btnName="Add New PG Degree"
                        />
                    </div>
                    <NavBarTitles
                        titles={
                            [
                                {
                                    name: "Primary Specialisation",
                                    navigatePath: "/master-primary-specialisation",
                                },
                                {
                                    name: "In-Clinic Fee",
                                    navigatePath: "/master-inclinic-fee",
                                },
                                {
                                    name: "UG Degree",
                                    navigatePath: "/master-ug-course",
                                },
                                {
                                    name: "PG Degree",
                                    navigatePath: "/master-pg-degree",
                                    className: "career-tab-jobpost master-inclinic"
                                }
                            ]
                        }
                    />
                    <FilterOptions
                        limit={limit}
                        sortshow={sortshow}
                        sortName={sortName}
                        search={search}
                        setFilterShow={setFilterShow}
                        setSortShow={setSortShow}
                        filter_records={filter_records}
                        setLimit={setLimit}
                    />

                    <div className="healtharticle-table master-doctor-table">
                        <table>
                            <thead>
                                <tr>
                                    <td className="career-jobpost-td1">S.No</td>
                                    <td className="career-jobpost-td2">PG Degree</td>
                                    <td className="career-jobpost-td3">Status</td>
                                    <td className="career-jobpost-td12">Actions</td>
                                </tr>
                            </thead>
                            <tbody>
                                {(filtered_records.length > 0
                                    ? filtered_records
                                    : records
                                ).map((el, i) => {
                                    return (
                                        <tr key={el._id}>
                                            <td>{(current_page - 1) * limit + (i + 1)}</td>
                                            <td>{el.pg_degree}</td>
                                            <td>{el.active === true ? "Verified" : "Not Verified"}</td>
                                            <td>
                                                <span className="master-edit" onClick={() => { handleEdit(el._id) }}>Edit</span>
                                                <span className="contact-logo">
                                                    <img src={contact} alt="" />
                                                </span>
                                                <span className="master-delete" onClick={() => {
                                                    setId(el._id)
                                                    setDel(true);
                                                }}>Delete</span>
                                                <span className="newdelete-logo">
                                                    <img src={newdelete} alt="" />
                                                </span>
                                                <span className="master-view" onClick={() => { handleView(el._id) }}>View</span>
                                                <span className="inactive-icon">
                                                    <img src={inactive} alt="" />
                                                </span>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Pagination
                setCurrentPage={setCurrentPage}
                current_page={current_page}
                pages={pages}
            />
            {
                del === true
                &&
                <Deletepopup
                    handleDelete={handleDelete}
                    id={id}
                />
            }

            {sortshow ? (
                <div className="sortby-Common-career-main sortby-master-common">
                    <div
                        onClick={() => {
                            setTextColor(11);
                            setSortShow(false);
                            setSort("oldest");
                            setSortName("Oldest");
                        }}
                        style={{
                            color: textcolor === 11 ? "#CB1B5B" : "",
                            fontWeight: textcolor === 11 ? "bold" : "",
                        }}
                    >
                        Oldest
                    </div>
                    <div
                        onClick={() => {
                            setTextColor(12);
                            setSortShow(false);
                            setSort("newest");
                            setSortName("Newest");
                        }}
                        style={{
                            color: textcolor === 12 ? "#CB1B5B" : "",
                            fontWeight: textcolor === 12 ? "bold" : "",
                        }}
                    >
                        Newest
                    </div>
                    <div
                        onClick={() => {
                            setTextColor(13);
                            setSortShow(false);
                            setSort("ascending");
                            setSortName("Ascending");
                        }}
                        style={{
                            color: textcolor === 13 ? "#CB1B5B" : "",
                            fontWeight: textcolor === 13 ? "bold" : "",
                        }}
                    >
                        Alphabetical ↑
                    </div>
                    <div
                        onClick={() => {
                            setTextColor(14);
                            setSortShow(false);
                            setSort("descending");
                            setSortName("Descending");
                        }}
                        style={{
                            color: textcolor === 14 ? "#CB1B5B" : "",
                            fontWeight: textcolor === 14 ? "bold" : "",
                        }}
                    >
                        Alphabetical ↓
                    </div>
                </div>
            ) : null}

            {/*---------Filter Portion--------*/}
            {filtershow ? (
                <div className="filter-container-career">
                    <div className="filter-header">Filter</div>
                    <div className="filter-header-hr"></div>
                    <div className="filter-content-doctor">
                        <div
                            className="filter-content-card-doctor"
                            onClick={() => {
                                setTextColor(1);
                            }}
                        >
                            <div
                                style={{
                                    color: textcolor === 1 ? "#CB1B5B" : "",
                                    fontWeight: textcolor === 1 ? "bold" : "",
                                }}
                            >
                                Experience
                            </div>
                            <div>
                                <FaAngleRight className="rightarrow" />
                            </div>
                        </div>
                        <div
                            className="filter-content-card-doctor"
                            onClick={() => {
                                setTextColor(2);
                            }}
                        >
                            <div
                                style={{
                                    color: textcolor === 2 ? "#CB1B5B" : "",
                                    fontWeight: textcolor === 2 ? "bold" : "",
                                }}
                            >
                                Status
                            </div>
                            <div>
                                <FaAngleRight className="rightarrow" />
                            </div>
                        </div>
                    </div>
                    <div className="filter-foot-btn">
                        <div>
                            <button
                                onClick={() => {
                                    setFilterShow(false);
                                }}
                            >
                                Clear
                            </button>
                        </div>
                        <div>
                            <button
                                onClick={() => {
                                    setFilterShow(false);
                                    setSubmitFilter(true);
                                }}
                            >
                                Apply
                            </button>
                        </div>
                    </div>
                </div>
            ) : null}

            {/*-------Experience---------*/}
            {textcolor === 1 && filtershow === true ? (
                <div className="filter-doctor-fee-career">
                    <div className="filter-doctor-fee-header">Experience</div>
                    <div className="filter-doctor-fee-hr"></div>
                    <div className="filter-doctor-fee-container">
                        {experience.map((el, i) => {
                            return (
                                <div className="filter-doctor-fee-box">
                                    <div>{el.text}</div>
                                    <div className="checkbox-wrapper">
                                        <input
                                            type="checkbox"
                                            className={isChecked ? "checked" : ""}
                                            name={el.text}
                                            id=""
                                            onChange={(e) => {
                                                setExperienceData([...expriencedata, e.target.name]);
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            ) : null}

            {/*-------Status---------*/}
            {textcolor === 2 && filtershow === true ? (
                <div className="filter-doctor-fee-career">
                    <div className="filter-doctor-fee-header">Status</div>
                    <div className="filter-doctor-fee-hr"></div>
                    <div className="filter-doctor-fee-container">
                        {status.map((el, i) => {
                            return (
                                <div className="filter-doctor-fee-box">
                                    <div>{el.text}</div>
                                    <div className="checkbox-wrapper">
                                        <input
                                            type="checkbox"
                                            className={isChecked ? "checked" : ""}
                                            name={el.text}
                                            id=""
                                            onChange={(e) => {
                                                setStatusData([...statusdata, e.target.name]);
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            ) : null}
        </>
    )
}

export default PGDegree 