import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import backRed from "../../../assets/svg/back-rose-icon.svg";
import NewSuccessModal from "../../modal/NewSuccessModal";
import "./index.scss";

const RetailPharmacyActive = () => {
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);

  return (
    <>
      {/* <Home> */}
      <div
        onClick={() => navigate("/add-new-retail-pharmacy")}
        className="rose back-button"
      >
        <img src={backRed} alt="" />
        <span className="rose-back">Back</span>
      </div>
      <div className="healtharticle">
        <div className="healtharticle-container ps-3">
          <div className="healtharticle-title ms-0">
            <Link
              to="/dashboard"
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "15px",
              }}
            >
              <span style={{ paddingLeft: "5%" }}>Dashboard</span>
            </Link>
            <span style={{ paddingLeft: "1%" }}>{`>`}</span>
            <span style={{ paddingLeft: "0.4%" }}>
              Partnered Retail Pharmacies
            </span>
            <span style={{ paddingLeft: "0.4%" }}>{`>`}</span>
            <span style={{ paddingLeft: "0.4%" }}>
              Activate Retail Pharmacy
            </span>
          </div>

          <div className="mt-5 ms-1" style={{ fontWeight: "700" }}>
            Please Verify & confirm details before activating
          </div>

          <div className="health-active">
            <div className="common-active">
              <div className="active-title">Name of Pharmacy</div>
              <div className="active-box">Nalam Pharmacy</div>
            </div>
            <div className="common-active ms-5">
              <div className="active-title">State</div>
              <div style={{ width: "200px" }} className="active-box">
                Tamilnadu
              </div>
            </div>
            <div className="common-active ms-5">
              <div className="active-title">City / Town</div>
              <div style={{ width: "200px" }} className="active-box">
                Coimbatore
              </div>
            </div>
          </div>

          <div className="health-active">
            <div className="common-active">
              <div className="active-title">Pincode</div>
              <div style={{ width: "200px" }} className="active-box">
                641 006
              </div>
            </div>
            <div className="common-active ms-5">
              <div className="active-title">Pharmacy License No</div>
              <div style={{ width: "200px" }} className="active-box">
                TNDLOU1619
              </div>
            </div>
            <div className="common-active ms-5">
              <div className="active-title">Choose Admins</div>
              <div style={{ width: "250px" }} className="active-box">
                S. Suguthan Pharmacist
              </div>
            </div>
          </div>

          <div className="health-active">
            <div className="common-active">
              <div className="active-title">
                Email ( For all Communication & Login )
              </div>
              <div style={{ width: "250px" }} className="active-box">
                Nalampharmacy@gmail.com
              </div>
            </div>
            <div className="common-active ms-5">
              <div className="active-title">
                Mobile Number ( For all Communications & Login )
              </div>
              <div style={{ width: "250px" }} className="active-box">
                +91 9929192621
              </div>
            </div>
          </div>

          <div className="doctor-activate-submit">
            <button onClick={() => setSuccess(true)}>
              CONFIRM & SEND LOGIN CREDENTIALS
            </button>
          </div>
        </div>
      </div>
      {/* </Home> */}
      {success && (
        <NewSuccessModal
          page={"/add-new-retail-pharmacy"}
          name="Retail Pharmacy"
        />
      )}
    </>
  );
};

export default RetailPharmacyActive;
