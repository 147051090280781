import axios from "axios"
import { useEffect, useState } from "react"
import { FaAngleRight } from "react-icons/fa"
import { useNavigate } from "react-router"

import contact from "../../../assets/images/HealthSy/contact.png";
import newdelete from "../../../assets/images/HealthSy/delete.png";
import inactive from "../../../assets/svg/inactive.svg";
import search from "../../../assets/images/searchIcon.png"
import Breadcrumbs from "../../Common/Breadcrumbs/Breadcrumbs"
import AddButton from "../../Common/addButton"
import FilterOptions from "../../Common/filterOptions"
import Pagination from "../../Common/pagination"
import Deletepopup from "../../modal/Deletepopup"
import DoctorCommonNavTitles from "../../Common/doctorCommonNavTitles";

const DoctorsLanguage = () => {

    const navigate = useNavigate()

    const handleGenderForm = () => {
        navigate("/doctors-language-create")
    }

    const handleEdit = (id) => {
        navigate(`/doctors-language-edit/${id}`)
    }

    const handleView = (id) => {
        navigate(`/doctors-language-view/${id}`)
    }

    const [del, setDel] = useState(false)
    const [id, setId] = useState("")

    const handleDelete = (id) => {
        fetch(`${process.env.REACT_APP_API_URL}doctors-languages/delete/${id}`,
            {
                method: "DELETE",
            }
        ).then((result) => {
            result.json().then((res) => {
                list_api_call();
                setDel(false)
            });
        });
    };

    const [filtered_records, setFilteredRecords] = useState([]);
    const [pages, setPages] = useState(0);
    const [current_page, setCurrentPage] = useState(1);
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(10);
    const [sort, setSort] = useState("newest");
    const [sortName, setSortName] = useState("Sort by");
    const [filter, ] = useState({});
    const [records, setRecords] = useState([]);

    const [sortshow, setSortShow] = useState(false);
    const [textcolor, setTextColor] = useState(0);
    const [filtershow, setFilterShow] = useState(false);
    const [, setSubmitFilter] = useState(false);
    const [languagesdata, setLanguagesData] = useState([]);
    const [statusdata, setStatusData] = useState([]);
    const [isChecked, ] = useState(true);

    const languages = [
        {
            text: "English"
        },
        {
            text: "Tamil"
        },
        {
            text: "Telugu"
        }
    ]

    const status = [
        {
            text: "Active",
        },
        {
            text: "Inactive",
        },
    ];

    useEffect(() => {
        list_api_call();
    }, [filter, limit, sort, skip]);

    useEffect(() => {
        if (current_page > 1) {
            setSkip((current_page - 1) * limit);
        } else {
            setSkip(0);
        }
    }, [current_page]);

    const list_api_call = () => {
        axios
            .post(`${process.env.REACT_APP_API_URL}doctors-languages/list`, {
                ...filter,
                skip: skip,
                limit: limit,
                sort: sort,
            })
            .then((response) => {
                setFilteredRecords([]);
                setRecords(response.data.rows);
                // setRowCount(response.data.count);
                setPages(Math.ceil(response.data.count / limit));
            })
            .catch((err) => {
                console.error(err);
                alert("Some error");
            });
    };

    const filter_records = (filter) => {
        let rows = records.filter((x) => {
            return JSON.stringify(x).toLowerCase().indexOf(filter.toLowerCase()) > -1;
        });
        setFilteredRecords(rows);
    };

    return (
        <>
            <div className="healtharticle">
                <div className="healtharticle-container">
                    <div className="healtharticle-title carrer-job-title ms-4">
                        <Breadcrumbs
                            breadcrumbText="Doctor"
                            breadcrumbLink="/add-new-doctors"
                            nestedText="Common Heads"
                            nestedLink=""
                            nestedText2="Language Known"
                            nestedLink2=""
                        />
                    </div>
                    <div className="healtharticle-addnew">
                        <AddButton
                            onClick={handleGenderForm}
                            btnName="Add New Languages Known"
                        />
                    </div>
                    <DoctorCommonNavTitles />
                    <FilterOptions
                        limit={limit}
                        sortshow={sortshow}
                        sortName={sortName}
                        search={search}
                        setFilterShow={setFilterShow}
                        setSortShow={setSortShow}
                        filter_records={filter_records}
                        setLimit={setLimit}
                    />
                    <div className="healtharticle-table master-doctor-table">
                        <table>
                            <thead>
                                <tr>
                                    <td className="career-jobpost-td1">S.No</td>
                                    <td className="career-jobpost-td2">Languages Known</td>
                                    <td className="career-jobpost-td3">Status</td>
                                    <td className="career-jobpost-td12">Actions</td>
                                </tr>
                            </thead>
                            <tbody>
                                {(filtered_records.length > 0
                                    ? filtered_records
                                    : records
                                ).map((el, i) => {
                                    return (
                                        <tr key={el._id}>
                                            <td>{(current_page - 1) * limit + (i + 1)}</td>
                                            <td>{el.type}</td>
                                            <td>{el.active === true ? "Verified" : "Not Verified"}</td>
                                            <td>
                                                <span className="master-edit" onClick={() => { handleEdit(el._id) }}>Edit</span>
                                                <span className="contact-logo">
                                                    <img src={contact} alt="" />
                                                </span>
                                                <span className="master-delete" onClick={() => {
                                                    setId(el._id)
                                                    setDel(true);
                                                }}>Delete</span>
                                                <span className="newdelete-logo">
                                                    <img src={newdelete} alt="" />
                                                </span>
                                                <span className="master-view" onClick={() => { handleView(el._id) }}>View</span>
                                                <span className="inactive-icon">
                                                    <img src={inactive} alt="" />
                                                </span>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Pagination
                setCurrentPage={setCurrentPage}
                current_page={current_page}
                pages={pages}
            />
            {
                del === true
                &&
                <Deletepopup
                    handleDelete={handleDelete}
                    id={id}
                />
            }

            {sortshow ? (
                <div className="sortby-Common-career-main sortby-master-common">
                    <div
                        onClick={() => {
                            setTextColor(11);
                            setSortShow(false);
                            setSort("oldest");
                            setSortName("Oldest");
                        }}
                        style={{
                            color: textcolor === 11 ? "#CB1B5B" : "",
                            fontWeight: textcolor === 11 ? "bold" : "",
                        }}
                    >
                        Oldest
                    </div>
                    <div
                        onClick={() => {
                            setTextColor(12);
                            setSortShow(false);
                            setSort("newest");
                            setSortName("Newest");
                        }}
                        style={{
                            color: textcolor === 12 ? "#CB1B5B" : "",
                            fontWeight: textcolor === 12 ? "bold" : "",
                        }}
                    >
                        Newest
                    </div>
                    <div
                        onClick={() => {
                            setTextColor(13);
                            setSortShow(false);
                            setSort("ascending");
                            setSortName("Ascending");
                        }}
                        style={{
                            color: textcolor === 13 ? "#CB1B5B" : "",
                            fontWeight: textcolor === 13 ? "bold" : "",
                        }}
                    >
                        Alphabetical ↑
                    </div>
                    <div
                        onClick={() => {
                            setTextColor(14);
                            setSortShow(false);
                            setSort("descending");
                            setSortName("Descending");
                        }}
                        style={{
                            color: textcolor === 14 ? "#CB1B5B" : "",
                            fontWeight: textcolor === 14 ? "bold" : "",
                        }}
                    >
                        Alphabetical ↓
                    </div>
                </div>
            ) : null}

            {/*---------Filter Portion--------*/}
            {filtershow ? (
                <div className="filter-container-career">
                    <div className="filter-header">Filter</div>
                    <div className="filter-header-hr"></div>
                    <div className="filter-content-doctor">
                        <div
                            className="filter-content-card-doctor"
                            onClick={() => {
                                setTextColor(1);
                            }}
                        >
                            <div
                                style={{
                                    color: textcolor === 1 ? "#CB1B5B" : "",
                                    fontWeight: textcolor === 1 ? "bold" : "",
                                }}
                            >
                                Languages
                            </div>
                            <div>
                                <FaAngleRight className="rightarrow" />
                            </div>
                        </div>
                        <div
                            className="filter-content-card-doctor"
                            onClick={() => {
                                setTextColor(2);
                            }}
                        >
                            <div
                                style={{
                                    color: textcolor === 2 ? "#CB1B5B" : "",
                                    fontWeight: textcolor === 2 ? "bold" : "",
                                }}
                            >
                                Status
                            </div>
                            <div>
                                <FaAngleRight className="rightarrow" />
                            </div>
                        </div>
                    </div>
                    <div className="filter-foot-btn">
                        <div>
                            <button
                                onClick={() => {
                                    setFilterShow(false);
                                }}
                            >
                                Clear
                            </button>
                        </div>
                        <div>
                            <button
                                onClick={() => {
                                    setFilterShow(false);
                                    setSubmitFilter(true);
                                }}
                            >
                                Apply
                            </button>
                        </div>
                    </div>
                </div>
            ) : null}

            {/*-------Languages---------*/}
            {textcolor === 1 && filtershow === true ? (
                <div className="filter-doctor-fee-career">
                    <div className="filter-doctor-fee-header">Languages</div>
                    <div className="filter-doctor-fee-hr"></div>
                    <div className="filter-doctor-fee-container">
                        {languages.map((el, i) => {
                            return (
                                <div className="filter-doctor-fee-box">
                                    <div>{el.text}</div>
                                    <div className="checkbox-wrapper">
                                        <input
                                            type="checkbox"
                                            className={isChecked ? "checked" : ""}
                                            name={el.text}
                                            id=""
                                            onChange={(e) => {
                                                setLanguagesData([...languagesdata, e.target.name]);
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            ) : null}

            {/*-------Status---------*/}
            {textcolor === 2 && filtershow === true ? (
                <div className="filter-doctor-fee-career">
                    <div className="filter-doctor-fee-header">Status</div>
                    <div className="filter-doctor-fee-hr"></div>
                    <div className="filter-doctor-fee-container">
                        {status.map((el, i) => {
                            return (
                                <div className="filter-doctor-fee-box">
                                    <div>{el.text}</div>
                                    <div className="checkbox-wrapper">
                                        <input
                                            type="checkbox"
                                            className={isChecked ? "checked" : ""}
                                            name={el.text}
                                            id=""
                                            onChange={(e) => {
                                                setStatusData([...statusdata, e.target.name]);
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            ) : null}
        </>
    )
}

export default DoctorsLanguage