import axios from "axios";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import Autocomplete from "react-google-autocomplete";
import { degree, startedYear, states } from "../../../constants";
import Breadcrumbs from "../../Common/Breadcrumbs/Breadcrumbs";
import LanguageField from "../../Common/LanguageField";
import AddButton from "../../Common/addButton";
import CustomFieldsComp from "../../Common/customFields";
import CustomFieldsLevel from "../../Common/customFieldsLevel";
import NextBackBtns from "../../Common/nextBackBtns";
import DoctorFormCustomField from "../../modal/DoctorFormCustomField";
import SucessModal from "../../modal/SucessModal";

const HealthcareRegistrationEdit = () => {

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        control,
        trigger,
        getValues,
        formState: { errors },
    } = useForm();

    const { id } = useParams();
    const [gender, setGender] = useState([]);
    const [service, setService] = useState([]);
    const [city, setCity] = useState("");
    const [success, setSuccess] = useState(false);
    const [language, setLanguage] = useState([]);
    const [selectedLang, setSelectedLang] = useState([]);
    const [inclinic, setInclinic] = useState([]);
    const [customFields, setCustomFields] = useState([])
    const [level, setLevel] = useState(1);
    const [customFieldModel, setCustomFieldModel] = useState(false)
    const [customFieldEditModel, setCustomFieldEditModel] = useState({ status: false, index: null })

    useEffect(() => {
        getGender();
        getService();
        getHealthcareRegistrationForm();
        getLangauges();
        getInClinic();
    }, []);

    const getLangauges = () => {
        axios.post(`${process.env.REACT_APP_API_URL}languages/list`).then((res) => {
            setLanguage(res.data.rows);
        });
    };

    const getGender = () => {
        axios.post(`${process.env.REACT_APP_API_URL}gender/list`).then((res) => {
            setGender(res.data.rows);
        });
    };

    const getService = () => {
        axios
            .post(`${process.env.REACT_APP_API_URL}service-category/list`)
            .then((res) => {
                setService(res.data.rows);
            });
    };

    const getInClinic = () => {
        axios
            .post(`${process.env.REACT_APP_API_URL}in-clinic-fee/list`)
            .then((res) => {
                setInclinic(res.data.rows);
            });
    };

    const getHealthcareRegistrationForm = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}healthcare-registration/get/${id}`)
            .then((response) => {
                setValue("name", response?.data?.name);
                setValue("mobile", response?.data?.mobile);
                setValue("email", response?.data?.email);
                setValue("degree", response?.data?.degree)
                setValue("gender", response?.data?.gender);
                setValue("service_category", response?.data?.service_category);
                setValue("qualification", response?.data?.qualification);
                setValue("license_number", response?.data?.license_number);
                setValue("experience", response?.data?.experience);
                setValue("about", response?.data?.about);
                setValue("fee", response?.data?.fee);
                setValue("location", response?.data?.location);
                setValue("state", response?.data?.state);
                setValue("city", response?.data?.city);
                setCity(response?.data?.city)
                setValue("address", response?.data?.address);
                setValue("pincode", response?.data?.pincode);
                setValue("memberships_or_associations", response?.data?.memberships_or_associations);
                setValue("other_home_healthcare_platforms", response?.data?.other_home_healthcare_platforms);
                setValue("establishment", response?.data?.establishment);
                setValue("age", response?.data?.age);
                if (response?.data?.customFields?.length) {
                    setCustomFields(response.data.customFields)
                }
                setValue("languages_known", response?.data?.languages_known);
                setValue("other_certified_courses", response?.data?.other_certified_courses);
                setSelectedLang(response?.data?.languages_known?.map((data) => ({ value: data })))
                trigger()
            })
            .catch((err) => {
                console.error(err);
                alert("Some error");
            });
    };

    const onSubmit = (fields) => {
        // fields.city = city;
        fields.languages_known = fields.languages_known?.length && fields.languages_known?.map((data) => data?.value)
        // if (!city) {
        //     setValue("city", "");
        //     trigger()
        // } else {
        if (customFields) {
            fields.customFields = customFields;
        }
        axios
            .patch(
                `${process.env.REACT_APP_API_URL}healthcare-registration/update/${id}`, fields)
            .then((response) => {
                setSuccess(true);
                reset()
            });
        // }
    }

    return (
        <>
            <div className="healtharticle pb-4">
                <div className="healtharticle healthcareEditFormWrapper pb-4">
                    <div className="healtharticle-container">
                        <div className="px-3 d-flex justify-content-between">

                            <Breadcrumbs
                                breadcrumbText="HealthSynergy"
                                breadcrumbLink="/doctor-registration"
                                nestedText2="HealthSy Partnered Pharmacy Network Programme"
                                nestedLink2="/pharmacy-registration"
                                nestedText3="Edit"
                                nestedLink="#"
                                className="w-auto"
                            />
                            <div className="doctorAddNewField">
                                <AddButton
                                    onClick={() => setCustomFieldModel(true)}
                                    btnClassName="fw-bold w-100"
                                    btnName="Add New Field"
                                />
                            </div>
                        </div>
                        {customFields?.length ? <CustomFieldsLevel
                            page={level}
                            customFieldLength={customFields?.length}
                        /> : <></>}
                        <form className="addEditInsiders" onSubmit={handleSubmit(onSubmit)}>
                            {(level === 1 || !customFields?.length) ? <div className="doctor-page1">
                                <div className="addnew-form1-autor-detail mt-3 d-flex">
                                    <div className="col-md-4">
                                        <label for="inputAddress" className="form-label title">
                                            Name of the HHSP <span className="star">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            {...register("name", { required: true })}
                                            className="form-control addnew-input"
                                            // id="authordetails"
                                            style={{ paddingLeft: "10px" }}
                                        />
                                        {errors.name && (
                                            <div className={`invalid-feedback d-block`}>
                                                Please provide a Name
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-4">
                                        <label for="inputAddress" className="form-label title">
                                            Mobile Number <span className="star">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            {...register("mobile", { required: true })}
                                            className="form-control addnew-input"
                                            // id="mobile"
                                            // placeholder="Type here"
                                            style={{ paddingLeft: "10px" }}
                                        />
                                        {errors.mobile && (
                                            <div className={`invalid-feedback d-block`}>
                                                Please provide a Mobile Number
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-4">
                                        <label for="inputAddress" className="form-label title">
                                            Email <span className="star">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            {...register("email", { required: true })}
                                            className="form-control addnew-input"
                                            // id="authordetails"
                                            style={{ paddingLeft: "10px" }}
                                        />
                                        {errors.email && (
                                            <div className={`invalid-feedback d-block`}>
                                                Please provide a Email
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="addnew-form1-autor-detail mt-5 d-flex">
                                    <div className="col-md-4">
                                        <label className="form-label title">
                                            Degree{" "}
                                            <span className="star">*</span>
                                        </label>
                                        <select
                                            {...register("degree", { required: true })}
                                            className="form-control addnew-input"
                                            defaultValue={""}
                                        >
                                            <option value="" disabled pri-specialization="true">
                                                Select degree
                                            </option>
                                            {degree.map((data, idx) => {
                                                return (
                                                    <option key={idx}>
                                                        {data}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        {errors.degree && (
                                            <div className={`invalid-feedback d-block`}>
                                                Please provide a degree
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-4">
                                        <label className="form-label title">
                                            Other Certified Education
                                            <span className="star"> * </span>
                                        </label>
                                        <input
                                            className="form-control addnew-input"
                                            type="text"
                                            placeholder="Type here"
                                            {...register("other_certified_courses", { required: false })}
                                        />
                                        {/* {errors.other_course && (
                                            <div className={`invalid-feedback d-block`}>
                                                Please provide a MCI
                                            </div>
                                        )} */}
                                    </div>
                                    <div className="col-md-4">
                                        <label for="inputAddress" className="form-label title">
                                            Age <span className="star">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            {...register("age", { required: true })}
                                            className="form-control addnew-input"
                                            // id="authordetails"
                                            style={{ paddingLeft: "10px" }}
                                        />
                                        {errors.age && (
                                            <div className={`invalid-feedback d-block`}>
                                                Please provide a Age
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="addnew-form1-autor-detail mt-5 d-flex">
                                    <div className="col-md-4">
                                        <label className="form-label title">
                                            Gender <span className="star">*</span>
                                        </label>{" "}
                                        <br />
                                        <div className="d-flex">
                                            {gender.map((el, index) => {
                                                if (el.active === true) {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className="form-check form-check-inline d-flex align-items-center"
                                                        >
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                {...register("gender", { required: true })}
                                                                value={el.type}
                                                            />
                                                            <label className="ps-2">
                                                                {el.type}
                                                            </label>
                                                        </div>
                                                    );
                                                } else {
                                                    return <></>
                                                }
                                            })}
                                        </div>
                                        {errors.gender && (
                                            <div className={`invalid-feedback d-block`}>
                                                Please select an option
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-4">
                                        <label className="form-label title">
                                            Service Category <span className="star">*</span>
                                        </label>
                                        <select
                                            {...register("service_category", { required: true })}
                                            className="form-control addnew-input"
                                            value={getValues().service_category}
                                            onChange={(e) => {
                                                setValue("service_category", e.target.value);
                                                trigger();
                                            }}
                                        >
                                            <option value="" disabled>
                                                Select your Category
                                            </option>
                                            {service.map((el, inx) => {
                                                if (el.active === true) {
                                                    return <option value={el.type} key={inx}>{el.type}</option>;
                                                } else {
                                                    return <></>
                                                }
                                            })}
                                        </select>
                                        {errors.service_category && (
                                            <div className={`invalid-feedback d-block`}>
                                                Please provide a Service Category
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-4">
                                        <label className="form-label title">
                                            Experience (Year of Start)<span className="star">*</span>
                                        </label>
                                        <select
                                            {...register("experience", { required: true })}
                                            className="form-control addnew-input"
                                            defaultValue={""}
                                        >
                                            <option value="" disabled>
                                                Select your Experience
                                            </option>
                                            {startedYear.map((el, inx) => {
                                                return <option value={el} key={inx}>{el}</option>;
                                            })}
                                        </select>
                                        {errors.experience && (
                                            <div className={`invalid-feedback d-block`}>
                                                Please provide a Experience
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="addnew-form1-autor-detail mt-5 d-flex">
                                    <div className="col-md-4">
                                        <label className="form-label title">
                                            License Number / Council Number /  Registration Number
                                            <span className="star">*</span>
                                        </label>
                                        <input
                                            className="form-control addnew-input"
                                            type="text"
                                            {...register("license_number", { required: true }
                                            )}
                                        />
                                        {errors.license_number && (
                                            <div className={`invalid-feedback d-block`}>
                                                Please provide License Number / Council Number /  Registration Number *
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-4">
                                        <label className="form-label title">
                                            Memberships / Associations Details
                                            {/* <span className="star">*</span> */}
                                        </label>
                                        <input
                                            className="form-control addnew-input"
                                            type="text"
                                            {...register("memberships_or_associations", {
                                            })}
                                        />
                                    </div>
                                </div>
                                <div className="addnew-form1-autor-detail mt-5 d-flex">
                                    <div className="col-md-11">
                                        <label className="form-label title">About Yourself </label>
                                        <textarea
                                            className="form-control addnew-textArea"
                                            type="textArea"
                                            {...register("about")}
                                        />
                                        {/* {errors.about && (
                                        <div className={`invalid-feedback d-block`}>
                                            Please provide a Memberships
                                        </div>
                                    )} */}
                                    </div>
                                </div>
                                <div className="addnew-form1-autor-detail mt-5 d-flex">
                                    <div className="col-md-4">
                                        <label className="form-label title">
                                            Fee <span className="star">*</span>
                                        </label>
                                        <select
                                            {...register("fee", { required: true })}
                                            className="form-control addnew-input"
                                            defaultValue={""}>
                                            <option value="" disabled pri-specialization="true">Choose your Fee</option>
                                            {inclinic.map((option) => {
                                                if (option.active === true) {
                                                    return (
                                                        <option key={option.type} value={option.type}>
                                                            {option.type}
                                                        </option>
                                                    );
                                                } else {
                                                    return <></>
                                                }
                                            })}
                                        </select>
                                        {errors.fee && (
                                            <div className={`invalid-feedback d-block`}>
                                                Please provide a Fee
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-4">
                                        <label className="form-label title">
                                            Are your currently available on other home healthcare platforms? {" "}
                                            <span className="star">*</span>
                                        </label>{" "}
                                        <div className="d-flex align-items-center">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                {...register("other_home_healthcare_platforms", { required: true })}
                                                value="Yes"
                                            />
                                            <label className="ps-2 pe-4">
                                                Yes
                                            </label>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                {...register("other_home_healthcare_platforms", { required: true })}
                                                value="No"
                                            />
                                            <label className="ps-2">
                                                No
                                            </label>
                                        </div>
                                        {errors.other_home_healthcare_platforms && (
                                            <div className={`invalid-feedback d-block`}>
                                                Please select an option
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-4">
                                        <label className="form-label title">
                                            Your current organisation name{" "}
                                            <span className="text-muted">(hospital / clinic name)</span>
                                        </label>{" "}
                                        <br />
                                        <input
                                            className="form-control addnew-input"
                                            type="text"
                                            {...register("establishment", { required: false })}
                                        />
                                    </div>
                                </div>
                                <div className="addnew-form1-autor-detail mt-5 d-flex">
                                    <div className="col-md-4">
                                        <label className="form-label title">
                                            State<span className="star">*</span>
                                        </label>
                                        <select
                                            {...register("state", { required: true })}
                                            className="form-control addnew-input"
                                            defaultValue={""}
                                        >
                                            <option value="" disabled>
                                                Select your State
                                            </option>
                                            {states.map((el, inx) => {
                                                return <option value={el} key={inx}>{el}</option>;
                                            })}
                                        </select>
                                        {errors.state && (
                                            <div className={`invalid-feedback d-block`}>
                                                Please provide a State
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-4">
                                        <label for="inputAddress" className="form-label title">
                                            City{" "}
                                            <span className="star">*</span>
                                        </label>
                                        <Controller
                                            control={control}
                                            name="city"
                                            // rules={{ required: "City is required" }}
                                            render={({ field }) => (
                                                <Autocomplete
                                                    apiKey={"AIzaSyBJUZFe3zt4HNVzwdTlmJYMWtXhZ7mfclk"}
                                                    value={field.value}
                                                    onChange={(event) => {
                                                        field.onChange(event.target.value)
                                                        if (city) {
                                                            setCity("")
                                                        }
                                                    }
                                                    }
                                                    onPlaceSelected={(place) => {
                                                        field.onChange(place ? place.formatted_address : "")
                                                        setCity(place.formatted_address)
                                                    }}
                                                    types={["address"]}
                                                    className={`form-control  addnew-input`}
                                                    componentrestrictions={{ country: "us" }}
                                                    preventdefault="true"
                                                />
                                            )}
                                        />
                                        {errors.city && (
                                            <div className={`invalid-feedback d-block`}>
                                                Please provide a City
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-4">
                                        <LanguageField
                                            errors={errors}
                                            language={language}
                                            register={register}
                                            setValue={setValue}
                                            trigger={trigger}
                                            getValues={getValues}
                                            multiSelectClassName={"form-control addnew-input"}
                                            selectedLang={selectedLang}
                                        />
                                    </div>
                                </div>
                                <div className="addnew-form1-autor-detail mt-5 d-flex">
                                    <div className="col-md-4">
                                        <label for="inputAddress" className="form-label title">
                                            Location <span className="star">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            {...register("location", { required: true })}
                                            className="form-control addnew-input"
                                            // id="authordetails"
                                            style={{ paddingLeft: "10px" }}
                                        />
                                        {errors.location && (
                                            <div className={`invalid-feedback d-block`}>
                                                Please provide a Location
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-4">
                                        <label className="form-label title">
                                            Pincode <span className="star">*</span>
                                        </label>
                                        <input
                                            className="form-control addnew-input"
                                            type="text"
                                            {...register("pincode", {
                                                required: true,
                                                min: 110000,
                                                pattern: /[0-9]{6}/,
                                            })}
                                            placeholder="Enter pincode"
                                            maxLength="6"
                                            onKeyPress={(e) => {
                                                if (!/[0-9]/.test(e.key)) {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                        {errors.pincode && (
                                            <div className={`invalid-feedback d-block`}>
                                                Please provide a Pincode
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="addnew-form1-autor-detail mt-5 d-flex">
                                    <div className="col-md-11">
                                        <label className="form-label title">
                                            Address <span className="star">*</span>
                                        </label>
                                        <textarea
                                            className="form-control addnew-textArea"
                                            type="textArea"
                                            placeholder="Enter Address"
                                            {...register("address")}
                                        />
                                    </div>
                                </div>
                                <NextBackBtns
                                    back={false}
                                    next={customFields?.length ? true : false}
                                    handleNext={() => setLevel(2)}
                                    submitBtn={true}
                                    submitBtnText={"Update"}
                                />
                            </div> : (
                                <CustomFieldsComp
                                    customFields={customFields}
                                    setCustomFields={setCustomFields}
                                    setCustomFieldEditModel={setCustomFieldEditModel}
                                    register={register}
                                    customFieldsLength={customFields?.length}
                                    errors={errors}
                                    setLevel={setLevel}
                                    level={level}
                                />
                            )}
                        </form>
                    </div>
                </div>
            </div>
            {success && (
                <SucessModal
                    page={"/healthcare-registration"}
                    name="Healthcare Registration"
                    post="updated"
                />
            )}

            {customFieldModel && (
                <DoctorFormCustomField
                    setCustomFieldModel={setCustomFieldModel}
                    setCustomFields={setCustomFields}
                    setLevel={setLevel}
                    level={level}
                    customFieldsLength={customFields?.length}
                />
            )}
            {customFieldEditModel?.status && (
                <DoctorFormCustomField
                    setCustomFieldModel={setCustomFieldModel}
                    setCustomFieldEditModel={setCustomFieldEditModel}
                    setCustomFields={setCustomFields}
                    setLevel={setLevel}
                    customFieldIndex={customFieldEditModel?.index}
                    customFieldValue={customFields[customFieldEditModel?.index]}
                    edit={true}
                    level={level}
                    customFieldsLength={customFields?.length}
                />
            )}
        </>
    )
}

export default HealthcareRegistrationEdit;