import axios from "axios";
import JoditEditor from "jodit-react";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import graytick from "../../../../assets/images/graytick.PNG";
import greentick from "../../../../assets/images/greentick.PNG";
import back from "../../../../assets/svg/back-icon.svg";
import next from "../../../../assets/svg/next-icon.svg";
import { config, startedYear, states } from "../../../../constants";
import Breadcrumbs from "../../../Common/Breadcrumbs/Breadcrumbs";
import InputRadioDynamic from "../../../Common/inputRadioDynamic";
import InputTextField from "../../../Common/inputTextField";
import "../index.scss";

const Level = ({
    page,
}) => {
    return (
        <>
            <Breadcrumbs
                breadcrumbText="Doctor"
                breadcrumbLink=""
                nestedText="Add Doctors"
            />
            {
                <div className={page === 1 ? "doctor-page-tick" : page === 2 ? "doctor-page2-tick" : page === 3 ? "doctor-page3-tick" : page === 4 ? "doctor-page3-tick" : ""}>
                    <div>
                        <img src={greentick} alt="" />
                        <div>Page 1</div>
                    </div>
                    <div>
                        <img src={page === 1 ? graytick : greentick} alt="" />
                        <div>Page 2</div>
                    </div>
                    <div>
                        <img src={page === 3 ? greentick : graytick} alt="" />
                        <div>Page 3</div>
                    </div>
                </div>
            }
        </>
    )
}

const StepOne = ({
    register,
    errors,
    watch,
    setValue,
    trigger,
    handleNext,
}) => {

    const [primaryspl, setPrimaryspl] = useState([]);
    const [healthConcern, setHealthConcern] = useState([]);

    useEffect(() => {
        getPrimaryspl();
        getOnlineHealthConcern();
    }, [])

    const getPrimaryspl = () => {
        axios
            .post(`${process.env.REACT_APP_API_URL}primary-specialisation/list`, {
                noLimit: true,
            })
            .then((res) => {
                setPrimaryspl(res.data.rows);
            });
    };

    const getOnlineHealthConcern = () => {
        axios
            .post(`${process.env.REACT_APP_API_URL}doctor-specialisation/list`, {
                noLimit: true,
                active: true
            })
            .then((res) => {
                setHealthConcern(res.data.rows);
            });
    };

    return (
        <>
            <div>
                <div className="addnew-form1-autor-detail mt-5 d-flex">
                    <div className="col-md-6">
                        <InputTextField
                            labelName="Name of the Doctor"
                            name="doctor_name"
                            register={register}
                            errors={errors}
                            errorText={"Please provide a Doctor Name"}
                        />
                    </div>
                    <div className="col-md-6">
                        <InputRadioDynamic
                            register={register}
                            errors={errors}
                            errorMessage="Please choose the Profile Mode"
                            watch={watch}
                            setValue={setValue}
                            trigger={trigger}
                            label="Profile Mode"
                            name="profile-mode"
                            options={[
                                { name: "Online", },
                                { name: "InClinic", },
                                { name: "Both", }
                            ]}
                        />
                    </div>
                </div>
                <div className="addnew-form1-autor-detail mt-5 d-flex">
                    <div className="col-md-6">
                        <label for="inputAddress" className="form-label title">
                            Primary Specialization <span className="star">*</span>
                        </label>
                        <select
                            {...register("primary_specialization", { required: true })}
                            className="form-select car-job-select w-75"
                            defaultValue={""}
                        >
                            <option value="" disabled pri-specialization="true">
                                Select page type
                            </option>
                            {primaryspl.map((option, inx) => {
                                return (
                                    <option key={inx} value={option.type}>
                                        {option.type}
                                    </option>
                                );
                            })}
                        </select>
                        {errors.primary_specialization && (
                            <div className={`invalid-feedback d-block`}>
                                Please provide a City
                            </div>
                        )}
                    </div>
                    <div className="col-md-6">
                        <label for="inputAddress" className="form-label title">
                            Online Health Concern <span className="star">*</span>
                        </label>
                        <select
                            {...register("online_health_concern", { required: true })}
                            className="form-select car-job-select w-75"
                            defaultValue={""}
                        >
                            <option value="" disabled pri-specialization="true">
                                Select
                            </option>
                            {healthConcern.map((option, inx) => {
                                return (
                                    <option key={inx} value={option.health_concern}>
                                        {option.health_concern}
                                    </option>
                                );
                            })}
                        </select>
                        {errors.online_health_concern && (
                            <div className={`invalid-feedback d-block`}>
                                Please provide a City
                            </div>
                        )}
                    </div>
                </div>
                <div className="addnew-form1-autor-detail mt-5 d-flex">
                    <div className="col-md-6">
                        <InputTextField
                            labelName="Online Consultation Fee"
                            name="online-consultation-fee"
                            register={register}
                            errors={errors}
                            errorText={"Please provide a Online Consultation Fee"}
                        />
                    </div>
                    <div className="col-md-6">
                        <InputTextField
                            labelName="In-Clinic Health Concern"
                            name="inclinic-health-concern"
                            register={register}
                            errors={errors}
                            errorText={"Please provide a In-Clinic Health Concern"}
                        />
                    </div>
                </div>
                <div className="addnew-form1-autor-detail mt-5 d-flex">
                    <div className="col-md-6">
                        <InputTextField
                            labelName="In-Clinic Appointment Fee"
                            name="inClinic-appointment-fee"
                            register={register}
                            errors={errors}
                            errorText={"Please provide a In-Clinic Appointment Fee"}
                        />
                    </div>
                    <div className="col-md-6">
                        <InputRadioDynamic
                            register={register}
                            errors={errors}
                            errorMessage="Please choose the Verified"
                            watch={watch}
                            setValue={setValue}
                            trigger={trigger}
                            label="Verified"
                            name="verified"
                            options={[
                                { name: "Yes", },
                                { name: "No", },
                            ]}
                        />
                    </div>
                </div>
            </div>
            <div className="healtharticle mt-5 pt-4">
                <div className="career-jobpost-td2"><b>Clinic Related Details</b></div>
                <div className="col-md-6 mt-4">
                    <InputTextField
                        labelName="Clinic Name"
                        name="clinic-name"
                        register={register}
                        errors={errors}
                        placeholder="Search Clinic"
                        errorText={"Please provide a Clinic Name"}
                    />
                </div>
            </div>
            <div className="addnew-form1-next">
                <div onClick={handleNext}>
                    <div>
                        <button type="button">NEXT</button>
                    </div>
                    <div>
                        <img src={next} alt="" />
                    </div>
                </div>
            </div>
        </>
    )
}

const StepTwo = ({
    register,
    errors,
    content,
    editor,
    setPage,
    trigger,
    isValid,
    handleBack,
}) => {

    const [gender, setGender] = useState([]);
    const [language, setLanguage] = useState([]);

    useEffect(() => {
        getGender();
        getLanguages();
    }, [])

    const getGender = () => {
        axios.post(`${process.env.REACT_APP_API_URL}doctors-gender/list`).then((res) => {
            setGender(res.data.rows);
        });
    };

    const getLanguages = () => {
        axios.post(`${process.env.REACT_APP_API_URL}doctors-languages/list`).then((res) => {
            setLanguage(res.data.rows);
        });
    };

    const handleNextBtn = async () => {
        await trigger();
        if (isValid) {
            setPage(3);
        }
    }

    return (
        <div className="partneredDoctorForm">
            <div className="addnew-form1-autor-detail mt-5 d-flex">
                <div className="col-md-6">
                    <InputTextField
                        labelName="Name of the Doctor"
                        name="online_doctor_name"
                        register={register}
                        errors={errors}
                        errorText={"Please provide a Online Doctor Name"}
                    />
                </div>
                <div className="col-md-4">
                    <label className="form-label title">
                        Gender <span className="star">*</span>
                    </label>{" "}
                    <br />
                    <div className="d-flex">
                        {gender.map((el, index) => {
                            if (el.active === true) {
                                return (
                                    <div
                                        key={index}
                                        className="form-check form-check-inline d-flex align-items-center"
                                    >
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            {...register("gender", { required: true })}
                                            value={el.type}
                                        />
                                        <label className="ps-2">
                                            {el.type}
                                        </label>
                                    </div>
                                );
                            } else {
                                return <></>
                            }
                        })}
                    </div>
                    {errors.gender && (
                        <div className={`invalid-feedback d-block`}>
                            Please select an option
                        </div>
                    )}
                </div>
            </div>
            <div className="addnew-form1-autor-detail mt-5 d-flex">
                <div className="col-md-6">
                    <InputTextField
                        labelName="MCI/SMC/DCI number"
                        name="mci_number"
                        register={register}
                        errors={errors}
                        errorText={"Please provide a MCI Number"}
                    />
                </div>
                <div className="col-md-6">
                    <label for="inputAddress" className="form-label title">
                        Doctor's Experience (yrs) <span className="star">*</span>
                    </label>
                    <select
                        {...register("doctor_experience", { required: true })}
                        className="form-select car-job-select w-75"
                        defaultValue={""}
                    >
                        <option value="" disabled pri-specialization="true">
                            Select
                        </option>
                        {startedYear.map((el, inx) => {
                            return <option value={el} key={inx}>{el}</option>;
                        })}
                    </select>
                    {errors.doctor_experience && (
                        <div className={`invalid-feedback d-block`}>
                            Please select an option
                        </div>
                    )}
                </div>
            </div>
            <div className="addnew-form1-autor-detail d-flex">
                <div className="addnew-form1-desc">
                    <label className="title mt-4">
                        About <span className="star">*</span>
                    </label>
                    <div>
                        <JoditEditor
                            // {...register("healtharticle_description", { required: false })}
                            ref={editor}
                            value={content}
                            {...register("about", { required: true })}
                            id="about"
                            // name="about"
                            config={config}
                        />
                        {errors.about && (
                            <div className={`invalid-feedback d-block`}>
                                Please provide a About
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="addnew-form1-autor-detail mt-5 d-flex">
                {/* <div className=""> */}
                <div className="col-md-6">
                    <label for="inputAddress" className="form-label title">
                        Education <span className="star">*</span>
                    </label>
                    <div className="d-flex">
                        <input
                            type="text"
                            {...register("education", { required: true })}
                            className="form-control w-75"
                            // id="authordetails"
                            style={{ paddingLeft: "10px" }}
                        />
                        <div className="col-md-4">
                            <button type="button" className="partneredDoctorAddBtn">Add</button>
                        </div>
                    </div>
                </div>
                {/* </div> */}
                <div className="col-md-6">
                    <label for="inputAddress" className="form-label title">
                        Membership <span className="star">*</span>
                    </label>
                    <div className="d-flex">
                        <input
                            type="text"
                            {...register("membership", { required: true })}
                            className="form-control w-75"
                            style={{ paddingLeft: "10px" }}
                        />
                        {errors.membership && (
                            <div className={`invalid-feedback d-block`}>
                                Please provide a Membership
                            </div>
                        )}
                        <div className="col-md-4">
                            <button type="button" className="partneredDoctorAddBtn">Add</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="addnew-form1-autor-detail mt-5 d-flex">
                <div className="col-md-6">
                    <label for="inputAddress" className="form-label title">
                        Practise <span className="star">*</span>
                    </label>
                    <div className="d-flex">
                        <input
                            type="text"
                            {...register("practise", { required: true })}
                            className="form-control w-75"
                            // id="authordetails"
                            style={{ paddingLeft: "10px" }}
                        />
                        {errors.practise && (
                            <div className={`invalid-feedback d-block`}>
                                Please provide a Practise
                            </div>
                        )}
                        <div className="col-md-4">
                            <button type="button" className="partneredDoctorAddBtn">Add</button>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <InputTextField
                        labelName="Mobile Number"
                        name="mobile_number"
                        register={register}
                        errors={errors}
                        errorText={"Please provide a Mobile Number"}
                    />
                </div>
            </div>
            <div className="addnew-form1-autor-detail mt-5 d-flex">
                <div className="col-md-10">
                    <label for="inputAddress" className="form-label title">
                        Language <span className="star">*</span>
                    </label>
                    <select
                        {...register("language", { required: true })}
                        className="form-select car-job-select w-100"
                        defaultValue={""}
                    >
                        <option value="" disabled pri-specialization="true">
                            Select page type
                        </option>
                        {language.map((option, inx) => {
                            return (
                                <option key={inx} value={option.type}>
                                    {option.type}
                                </option>
                            );
                        })}
                    </select>
                    {errors.language && (
                        <div className={`invalid-feedback d-block`}>
                            Please select an option
                        </div>
                    )}
                </div>
            </div>
            <div className="addnew-form1-autor-detail mt-5 d-flex">
                <div className="col-md-4">
                    <InputTextField
                        labelName="Date of Registration"
                        name="date_registration"
                        register={register}
                        errors={errors}
                        errorText={"Please provide a Date of Registration"}
                    />
                </div>
                <div className="col-md-6">
                    <label for="inputAddress" className="form-label title">
                        Tag <span className="star">*</span>
                    </label>
                    <div className="d-flex">
                        <input
                            type="text"
                            {...register("tag", { required: true })}
                            className="form-control w-75"
                            // id="authordetails"
                            style={{ paddingLeft: "10px" }}
                        />
                        {errors.tag && (
                            <div className={`invalid-feedback d-block`}>
                                Please provide a Tag
                            </div>
                        )}
                        <div className="col-md-4">
                            <button type="button" className="partneredDoctorAddBtn">Add</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="addnew1-form1-next">
                <div className="addnew1-back-next">
                    <div className="addnew1-back">
                        <div onClick={handleBack}>
                            <div>
                                <img src={back} alt="" />
                            </div>
                            <div>BACK</div>
                        </div>
                    </div>
                    <div className="pharm-nxt1">
                        <div onClick={() => handleNextBtn}>
                            <div>
                                <button>NEXT</button>
                            </div>
                            <div>
                                <img src={next} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const StepThree = ({
    register,
    errors,
    setPage,
    isValid,
    trigger,
    handlePrevious
}) => {

    const [filename, setFilename] = useState('')

    const handleChange = () => {
        if (isValid) {
            setPage(4)
        }
    }

    return (
        <>
            <div className="addnew-form1-autor-detail d-flex">
                <div className="col-md-6">
                    <label for="inputAddress" className="form-label title">
                        State <span className="star">*</span>
                    </label>
                    <select
                        {...register("state", { required: true })}
                        className="form-select car-job-select w-75"
                        defaultValue={""}
                    >
                        <option value="" disabled pri-specialization="true">
                            Select state
                        </option>
                        {states.map((el, inx) => {
                            return <option value={el} key={inx}>{el}</option>;
                        })}
                    </select>
                    {errors.state && (
                        <div className={`invalid-feedback d-block`}>
                            Please provide a State
                        </div>
                    )}
                </div>
                <div className="col-md-6">
                    <label for="inputAddress" className="form-label title">
                        City <span className="star">*</span>
                    </label>
                    <select
                        {...register("city", { required: true })}
                        className="form-select car-job-select w-75"
                        defaultValue={""}
                    >
                        <option value="" disabled pri-specialization="true">
                            Select
                        </option>
                    </select>
                    {errors.city && (
                        <div className={`invalid-feedback d-block`}>
                            Please select an option
                        </div>
                    )}
                </div>
            </div>
            <div className="addnew-form1-autor-detail mt-5 d-flex">
                <div className="col-md-6">
                    <label for="inputAddress" className="form-label title">
                        Location <span className="star">*</span>
                    </label>
                    <select
                        {...register("location", { required: true })}
                        className="form-select car-job-select w-75"
                        defaultValue={""}
                    >
                        <option value="" disabled pri-specialization="true">
                            Select
                        </option>
                    </select>
                    {errors.location && (
                        <div className={`invalid-feedback d-block`}>
                            Please provide a Location
                        </div>
                    )}
                </div>
                <div className="col-md-6">
                    <InputTextField
                        labelName="Pincode"
                        name="pincode"
                        placeholder="Enter"
                        register={register}
                        errors={errors}
                        errorText={"Please provide a pincode"}
                    />
                </div>
            </div>
            <div className="addnew-form1-autor-detail mt-5 d-flex">
                <div className="col-md-6">
                    <InputTextField
                        labelName="Email ID"
                        name="email"
                        register={register}
                        placeholder="Enter email id"
                        errors={errors}
                        errorText={"Please provide a Email"}
                    />
                </div>
                <div className="col-md-6">
                    <InputTextField
                        labelName="Date of LOU signed"
                        name="date"
                        register={register}
                        placeholder="Enter date"
                        errors={errors}
                        errorText={"Please provide a Date"}
                    />
                </div>
            </div>
            <div className="addnew-form1-autor-detail mt-5 d-flex">
                <div className="col-md-6">
                    <label for="inputAddress" className="form-label title">
                        Moth of LOU Signed <span className="star">*</span>
                    </label>
                    <select
                        {...register("month", { required: true })}
                        className="form-select car-job-select w-75"
                        defaultValue={""}
                    >
                        <option value="" disabled pri-specialization="true">
                            Select month
                        </option>
                    </select>
                    {errors.month && (
                        <div className={`invalid-feedback d-block`}>
                            Please provide a month
                        </div>
                    )}
                </div>
                <div className="col-md-6">
                    <label for="inputAddress" className="form-label title">
                        Signature Type <span className="star">*</span>
                    </label>
                    <select
                        {...register("signature_type", { required: true })}
                        className="form-select car-job-select w-75"
                        defaultValue={""}
                    >
                        <option value="" disabled pri-specialization="true">
                            Select
                        </option>
                    </select>
                    {errors.signature_type && (
                        <div className={`invalid-feedback d-block`}>
                            Please select the Signature Type
                        </div>
                    )}
                </div>
            </div>
            <div className="addnew-form1-autor-detail mt-5 d-flex">
                <div className="col-md-6">
                    <InputTextField
                        labelName="Bank Account Details"
                        name="bank-account-details"
                        register={register}
                        errors={errors}
                        errorText={"Please provide a Bank Account Details"}
                    />
                </div>
                <div className="col-md-6">
                    <InputTextField
                        labelName="Partner ID"
                        name="partner_id"
                        register={register}
                        errors={errors}
                        errorText={"Please provide a Partner ID"}
                    />
                </div>
            </div>
            <div className="addnew-form1-autor-detail mt-5 d-flex">
                <div className="col-md-6">
                    <InputTextField
                        labelName="Date of Profile Activated"
                        name="profile-activated"
                        register={register}
                        errors={errors}
                        errorText={"Please provide a Profile Activated"}
                    />
                </div>
                <div className="col-md-6">
                    <label for="inputAddress" className="form-label title">
                        App Installation Status <span className="star">*</span>
                    </label>
                    <select
                        {...register("status", { required: true })}
                        className="form-select car-job-select w-75"
                        defaultValue={""}
                    >
                        <option value="" disabled pri-specialization="true">
                            Select
                        </option>
                    </select>
                    {errors.status && (
                        <div className={`invalid-feedback d-block`}>
                            Please provide a Status
                        </div>
                    )}
                </div>
            </div>
            <div className="addnew-form1-autor-detail mt-5 d-flex">
                <div className="col-md-6">
                    <label for="inputAddress" className="form-label title">
                        Ground Breaking Status <span className="star">*</span>
                    </label>
                    <select
                        {...register("ground", { required: true })}
                        className="form-select car-job-select w-75"
                        defaultValue={""}
                    >
                        <option value="" disabled pri-specialization="true">
                            Select
                        </option>
                    </select>
                    {errors.ground && (
                        <div className={`invalid-feedback d-block`}>
                            Please provide a Primary Specialization
                        </div>
                    )}
                </div>
                <div className="col-md-6">
                    <label for="inputAddress" className="form-label title">
                        Plus Membership <span className="star">*</span>
                    </label>
                    <select
                        {...register("plus_membership", { required: true })}
                        className="form-select car-job-select w-75"
                        defaultValue={""}
                    >
                        <option value="" disabled pri-specialization="true">
                            Select
                        </option>
                    </select>
                    {errors.plus_membership && (
                        <div className={`invalid-feedback d-block`}>
                            Please provide a Plus Membership
                        </div>
                    )}
                </div>
            </div>
            <div className="col-6">
                <label className="form-label title">Upload Profile Image</label>
                <br />
                <input
                    className="form-control-file custom-file-input"
                    type="file"
                    onChange={(e) => {
                        setFilename(e.target.files[0].name)
                    }}
                    {...register("profile_image", { required: true })}
                />
                <label className='text-danger'>{filename}</label>
                {errors.ground && (
                    <div className={`invalid-feedback d-block`}>
                        Please provide a Primary Specialization
                    </div>
                )}
            </div>
            <div className="addnew1-form1-next mt-5">
                <div className="addnew1-back-next">
                    <div className="addnew1-back">
                        <div onClick={handlePrevious}>
                            <div>
                                <img src={back} alt="" />
                            </div>
                            <div>BACK</div>
                        </div>
                    </div>
                    <div className="pharm-nxt1">
                        <div onClick={handleChange}>
                            <div>
                                <button>NEXT</button>
                            </div>
                            <div>
                                <img src={next} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const StepFour = ({
    register,
    errors,
    content,
    setPage,
    editor,
    handleNext,
    handleStepPrevious,
}) => {

    const handleChange = () => {
        console.log('111--->')
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center mt-4">
                <div><b>Create a FAQ</b></div>
                <select
                    // {...register("type", { required: true })}
                    className="form-select car-job-select w-25"
                    defaultValue={""}
                >
                    <option value="" disabled pri-specialization="true">
                        Select page type
                    </option>
                </select>
                <div> <button className="btn btn-success createFaqBtn" type="submit">Save</button></div>
            </div>
            <div className="mt-3">
                <b>Question</b>
                <div className="mt-4 mb-4">
                    <input type="text"
                        className="w-100 questionWrapper border-0"
                        name=""
                        id="type"
                        {...register("question", { required: true })}
                    />
                    {errors.question && (
                        <div className={`invalid-feedback d-block`}>
                            Please provide a question
                        </div>
                    )}
                </div>
                <b>Answer</b>
                <div className="mt-4">
                    <JoditEditor
                        // {...register("healtharticle_description", { required: false })}
                        ref={editor}
                        value={content}
                        {...register("answer", { required: false })}
                        id="about"
                        // name="about"
                        config={config}
                    />
                    {errors.about && (
                        <div className={`invalid-feedback d-block`}>
                            Please provide a About
                        </div>
                    )}
                </div>
            </div>
            <div className="addnew1-form1-next">
                <div className="addnew1-back-next">
                    <div className="addnew1-back">
                        <div onClick={handleStepPrevious}>
                            <div>
                                <img src={back} alt="" />
                            </div>
                            <div>BACK</div>
                        </div>
                    </div>
                    <div className="pharm-nxt1">
                        <div onClick={() => handleChange}>
                            <div>
                                <button>NEXT</button>
                            </div>
                            <div>
                                <img src={next} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const PartneredDoctorOnline = () => {

    const [page, setPage] = useState(1);
    const [content,] = useState("");
    const editor = useRef(null);


    const {
        register,
        formState: { errors, isValid },
        watch,
        setValue,
        trigger,
        handleSubmit,
    } = useForm();

    const onSubmit = () => {
        console.log('111--->')
    }

    const handleNext = async () => {
        await trigger();
        if (isValid) {
            setPage(2);
        }
    };

    const handleBack = () => {
        setPage(1);
    }

    const handlePrevious = () => {
        setPage(2);
    }

    const handleStepPrevious = () => {
        setPage(3)
    }

    return (
        <>
            <div className="healtharticle mt-5 pt-4 ps-3 pb-5 ps-4">
                <div>
                    <Level
                        page={page}
                    />
                    <form className="career-jobpost-form clinicProfile mt-2 ms-2" onSubmit={handleSubmit(onSubmit)}></form>
                    {page === 1 ? (
                        <StepOne
                            register={register}
                            errors={errors}
                            watch={watch}
                            setValue={setValue}
                            trigger={trigger}
                            isValid={isValid}
                            handleNext={handleNext}
                            handleBack={handleBack}
                        />
                    ) : page === 2 ? (
                        <StepTwo
                            register={register}
                            errors={errors}
                            content={content}
                            editor={editor}
                            setPage={setPage}
                            trigger={trigger}
                            isValid={isValid}
                            handleBack={handleBack}
                        />
                    ) : page === 3 ? (
                        <StepThree
                            register={register}
                            errors={errors}
                            setPage={setPage}
                            // handleNext2={handleNext2}
                            handlePrevious={handlePrevious}
                        />
                    ) : page === 4 ? (
                        <StepFour
                            register={register}
                            errors={errors}
                            content={content}
                            setPage={setPage}
                            editor={editor}
                            handleStepPrevious={handleStepPrevious}
                        />
                    ) : ""}
                </div>
            </div>
        </>
    )
}

export default PartneredDoctorOnline