import axios from "axios";
import JoditEditor from "jodit-react";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";

import apiCall from "../../../../api";
import graytick from "../../../../assets/images/graytick.PNG";
import greentick from "../../../../assets/images/greentick.PNG";
import { config, } from "../../../../constants";
import BackBtn from "../../../Common/backBtn";
import Breadcrumbs from "../../../Common/Breadcrumbs/Breadcrumbs";
import InputRadioDynamic from "../../../Common/inputRadioDynamic";
import NextBackBtns from "../../../Common/nextBackBtns";
import SelectField from "../../../Common/selectField";
import InputTextField from "../../../Common/inputTextField";
import SucessModal from "../../../modal/SucessModal";
import AsyncBlogOptions from './asyncBlogOptions';
import FaqForm from "../../../Common/faqForm";

const StepOne = ({
  menu,
  editor,
  errors,
  trigger,
  unregister,
  isValid,
  setLevel,
  control,
  register,
  watch,
  reset,
  setValue,
  category,
  getValues,
  uploadImage,
  authorImage,
  blogTags,
  setBlogTags,
  description,
  setUploadImage,
  setAuthorImage,
  setDescription
}) => {

  const handleNext = async () => {
    await trigger();
    if (isValid) {
      setLevel(2)
    }
  }

  // todo
  // const memoizedMenuOptions = useMemo(() => menu.map((data) => ({ value: data?.name, label: data?.name }))			
  // , [menu])

  const handleInputChange = (e, auther = false) => {
    if (auther) {
      setAuthorImage(e.target.files[0]);
    } else {
      setUploadImage(e.target.files[0]);
    }
  };

  const handleAddBlogTag = () => {
    const tagId = blogTags[blogTags?.length - 1]?.tagId + 1
    setBlogTags((pre) => [...pre, { tagId }]);
  };

  const handleRemoveBlogTag = (tagId) => {
    trigger()
    unregister(`tagname-${tagId}`)
    unregister(`blog-${tagId}`)
    setBlogTags(blogTags.filter((data) => data?.tagId !== tagId));
  };

  const getsrcValue = (value) => {
    if (typeof value === "string" && value.length)
      return process.env.REACT_APP_API_URL + "images/" + value;
    return typeof value === "object" ? URL.createObjectURL(value) : " ";
  };

  const removeImage = async (auther = false) => {
    if (auther) {
      setAuthorImage("");
      setValue(authorImage, "")
    } else {
      setUploadImage("");
      setValue(uploadImage, "")
    }
  };

  const trendingBlogOptions = [
    { label: '#1 Trending', value: '#1 Trending' },
    { label: '#2 Trending', value: '#2 Trending' },
    { label: 'None', value: "" },
  ]

  useEffect(() => {
    if (getValues()?.menu) {
      setValue("menu", getValues()?.menu)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu])

  useEffect(() => {
    if (getValues()?.category) {
      setValue("category", getValues()?.category)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category])

  return (
    <div className="addnew1-form ">
      <div className="d-flex mb-2">
        <div className="col-md-6">
          <SelectField
            id="menu"
            labelName="Menu"
            name="menu"
            // options={memoizedMenuOptions}
            options={menu.map((data) => ({ value: data?.name, label: data?.name }))}
            register={register}
            errors={errors}
            className="addnew-input"
            errorText="Please choose a Menu"
            defaultOption="Menu"
            isOptionObject={true}
          />
        </div>
        <div className="col-md-6">
          <SelectField
            id="category"
            labelName="Category"
            name="category"
            options={category.map((data) => ({ value: data?.category, label: data?.category }))}
            register={register}
            errors={errors}
            className="addnew-input"
            errorText="Please choose the Category"
            defaultOption="Category"
            isOptionObject={true}
          />
        </div>
      </div>

      <div className="col-12 mt-5">
        <InputTextField
          labelName="Title"
          name="title"
          register={register}
          errors={errors}
          className="w-100"
          errorText={"Please provide a Title"}
        />
      </div>
      <div className="addnew-form-active mb-3">
        <div className="addnew-active">
          <InputRadioDynamic
            register={register}
            errors={errors}
            errorMessage="Please choose the Active"
            watch={watch}
            setValue={setValue}
            trigger={trigger}
            label="Active"
            name="active"
            options={[
              { name: "Yes", id: true },
              { name: "No", id: false },
            ]}
          // isOptionO////bject={true}
          />
        </div>
        <div className="addnew-featured">
          <InputRadioDynamic
            register={register}
            errors={errors}
            errorMessage="Please choose the Featured"
            watch={watch}
            setValue={setValue}
            trigger={trigger}
            label="Featured"
            name="featured"
            options={[
              { name: "Yes", id: true },
              { name: "No", id: false },
            ]}
          // isOptionObject={true}
          />
        </div>
      </div>
      <div className="d-flex mb-2 mt-5">
        <div className="col-md-6">
          <SelectField
            id="trendingblog "
            labelName="Trending Blog"
            name="trendingblog"
            options={trendingBlogOptions}
            register={register}
            errors={errors}
            isRequired={false}
            className="addnew-input"
            defaultOption="Trending Blog"
            isOptionObject={true}
          />
        </div>
        <div className="col-md-6">
          <InputRadioDynamic
            register={register}
            errors={errors}
            watch={watch}
            setValue={setValue}
            trigger={trigger}
            label="Topic of the Month"
            name="topicmonth"
            isOptionObject={false}
            options={[
              { name: "Yes", id: true },
              { name: "No", id: false },
            ]}
          />
        </div>
      </div>
      <div className="addnew-form-upload mt-5">
        <div>
          <div className="title fo-tit fo-tit-top ">
            Upload image <span className="star">*</span>
          </div>
          {uploadImage ? (
            <div> <img
              src={getsrcValue(uploadImage)}
              style={{
                width: "auto",
                height: "200px",
                marginTop: "2%",
                marginBottom: "5%",
              }}
              alt="img"
            /></div>
          ) : null}
          {(uploadImage === "" || typeof (uploadImage) !== 'string') ? <input
            className="form-control-file custom-file-input"
            type="file"
            style={{
              width: "120px",
            }}
            {...(uploadImage ? {} : register("uploadImage", { required: true, onChange: handleInputChange }))}

          // {...register("uploadImage", { required: true })}
          // onChange={handleInputChange}
          /> : null}
          {uploadImage &&
            <button className="btn btn-danger ms-2"
              onClick={() => removeImage()}
            >Remove</button>}
          {errors?.uploadImage && (
            <div className={`invalid-feedback d-block`}>
              Please provide a Image
            </div>
          )}
        </div>
        <div className="authorUpload">
          <div className="title fo-tit fo-tit-top">
            Upload Author image <span className="star">*</span>
          </div>
          {authorImage ? (
            <div> <img
              src={getsrcValue(authorImage)}
              style={{
                width: "auto",
                height: "200px",
                marginTop: "2%",
                marginBottom: "5%",
              }}
              alt="img"
            /></div>
          ) : null}

          {(authorImage === "" || typeof (authorImage) !== 'string') ? <input
            className="form-control-file custom-file-input"
            type="file"
            style={{
              width: "120px",
            }}
            {...(authorImage ? {} : register("authorImage", { required: true, onChange: (e) => handleInputChange(e, true) }))}
          // {...register("authorImage", { required: true })}
          // onChange={(e) => handleInputChange(e, true)}
          /> : null}
          {authorImage &&
            <button className="btn btn-danger ms-2"
              onClick={() => removeImage(true)}
            >Remove</button>}
          {errors?.authorImage && (
            <div className={`invalid-feedback d-block`}>
              Please provide a Author Image
            </div>
          )}
        </div>
        <div className="ml-7">
          <label for="inputAddress" className="form-label title">
            Banner Image Alt <span className="star">*</span>
          </label>
          <input
            type="text"
            className="form-control addnew-input"
            id="bannername"
            {...register("bannername", { required: true })}
            style={{ paddingLeft: "10px" }}
          />
          {errors.bannername && (
            <div className={`invalid-feedback d-block`}>
              Please provide a Banner Name
            </div>
          )}
        </div>
      </div>
      <div className="addnew-form1-autor-detail mt-5 d-flex">
        <div className="col-6">
          <InputTextField
            labelName=" Author Name"
            name="authordetails"
            register={register}
            errors={errors}
            errorText={"Please provide a Author Name"}
          />
        </div>
        <div className="col-6">
          <InputTextField
            labelName="Author Designation"
            name="authorSpecialization"
            register={register}
            errors={errors}
            errorText={"Please provide a Author Designation"}
          />
        </div>
      </div>

      <div className="addnew-form1-autor-detail mt-5 d-flex">
        <div className="col-6">
          <label htmlFor="inputAddress" className="form-label title">
            About Author <span className="star">*</span>
          </label>
          <textarea
            className="form-control addnew-input width-76 ogTagsField"
            id="aboutauthor"
            {...register("aboutauthor")}
            errors={errors}
            errorText={"Please provide a About Author"}
          />
        </div>
        <div className="col-6">
          <InputTextField
            labelName="Author LinkedIn Link"
            name="authorlinkedin"
            register={register}
            errors={errors}
            errorText={"Please provide a Author LinkedIn Link"}
          />
        </div>
      </div>
      <div className="my-5 ">
        <div className="form-label title fw-bold">Blog Tags</div>
        {blogTags.map((blog, index) => (
          <div key={index} className="row mt-3">
            <div className="col-md-4">
              <InputTextField
                labelName="Tag Name"
                name={`tagname-${blog?.tagId}`}
                register={register}
                errors={errors}
                className="w-100"
                placeholder="Enter Tag Name"
                errorText={"Please provide a Tag Name"}
              />
            </div>
            <div className="col-md-4 blogTitleSelect">
              <AsyncBlogOptions
                control={control}
                errors={errors}
                labelName={"Blog Title"}
                defaultValue={getValues()?.[`blog-${blog?.tagId}`]}
                inputValue={getValues()?.[`blog-${blog?.tagId}`]}
                defaultOptions={[getValues()?.[`blog-${blog?.tagId}`]]}
                name={`blog-${blog?.tagId}`}
              />
            </div>
            {blogTags?.length > 1 ? <div className="col-md-4">
              <span
                onClick={() => handleRemoveBlogTag(blog?.tagId)}
                className="plus-icon"
                style={{
                  marginLeft: "0px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    height: "2px",
                    width: "50%",
                    backgroundColor: "#fff",
                  }}
                ></span>
              </span>
            </div> : null}
          </div>
        ))}
        <div className="d-flex justify-content-between col-md-4">
          <span
            onClick={handleAddBlogTag}
            className="addPlusIcon text-end text-white"
            style={{
              marginLeft: "0px",
              height: '34px',
              cursor: "pointer",
              marginTop: 32
            }}
          >
            Add
          </span>
        </div>
      </div>
      <div className="addnew-form1-desc mt-3">
        <div className="title">
          Description <span className="star">*</span>
        </div>
        <div>
          {/* <JoditEditor
            {...register("description", { required: false })}
            ref={editor}
            value={getValues('description')}
            config={config}
          /> */}
          <JoditEditor
            value={`${description}`}
            onChange={(value) => {
              setDescription(value);
              setValue("description", value);
            }}
          />
          {/* <JoditEditor
            // {...register("healtharticle_description", { required: false })}
            ref={editor}
            value={getValues('description')}
            {...register("description", { required: false })}
            id="description"
            // name="about"
            config={config}
          />
          {errors.description && (
            <div className={`invalid-feedback d-block`}>
              Please provide a description
            </div>
          )} */}
        </div>
      </div>
      <div className="addnew-form1-videourl mt-3">
        <div className="col-7">
          <InputTextField
            labelName="Video URL"
            name="videoUrl"
            id="videoUrl"
            register={register}
            isRequired={false}
            errors={errors}
            className="w-100"
            errorText={"Please provide a videoUrl"}
          />
        </div>
      </div>
      <NextBackBtns
        back={false}
        handleNext={handleNext}
      />
    </div>
  )
}

const StepTwo = ({
  trigger,
  isValid,
  register,
  errors,
  setLevel,
  metaTitle,
  setMetaTitle
}) => {

  const handleBack = () => {
    setLevel(1)
  }

  const handleNext = async () => {
    await trigger();
    if (isValid) {
      setLevel(3)
    }
  }

  return (
    <div className="addnew2-form">
      <div className="col-12">
        <InputTextField
          labelName="Meta Title"
          name="metatitle"
          register={register}
          className="addnew-input w-100"
          onChange={(e) => setMetaTitle(e.target.value)}
          errors={errors}
          errorText={"Please provide a Meta Title"}
        />
      </div>
      <div className="col-12">
        <InputTextField
          labelName="Meta Description"
          name="metadescription"
          register={register}
          className="addnew-input w-100"
          errors={errors}
          errorText={"Please provide a Meta Description"}
        />
      </div>
      <div className="col-12 ">
        <InputTextField
          labelName="Meta Keywords"
          name="metakeywords"
          register={register}
          className="addnew-input w-100"
          errors={errors}
          errorText={"Please provide a Meta Keywords"}
        />
      </div>
      <div className="col-12">
        <InputTextField
          labelName="Meta Tags"
          name="metatags"
          register={register}
          className="addnew-input w-100"
          errors={errors}
          errorText={"Please provide a Meta Tags"}
        />
      </div>
      <div className="col-12">
        <label htmlFor="inputAddress" className="form-label title">
          Others <span className="star"></span>
        </label>
        <textarea
          className="form-control addnew-input w-100 ogTagsField"
          id="others"
          {...register("others")}
        />
      </div>
      <div className="col-12">
        <InputTextField
          labelName="Canonical Url"
          name="canonicalUrl"
          register={register}
          isRequired={false}
          errors={errors}
          className="w-100"
          errorText={"Please provide a Canonical Url"}
        />
      </div>
      <NextBackBtns
        handleNext={handleNext}
        handleBack={handleBack}
      />
    </div>
  )
}


const Level = ({
  level,
  edit
}) => {
  return (
    <>
      <Breadcrumbs
        breadcrumbText="Blog"
        breadcrumbLink=""
        nestedText={edit ? "Edit New Blog" : "Add New Blog"}
      />
      {
        <div className={level === 1 ? "doctor-page-tick" : level === 2 ? "doctor-page2-tick" : level === 3 ? "doctor-page3-tick" : level === 4 ? "doctor-page4-tick" : ""}>
          <div>
            <img src={greentick} alt="" />
            <div>Page 1</div>
          </div>
          <div>
            <img src={level === 1 ? graytick : greentick} alt="" />
            <div>Page 2</div>
          </div>
          <div>
            <img src={(level === 3 || level === 4) ? greentick : graytick} alt="" />
            <div>Page 3</div>
          </div>
        </div>
      }
    </>
  )
}

const HealthArticleEdit = ({ edit }) => {

  const { id } = useParams();

  const [level, setLevel] = useState(1);
  const [success, setSuccess] = useState(false);

  const editor = useRef(null);
  const [faqs, setFaqs] = useState([]);
  const [menu, setMenu] = useState([]);
  const [category, setCategory] = useState([]);
  const [blogTags, setBlogTags] = useState([{ tagId: 1 }])
  
  const {
    register,
    unregister,
    getValues,
    watch,
    reset,
    trigger,
    control,
    setValue,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm();

  const [activecheck, setActiveCheck] = useState(false)
  const [editBlogId, setEditBlogId] = useState(null)
  const [featuredcheck, setFeaturedCheck] = useState(false)
  const [uploadImage, setUploadImage] = useState('')
  const [authorImage, setAuthorImage] = useState('')
  const [metaTitle, setMetaTitle] = useState("")
  const [description, setDescription] = useState("")

  const getHealthArticle = async () => {
    const res = await apiCall(`health-article/get/${id}`, "GET");

    if (res?.data) {
      setUploadImage(res.data.uploadImage)
      setAuthorImage(res.data.authorImage)
      setActiveCheck(res.data.active)
      setFeaturedCheck(res.data.featured)
      setFaqs(res?.data?.faq ?? []);

      Object.keys(res.data)?.forEach((x) => {
        const arr = res.data.metatags;
        const stringData = arr.reduce((result, item) => {
          return `${result}${item?.title} `
        }, "")
        if (res.data[x] === res.data.metatags) {
          setValue(x, stringData)
        } else {
          setValue(x, res.data[x]);
        }

      });
      setValue("active", res.data.active ? "Yes" : "No")
      setValue("featured", res.data.featured ? "Yes" : "No")
      setValue("trendingblog", res.data.trendingblog);
      setValue("topicmonth", res.data.topicmonth ? "Yes" : "No")
      let blogTagsArr = []
      res.data?.blogTags?.forEach((data, index) => {
        setValue(`tagname-${index + 1}`, data?.tagName)
        setValue(`blog-${index + 1}`, { value: data?.blog?._id, label: data?.blog?.title })
        blogTagsArr.push({ tagId: index + 1 })
      })
      if (res.data?.blogTags?.length > 1) {
        setBlogTags(blogTagsArr)
      }
      setDescription(res?.data?.description)
      setEditBlogId(res.data._id)
      setMetaTitle(res.data.metatitle)
    }
  }

  useEffect(() => {
    if (edit) {
      getHealthArticle()
    }
  }, []);

  const onSubmit = () => {
    let fields;
    fields = getValues();
    delete fields.job_post_image;
    fields["uploadImage"] = fields.uploadImage && (typeof (fields.uploadImage) === 'string' ? fields.uploadImage : fields.uploadImage[0]);
    fields["authorImage"] = fields.authorImage && (typeof (fields.authorImage) === 'string' ? fields.authorImage : fields.authorImage[0]);
    // fields["authorImage"] = document.getElementById("authorImage")?.files[0] || fields.authorImage; // Access files property after checking if the element exists
    fields["metatags"] = fields.metatags.trim().split(' ');
    fields["faq"] = JSON.stringify(faqs);
    fields["active"] = fields?.active === "Yes";
    fields["featured"] = fields?.featured === "Yes";
    fields["topicmonth"] = fields?.topicmonth === "Yes";

    fields["blogTags"] = blogTags.map((data) => {
      return { tagName: fields?.[`tagname-${data?.tagId}`], blogId: fields?.[`blog-${data.tagId}`]?.value }
    })
    if (fields["trendingblog"] === "none") {
      fields["trendingblog"] = ""
    }
    const fieldKeys = Object.keys(fields);
    fieldKeys?.forEach((key) => {
      if (key.includes("tagname-") || key.includes("blog-")) {
        delete fields[key];
      }
    });
    if (fields?._id) {
      delete fields._id
    }
    if (edit) {
      axios
        .postForm(
          `${process.env.REACT_APP_API_URL}health-article/update/${editBlogId}`,
          fields
        )
        .then((response) => {
          if (response.data?._id) {
            setSuccess(true);
          } else {
            alert("Fail");
          }
        })
    } else {
      axios
        .postForm(`${process.env.REACT_APP_API_URL}health-article/create`,
          fields
          // updatedFields
        )
        .then((response) => {
          if (response.data?._id) {
            setSuccess(true)
          } else {
            alert("Fail");
          }
        })
    }
  };

  const getMenuData = async () => {
    // axios
    //   .post(`${process.env.REACT_APP_API_URL}health-article-menu/list`)
    //   .then((res) => {
    //     setMenu(res.data.rows);
    //   });

    try {
      const res = await apiCall('health-article-menu/list', "POST", { active: true });
      if (res.data) {
        setMenu(res.data.rows);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getCategoryData = async () => {
    // axios
    //   .post(`${process.env.REACT_APP_API_URL}health-article-category/list`)
    //   .then((res) => {
    //     setCategory(res.data.rows);
    //   });

    try {
      const res = await apiCall('health-article-category/list', "POST", { active: true });
      if (res.data) {
        setCategory(res.data.rows);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getMenuData();
    getCategoryData();
  }, []);

  return (
    <>
      {/* <Home> */}
      <BackBtn
        navigatePath="/healtharticle"
      />

      <div className="healtharticle stepOne mt-5 pt-4 ps-3 pb-5 ps-4">
        <div style={{ marginLeft: "2%" }}>
          <Level
            level={level}
            edit={edit}
          />
          <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
            {level === 1 ? (
              <StepOne
                menu={menu}
                errors={errors}
                control={control}
                editor={editor}
                config={config}
                trigger={trigger}
                reset={reset}
                isValid={isValid}
                unregister={unregister}
                setLevel={setLevel}
                setValue={setValue}
                register={register}
                category={category}
                watch={watch}
                getValues={getValues}
                uploadImage={uploadImage}
                activecheck={activecheck}
                featuredcheck={featuredcheck}
                setActiveCheck={setActiveCheck}
                setFeaturedCheck={setFeaturedCheck}
                authorImage={authorImage}
                blogTags={blogTags}
                description={description}
                setDescription={setDescription}
                setBlogTags={setBlogTags}
                setUploadImage={setUploadImage}
                setAuthorImage={setAuthorImage}
              />
            ) : ""}

            {level === 2 ? (
              <StepTwo
                trigger={trigger}
                isValid={isValid}
                errors={errors}
                register={register}
                setLevel={setLevel}
                metaTitle={metaTitle}
                setMetaTitle={setMetaTitle}
              />
            ) : ""}

            {level === 3 ? (
              <FaqForm
                faqs={faqs}
                setFaqs={setFaqs}
                handleBack={() => setLevel(2)}
              />
            ) : ""}

          </form>
        </div>
      </div>
      {/* </Home> */}

      {success && (
        <SucessModal
          page={"/healtharticle"}
          name="Health Article"
          post='updated'
        />
      )}
    </>
  );
};

export default HealthArticleEdit;