import axios from "axios";
import Moment from "moment";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";

import apiCall from "../../api";
import { getWebBookingStatus, webBookingProfileStatus } from "../../constants";
import { formatDateTime, updatedBookingStatus } from "../../utils";
import AssignedTo from "../modal/AssignedTo";
import Deletepopup from "../modal/Deletepopup";
import LeadStatus from "../modal/LeadStatus";
import SucessModal from "../modal/SucessModal";
import './webBooking.scss';

const WebBookingInClinicConsultation = () => {

  const [webBookingsList, setWebBookingsList] = useState([]);
  const [assignModal, setAssignModal] = useState({ id: "", status: false })
  const [deleteModal, setDeleteModal] = useState({ id: "", status: false });
  const [leadStatusModal, setLeadStatusModal] = useState({ id: "", status: false })
  const [success, setSuccess] = useState(false);
  const [leadStatussuccess, setLeadStatusSuccess] = useState(false);

  const location = useLocation();

  useEffect(() => {
    getWebBookingsList()
  }, []);

  const getWebBookingsList = async () => {
    const result = await apiCall('inclinic-booking-details/list', "POST");
    if (result?.data) {
      setWebBookingsList(result.data);
    }

  }
  const handleDelete = async (id) => {
    try {
      const result = await apiCall(`inclinic-booking-details/delete/${id}`, "DELETE");
      if (result.data) {
        getWebBookingsList();
        setDeleteModal({ id: "", status: false });
      }
    } catch (error) {
      console.error("Error deleting:", error);
      // Handle errors
    }
  };

  const handleClose = () => {
    setDeleteModal({ id: "", status: false });
  };

  const handleNotInterested = async (id) => {
    try {
      const result = await apiCall(
        `inclinic-booking-details/update/${id}`,
        "PATCH",
        {
          not_intrested: true,
          booking_confirmed: false,
          yet_to_contact: false,
        }
      );
      if (result.data) {
        getWebBookingsList();
      }
    } catch (error) {
      console.error("Error updating:", error);
      // Handle errors
    }
  };

  //NotContact Functionality
  const handleBookingConfirmed = async (id) => {
    try {
      const result = await apiCall(`inclinic-booking-details/update/${id}`, "PATCH",
        {
          not_intrested: false,
          booking_confirmed: true,
          yet_to_contact: false,
        }
      );
      if (result.data) {
        getWebBookingsList();
      }
    } catch (error) {
      console.error(error)
    }
  };

  //On-Board Functionality
  const handleYetToContact = async (id) => {
    try {
      const result = await apiCall(`inclinic-booking-details/update/${id}`, "PATCH",
        {
          not_intrested: false,
          booking_confirmed: false,
          yet_to_contact: true,
        })
      if (result.data) {
        getWebBookingsList();
      }
    } catch (error) {
      console.log("error", error)
    }
  };

  const successModalClose = () => {
    setSuccess(false)
    setLeadStatusSuccess(false)
    getWebBookingsList()
  }

  const handleOptionChange = (e, id) => {
    const selectedValue = e.target.value;
    const updatedStatus = updatedBookingStatus(selectedValue);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}inclinic-booking-details/update/${id}`,
        updatedStatus
      )
      .then((result) => {
        getWebBookingsList();
      });
  }

  return (
    <>
      <div className="pt-4">
        {webBookingsList?.length && location.pathname === "/web-bookings/in-clinic-appointments" ? (
          <div className=" webBookingsTable ms-4">
            <table>
              <thead>
                <tr>
                  <td className="">S.No</td>
                  <td className="">Name</td>
                  <td className="">Doctor Name</td>
                  <td className="">Mobile No</td>
                  <td className="">Email id</td>
                  <td className="">Health Conditions</td>
                  <td className="">About</td>
                  <td className="">Received At</td>
                  <td className="">Booking Requested Date & Time</td>
                  <td className="">Action</td>
                </tr>
              </thead>
              <tbody>
                {webBookingsList.map((el, i) => {
                  return (
                    <tr key={el._id}>
                      <td>{i + 1}</td>
                      <td>{el.name}</td>
                      <td>{el.doctor_name}</td>
                      <td>{el.mobile}</td>
                      <td>{el.email}</td>
                      <td>{el.symptoms.join(", ")}</td>
                      {/* <td>{el.inclinic_primary_specialization}</td> */}
                      <td>{el.about}</td>
                      <td>
                        {" "}
                        {Moment(el.createdAt).format("MMMM DD, YYYY LT")}
                      </td>
                      <td>
                        {formatDateTime(el?.date, el?.time)}
                      </td>
                      <td className="">
                        <select
                          className="bg-white"
                          onChange={(e) => handleOptionChange(e, el._id)}
                          style={{ color: "#CB1B5B" }}
                          value={getWebBookingStatus(el)}
                        >
                          {webBookingProfileStatus.map((action, inx) => {
                            return <option key={inx} value={action} className="text-dark">{action}</option>
                          })}
                        </select>
                        {/* <div
                          className={`text-warning cursorPointer ${el.not_intrested && "highlight"}`}
                          onClick={() => {
                            handleNotInterested(el._id);
                          }}
                        >
                          Not Interested</div> */}
                        <div
                          className="text-danger cursorPointer"
                          onClick={() => {
                            setDeleteModal({ id: el._id, status: true })
                          }}
                        >
                          Delete</div>
                        {/* <div
                          className={`text-success cursorPointer ${el.booking_confirmed && "highlight"}`}
                          onClick={() => {
                            handleBookingConfirmed(el._id);
                          }}
                        >
                          Booking Confirmed</div>
                        <div
                          className={`text-primary cursorPointer ${el.yet_to_contact && "highlight"}`}
                          onClick={() => {
                            handleYetToContact(el._id);
                          }}
                        >
                          Yet to Contact
                        </div> */}

                        {/* <span
                            className="doctor-edit"
                            onClick={() => {
                              navigate(`/web-booking-update/${el._id}`);
                            }}
                          >
                            Edit
                          </span>
                          <span className="onboard-logo">
                            <img src={onboard} alt="edit-logo" />
                          </span> */}
                      </td>
                    </tr>
                  );
                })}

              </tbody>
            </table>
          </div>
        ) : (
          <></>
        )}
      </div>

      {deleteModal.status === true && (
        <Deletepopup
          handleDelete={handleDelete}
          id={deleteModal?.id}
          isCloseModalEnable={true}
          handleClose={handleClose}
        />
      )}

      {assignModal.status === true && (
        <AssignedTo
          id={assignModal?.id}
          setAssignModal={setAssignModal}
          setSuccess={setSuccess}
        />
      )}

      {success && (
        <SucessModal
          name="BD"
          post="Assigned"
          onClick={() => successModalClose()}
        />
      )}

      {leadStatusModal.status === true && (
        <LeadStatus
          id={leadStatusModal?.id}
          setLeadStatusModal={setLeadStatusModal}
          setLeadStatusSuccess={setLeadStatusSuccess}
        />
      )}

      {leadStatussuccess && (
        <SucessModal
          name="Lead Status"
          post="Updated"
          onClick={() => successModalClose()}
        />
      )}
    </>
  );
};

export default WebBookingInClinicConsultation;
