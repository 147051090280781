import axios from "axios";
import { debounce } from 'lodash';
import Moment from "moment";
import { useEffect, useState } from "react";
import { AiOutlineBackward, AiOutlineForward } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";

import apiCall from "../../../api";
import newdelete from "../../../assets/images/HealthSy/delete.png";
import search from "../../../assets/images/searchIcon.png";
import { doctorProfileStatus, filterInclinicDoctorsOptions, inclinicMode, mode, status } from "../../../constants";
import AddButton from "../../Common/addButton";
import Breadcrumbs from "../../Common/Breadcrumbs/Breadcrumbs";
import FilterOptions from "../../Common/doctorsFilterOptions";
import ExcelDownload from "../../Common/excelDownload";
import FiltersRootModal, { FilterRangeSubModel, FilterSubModal, SortByModel } from "../../Common/filtersRootModals";
import FilterStatusModal from "../../Common/filterStatusModal";
import NavBarTitles from "../../Common/navTabTitles";
import ExcelDownloadModal from "../../masters/Common/excelDownloadAccess";
import Deletepopup from "../../modal/Deletepopup";
import SucessModal from "../../modal/SucessModal";

const PartneredDoctorForInClinic = () => {

  const navigate = useNavigate();

  const [del, setDel] = useState(false);
  const [id, setId] = useState("");
  const [pages, setPages] = useState(0);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState("newest");
  const [sortName,] = useState("Sort by");
  const [records, setRecords] = useState([]);
  const [sortshow, setSortShow] = useState(false);
  const [filtershow, setFilterShow] = useState(false);
  const [deleteModal, setDeleteModal] = useState({ id: "", status: false });
  const [success, setSuccess] = useState(false);
  const [feeValues, setFeeValues] = useState([0, null]);
  const [pageOffset, setPageOffset] = useState(0);
  const [isClearClicked, setIsClearClicked] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchText, setSearchText] = useState('');
  const [filterOptions, setFilterOptions] = useState({
    primarySpecialist: [],
    statusValues: [],
    genderdata: [],
    modeData: [],
    languageData: [],
    feeRange: { min: 0, max: Infinity },
    cityData: [],
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const [filteredData, setFilteredData] = useState({
    primarySpecialist: [],
    statusValues: [],
    genderdata: [],
    modeData: [],
    languageData: [],
    cityData: []
  });
  const [searchPrimarySpecialist, setSearchPrimarySpecialist] = useState([]);
  const [textcolor, setTextColor] = useState(0);
  const [selectedSort, setSelectedSort] = useState(null);
  const [searchCityQuery, setSearchCityQuery] = useState([]);
  const [searchcityList, setSearchCityList] = useState([]);

  const list_api_call = async () => {
    let filterOptions = {};

    if (filteredData?.primarySpecialist?.length) {
      filterOptions["primarySpecialist"] = filteredData?.primarySpecialist
    }
    if (filteredData?.genderdata.length) {
      filterOptions["genderdata"] = filteredData?.genderdata
    }
    if (filteredData?.cityData.length) {
      filterOptions["cityData"] = filteredData?.cityData
    }
    if (filteredData?.languageData.length) {
      filterOptions["languageData"] = filteredData?.languageData
    }

    if (filteredData?.modeData.length) {
      filterOptions["modeData"] = filteredData?.modeData;
    }

    if (filteredData.statusValues && filteredData.statusValues?.length === 1) {
      filterOptions["verified"] = filteredData.statusValues[0];
    }

    if (feeValues[1]) {
      filterOptions["feeValues"] = { min: feeValues[0], max: feeValues[1] };
    }

    const response = await apiCall(`partnered-doctor/list`, "POST",
      {
        filterOptions: filterOptions,
        skip: skip,
        limit: Number(limit),
        sort: sort,
        profile_mode: "InClinic",
        searchText
      })
    setPages(Math.ceil(response.data.totalCount / Number(limit)))
    setRecords(response?.data?.rows);
  };

  const filtersDataList = async () => {
    try {
      const response = await apiCall('partnered-doctor/filter-options', 'POST', { type: "inclinicPartenredDoctor" });

      let tempFilterOptions = {};

      if (response.data?.usedSpecializations?.length) {
        tempFilterOptions["primarySpecialist"] = response.data?.usedSpecializations;
      }
      if (response.data?.gender?.length) {
        tempFilterOptions["genderdata"] = response.data?.gender;
      }
      if (response.data?.cityList?.length) {
        tempFilterOptions["cityData"] = response.data?.cityList;
      }
      if (response.data?.language?.length) {
        tempFilterOptions["languageData"] = response.data?.language;
      }
      if (response.data?.fees) {
        const fee = response.data?.fees?.[0]
        tempFilterOptions["feeRange"] = fee;
        setFeeValues([fee?.min, fee?.max])
      }

      setFilterOptions((prev) => ({ ...prev, ...tempFilterOptions }));
    } catch (err) {
      console.error(err);
      alert("Some error occurred while fetching filter options");
    }
  };

  useEffect(() => {
    list_api_call();
    filtersDataList()
  }, []);

  useEffect(() => {
    list_api_call();
  }, [skip, limit, sort, searchText]);

  useEffect(() => {
    if (isClearClicked) {
      list_api_call();
      setIsClearClicked(false)
    }
  }, [isClearClicked]);

  const handlePartneredDoctorForm = () => {
    navigate("/partnered-doctor-form-create");
  };

  const handleEdit = (id) => {
    navigate(`/partnered-doctor/edit/${id}`);
  };

  const handleDelete = (id) => {
    fetch(
      `${process.env.REACT_APP_API_URL}partnered-doctor/delete/${id}`,
      {
        method: "DELETE",
      }
    ).then((result) => {
      result.json().then((res) => {
        list_api_call();
        setDel(false);
      });
    });
  };

  const debouncedSearch = debounce((value) => {
    handlePageChange({ selected: 0 })
    setSearchText(value);
  }, 500);

  const onChangeSearch = (value) => {
    debouncedSearch(value);
  };

  const getsrcValue = (value) => {

    if (typeof value === "string" && value.length)
      return process.env.REACT_APP_API_URL + "profile-images/" + value;
  };

  const successModalClose = () => {
    setSuccess(false)
    list_api_call()
  }

  const handleClose = () => {
    setDeleteModal({ id: "", status: false });
  };

  const handleOptionChange = (e, id) => {
    const selectedValue = e.target.value;
    const fields = {};

    fields.action = selectedValue
    axios
      .post(
        `${process.env.REACT_APP_API_URL}partnered-doctor/update/${id}`,
        fields
      )
      .then((response) => {
        list_api_call()
        // getInstaDocQrCodeList()
      })

    if (selectedValue === "Delete") {
      setDeleteModal({ id: id, status: true });
    }
  }

  const handleSortClick = (sortOption, colorCode) => {
    setSelectedSort(sortOption);
    setTextColor(colorCode);
  };

  const handleApplyClick = () => {
    if (selectedSort) {
      setSort(selectedSort);
      setSortShow(false);
    }
  };

  const handlePageChange = (event) => {
    setSkip(event.selected * limit)
    setPageOffset(event.selected);
  };

  const handleSearchChange = (value) => {
    setSearchQuery(value)
    if (value?.length) {
      setSearchPrimarySpecialist(filterOptions?.primarySpecialist.filter(data => data?.name?.toLowerCase()?.includes(value.toLowerCase())))
    } else {
      setSearchPrimarySpecialist([])
    }
  }

  const handleCitySearchChange = (value) => {
    setSearchCityQuery(value)
    if (value?.length) {
      setSearchCityList(filterOptions?.cityData.filter(data => data?.name?.toLowerCase()?.includes(value.toLowerCase())))
    } else {
      setSearchCityList([])
    }
  }

  const handleCheckboxChange = (type, _id) => {
    if (type === 'spl') {
      if (filteredData.primarySpecialist.includes(_id)) {

        setFilteredData((pre) => ({ ...pre, primarySpecialist: pre.primarySpecialist.filter((data) => data !== _id) }))

      } else {
        setFilteredData((pre) => ({ ...pre, primarySpecialist: [...pre.primarySpecialist, _id] }))
      }
    }
  };

  const handleStatusCheckboxChange = (type, status) => {
    if (type === 'status') {
      if (filteredData.statusValues.includes(status)) {
        setFilteredData((prev) => ({
          ...prev,
          statusValues: prev.statusValues.filter((val) => val !== status),
        }));
      } else {
        setFilteredData((prev) => ({
          ...prev,
          statusValues: [...prev.statusValues, status],
        }));
      }
    }
  };

  const handleGenderCheckboxChange = (type, _id) => {
    if (type === 'gender') {
      if (filteredData.genderdata.includes(_id)) {

        setFilteredData((pre) => ({ ...pre, genderdata: pre.genderdata.filter((data) => data !== _id) }))

      } else {
        setFilteredData((pre) => ({ ...pre, genderdata: [...pre.genderdata, _id] }))
      }
    }
  }

  const handleModeCheckboxChange = (type, _id) => {
    if (type === 'mode') {
      if (filteredData.modeData.includes(_id)) {
        setFilteredData((pre) => ({ ...pre, modeData: pre.modeData.filter((data) => data !== _id) }));
      } else {
        setFilteredData((pre) => ({ ...pre, modeData: [...pre.modeData, _id] }));
      }
    }
  };

  const handleLanguageCheckboxChange = (type, _id) => {
    if (type === 'language') {
      if (filteredData.languageData.includes(_id)) {

        setFilteredData((pre) => ({ ...pre, languageData: pre.languageData.filter((data) => data !== _id) }))

      } else {
        setFilteredData((pre) => ({ ...pre, languageData: [...pre.languageData, _id] }))
      }
    }
  }

  const handleCityCheckboxChange = (name, _id) => {
    if (name === 'city') {
      if (filteredData.cityData.includes(_id)) {

        setFilteredData((pre) => ({ ...pre, cityData: pre.cityData.filter((data) => data !== _id) }))

      } else {
        setFilteredData((pre) => ({ ...pre, cityData: [...pre.cityData, _id] }))
      }
    }
  }

  const onClickDownload = async () => {
    try {

      let filterOptions = {};

      if (filteredData?.primarySpecialist?.length) {
        filterOptions["primarySpecialist"] = filteredData?.primarySpecialist
      }
      if (filteredData?.genderdata.length) {
        filterOptions["genderdata"] = filteredData?.genderdata
      }
      if (filteredData?.cityData.length) {
        filterOptions["cityData"] = filteredData?.cityData
      }
      if (filteredData?.languageData.length) {
        filterOptions["languageData"] = filteredData?.languageData
      }

      if (filteredData?.modeData.length) {
        filterOptions["modeData"] = filteredData?.modeData;
      }

      if (filteredData.statusValues && filteredData.statusValues?.length === 1) {
        filterOptions["verified"] = filteredData.statusValues[0];
      }

      if (feeValues[1]) {
        filterOptions["feeValues"] = { min: feeValues[0], max: feeValues[1] };
      }

      const response = await axios
        .post(`${process.env.REACT_APP_API_URL}partnered-doctor/list`, {
          skip: 0,
          limit: 3000,
          searchText,
          filterOptions: filterOptions,
          profile_mode: 'InClinic',
        })
      if (!response?.data?.rows?.length) {
        throw new Error("No records available to download.");
      }
      const records = response.data.rows
      const formattedRecords = records.map((record, index) => ({
        "S.No": index + 1,
        "Time of Registration": record.createdAt ? new Date(record.createdAt).toLocaleTimeString() : "",
        "Date of Registration": record.createdAt ? new Date(record.createdAt).toLocaleDateString() : "",
        "Name of Doctor": record.doctor_name || "",
        "Degree": record?.degree?.ug_degree,
        "Specialization (Primary)": record.inclinic_primary_specialization?.inclinic_doctor_specialization || "",
        "Fee": record.inclinic_consultation_fee || "",
        "Language": record.languages_known?.map(item => item?.type)?.join(', ') || "",
        "city": record.city?.city || "",
        "Mode": record.profile_mode || "",
        "Mobile Number": record.mobile || "",
        "Status": record.verified ? "Verified" : "Not Verified",
      }));

      ExcelDownload(formattedRecords, "", "InClinic-Partnered-doctor.xlsx")
      console.log("File downloaded successfully.");

    } catch (err) {
      console.error("Error downloading the file while getting all Doctor registration records", err, "err.message", err?.message)
      alert(err?.message || "An error occurred while downloading the file.");
    }
  }

  const showModal = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <div className="healtharticle">
        <div className="healtharticle-container">
          <div className="healtharticle-title carrer-job-title ms-4">
            <Breadcrumbs
              breadcrumbText="Doctor"
              breadcrumbLink=""
              nestedText="Add Doctors"
              nestedLink=""
            />
          </div>

          <div className="healtharticle-addnew">
            <AddButton
              onClick={handlePartneredDoctorForm}
              btnName="Add Doctors"
            />
          </div>
          <NavBarTitles
            titles={
              [
                {
                  name: "List of Doctors for Online Consultation",
                  navigatePath: "/partnered-doctor",
                  className: "career-tab-jobpost"
                },
                {
                  name: "List of Doctors for In-Clinic Appointment",
                  navigatePath: "",
                  className: "master-doctor"
                },
              ]
            }
          />

          <FilterOptions
            limit={limit}
            sortshow={sortshow}
            sortName={sortName}
            search={search}
            setFilterShow={setFilterShow}
            setSortShow={setSortShow}
            onChange={onChangeSearch}
            setLimit={setLimit}
            downloadExcel={true}
            onClickDownload={showModal}
          />

          <div className="healtharticle-table inclin-doctor-list-table master-doctor-table">
            <table>
              <thead>
                <tr>
                  <td>S.No</td>
                  <td>Image</td>
                  <td>Name of Doctor</td>
                  {/* <td>Partner ID</td> */}
                  <td>Degree</td>
                  <td>Specialization (Primary)</td>
                  <td>Fee</td>
                  <td>Language</td>
                  <td>City</td>
                  <td>Mode </td>
                  {/* <td>Time Slot</td>
                  <td>Upcoming Slot</td>
                  <td>Past Slot</td> */}
                  <td>Mobile Number</td>
                  <td>Status</td>
                  <td className="">Created at</td>
                  <td>Profile Status of the Partner</td>
                  {/* <td className="">Assigned To</td>
                  <td>Lead Status</td> */}
                  <td>Action</td>
                </tr>
              </thead>
              <tbody>
                {records.map((el, i) => {
                  return (
                    <tr key={el._id}>
                      <td>{skip + (i + 1)}</td>
                      <td><img
                        src={getsrcValue(el.profileImage)}
                        className="doctorProfileImage"
                        alt="profile"
                      /></td>
                      <td>{el.doctor_name}</td>
                      {/* <td>{el?.partner_id}</td> */}
                      <td>
                        <ul>
                          <li>{typeof (el?.degree) === "object" ? el?.degree?.ug_degree : ""},
                            {typeof (el?.education) === "string" ? el?.education : ""}
                          </li>
                          {el?.pg_degree ? <li>{el.pg_degree?.pg_degree}, {el?.pg_education}</li> : null}
                        </ul>
                      </td>
                      <td>{el?.inclinic_primary_specialization?.inclinic_doctor_specialization}</td>
                      <td>{el.inclinic_consultation_fee}</td>
                      <td>{el.languages_known?.map(item => item.type)?.join(', ')}</td>
                      <td>{el.city?.city}</td>
                      <td>{el.profile_mode}</td>
                      {/* <td>{el.doctor_name}</td>
                      <td>{el.doctor_name}</td>
                      <td>{el.doctor_name}</td> */}
                      <td>{el.mobile}</td>
                      <td>{el.verified ? "Verified" : "Not verified"}</td>
                      <td>{Moment(el.createdAt).format("DD-MM-YYYY")} {Moment(el.createdAt).format("LT")}</td>
                      <td>
                        {
                          <select
                            className="bg-white"
                            onChange={(e) => handleOptionChange(e, el._id)}
                            style={{ color: "#CB1B5B" }}
                            value={el.action}
                          >
                            {doctorProfileStatus.map((action, inx) => {
                              return <option key={inx} value={action} className="text-dark">{action}</option>
                            })}
                          </select>
                        }
                      </td>
                      {/* <td>{el.assigned_to ? el.assigned_to : "-"}</td>
                      <td>{el.lead_status ? el.lead_status : "-"}</td> */}
                      <td>
                        {/* <span
                          className="pe-1 primaryColor ps-2"
                          onClick={() => setAssignModal({ id: el._id, status: true })}
                        >
                          Assigned
                        </span>
                        <span className="pe-1">
                          <FaAngleDown className="drop-down-arrow" />
                        </span>
                        <span
                          className="pe-1 primaryColor ps-2"
                          onClick={() => setLeadStatusModal({ id: el._id, status: true })}
                        >
                          Lead Status
                        </span>
                        <span className="pe-1">
                          <FaAngleDown className="drop-down-arrow" />
                        </span> */}
                        <span
                          className="master-edit"
                          onClick={() => {
                            handleEdit(el._id);
                          }}
                        >
                          Edit
                        </span>
                        <span
                          className="master-delete"
                          onClick={() => {
                            setId(el._id);
                            setDel(true);
                          }}
                        >
                          Delete
                        </span>
                        <span className="newdelete-logo">
                          <img src={newdelete} alt="" />
                        </span>
                        {/* <span
                          className="master-view"
                          onClick={() => {
                            handleActive(el._id, el.active);
                          }}
                        >
                          View
                        </span> */}
                        <span className="master-view-icon"></span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div>
        <div className="mb-5 col d-flex pagination justify-content-end admin-pagination">
          <button
            className="page-link"
            onClick={() => handlePageChange({ selected: 0 })}

            disabled={skip === 0}
          >
            <AiOutlineBackward size={20} />
          </button>
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            pageCount={pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName="pagination my-0"
            activeClassName="active"
            forcePage={pageOffset}
          />
          <button
            className="page-link"
            onClick={() => handlePageChange({ selected: pages - 1 })}

            disabled={(skip / limit) === (pages - 1)}
          >
            <AiOutlineForward size={20} />
          </button>
        </div>
      </div>

      {del === true && <Deletepopup handleDelete={handleDelete} id={id} />}

      {filtershow ? (
        <FiltersRootModal
          filterOptions={filterInclinicDoctorsOptions}
          name="inclinic"
          onClickClear={() => {
            setFilterShow(false);
            setFilteredData({
              primarySpecialist: [],
              statusValues: [],
              genderdata: [],
              modeData: [],
              languageData: [],
              cityData: []
            })
            setIsClearClicked(true)
          }}
          onClickApply={() => {
            setFilterShow(false);
            setSkip(0);
            setPageOffset(0)
            list_api_call()
            // filter_call()
          }}
          selectOption={textcolor}
          setSelectOption={setTextColor}
          className="filter-container-partnered-doctor"
        />
      ) : null}

      {
        textcolor === 1 && filtershow === true
          ?
          <FilterSubModal
            name="Specialist"
            onSearchChange={handleSearchChange}
            searchQuery={searchQuery}
            optionList={searchQuery?.length ? searchPrimarySpecialist : filterOptions?.primarySpecialist}
            selectedOptions={filteredData?.primarySpecialist}
            handleChange={handleCheckboxChange}
            keyName={"spl"}
            isSearchable={true}
            dataName="name"
          />
          : null
      }
      {
        textcolor === 2 && filtershow === true
          ?
          <FilterStatusModal
            title={"Status"}
            data={status}
            className="filter-doctor-fee-partnered-doctor"
            activeFilters={filteredData.statusValues}
            onChange={(e) => handleStatusCheckboxChange("status", e.target.name)}
          />
          : null
      }
      {
        textcolor === 3 && filtershow === true
          ?
          <FilterSubModal
            name="Gender"
            optionList={filterOptions?.genderdata}
            selectedOptions={filteredData?.genderdata}
            handleChange={handleGenderCheckboxChange}
            keyName={"gender"}
            dataName="type"
          />
          : null
      }
      {
        textcolor === 4 && filtershow === true
          ?
          <FilterSubModal
            name="City"
            onSearchChange={handleCitySearchChange}
            searchQuery={searchCityQuery}
            optionList={searchCityQuery?.length ? searchcityList : filterOptions?.cityData}
            selectedOptions={filteredData?.cityData}
            handleChange={handleCityCheckboxChange}
            keyName={"city"}
            isSearchable={true}
            dataName="name"
          />
          : null
      }
      {
        textcolor === 5 && filtershow === true
          ?
          <FilterStatusModal
            title={"InclinicMode"}
            data={inclinicMode}
            className="filter-doctor-fee-partnered-doctor"
            activeFilters={filteredData.modeData}
            onChange={(e) => handleModeCheckboxChange("mode", e.target.name)}
          />
          : null
      }
      {textcolor === 6 && filtershow && (
        <FilterRangeSubModel
          name="Fee"
          subName="Select Price Range"
          feeValues={feeValues}
          setFeeValues={setFeeValues}
          rangeMinValue={feeValues[0]}
          rangeMaxValue={feeValues[1]}
          minValue={filterOptions?.feeRange?.min}
          maxValue={filterOptions?.feeRange?.max}
        />
      )}
      {
        textcolor === 7 && filtershow === true
          ?
          <FilterSubModal
            name="Language"
            optionList={filterOptions?.languageData}
            selectedOptions={filteredData?.languageData}
            handleChange={handleLanguageCheckboxChange}
            keyName={"language"}
            dataName="type"
          />
          : null
      }
      {sortshow ? (
        <SortByModel
          latest="Latest"
          oldest="Oldest"
          handleSortClick={handleSortClick}
          selectedSort={selectedSort}
          textColor={textcolor}
          handleApplyClick={handleApplyClick}
        />
      ) : null}
      {
        success && (
          <SucessModal
            name="BD"
            post="Assigned"
            onClick={() => successModalClose()}
          />
        )
      }
      {
        deleteModal.status === true && (
          <Deletepopup
            handleDelete={handleDelete}
            id={deleteModal?.id}
            isCloseModalEnable={true}
            handleClose={handleClose}
          />
        )
      }
      <ExcelDownloadModal onClickDownload={onClickDownload} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </>
  );
}

export default PartneredDoctorForInClinic