import { Menu } from 'antd';
import axios from "axios";
import React, { useEffect, useState } from "react";
import { CgMathEqual } from "react-icons/cg";
import { MdOutlineDashboard } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import { LegalPageAppDownloadLinks } from "../../constants";
import "./home.scss";

const sideMenuItems = [
  {
    key: '1',
    icon: <MdOutlineDashboard size={24} />,
    label: 'Dashboard',
    path: "dashboard"

  },
  {
    key: 's1',
    label: 'HealthSynergy',
    icon: <CgMathEqual size={20} />,
    children: [
      {
        key: '101',
        label: 'HealthSy Partnered Doctor..',
        path: "doctor-registration"
      },
      {
        key: '102',
        label: 'HealthSy Partnered Pharma..',
        path: "pharmacy-registration"
      },
      {
        key: '103',
        label: 'HealthSy Partnered Home H..',
        path: "healthcare-registration"
      },
      {
        key: '104',
        label: 'InstaDoc',
        path: "insta-doc"
      },
      {
        key: '105',
        label: 'Others',
        path: "other-registration"
      },
      {
        key: '106',
        label: 'Book a Demo',
        path: "doctor-demo"
      },
    ],
  },
  {
    key: 'sub3',
    label: 'Masters',
    icon: <CgMathEqual size={20} />,
    children: [
      {
        key: '301',
        label: 'Doctors',
        path: "master-primary-specialisation"
      },
      {
        key: '302',
        label: 'Home Healthcare Servoice Pr..',
        path: "master-health-care"
      },
      {
        key: '303',
        label: 'Partnered Pharamcies',
        path: "master-partnered-pharmacy"
      },
      {
        key: '304',
        label: 'Others',
        path: "master-others"
      },
      {
        key: '305',
        label: 'Common',
        path: "master-gender"
      },
    ],
  },
  {
    key: 's2',
    label: 'Popup Data',
    icon: <CgMathEqual size={20} />,
    children: [
      {
        key: '41',
        label: 'Post a Comment',
        path: "comment"
      },
      {
        key: '42',
        label: 'News letter Subscription',
        path: "blog-subscribe"
      },
    ],
  },
  {
    key: 's3',
    label: 'Blogs',
    icon: <CgMathEqual size={20} />,
    children: [
      {
        key: '401',
        label: 'Blogs',
        path: "healtharticle"
      },
      {
        key: '402',
        label: 'Blog Search Keyword',
        path: "blog-search-keyword"
      },
      {
        key: '403',
        label: 'Blog Banner Image',
        path: "blog-banner-image"
      },
    ],
  },
  {
    key: '2',
    icon: <CgMathEqual size={20} />,
    label: 'Careers',
    path: "careers"

  },
  // {
  //   key: 's4',
  //   label: 'Healthsy Services',
  //   icon: <CgMathEqual size={20} />,
  //   children: [
  //     {
  //       key: 'sn1',
  //       label: 'Order Medicine',
  //       children: [
  //         {
  //           key: '501',
  //           label: 'Medicine List',
  //           path: 'medicine-list'
  //         },
  //         {
  //           key: '502',
  //           label: 'Masters',
  //           path: 'healthsy-services/order-medicines/masters/manufacturer-list'
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    key: 's5',
    label: 'Doctors',
    icon: <CgMathEqual size={20} />,
    children: [
      {
        key: '601',
        label: 'Partnered Doctor..',
        path: "partnered-doctor"
      },
      {
        key: '602',
        label: 'Specialisation Listing Meta',
        path: "add-new-doctors"
      },
      {
        key: '603',
        label: 'Doctor Specialisation',
        path: "online-doctor-specialisation"
      },
      {
        key: '604',
        label: 'City & Localities List',
        path: "doctors-location"
      },
      {
        key: '605',
        label: 'Clinic Profile',
        path: "doctor-clinic-profile"
      },
      {
        key: '606',
        label: 'Clinic Services',
        path: "doctor-clinic-service"
      },
      {
        key: '607',
        label: 'Common Heads',
        path: "doctors-gender"
      },
      {
        key: '608',
        label: 'Report a Error',
        path: "doctors-report"
      },
    ],
  },
  {
    key: 's6',
    label: 'Home Healthcare Se..',
    icon: <CgMathEqual size={20} />,
    children: [
      {
        key: 's61',
        label: 'Add New Service Provider',
        path: "home-healthcare-service-provider"
      },
      {
        key: 's62',
        label: 'HHSP Specialisations',
        path: "home-healthcare-hhsp"
      },
    ],
  },
  {
    key: 's7',
    label: 'Partnered Pharmacies',
    icon: <CgMathEqual size={20} />,
    children: [
      {
        key: 's71',
        label: 'Add New Retail Pharmacies',
        path: "add-new-retail-pharmacy"
      },
    ],
  },
  {
    key: 's8',
    label: 'Clinic / Hospital',
    icon: <CgMathEqual size={20} />,
    children: [
      {
        key: 's81',
        label: 'Add New Clinic / Hospital',
        path: "add-new-clinic"
      },
    ],
  },
  {
    key: 's9',
    label: 'Settings',
    icon: <CgMathEqual size={20} />,
    children: [
      {
        key: '701',
        label: 'Social Media Links',
        path: "settings-social-media-form"
      },
      {
        key: '702',
        label: 'Banner Images',
        path: "add-banner-images"
      },
      {
        key: '703',
        label: 'Play Store / App Store Links',
        path: "store-links"
      },
      {
        key: '704',
        label: 'Business Settings',
        path: "business-settings"
      },
      {
        key: '705',
        label: 'Meta Tags',
        path: "meta-tags"
      },
      {
        key: '706',
        label: 'Email Settings',
        path: "email-setting"
      },
      {
        key: '707',
        label: 'Insiders',
        path: "insiders"
      },
      {
        key: '708',
        label: 'Company Reels',
        path: "company-reels"
      },
    ],
  },
  {
    key: 's10',
    label: 'Sitemap',
    icon: <CgMathEqual size={20} />,
    children: [
      {
        key: 's101',
        label: 'Sitemap Analysis',
        path: "sitemap/parent-sitemap"
      },
      {
        key: 's102',
        label: 'Other Codes / Pixel',
        path: "site-map-setting"
      },
      {
        key: 's103',
        label: 'Common Masters',
        path: "sitemap-category"
      },
    ],
  },
  {
    key: 's11',
    label: 'Email Brochures',
    icon: <CgMathEqual size={20} />,
    children: [
      {
        key: 's111',
        label: 'Doctor Registration',
        path: "doctor-registration-brochures"
      },
      {
        key: 's112',
        label: 'Doctor Demo',
        path: "doctor-demo-registration-brochures"
      },
      {
        key: 's113',
        label: 'Pharmacy Registration',
        path: "pharmacy-registration-brochures"
      },
      {
        key: 's114',
        label: 'Healthcare Registration',
        path: "healthcare-registration-brochures"
      },
      {
        key: 's115',
        label: 'Other Registartion',
        path: "others-registration-brochures"
      },
    ],
  },
];
const sideMenuItems2 = [
  {
    key: 's13',
    label: 'CTA Banner',
    icon: <CgMathEqual size={20} />,
    children: [
      {
        key: 's131',
        label: 'Retail Pharmacies',
        path: "cta-banner/retail-pharmacies"
      },
      {
        key: 's132',
        label: 'Doctors',
        path: "cta-banner/doctors"
      },
      {
        key: 's133',
        label: 'Home Healthcare Service Providers',
        path: "cta-banner/home-healthcare-service-providers"
      },
    ],
  },
  {
    key: 's14',
    label: 'CTA Form',
    icon: <CgMathEqual size={20} />,
    children: [
      {
        key: 's141',
        label: 'Retail Pharmacies',
        path: "cta-form/retail-pharmacies"
      },
      {
        key: 's142',
        label: 'Doctors',
        path: "cta-form/doctors"
      },
      {
        key: 's143',
        label: 'Home Healthcare Service Providers',
        path: "cta-form/home-healthcare-service-providers"
      },
    ],
  },
  {
    key: '3',
    icon: <CgMathEqual size={20} />,
    label: 'Contact Form',
    path: "contact-us"

  },
  {
    key: 's15',
    label: 'Legal Pages',
    icon: <CgMathEqual size={20} />,
    children: LegalPageAppDownloadLinks.map((data, inx) => ({
      key: `s15${inx}`,
      label: data.name,
      path: `app-download-link/${data.slugurl}`
    }))
  },
  {
    key: '4',
    icon: <CgMathEqual size={20} />,
    label: 'Web Bookings, Consultations & Appointments',
    path: "web-bookings/home-healthcare-bookings"

  },
  {
    key: '5',
    icon: <CgMathEqual size={20} />,
    label: 'Ad Landing Forms',
    path: 'ad-landing-forms/partnered-doctors'

  },
  {
    key: '6',
    icon: <CgMathEqual size={20} />,
    label: 'Ad Landing Meta Tags',
    path: 'ad-landing-meta-tags'

  },
  {
    key: '7',
    icon: <CgMathEqual size={20} />,
    label: 'Insta Doc QR Code',
    path: 'insta-doc-qr-code'

  },
  {
    key: 's16',
    label: 'Counter',
    icon: <CgMathEqual size={20} />,
    children: [
      {
        key: 's161',
        label: 'Home Page',
        path: "counter-home"
      },
      {
        key: 's162',
        label: 'Ad Landing User General',
        path: "ad-user-counter"
      },
      {
        key: 's163',
        label: 'Sustainability Policy',
        path: "sustainability-policy-counter"
      },
    ],
  },
  {
    key: '8',
    icon: <CgMathEqual size={20} />,
    label: 'Sustainability Policy',
    path: 'sustainability-policy'
  },
  {
    key: '9',
    icon: <CgMathEqual size={20} />,
    label: 'FRP',
    path: 'frp-states'

  },
  {
    key: '10',
    icon: <CgMathEqual size={20} />,
    label: 'Franchise Programme',
    path: 'franchise-programme'
  },
  {
    key: 's17',
    label: 'RP QR Ratings',
    icon: <CgMathEqual size={20} />,
    children: [
      {
        key: 's171',
        label: 'Pharmacies Partner ID',
        path: "rp-qr-ratings/pharmacies-partner-id"
      },
      {
        key: 's172',
        label: 'Rp-Qr-Rating Form',
        path: "rp-qr-ratings/pharmacy-rating"
      },
    ],
  },
]

const SideBarMenu = () => {
  const [faqSections, setFaqsections] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getFaqSections();
  }, []);

  const getFaqSections = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}faq-section/list`)
      .then((response) => {
        if (response?.data?.rows?.length) {
          const faqSideMenus = response?.data?.rows.map((data, inx) => (
            {
              key: `s12${inx}`,
              label: data.name,
              path: `faq/${data.slugurl}`
            }
          ))
          setFaqsections({
            key: 's12',
            label: "FAQ's",
            icon: <CgMathEqual size={20} />,
            children: faqSideMenus
          });
        }
      })
      .catch((err) => {
        console.error(err);
        alert("Some error");
      });
  };
  // const [collapsed, setCollapsed] = useState(false);
  // const toggleCollapsed = () => {
  //   setCollapsed(!collapsed);
  // };

  const handleClick = ({ item, key, keyPath, domEvent, ...rest }) => {
    console.log("{ item, key, keyPath, domEvent }", { item, key, keyPath, domEvent, rest, }, item.props.path);
    if (item.props?.path) {
      navigate(`/${item.props.path}`)
    }
  }
  const menuItems = [...sideMenuItems, faqSections, ...sideMenuItems2]
  return (
    <aside className="sideMenuWrapper">
      {/* <Button
        type="primary"
        onClick={toggleCollapsed}
        style={{
          marginBottom: 16,
        }}
      >
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </Button> */}
      <Menu
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        mode="inline"
        theme="dark"
        // inlineCollapsed={collapsed}
        items={menuItems}
        onClick={handleClick}
      />
    </aside>
  );
};

export default SideBarMenu;
