import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import backRed from "../../../../assets/svg/back-rose-icon.svg";
import SucessModal from "../../../modal/SucessModal";

const MenuEdit = () => {

  const {id} = useParams()
  const [ fields, setFields ] = useState({});
  const navigate = useNavigate()
  const [success, setSuccess] = useState(false);

  const handleUpdateData=()=>{
    let item = { name: fields.name, active: fields.active };
    fetch(`${process.env.REACT_APP_API_URL}health-article-menu/update/${id}`, {
    method: 'PATCH',
    headers:{
        'Accept':'application/json',
        'Content-Type':'application/json'
    },
    body:JSON.stringify(item)
    })
    .then((result)=>{
        result.json()
        .then((resp) => {
          setSuccess(true);
        })
    });
  }

  const getMenuData = () =>{
    axios.get(`${process.env.REACT_APP_API_URL}health-article-menu/get/${id}`).then(response=>{
      setFields(response.data);
    }).catch(err=>{
      console.error(err);
      alert("Some error");
    })
  }

  useEffect(()=>{
    getMenuData();
  },[]);
  
    return (
      <div>
        {/* <Home> */}
        <div 
            onClick={()=>navigate("/healtharticlemenu")} 
            className="rose back-button"
          >
            <img src={backRed} alt="" />
            <span className="rose-back">Back</span>
          </div>
          <div className="healtharticle">
            <div className="healtharticle-container ps-3">
              <div className="healtharticle-title">
              <Link to="/dashboard" style={{textDecoration:"none", color:"black", fontSize:"15px"}}><span style={{paddingLeft:"5%"}}>Dashboard</span></Link>
                <span style={{paddingLeft:'1%'}}>{`>`}</span>
                <Link to="/healtharticlemenu" style={{textDecoration:"none", color:"black", paddingLeft:"0.4%"}}><span>Manage Menu and Category</span></Link>
                <span style={{paddingLeft:"0.4%"}}>{`>`}</span>
                <span style={{paddingLeft:"0.4%"}}>Edit Menu</span>
              </div>
              <div className="row mt-5 new-menu ">
                <div className="col-3">
                  <label className="form-label " htmlFor="">
                    Name <span>*</span>
                  </label>
                  <input
                    className="form-control w-100 input-menu "
                    type="text"
                    name=""
                    id="name"
                    value={fields.name}
                    onChange={(e)=>setFields(prevState=>{
                      return {...prevState, name: e.target.value}
                    })}
                    placeholder="Enter"
                    
                  />
                </div>
  
                <div className="col">
                  <label htmlFor="" className="mb-3 ms-3 ">
                    Active Status <span className="text-danger">*</span>
                  </label>
                  <div className="d-flex ">
                    <div className="form-check form-check-inline">
                      <input
                        className="me-3"
                        type="radio"
                        name="active"
                        id="active"
                        onChange={(e)=>{setFields(prevState=> {
                          return {...prevState, active: true};
                         }); }}
                       value={true}
                       checked={fields.active===true ? "checked" : null}
                      />
                      <label className="" htmlFor="inline1">
                        Yes
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="me-3"
                        type="radio"
                        name="active"
                        id="active"
                        value={false}
                        onChange={(e)=>{setFields(prevState=> {
                          return {...prevState, active: false};
                          }); }}
                        checked={fields.active===false ? "checked" : null}
                      />
                      <label className="" htmlFor="inline2">
                        No
                      </label>
                    </div>
                  </div>
                </div>
  
              </div>
              <div className="Add-Btn">
              <button onClick={handleUpdateData} className="add-button">UPDATE</button>
              </div>
            
            </div>
          </div>
        {/* </Home> */}
        
        {success && (
        <SucessModal 
          page={"/healtharticlemenu"} 
          name="Menu" 
          post='updated'
        />
      )}
      </div>
    );
  }

export default MenuEdit