import axios from "axios";
import { debounce } from 'lodash';
import Moment from "moment";
import React, { useEffect, useState } from "react";
import Autocomplete from "react-google-autocomplete";
import { AiOutlineBackward, AiOutlineForward } from "react-icons/ai";
import { FaAngleDown } from "react-icons/fa";
import ReactPaginate from 'react-paginate';
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import contact from "../../../assets/images/HealthSy/contact.png";
import newdelete from "../../../assets/images/HealthSy/delete.png";
import searchImg from "../../../assets/images/searchIcon.png";
import { experience, fee, gender } from "../../../constants";
import FilterOptions from "../../Common/doctorsFilterOptions";
import ExcelDownload from "../../Common/excelDownload";
import FilterModal from "../../Common/filterModal";
import FilterRootModal from "../../Common/filterRootModal";
import ExcelDownloadModal from "../../masters/Common/excelDownloadAccess";
import AssignedTo from "../../modal/AssignedTo";
import Deletepopup from "../../modal/Deletepopup";
import LeadStatus from "../../modal/LeadStatus";
import LeadStatusConfirm from "../../modal/LeadStatusConfirm";
import SucessModal from "../../modal/SucessModal";
import "./healthsy.scss";

const filterOptions = ["Fee", "Experience", "Gender", "Language", "City / Town", "Primary Specialisation", "Lead Status", "BD"]

const columnOrder = [
  "S.No",
  "Time of Registration",
  "Date of Registration",
  "Name of the Doctor",
  "Contact Number",
  "Email ID",
  "Degree",
  "PG Degree",
  "Other Certified Education",
  "Gender",
  "Primary Specialisation",
  "MCI / SMC Number",
  "Year of Registration with Medical Council",
  "Council Type",
  "State Medical Council",
  "Awards & Recognition",
  "Organisation",
  "Fellowship",
  "Mode of Consultation",
  "Memberships",
  "About Yourself",
  "Online Fee",
  "In-Clinic Fee",
  "Primary Language",
  "Other Language Known",
  "State",
  "City",
  "Location",
  "Pincode",
  "Clinic Name",
  "Clinic Address",
  "Clinic Contact Number",
  "PoC Name",
  "PoC Number",
  "Clinic Name - 2",
  "Clinic Address - 2",
  "Clinic Contact Number - 2",
  "PoC Name",
  "PoC Number",
  "Clinic Name - 3",
  "Clinic Address - 3",
  "Clinic Contact Number - 3",
  "PoC Name",
  "PoC Number",
  "Are you currently using telemedicine platform",
  "Establishment Details",
  "BD Name",
  "App Download Android Click",
  "App Download iOS Click",
  "Brochure Icon Click Status (1.0)",
  "Assigned To",
  "Lead Status",
  // "Actions",
];

const DoctorRegistration = () => {

  const [records, setRecords] = useState([]);
  const [filtered_records, setFilteredRecords] = useState([]);
  const [pages, setPages] = useState(0);
  const navigate = useNavigate();
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState("newest");
  const [sortName, setSortName] = useState("Sort by");

  //filter portion
  const [filtershow, setFilterShow] = useState(false);
  const [sortshow, setSortShow] = useState(false);
  const [textcolor, setTextColor] = useState(0);

  const [feedata, setFeeData] = useState([]);
  const [expdata, setExpData] = useState([]);
  const [genderdata, setGenderData] = useState([]);
  const [languagedata, setLanguageData] = useState([]);
  const [spldata, setSplData] = useState([]);
  const [location, setLocation] = useState("");

  const [assignModal, setAssignModal] = useState({ id: "", status: false })
  const [leadStatusModal, setLeadStatusModal] = useState({ id: "", status: false })
  const [success, setSuccess] = useState(false);
  const [leadStatussuccess, setLeadStatusSuccess] = useState(false);
  const [leadStatusConfirmModal, setLeadStatusConfirmModal] = useState({ leadType: "", status: false, id: "" })
  const [leadStatus, setLeadStatus] = useState([]);
  const [filterLeadStatus, setFilterLeadStatus] = useState([]);
  const [bdModal, setBdModal] = useState([]);
  const [filterBdModal, setFilterBdModal] = useState([]);
  const [language, setLanguage] = useState([]);
  const [primarySpl, setPrimarySpl] = useState([]);
  const [del, setDel] = useState(false);
  const [id, setId] = useState("");
  const [pageOffset, setPageOffset] = useState(0);
  const [search, setSearch] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    getLeadStatus()
    getBdModal()
    getPrimarySpl()
    getLanguage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    list_api_call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, sort, skip, search]);

  useEffect(() => {
    if (location) {
      filter_Location_Search_call()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const handleBookDemo = () => {
    navigate("/doctor-demo");
  };
  const handleService = () => {
    navigate("/healthcare-registration");
  };
  const handleRetail = () => {
    navigate("/pharmacy-registration");
  };
  const handleOthers = () => {
    navigate("/other-registration");
  };
  const handleInstaDoc = () => {
    navigate("/insta-doc");
  };

  const getLeadStatus = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}lead-status/list`)
      .then((res) => {
        setLeadStatus(res.data.rows.map((lead) => lead?.type));
      });
  };

  const getBdModal = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}bd/list`)
      .then((res) => {
        setBdModal(res.data.rows.map((data) => data?.bd_name));
      });
  };

  const getLanguage = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}languages/list`)
      .then((res) => {
        setLanguage(res.data.rows.map((data) => data?.type));
      });
  };

  const getPrimarySpl = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}primary-specialisation/list`)
      .then((res) => {
        setPrimarySpl(res.data.rows.map((data) => data?.type));
      });
  };

  const filter_call = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}doctor-registration/list`, {
        // key: "doctors",
        values: {
          languages_known: languagedata,
          in_clinic_fee: feedata,
          experience: expdata,
          specialization: spldata,
          gender: genderdata,
          location: location,
          lead_status: filterLeadStatus,
          assigned_to: filterBdModal
        },
        skip: skip,
        limit: limit,
        sort: sort,
        searchText: search
      })
      .then((res) => {
        setRecords(res.data?.rows);
        setPages(Math.ceil(res.data.count / limit));
      });
  };

  const filter_Location_Search_call = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}filters/location`, {
        key: "doctors",
        values: {
          location: location
        }
      })
      .then((res) => {
        setRecords(res.data)
      })
  }

  const list_api_call = (clearFilter = false) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}doctor-registration/list`, {
        skip: skip,
        limit: limit,
        // key: "doctors",
        sort: sort,
        searchText: search,
        values: clearFilter ? {} : {
          languages_known: languagedata,
          in_clinic_fee: feedata,
          experience: expdata,
          specialization: spldata,
          gender: genderdata,
          location: location,
          lead_status: filterLeadStatus,
          assigned_to: filterBdModal
        },
      })
      .then((response) => {
        setFilteredRecords([]);
        setRecords(response.data.rows);
        // setRowCount(response.data.count);
        setPages(Math.ceil(response.data.count / limit));
      })
      .catch((err) => {
        console.error(err);
        alert("Some error");
      });
  };

  //Delete Functionality
  const handleDelete = (id) => {
    fetch(`${process.env.REACT_APP_API_URL}doctor-registration/delete/${id}`, {
      method: "DELETE",
    }).then((result) => {
      result.json().then((res) => {
        list_api_call();
        setDel(false);
      });
    });
  };

  const onClickDownload = async () => {
    try {
      const response = await axios
        .post(`${process.env.REACT_APP_API_URL}doctor-registration/list`, {
          skip: 0,
          limit: 3000,
          searchText: search,
          values: {
            languages_known: languagedata,
            in_clinic_fee: feedata,
            experience: expdata,
            specialization: spldata,
            gender: genderdata,
            location: location,
            lead_status: filterLeadStatus,
            assigned_to: filterBdModal
          },
          sort: sort,
        })
      if (!response?.data?.rows?.length) {
        throw new Error("No records available to download.");
      }
      const records = response.data.rows
      const formattedRecords = records.map((record, index) => ({
        "S.No": index + 1,
        "Time of Registration": record.createdAt ? new Date(record.createdAt).toLocaleTimeString() : "",
        "Date of Registration": record.createdAt ? new Date(record.createdAt).toLocaleDateString() : "",
        "Name of the Doctor": record.name || "",
        "Contact Number": record.mobile || "",
        "Email ID": record.email || "",
        Degree: record.degree || "",
        "PG Degree": record.qualification || "",
        "Other Certified Education": record.education || "",
        Gender: record.gender || "",
        "Primary Specialisation": record.specialization || "",
        "MCI / SMC Number": record.mci || "",
        "Year of Registration with Medical Council": record.registered_year || "",
        "Council Type": record.council_type || "",
        "State Medical Council": record.medical_council || "",
        "Awards & Recognition": record.awards_recognition || "",
        Organisation: record.organisation.join(", ") || "",
        Fellowship: record.fellowship || "",
        "Mode of Consultation": record.intrested_mode || "",
        Memberships: record.memberships || "",
        "About Yourself": record.about || "",
        "Online Fee": record.online_consultation_fee || "",
        "In-Clinic Fee": record.in_clinic_fee || "",
        "Primary Language": record.languages_known[0] || "",
        "Other Language Known": record.languages_known.slice(1).join(", ") || "",
        State: record.state || "",
        City: record.city || "",
        Location: record.location || "",
        Pincode: record.pincode || "",
        "Clinic Name": record.clinic_name || "",
        "Clinic Address": record.clinic_address || "",
        "Clinic Contact Number": record.clinic_contact_number || "",
        "PoC Name": "",
        "PoC Number": "",
        "Clinic Name - 2": record.clinic_name_2 || "",
        "Clinic Address - 2": record.clinic_address_2 || "",
        "Clinic Contact Number - 2": record.clinic_contact_number_2 || "",
        "Clinic Name - 3": "",
        "Clinic Address - 3": "",
        "Clinic Contact Number - 3": "",
        "Are you currently using telemedicine platform": record.telemedicine || "",
        "Establishment Details": record.establishment || "",
        "BD Name": "",
        "App Download Android Click": record.android_click || 0,
        "App Download iOS Click": record.ios_click || 0,
        "Brochure Icon Click Status (1.0)": record.brochure_click || 0,
        "Assigned To": record.assigned_to || "",
        "Lead Status": record.lead_status || "",
        // Actions: "",
      }));

      ExcelDownload(formattedRecords, columnOrder, "doctor-registrations.xlsx")
      console.log("File downloaded successfully.");

    } catch (err) {
      console.error("Error downloading the file while getting all Doctor registration records", err, "err.message", err?.message)
      // console.error("Error downloading the file:", err.message);
      alert(err?.message || "An error occurred while downloading the file.");
    }
  }

  const successModalClose = () => {
    setSuccess(false)
    setLeadStatusSuccess(false)
    list_api_call();
  }

  const handlePageChange = (event) => {
    setSkip(event.selected * limit)
    setPageOffset(event.selected);
  };

  const debouncedSearch = debounce((value) => {
    handlePageChange({ selected: 0 })
    setSearch(value); // Update the state after debounce
  }, 500);

  const onChangeSearch = (value) => {
    debouncedSearch(value); // Trigger the debounced function
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      {/* <Home> */}
      <div className="healtharticle ps-3">
        <div className="healtharticle-container ">
          <div className="healtharticle-title ms-0">
            <span>
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to="/dashboard"
              >
                Dashboard
              </Link>
            </span>
            <span>&#62;</span>
            <span>
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to="/doctor-registration"
              >
                HealthSynergy
              </Link>
            </span>
            <span style={{ paddingLeft: "0.4%" }}>&#62;</span>
            <span style={{ paddingLeft: "0.4%" }}>
              HealthSy Partnered Doctors Network Programme
            </span>
          </div>
          <div className="careers-tab  ">
            <div className="career-tab-jobpost pink-line">Doctors</div>
            <div className="career-tab-jobpost " onClick={handleRetail}>
              Retail Pharmacy
            </div>
            <div className="career-post-department" onClick={handleService}>
              Home Healthcare Service Providers
            </div>
            <div className="career-post-department" onClick={handleInstaDoc}>
              InstaDoc
            </div>
            <div className="career-post-position other" onClick={handleOthers}>
              Others
            </div>
            <div
              className="career-post-position "
              style={{ color: "#65626C" }}
              onClick={handleBookDemo}
            >
              Book a Demo
            </div>
          </div>
          <FilterOptions
            limit={limit}
            sortshow={sortshow}
            sortName={sortName}
            search={search}
            setFilterShow={setFilterShow}
            setSortShow={setSortShow}
            onChange={onChangeSearch}
            setLimit={setLimit}
            downloadExcel={true}
            onClickDownload={showModal}
          />
          {/* <FilterOptions
            limit={limit}
            sortshow={sortshow}
            sortName={sortName}
            search={search}
            setFilterShow={setFilterShow}
            setSortShow={setSortShow}
            filter_records={onChangeSearch}
            setLimit={setLimit}
          /> */}
        </div>
        <div className="doctorregistetr healtharticle-table ms-2">
          <table>
            <thead className="">
              <tr>
                <td className="sno ">S.No</td>
                <td className="align-middle">Time of Registration</td>
                <td className="align-middle">Date of Registration</td>
                <td className="align-middle">Name of the Doctor</td>
                <td className="align-middle">Contact Number</td>
                <td className="align-middle">Email ID</td>
                <td className="align-middle">Degree</td>
                <td className="align-middle">PG Degree</td>
                <td className="align-middle">Other Certified Education</td>
                <td className="align-middle">Gender</td>
                <td className="align-middle">Primary Specialisation</td>
                {/* <td className="align-middle">Secondary Specialisation</td> */}
                <td className="align-middle meddical-reg-no">MCI / SMC Number</td>
                <td className="align-middle">Year of Registration with Medical Council</td>
                <td className="align-middle">Council Type</td>
                <td className="align-middle">State Medical Council</td>
                <td className="align-middle">Awards & Recognition</td>
                <td className="align-middle">Organisation</td>
                <td className="align-middle">Fellowship</td>
                <td className="align-middle">Mode of Consultation</td>
                <td className="align-middle">Memberships</td>
                <td className="align-middle">About Yourself</td>
                <td className="align-middle">Online Fee</td>
                <td className="align-middle">In-Clinic Fee</td>
                <td className="align-middle">Primary Language</td>
                <td className="align-middle">Other Language Known</td>
                <td className="align-middle">State</td>
                <td className="align-middle">City</td>
                <td className="align-middle">Location</td>
                <td className="align-middle">Pincode</td>
                <td className="align-middle">Clinic Name</td>
                <td className="align-middle">Clinic Address</td>
                <td className="align-middle">Clinic Contact Number</td>
                <td className="align-middle">PoC Name</td>
                <td className="align-middle">PoC Number</td>
                <td className="align-middle">Clinic Name - 2</td>
                <td className="align-middle">Clinic Address - 2</td>
                <td className="align-middle">Clinic Contact Number - 2</td>
                <td className="align-middle">PoC Name</td>
                <td className="align-middle">PoC Number</td>
                <td className="align-middle">Clinic Name - 3</td>
                <td className="align-middle">Clinic Address - 3</td>
                <td className="align-middle">Clinic Contact Number - 3</td>
                <td className="align-middle">PoC Name</td>
                <td className="align-middle">PoC Number</td>
                <td className="align-middle">Are you currently using telemedicine platform</td>
                <td className="align-middle">Establishment Details</td>
                <td className="align-middle">BD Name</td>
                <td>App Download Android Click</td>
                <td>App Download iOS Click</td>
                <td>Brochure Icon Click Status (1.0)</td>
                <td className="">Assigned To</td>
                <td className="">Lead Status</td>
                <td className="align-middle">Actions</td>
              </tr>
            </thead>
            <tbody>
              {(filtered_records?.length > 0 ? filtered_records : records)?.map(
                (x, i) => {
                  if (x.show === undefined || x.show === true) {
                    return (
                      <tr key={x._id}>
                        <td>{skip + (i + 1)}</td>
                        <td>{Moment(x.createdAt).format("LT")}</td>
                        <td>{Moment(x.createdAt).format("DD-MM-YYYY")}</td>
                        <td>{x.name}</td>
                        <td>{x.mobile}</td>
                        <td>{x.email}</td>
                        <td>{x.degree}, {x.education}</td>
                        <td>{x?.pg_degree ? `${x.pg_degree}, ${x.pg_education}` : <></>}</td>
                        <td>{x.qualification ? x.qualification : "-"}</td>
                        <td>{x.gender}</td>
                        <td>{x.specialization}</td>
                        {/* <td>{x.secondary_specialization ? x.secondary_specialization : "-"}</td> */}
                        <td>{x.mci}</td>
                        <td>{x.registered_year}</td>
                        <td>{x?.council_type}</td>
                        <td>{x.medical_council}</td>
                        <td>{x?.awards_recognition ?? ""}</td>
                        <td>{x?.organisation?.length ? x?.organisation.map((ele, inx) => ele[`organisation${inx}`]).join(', ') : ""}</td>
                        <td>{x?.fellowship ?? ""}</td>
                        <td>{x.intrested_mode}</td>
                        <td>{x.memberships ? x.memberships : "-"}</td>
                        <td>{x.about ? x.about : "-"}</td>
                        <td>{x.online_consultation_fee ? x.online_consultation_fee : "-"}</td>
                        <td>{x.in_clinic_fee ? x.in_clinic_fee : "-"}</td>
                        <td>{x.primary_language ? x.primary_language : "-"}</td>
                        <td>{x?.languages_known?.join(", ")}</td>
                        <td>{x.state ? x.state : "-"}</td>
                        <td>{x.city ? x.city : "-"}</td>
                        <td>{x.location ? x.location : "-"}</td>
                        <td>{x.pincode ? x.pincode : "-"}</td>
                        <td>{x.clinic_name ? x.clinic_name : "-"}</td>
                        <td>{x.clinic_address ? x.clinic_address : "-"}</td>
                        <td>{x.clinic_contact_number}</td>
                        <td>{x.poc_clinic_name ? x.poc_clinic_name : ""}</td>
                        <td>{x.poc_clinic_contact_no ? x.poc_clinic_contact_no : ""}</td>
                        <td>{x.clinic_details[0]?.clinic_name ? x.clinic_details[0]?.clinic_name : "-"}</td>
                        <td>{x.clinic_details[0]?.clinic_address ? x.clinic_details[0]?.clinic_address : "-"}</td>
                        <td>{x.clinic_details[0]?.clinic_contact_number ? x.clinic_details[0]?.clinic_contact_number : "-"}</td>
                        <td>{x.clinic_details[0]?.poc_clinic_name ? x.clinic_details[0]?.poc_clinic_name : ""}</td>
                        <td>{x.clinic_details[0]?.poc_clinic_contact_no ? x.clinic_details[0]?.poc_clinic_contact_no : "-"}</td>
                        <td>{x.clinic_details[1]?.clinic_name ? x.clinic_details[1]?.clinic_name : "-"}</td>
                        <td>{x.clinic_details[1]?.clinic_address ? x.clinic_details[1]?.clinic_address : "-"}</td>
                        <td>{x.clinic_details[1]?.clinic_contact_number ? x.clinic_details[1]?.clinic_contact_number : "-"}</td>
                        <td>{x.clinic_details[1]?.poc_clinic_name ? x.clinic_details[1]?.poc_clinic_name : ""}</td>
                        <td>{x.clinic_details[1]?.poc_clinic_contact_no ? x.clinic_details[1]?.poc_clinic_contact_no : "-"}</td>
                        <td>{x.telemedicine ? x.telemedicine : "-"}</td>
                        <td>{x.establishment ? x.establishment : "-"}</td>
                        <td>{"-"}</td>
                        <td>{x.android_click}</td>
                        <td>{x.ios_click}</td>
                        <td>{x.brochure_click}</td>
                        <td>{x.assigned_to ? x.assigned_to : "-"}</td>
                        <td>{x.lead_status ? x.lead_status : "-"}</td>
                        <td>
                          <span
                            className="pe-1 primaryColor ps-2"
                            onClick={() => setAssignModal({ id: x._id, status: true })}
                          >
                            Assigned
                          </span>
                          <span className="pe-1">
                            <FaAngleDown className="drop-down-arrow" />
                          </span>
                          <span
                            className="pe-1 primaryColor ps-2"
                            onClick={() => setLeadStatusModal({ id: x._id, status: true })}
                          >
                            Lead Status
                          </span>
                          <span className="pe-1">
                            <FaAngleDown className="drop-down-arrow" />
                          </span>
                          <span
                            className="text-primary cursorPointer ms-2"
                            onClick={() => { navigate(`/doctor-registration-edit/${x._id}`) }}
                          >
                            Edit
                          </span>
                          <span className="contact-logo">
                            <img src={contact} alt="" />
                          </span>
                          <span
                            className="newdelete"
                            onClick={() => {
                              setId(x._id);
                              setDel(true);
                            }}
                          >
                            Delete
                          </span>
                          <span className="newdelete-logo">
                            <img src={newdelete} alt="" />
                          </span>
                        </td>
                      </tr>
                    );
                  } else {
                    return <></>
                  }
                }
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <div className="mb-5 col d-flex pagination justify-content-end admin-pagination">
          <button
            className="page-link"
            onClick={() => handlePageChange({ selected: 0 })}

            disabled={skip === 0}
          >
            <AiOutlineBackward size={20} />
          </button>
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            pageCount={pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName="pagination my-0"
            activeClassName="active"
            forcePage={pageOffset}
          />
          <button
            className="page-link"
            onClick={() => handlePageChange({ selected: pages - 1 })}

            disabled={(skip / limit) === (pages - 1)}
          >
            <AiOutlineForward size={20} />
          </button>
        </div>
      </div>

      {/* </Home> */}
      {del === true && <Deletepopup handleDelete={handleDelete} id={id} />}

      {/*---------Filter Portion--------*/}
      {filtershow ? (
        <FilterRootModal
          filterOptions={filterOptions}
          onClickClear={() => {
            list_api_call(true)
            setFilterShow(false);
            setFeeData([]);
            setExpData([]);
            setGenderData([]);
            setLanguageData([]);
            setSplData([]);
            setLocation("");
            setFilterLeadStatus([]);
            setFilterBdModal([]);
          }}
          onClickApply={() => {
            setFilterShow(false);
            filter_call()
          }}
          selectOption={textcolor}
          setSelectOption={setTextColor}
        />
      ) : null}

      {/*-------Fee---------*/}
      {textcolor === 1 && filtershow === true ? (
        <FilterModal
          title={"Fee"}
          data={fee}
          className="filterModelNestedLevel"
          activeFilters={feedata}
          onChange={(e) => {
            if (feedata.includes(e.target.name)) {
              setFeeData(feedata.filter((ele) => ele !== e.target.name))
            } else {
              setFeeData((pre) => [...pre, e.target.name])
            }
          }}
        />
      ) : null}

      {/*-------Experience--------*/}
      {textcolor === 2 && filtershow === true ? (
        <FilterModal
          title={"Experience"}
          data={experience}
          className="filterModelNestedLevel"
          activeFilters={expdata}
          onChange={(e) => {
            if (expdata.includes(e.target.name)) {
              setExpData(expdata.filter((ele) => ele !== e.target.name))
            } else {
              setExpData((pre) => [...pre, e.target.name])
            }
          }}
        />
      ) : null}

      {/*-------Gender--------*/}
      {textcolor === 3 && filtershow === true ? (
        <FilterModal
          title={"Gender"}
          data={gender}
          className="filterModelNestedLevel"
          activeFilters={genderdata}
          onChange={(e) => {
            if (genderdata.includes(e.target.name)) {
              setGenderData(genderdata.filter((ele) => ele !== e.target.name))
            } else {
              setGenderData((pre) => [...pre, e.target.name])
            }
          }}
        />
      ) : null}

      {/*-------Language--------*/}
      {textcolor === 4 && filtershow === true ? (
        <FilterModal
          title={"Language"}
          data={language}
          className="filterModelNestedLevel filterOptionsModel"
          activeFilters={languagedata}
          onChange={(e) => {
            if (languagedata.includes(e.target.name)) {
              setLanguageData(languagedata.filter((ele) => ele !== e.target.name))
            } else {
              setLanguageData((pre) => [...pre, e.target.name])
            }
          }}
        />
      ) : null}

      {/*-------City / Town--------*/}
      {textcolor === 5 && filtershow === true ? (
        <div className="filter-doctor-fee">
          <div className="filter-doctor-fee-header">City / Town</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            <div className="filter-doctor-search">
              <div>
                <div>
                  <img src={searchImg} alt="" />
                </div>
                <div>
                  <Autocomplete
                    apiKey={"AIzaSyBJUZFe3zt4HNVzwdTlmJYMWtXhZ7mfclk"}
                    onPlaceSelected={(place) => {
                      setLocation(place.formatted_address);
                    }}
                    types={["address"]}
                    className="form-control newlocation"
                    defaultValue={location}
                    placeholder=""
                    componentRestrictions={{ country: "us" }}
                    preventDefault
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {/*-------Primary Specialisation--------*/}
      {textcolor === 6 && filtershow === true ? (
        <FilterModal
          title={"Primary Specialisation"}
          data={primarySpl}
          className="filterModelNestedLevel filterOptionsModel"
          activeFilters={spldata}
          onChange={(e) => {
            if (spldata.includes(e.target.name)) {
              setSplData(spldata.filter((ele) => ele !== e.target.name))
            } else {
              setSplData((pre) => [...pre, e.target.name])
            }
          }}
        />
      ) : null}
      {/*-------Lead Status--------*/}
      {textcolor === 7 && filtershow === true ? (
        <FilterModal
          title={"Lead Status"}
          data={leadStatus}
          className="filterModelNestedLevel"
          activeFilters={filterLeadStatus}
          onChange={(e) => {
            if (filterLeadStatus.includes(e.target.name)) {
              setFilterLeadStatus(filterLeadStatus.filter((ele) => ele !== e.target.name))
            } else {
              setFilterLeadStatus((pre) => [...pre, e.target.name])

            }
          }}
        />
      ) : null}

      {/* -------BD-------- */}
      {textcolor === 8 && filtershow === true ? (
        <FilterModal
          title={"BD"}
          data={bdModal}
          className="filterModelNestedLevel filterOptionsModel"
          activeFilters={filterBdModal}
          onChange={(e) => {
            if (filterBdModal.includes(e.target.name)) {
              setFilterBdModal(filterBdModal.filter((ele) => ele !== e.target.name))
            } else {
              setFilterBdModal((pre) => [...pre, e.target.name])
            }
          }}
        />
      ) : null}

      {/*--------Sort By Portion---------*/}

      {sortshow ? (
        <div className="sortby-Common">
          <div
            onClick={() => {
              setTextColor(11);
              setSortShow(false);
              setSort("oldest");
              setSortName("Oldest");
            }}
            style={{
              color: textcolor === 11 ? "#CB1B5B" : "",
              fontWeight: textcolor === 11 ? "bold" : "",
            }}
          >
            Oldest
          </div>
          <div
            onClick={() => {
              setTextColor(12);
              setSortShow(false);
              setSort("newest");
              setSortName("Newest");
            }}
            style={{
              color: textcolor === 12 ? "#CB1B5B" : "",
              fontWeight: textcolor === 12 ? "bold" : "",
            }}
          >
            Newest
          </div>
          <div
            onClick={() => {
              setTextColor(13);
              setSortShow(false);
              setSort("ascending");
              setSortName("Ascending");
            }}
            style={{
              color: textcolor === 13 ? "#CB1B5B" : "",
              fontWeight: textcolor === 13 ? "bold" : "",
            }}
          >
            Alphabetical ↑
          </div>
          <div
            onClick={() => {
              setTextColor(14);
              setSortShow(false);
              setSort("descending");
              setSortName("Descending");
            }}
            style={{
              color: textcolor === 14 ? "#CB1B5B" : "",
              fontWeight: textcolor === 14 ? "bold" : "",
            }}
          >
            Alphabetical ↓
          </div>
        </div>
      ) : null}

      {assignModal.status === true && (
        <AssignedTo
          id={assignModal?.id}
          setAssignModal={setAssignModal}
          setSuccess={setSuccess}
          pathName="doctor-registration"
        />
      )}

      {success && (
        <SucessModal
          name="BD"
          post="Assigned"
          onClick={() => successModalClose()}
        />
      )}

      {leadStatusModal.status === true && (
        <LeadStatus
          id={leadStatusModal?.id}
          setLeadStatusModal={setLeadStatusModal}
          setLeadStatusSuccess={setLeadStatusSuccess}
          setLeadStatusConfirmModal={setLeadStatusConfirmModal}
        />
      )}

      {leadStatusConfirmModal.status === true && (
        <LeadStatusConfirm
          id={leadStatusConfirmModal?.leadType}
          setLeadStatusConfirmModal={setLeadStatusConfirmModal}
          setLeadStatusSuccess={setLeadStatusSuccess}
          leadStatusConfirmModal={leadStatusConfirmModal}
          formType="doctor-registration"
        />
      )}
      {leadStatussuccess && (
        <SucessModal
          name="Lead Status"
          post="Updated"
          onClick={() => successModalClose()}
        />
      )}
      <ExcelDownloadModal onClickDownload={onClickDownload} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </>
  );
};

export default DoctorRegistration;
