import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IoCloseCircleSharp } from "react-icons/io5";
import { useLocation } from "react-router";

import "./SucessModel.scss";

// const LeadStatusArr = [
//   "Prospect",
//   "Hot Follow up",
//   "Cold Follow up",
//   "NATC / DNP",
//   "Not Interested",
//   "Invalid",
//   "Registration Completed",
//   "Document Verification Completed",
//   "Demo Completed",
//   "LoU Sent",
//   "LoU Signed",
//   "Profile Activated",
//   "App Installed",
//   "Ground Breaking",
//   "Account Terminated",
// ]

const LeadStatus = ({
  id,
  setLeadStatusModal = () => { },
  setLeadStatusSuccess = () => { },
  setLeadStatusConfirmModal = () => { }
}) => {

  const [inputValue, setInputValue] = useState('');
  const [leadStatus, setLeadStatus] = useState([]);
  const router = useLocation()

  const {
    handleSubmit,
  } = useForm();

  useEffect(() => {
    getLeadStatus()
  }, [])

  const getLeadStatus = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}lead-status/list`)
      .then((res) => {
        setLeadStatus(res.data.rows.map((lead) => lead?.type));
      });
  };

  const onSubmit = (fields) => {
    fields.lead_status = inputValue;
    if (["/doctor-registration", "/pharmacy-registration", "/healthcare-registration", "/insta-doc", "/other-registration", "/rp-qr-ratings/pharmacy-rating", "/web-bookings/home-healthcare-bookings", "/ad-landing-forms/partnered-doctors", "/ad-landing-forms/users-general", "other-registration", "/doctor-demo"].includes(router?.pathname) && ["LoU Signed", "Profile Activated"].includes(inputValue)) {
      setLeadStatusConfirmModal({ leadType: inputValue, status: true, id: id })
      setLeadStatusModal({ id: "", status: false });
    } else {
      if(router.pathname === "/ad-landing-forms/partnered-doctors"){
          axios
            .patch(
              `${process.env.REACT_APP_API_URL}doctors-ad/doctors-ad-update/${id}`,
              fields
            )
            .then((response) => {
              setLeadStatusSuccess(true);
              setLeadStatusModal({ id: "", status: false });
            });
      } else if(router.pathname === "/web-bookings/home-healthcare-bookings"){
          axios
            .patch(
              `${process.env.REACT_APP_API_URL}web-bookings/update/${id}`,
              fields
            )
            .then((response) => {
              setLeadStatusSuccess(true);
              setLeadStatusModal({ id: "", status: false });
            });
      } else if(router.pathname === "/pharmacy-registration"){
          axios
            .patch(
              `${process.env.REACT_APP_API_URL}pharmacy-registration/update/${id}`,
              fields
            )
            .then((response) => {
              setLeadStatusSuccess(true);
              setLeadStatusModal({ id: "", status: false });
            });
      } else if(router.pathname === "/doctor-registration"){
          axios
            .patch(
              `${process.env.REACT_APP_API_URL}doctor-registration/update/${id}`,
              fields
            )
            .then((response) => {
              setLeadStatusSuccess(true);
              setLeadStatusModal({ id: "", status: false });
            });
      } else if(router.pathname === "/healthcare-registration"){
          axios
            .patch(
              `${process.env.REACT_APP_API_URL}healthcare-registration/update/${id}`,
              fields
            )
            .then((response) => {
              setLeadStatusSuccess(true);
              setLeadStatusModal({ id: "", status: false });
            });
      } else if(router.pathname === "/insta-doc"){
          axios
            .patch(
              `${process.env.REACT_APP_API_URL}insta-doc-registration/update/${id}`,
              fields
            )
            .then((response) => {
              setLeadStatusSuccess(true);
              setLeadStatusModal({ id: "", status: false });
            });
      } else if(router.pathname === "/other-registration"){
          axios
            .patch(
              `${process.env.REACT_APP_API_URL}other-registration/update/${id}`,
              fields
            )
            .then((response) => {
              setLeadStatusSuccess(true);
              setLeadStatusModal({ id: "", status: false });
            });
      } else if(router.pathname === "/doctor-demo"){
          axios
            .patch(
              `${process.env.REACT_APP_API_URL}doctor-book-a-demo/update/${id}`,
              fields
            )
            .then((response) => {
              setLeadStatusSuccess(true);
              setLeadStatusModal({ id: "", status: false });
            });
      } else if(router.pathname === "/ad-landing-forms/users-general"){
          axios
            .patch(
              `${process.env.REACT_APP_API_URL}user-general/user-general-update/${id}`,
              fields
            )
            .then((response) => {
              setLeadStatusSuccess(true);
              setLeadStatusModal({ id: "", status: false });
            });
      } else if(router.pathname === "/rp-qr-ratings/pharmacy-rating"){
          axios
            .patch(
              `${process.env.REACT_APP_API_URL}rating-form/rating-form-update/${id}`,
              fields
            )
            .then((response) => {
              setLeadStatusSuccess(true);
              setLeadStatusModal({ id: "", status: false });
            });
      }
    }
  }

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const submitButtonStyle = {
    backgroundColor: inputValue.trim() ? 'green' : 'gray',
  };

  return (
    <>
      <div className="assignModal">
        <div className="modal-backdrop fade show success-model"></div>
        <div
          style={{ display: "block" }}
          className="modal fade show modal-sm"
          id="exampleModal2"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className=" modal-content p-4">
              <div className="d-flex justify-content-between w-100">
                <p className="assignTitle">Update Lead Status</p>
                <IoCloseCircleSharp size={30} color={"gray"} className="cursorPointer" onClick={() => setLeadStatusModal({ id: "", status: false })} />
              </div>
              <p className="assignName">Select Lead Status</p>
              <form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column align-items-center">
                <select
                  className="form-control"
                  defaultValue={""}
                  value={inputValue}
                  onChange={handleInputChange}
                >
                  <option disabled value="">
                    -- Select lead Status --
                  </option>
                  {leadStatus.map((data, idx) => {
                    return (
                      <option key={idx} value={data}>
                        {data}
                      </option>
                    );
                  })}
                </select>
                <button
                  className="assignSubmit"
                  type="submit"
                  style={submitButtonStyle}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LeadStatus;