import React from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import buttonadd from "../../assets/images/plus.png";
import search from "../../assets/images/searchIcon.png";
import "./index.scss";

const AddNewRetailPharmacies = () => {
  const navigate = useNavigate();

  const handleRetailPharmacyForm = () => {
    navigate("/add-new-retail-pharmacy-form");
  };

  const handleEdit = () => {
    navigate("/edit-retail-pharmacy-form");
  };

  const data = [
    {
      sno: "01",
      name: "Nalam Pharmacy",
      city: "Coimbatore",
      state: "Tamilnadu",
      pincode: "641 006",
      locality: "Ganapathy",
      license: "TNPLOU1619",
      mobile: "92165 61226",
      actions: "Activate",
    },
    {
      sno: "02",
      name: "Orbit Pharmacy",
      city: "Chennai",
      state: "Tamilnadu",
      pincode: "600016",
      locality: "Alwarpet",
      license: "TN002612607",
      mobile: "92165 61226",
      actions: "Track",
    },
    {
      sno: "03",
      name: "isue Pharmacy",
      city: "Madhurai",
      state: "Tamilnadu",
      pincode: "600 610",
      locality: "kambar Nagar",
      license: "TN00RD7570",
      mobile: "92165 61226",
      actions: "Track",
    },
    {
      sno: "04",
      name: "KMC Medical",
      city: "Trichy",
      state: "Tamilnadu",
      pincode: "641 060",
      locality: "Avinash Road",
      license: "TN00RD7570",
      mobile: "92165 61226",
      actions: "Track",
    },
  ];

  return (
    <>
      {/* <Home> */}
      <div className="healtharticle">
        <div className="healtharticle-container">
          <div className="healtharticle-title carrer-job-title ms-4">
            <Link
              to="/dashboard"
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "15px",
              }}
            >
              <span style={{ paddingLeft: "5%" }}>Dashboard</span>
            </Link>
            <span style={{ paddingLeft: "1%", paddingRight: "0.4%" }}>{`>`}</span>
            <Link
              to="/add-new-retail-pharmacy"
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "15px",
              }}
            >
              <span style={{ paddingLeft: "0%" }}>
                Partnered Retail Pharmacies
              </span>
            </Link>
            <span style={{ paddingLeft: "1%", paddingRight: "0.4%" }}>{`>`}</span>
            <Link
              to=""
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "15px",
              }}
            >
              <span>List of Partnered Retail Pharmacies</span>
            </Link>
          </div>

          <div className="healtharticle-addnew">
            <div
              id="addnewpost"
              onClick={handleRetailPharmacyForm}
              className="addnewhealth"
              style={{ width: "270px" }}
            >
              <div>
                {/* <BsFillPlusCircleFill /> */}
                <img src={buttonadd} alt="" />
              </div>
              <div>
                <button>Add New Retail Pharmacies</button>
              </div>
            </div>
          </div>

          <div className="healtharticle-show mt-5">
            <div>Show</div>
            <div>
              <select className="me-2">
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
                <option value="0">All</option>
              </select>
            </div>
            <div>entries</div>
            <div>
              <div>
                {/* <FaSearch /> */}
                <img src={search} alt="" />
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Search"
                  className="form-control"
                // onChange={(e) => filter_records(e.target.value)}
                />
              </div>
            </div>
            <div className="tamilnadu">
              <select name="" id="">
                <option value="">Tamil Nadu</option>
              </select>
            </div>
          </div>

          <div className="healtharticle-table add-new-retail-pharmacy-table">
            <table>
              <thead>
                <tr>
                  <td>S.No</td>
                  <td>Name of Pharmacy</td>
                  <td>City / Town</td>
                  <td>State</td>
                  <td>Pincode</td>
                  <td>Locality</td>
                  <td>Pharmacy License No</td>
                  <td>Contact Details (Mobile)</td>
                  <td>Actions</td>
                  <td>Track Orders / Progress</td>
                </tr>
              </thead>
              <tbody>
                {data.map((x, i) => {
                  return (
                    <tr>
                      <td>{x.sno}</td>
                      <td>{x.name}</td>
                      <td>{x.city}</td>
                      <td>{x.state}</td>
                      <td>{x.pincode}</td>
                      <td>{x.locality}</td>
                      <td>{x.license}</td>
                      <td>{x.mobile}</td>
                      <td>
                        <span
                          className="doctor-edit"
                          onClick={() => {
                            handleEdit();
                          }}
                        >
                          Edit
                        </span>
                        <span className="doctor-edit-logo"></span>
                        <span className="doctor-delete ms-2">Delete</span>
                        <span className="doctor-delete-logo"></span>
                        <span
                          className="doctor-view ms-2"
                          onClick={() => {
                            navigate("/view-retail-pharmacy-form");
                          }}
                        >
                          View
                        </span>
                        <span className="doctor-view-logo"></span>
                      </td>
                      <td>
                        <span
                          className="doctor-activate"
                          onClick={() => {
                            if (x.actions === "Activate") {
                              navigate("/retail-pharmacy-active");
                            }
                            if (x.actions === "Track") {
                              navigate("/retail-pharmacy-track");
                            }
                          }}
                        >
                          {x.actions}
                        </span>
                        <span className="doctor-activate-logo"></span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* </Home> */}
    </>
  );
};

export default AddNewRetailPharmacies;
