import axios from "axios";
import Moment from "moment";
import { useEffect, useState } from "react";
import { FaAngleDown } from "react-icons/fa";

import search from "../../assets/images/searchIcon.png";
import filter1 from "../../assets/svg/Health Article/filtericon.svg";
import sort1 from "../../assets/svg/Health Article/sortbyicon.svg";
import Breadcrumbs from "../Common/Breadcrumbs/Breadcrumbs";
import AssignedTo from "../modal/AssignedTo";
import Deletepopup from "../modal/Deletepopup";
import LeadStatus from "../modal/LeadStatus";
import PharmacyRatingCount from "../modal/PharmacyRatingCount";
import SucessModal from "../modal/SucessModal";

const getsrcValue = (value) => {
    try {
        if (typeof value === "string" && value.length)
            return process.env.REACT_APP_API_URL + "pharmacy-rating-images/" + value;
        return typeof value === "object" ? URL.createObjectURL(value) : " ";
    } catch (err) {
        console.error("err while loading image - ", err);
    }
};

const PharmacyRatingForm = () => {
    const [pharmacyIDList, setPharmacyIDList] = useState([]);
    const [pages,] = useState(0);
    const [current_page,] = useState(1);
    const [countModal, setCountModal] = useState({ id: "", status: false })
    const [assignModal, setAssignModal] = useState({ id: "", status: false })
    const [leadStatusModal, setLeadStatusModal] = useState({ id: "", status: false })
    const [success, setSuccess] = useState(false);
    const [leadStatussuccess, setLeadStatusSuccess] = useState(false);

    // const navigate = useNavigate();

    const [deleteModal, setDeleteModal] = useState({ id: "", status: false });

    useEffect(() => {
        getPharmacyIDList()
    }, []);

    const getPharmacyIDList = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}rating-form/rating-form-list`)
            .then((response) => {
                setPharmacyIDList(response?.data?.rows);
            });
    }

    const handleDelete = (id) => {
        axios
            .delete(`${process.env.REACT_APP_API_URL}rating-form/rating-form-delete/${id}`)
            .then(() => {
                getPharmacyIDList()
                setDeleteModal({ id: "", status: false });
            });
    };

    const handleClose = () => {
        setDeleteModal({ id: "", status: false });
    };


    const successModalClose = () => {
        setSuccess(false)
        setLeadStatusSuccess(false)
        getPharmacyIDList()
    }

    return (
        <>
            <div className="mt-5 p-4 bg-white">
                <Breadcrumbs
                    breadcrumbText="RP QR Ratings"
                    breadcrumbLink="/rp-qr-ratings/pharmacy-rating"
                    nestedText="QR Ratings Submission"
                    nestedLink="#"
                />
                <hr />
                <div className="healtharticle-show m-0 ps-4">
                    <div>Show</div>
                    <div>
                        <select
                            className="me-2"
                        >
                            <option>10</option>
                            <option>25</option>
                            <option>50</option>
                            <option>100</option>
                            <option value="0">All</option>
                        </select>
                    </div>
                    <div>entries</div>
                    <div>
                        <div>
                            <img src={search} alt="" />
                        </div>
                        <div>
                            <input
                                type="text"
                                placeholder="Search"
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="sortandfilter-new">
                        <div className="sortByPositionNew">
                            <div>
                                <div>Sort by</div>
                                <div>
                                    <img src={sort1} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="sortByDepartment">
                            <div>
                                <div>
                                    <div>
                                        Filter
                                    </div>
                                </div>
                                <div>
                                    <img src={filter1} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white">
                    {pharmacyIDList?.length ? (
                        <div className="adLandingFormsTable ms-4">
                            <table className="text-center">
                                <thead>
                                    <tr>
                                        <td>S.No</td>
                                        <td>Time of Registration</td>
                                        <td>Date of Registration</td>
                                        <td>Pharmacy Partner ID</td>
                                        <td>Name</td>
                                        <td>Mobile No</td>
                                        <td>Was all your required prescribed medicine available?</td>
                                        <td>Was all your required OTC's/Healthcare products available?</td>
                                        <td>Are you satisfied with the discount % provided for prescribed medicine</td>
                                        <td>Does the pharmacy owner / manager explain the advantages of InstaDoc</td>
                                        <td>Will you come back to buy your prescribed medicine in my pharmacy</td>
                                        <td>Will you refer / suggest my pharmacy to others?</td>
                                        <td>Rate your overall experience</td>
                                        <td>Feedback</td>
                                        <td>Share your concerns and suggestions through an image</td>
                                        <td>App Download Android Click</td>
                                        <td>App Download iOS Click</td>
                                        <td>Brochure Icon Click Status (1.0)</td>
                                        <td>Assigned To</td>
                                        <td>Lead Status</td>
                                        <td>Actions</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {pharmacyIDList?.map((el, i) => {
                                        return (
                                            <tr key={el._id}>
                                                <td>{i + 1}</td>
                                                <td>{Moment(el.createdAt).format("LT")}</td>
                                                <td>{Moment(el.createdAt).format("DD-MM-YYYY")}</td>
                                                <td onClick={() => setCountModal({ id: el.pharmacy_id, status: true })}>{el.pharmacy_id}</td>
                                                <td>{el.name}</td>
                                                <td>{el.mobile}</td>
                                                <td>{el.prescribed_medicine}</td>
                                                <td>{el.products_available}</td>
                                                <td>{el.satisfy_with_discount}</td>
                                                <td>{el.explain_adv}</td>
                                                <td>{el.come_back}</td>
                                                <td>{el.refer}</td>
                                                <td>{el.rating}</td>
                                                <td>{el.feedback}</td>
                                                <td>
                                                    {el.images && el.images.length ? (
                                                        <div>
                                                            {el.images.map((image, index) => (
                                                                <img
                                                                    key={index}
                                                                    src={getsrcValue(image)}
                                                                    style={{
                                                                        width: "50px",
                                                                        height: "50px",
                                                                        marginTop: "2%",
                                                                        marginBottom: "5%",
                                                                    }}
                                                                    alt="pics"
                                                                />
                                                            ))}
                                                        </div>
                                                    ) : (
                                                        "-"
                                                    )}
                                                </td>
                                                <td>{el.android_click ? el.android_click : "-"}</td>
                                                <td>{el.ios_click ? el.ios_click : "-"}</td>
                                                <td>{el.brochure_click ? el.brochure_click : "-"}</td>
                                                <td>{el.assigned_to ? el.assigned_to : "-"}</td>
                                                <td>{el.lead_status ? el.lead_status : "-"}</td>
                                                <td>
                                                    <span
                                                        className="pe-1"
                                                        onClick={() => setAssignModal({ id: el._id, status: true })}
                                                    >
                                                        Assigned
                                                    </span>
                                                    <span className="pe-1">
                                                        <FaAngleDown className="drop-down-arrow" />
                                                    </span>
                                                    <span
                                                        className="pe-1"
                                                        onClick={() => setLeadStatusModal({ id: el._id, status: true })}
                                                    >
                                                        Lead Status
                                                    </span>
                                                    <span className="pe-1">
                                                        <FaAngleDown className="drop-down-arrow" />
                                                    </span>
                                                    <span
                                                        className="pe-1"
                                                        onClick={() => setDeleteModal({ id: el._id, status: true })}
                                                    >
                                                        Delete
                                                    </span>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div className="mt-4">No Pharmacy ID Forms are registered </div>
                    )}
                </div>
                <div className="col d-flex pagination admin-pagination justify-content-end">
                    <input
                        type="button"
                        className="page-item page-link"
                        value="<<"
                        // onClick={(e) => setCurrentPage(1)}
                        disabled={current_page === 1}
                    />
                    <input
                        type="button"
                        className="page-item page-link"
                        value="<"
                        // onClick={(e) => setCurrentPage(current_page - 1)}
                        disabled={current_page === 1}
                    />

                    {current_page > 1 && (
                        <input
                            type="button"
                            value={current_page - 1}
                            onClick={(e) => {
                                // setCurrentPage(current_page - 1);
                            }}
                        />
                    )}
                    <input
                        className="page-item page-link"
                        type="button"
                        value={current_page}
                    />
                    {current_page < pages && (
                        <input
                            type="button"
                            value={current_page + 1}
                            onClick={(e) => {
                                // setCurrentPage(current_page + 1);
                            }}
                        />
                    )}
                    <input
                        type="button"
                        value=">"
                        className="page-item page-link"
                        // onClick={(e) => setCurrentPage(current_page + 1)}
                        disabled={current_page === pages}
                    />
                    <input
                        type="button"
                        value=">>"
                        className="page-link"
                        // onClick={(e) => setCurrentPage(pages)}
                        disabled={current_page === pages}
                    />
                </div>
            </div>
            {assignModal.status === true && (
                <AssignedTo
                    id={assignModal?.id}
                    setAssignModal={setAssignModal}
                    setSuccess={setSuccess}
                />
            )}
            {leadStatusModal.status === true && (
                <LeadStatus
                    id={leadStatusModal?.id}
                    setLeadStatusModal={setLeadStatusModal}
                    setLeadStatusSuccess={setLeadStatusSuccess}
                />
            )}
            {success && (
                <SucessModal
                    name="BD"
                    post="Assigned"
                    onClick={() => successModalClose()}
                />
            )}
            {deleteModal.status === true && (
                <Deletepopup
                    handleDelete={handleDelete}
                    id={deleteModal?.id}
                    isCloseModalEnable={true}
                    handleClose={handleClose}
                />
            )}
            {countModal.status === true && (
                <PharmacyRatingCount
                    id={countModal?.id}
                    setCountModal={setCountModal}
                />
            )}
            {leadStatussuccess && (
                <SucessModal
                    name="Lead Status"
                    post="Updated"
                    onClick={() => successModalClose()}
                />
            )}
        </>
    );
}

export default PharmacyRatingForm;