import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";

import backRed from "../../assets/svg/back-rose-icon.svg";
import SucessModal from "../modal/SucessModal";
import { CTACategoryList } from "../../constants";

const AddCTA = () => {

  const navigate = useNavigate();
  const pathName = useLocation()
  const [success, setSuccess] = useState(false);
  const [bannerImage, setbannerImage] = useState("")

  const {
    handleSubmit,
    getValues,
    register,
    reset,
    formState: { errors },
  } = useForm();

  const faqBredcrumbs = () => {
    return CTACategoryList?.filter((data) => data?.slugurl === pathName.pathname.split('/')[2]).map((val) => val.name)
  }

  const handleInputChange = (e) => {
    setbannerImage(e.target.files[0]);
  };
  const getsrcValue = (value) => {
    if (typeof value === "string" && value.length)
      return process.env.REACT_APP_API_URL + "images/" + value;
    return typeof value === "object" ? URL.createObjectURL(value) : " ";
  };
  const removeImage = async () => {
    // setIsUpload(false);
    setbannerImage("");

  }

  const onSubmit = () => {
    const fields = getValues()
    fields["category"] = pathName.pathname.split('/')[2]
    fields["active"] = fields?.active === "Active" ? true : false
    fields["bannerImage"] = fields?.bannerImage[0]
    axios
    .postForm(
      `${process.env.REACT_APP_API_URL}cta-banner/create`,
      fields
    )
    .then((response) => {
      setSuccess(true);
      reset()
    });
  }

  return (
    <>
      <div
        onClick={() => navigate("/home-healthcare-hhsp")}
        className="rose back-button"
      >
        <img src={backRed} alt="" />
        <span className="rose-back">Back</span>
      </div>

      <div className="healtharticle">
        <div className="healtharticle-container">
          <div className="healtharticle-title">
            <Link
              to="/"
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "15px",
              }}
            >
              <span style={{ paddingLeft: "0%" }}>
                Dashboard
              </span>
            </Link>
            <span style={{ fontSize: "15px", paddingLeft: "1%" }}>&#62;</span>
            <Link
              to="/cta-banner/retail-pharmacies"
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "15px",
                paddingLeft: "0.4%",
              }}
            >
              <span>CTA Banner</span>
            </Link>
            <span style={{ paddingLeft: "1%", fontSize: "15px" }}>&#62;</span>
            <span style={{ paddingLeft: "0.7%", fontSize: "15px" }}>
              {faqBredcrumbs()}
            </span>
            <span style={{ paddingLeft: "1%", fontSize: "15px" }}>&#62;</span>
            <span style={{ paddingLeft: "0.7%", fontSize: "15px" }}>
              Add New CTA Banner
            </span>
          </div>

          <form className="career-jobpost-form" onSubmit={handleSubmit(onSubmit)}>

            <div className="addnew-form1-autor-detail mt-3 d-flex">
              <div className="col-md-4">
                <label for="inputAddress" className="form-label title">
                  Banner Title
                  <span className="star">*</span>
                </label>
                <input
                  type="text"
                  className="form-control addnew-input"
                  id="authordetails"
                  {...register("title", { required: true })}
                  style={{ paddingLeft: "10px" }}
                />
                {errors.title && (
                  <div className={`invalid-feedback d-block`}>
                    Please provide a Banner Title
                  </div>
                )}
              </div>
              <div className="addnew-form1-autor-detail col-md-6 mt-3 d-flex">
                <div className="  ms-5">
                  <div className="">
                    <label className=" small mb-2" htmlFor="">
                      Active <span className="star">*</span>
                    </label>
                    <div className="d-flex">
                      <div className="form-check form-check-inline me-3 mt-1">
                        <input
                          className="form-check-input me-3"
                          type="radio"
                          name="gender"
                          id="gender"
                          value="Active"
                          checked
                          {...register("active", { required: true })}
                        />
                        <label htmlFor="option-1" className="text-dark mt-1">
                          Yes
                        </label>
                      </div>
                      <div className="form-check form-check-inline mt-1">
                        <input
                          className="form-check-input  me-3"
                          type="radio"
                          name="gender"
                          id="gender"
                          value="Inactive"
                          {...register("active", { required: true })}
                        />
                        <label htmlFor="option-2" className="text-dark mt-1">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  {errors.active && (
                    <div className={`invalid-feedback d-block`}>
                      Please select an Option
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="authorUpload ms-0">
              <div className="title fo-tit-top">
                Upload Banner Image{" "}
                <span className="star">*</span>
              </div>
              {bannerImage ? (
                <div> <img
                  src={getsrcValue(bannerImage)}
                  style={{
                    width: "auto",
                    height: "200px",
                    marginTop: "2%",
                    marginBottom: "5%",
                  }}
                  alt="banner"
                /></div>
              ) : null}
              <input
                className="form-control-file custom-file-input"
                type="file"
                {...register("bannerImage", { required: true })}
                // id={key}
                // name={key}
                style={{
                  width: "120px",
                }}
                onChange={handleInputChange}
              // required={false}
              />
              {bannerImage &&
                <button className="btn btn-danger ms-2"
                  onClick={() => removeImage()}
                >Remove</button>}
            </div>
            {errors.bannerImage && (
              <div className={`invalid-feedback d-block`}>
                Please provide a Banner Image
              </div>
            )}
            <div className="car-pos-form-button mb-5 mt-5 pb-5">
              <button type="submit">ADD</button>
            </div>
          </form>
        </div>
      </div>
      {/* </Home> */}
      {success && (
        <SucessModal
          page={`/cta-banner/${pathName.pathname.split('/')[2]}`}
          name="CTA Banner"
          post='posted'
        />
      )}
    </>
  );
};

export default AddCTA;
