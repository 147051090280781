import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import AddButton from "../../Common/addButton";
import Breadcrumbs from "../../Common/Breadcrumbs/Breadcrumbs";
import FilterOptions from "../../Common/filterOptions";
import Pagination from "../../Common/pagination";
import Deletepopup from "../../modal/Deletepopup";

import contact from "../../../assets/images/HealthSy/contact.png";
import newdelete from "../../../assets/images/HealthSy/delete.png";
import search from "../../../assets/images/searchIcon.png";

const CompanyReels = () => {

  const navigate = useNavigate();

  const [id, setId] = useState("")
  const [skip, setSkip] = useState(0);
  const [del, setDel] = useState(false);
  const [pages, setPages] = useState(0);
  const [limit, setLimit] = useState(10);
  const [filter,] = useState({});
  const [records, setRecords] = useState([]);
  const [sort,] = useState("newest");
  const [sortshow, setSortShow] = useState(false);
  const [current_page, setCurrentPage] = useState(1);
  const [sortName,] = useState("Sort by");
  // const [filtershow, setFilterShow] = useState(false);
  const [filtered_records, setFilteredRecords] = useState([]);

  const handleCompanyReels = () => {
    navigate("/add-company-reels")
  }

  const handleEdit = (id) => {
    navigate(`/company-reels-edit/${id}`)
  }

  const handleDelete = (id) => {
    fetch(`${process.env.REACT_APP_API_URL}company-reels/delete/${id}`,
      {
        method: "DELETE",
      }
    ).then((result) => {
      result.json().then((res) => {
        list_api_call();
        setDel(false)
      });
    });
  };

  useEffect(() => {
    list_api_call();
  }, [filter, limit, skip]);

  useEffect(() => {
    if (current_page > 1) {
      setSkip((current_page - 1) * limit);
    } else {
      setSkip(0);
    }
  }, [current_page]);

  const list_api_call = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}company-reels/list`, {
        ...filter,
        skip: skip,
        limit: limit,
        sort: sort,
      })
      .then((response) => {
        setFilteredRecords([]);
        setRecords(response.data);
        // setRowCount(response.data.count);
        setPages(Math.ceil(response.data.count / limit));
      })
      .catch((err) => {
        console.error(err);
        alert("Some error");
      });
  };

  const filter_records = (filter) => {
    let rows = records.filter((x) => {
      return JSON.stringify(x).toLowerCase().indexOf(filter.toLowerCase()) > -1;
    });
    setFilteredRecords(rows); <span className="master-view-icon"></span>
  };

  return (
    <>
      <div className="healtharticle">
        <div className="healtharticle-container">
          <div className="healtharticle-title carrer-job-title ms-4">
            <Breadcrumbs
              breadcrumbText="Company Reels"
              breadcrumbLink="/company-reels"
            />
          </div>
          <div className="healtharticle-addnew">
            <AddButton
              onClick={handleCompanyReels}
              btnName="Add Company Reels"
            />
          </div>
          <FilterOptions
            limit={limit}
            sortshow={sortshow}
            sortName={sortName}
            search={search}
            // setFilterShow={setFilterShow}
            setSortShow={setSortShow}
            filter_records={filter_records}
            setLimit={setLimit}
          />
          <div className="healtharticle-table master-doctor-table">
            <table>
              <thead>
                <tr>
                  <td className="career-jobpost-td1">S.No</td>
                  <td className="career-jobpost-td2">Title</td>
                  <td className="career-jobpost-td3">Reel URL</td>
                  {/* <td className="career-jobpost-td3">Date of Publish</td> */}
                  <td className="career-jobpost-td3">Click Count (Not Interest)</td>
                  <td className="career-jobpost-td4">Status</td>
                  <td className="career-jobpost-td5">Actions</td>
                </tr>
              </thead>
              <tbody>
                {(filtered_records.length > 0
                  ? filtered_records
                  : records
                ).map((el, i) => {
                  return (
                    <tr key={el._id}>
                      <td>{(current_page - 1) * limit + (i + 1)}</td>
                      <td>{el.reel_title}</td>
                      <td>{el.reel_url}</td>
                      {/* <td>{el.date_publish}</td> */}
                      <td>{el.not_interested_count}</td>
                      <td>{el.active === true ? "Verified" : "Not Verified"}</td>
                      <td>
                        <span className="master-edit" onClick={() => { handleEdit(el._id) }}>Edit</span>
                        <span className="contact-logo">
                          <img src={contact} alt="" />
                        </span>
                        <span className="master-delete" onClick={() => {
                          setId(el._id)
                          setDel(true);
                        }}>Delete</span>
                        <span className="newdelete-logo">
                          <img src={newdelete} alt="" />
                        </span>
                        {/* <span className="master-view" onClick={() => { handleView(el._id) }}>View</span>
                        <span className="inactive-icon">
                          <img src={inactive} alt="" />
                        </span> */}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Pagination
        setCurrentPage={setCurrentPage}
        current_page={current_page}
        pages={pages}
      />
      {/* </Home> */}
      {
        del === true
        &&
        <Deletepopup
          handleDelete={handleDelete}
          id={id}
        />
      }
    </>
  )
}

export default CompanyReels