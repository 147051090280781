import React from "react";
import { Link, useNavigate } from "react-router-dom";
import backRed from "../../../../assets/svg/back-rose-icon.svg";
import { useForm } from "react-hook-form";
import { useState } from "react";
import "./index.scss";
import greenround from "../../../../assets/images/greenround.PNG";
import greentick from "../../../../assets/images/greentick.PNG";
import graytick from "../../../../assets/images/graytick.PNG";
import { ImageUploadAndPreview } from "../../../Pages/HealthArticle/AddNew/AddNew";
import next from "../../../../assets/svg/next-icon.svg";
import back from "../../../../assets/svg/back-icon.svg";
import SucessModal from "../../../modal/SucessModal";

const EditNewServiceProviderForm = () => {

 const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [show, setShow] = useState(false)
    const [success, setSuccess] = useState(false);
  
    const {
      // handleSubmit,
      formState: { errors },
    } = useForm();
  
    const handleNext = () => {
      setPage(2);
      setShow(true)
    };
  
    const handleBack=()=>{
      setPage(1)
      setShow(false)
    }
  

  return (
    <>
      {/* <Home> */}
        {page === 1 ? (
          <div
            onClick={() => navigate("/home-healthcare-service-provider")}
            className="rose back-button"
          >
            <img src={backRed} alt="" />
            <span className="rose-back">Back</span>
          </div>
        ) : page === 2 ? (
          <div onClick={handleBack} className="rose back-button">
            <img src={backRed} alt="" />
            <span className="rose-back">Back</span>
          </div>
        ) : null}

        <div className="healtharticle">
          <div className="healtharticle-container">
            <div className="healtharticle-title">
              <Link
                to="/dashboard"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                }}
              >
                <span style={{ paddingLeft: "5%" }}>Dashboard</span>
              </Link>
              <span style={{ fontSize: "15px", paddingLeft: "1%" }}>{`>`}</span>
              <Link
                to="/home-healthcare-service-provider"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                  paddingLeft: "0.4%",
                }}
              >
                <span>Home Healthcare Service Providers</span>
              </Link>
              <span style={{ paddingLeft: "1%", fontSize: "15px" }}>{`>`}</span>
              <span style={{ paddingLeft: "0.7%", fontSize: "15px" }}>
                Edit Home Healthcare Service Provider
              </span>
            </div>

            {page === 1 ? (
              <div className="doctor-page-tick">
                <div>
                  <img src={greenround} alt="" />
                  <div>Page 1</div>
                </div>
                <div>
                  <img src={graytick} alt="" />
                  <div>Page 2</div>
                </div>
              </div>
            ) :
             page === 2
             ?
             <div className="doctor-page2-tick">
                <div>
                  <img src={greentick} alt="" />
                  <div>Page 1</div>
                </div>
                <div>
                  <img src={greentick} alt="" />
                  <div>Page 2</div>
                </div>
              </div>
              :null
            }

            <form className="career-jobpost-form">
              {
                show === false
                ?
                <div className="doctor-page1">
                {/* one */}
                <div className="addnew-form1-autor-detail mt-3 d-flex">
                  <div className="col-md-4">
                    <label for="inputAddress" className="form-label title">
                      Name of Home Healthcare Service Provider<span className="star">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control addnew-input"
                      id="authordetails"
                      placeholder="Type here"
                      style={{ paddingLeft: "10px" }}
                    />
                    {errors.authordetails && (
                      <div className={`invalid-feedback d-block`}>
                        Please provide a Author Name
                      </div>
                    )}
                  </div>

                  <div className="col-md-4">
                    <label for="inputAddress" className="form-label title">
                      Primary Category <span className="star">*</span>
                    </label>
                    <select className="form-select w-75 car-job-select">
                      <option value="" hidden selected>
                        Select
                      </option>
                      <option value="">Genereal Physician</option>
                      <option value="">Allergist</option>
                      <option value="">Cardiologist</option>
                    </select>
                    {errors.authordetails && (
                      <div className={`invalid-feedback d-block`}>
                        Please provide a Author Name
                      </div>
                    )}
                  </div>

                  <div className="col-md-3">
                    <label for="inputAddress" className="form-label title">
                      Secondary Category
                    </label>
                    <select className="form-select w-75 car-job-select">
                      <option value="" hidden selected>
                        Select
                      </option>
                      <option value="">Allopathy</option>
                      <option value="">Allopathy</option>
                      <option value="">Allopathy</option>
                    </select>
                    {errors.authordetails && (
                      <div className={`invalid-feedback d-block`}>
                        Please provide a Author Name
                      </div>
                    )}
                  </div>
                </div>

                {/* two */}
                <div className="addnew-form1-autor-detail mt-5 d-flex">
                <div className="row col-md-6">
                    <div className="col">
                      <label className=" small mb-2" htmlFor="">
                        Gender <span className="star">*</span>
                      </label>
                      <div className="d-flex">
                        <div className="form-check form-check-inline me-3 mt-1">
                          <input
                            className="form-check-input me-3"
                            type="radio"
                            name="gender"
                            id="gender"
                            value="Active"
                          />
                          <label htmlFor="option-1" className="text-dark mt-1">
                            Male
                          </label>
                        </div>
                        <div className="form-check form-check-inline mt-1">
                          <input
                            className="form-check-input  me-3"
                            type="radio"
                            name="gender"
                            id="gender"
                            value="Active"
                            checked
                          />
                          <label htmlFor="option-2" className="text-dark mt-1">
                            Female
                          </label>
                        </div>
                        <div className="form-check form-check-inline mt-1">
                          <input
                            className="form-check-input  me-3"
                            type="radio"
                            name="gender"
                            id="gender"
                            value="Inactive"
                          />
                          <label htmlFor="option-2" className="text-dark mt-1">
                            Others
                          </label>
                        </div>
                      </div>
                    </div>
                    {errors.status && (
                      <div className={`invalid-feedback d-block`}>
                        Please Select an Option
                      </div>
                    )}
                  </div>

                  <div className="col-3" style={{marginLeft:"-15%"}}>
                  <label className="car-text">
                    Date of Birth
                  </label>
                  <input
                    className="form-control"
                    type="date"
                  />
                  {errors.employment_Startdate && (
                    <div className={`invalid-feedback d-block`}>
                      Please provide Employment Start Date
                    </div>
                  )}
                </div>

                  <div className="col-md-3 ms-5">
                    <label for="inputAddress" className="form-label title">
                      Age 
                    </label>
                    <select className="form-select w-75 car-job-select">
                      <option value="" hidden selected>
                        Select Age
                      </option>
                      <option value="">Allopathy</option>
                      <option value="">Allopathy</option>
                      <option value="">Allopathy</option>
                    </select>
                    {errors.authordetails && (
                      <div className={`invalid-feedback d-block`}>
                        Please provide a Author Name
                      </div>
                    )}
                  </div>
                </div>

                {/* three */}
                <div className="addnew-form1-autor-detail mt-5 d-flex">
                  
                <div className="col-md-2">
                    <label for="inputAddress" className="form-label title">
                      Fees (INR) Per Hour
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="authordetails"
                      placeholder="Type here"
                      // style={{ paddingLeft: "10px" }}
                    />
                    {errors.authordetails && (
                      <div className={`invalid-feedback d-block`}>
                        Please provide a Author Name
                      </div>
                    )}
                </div>

                <div className="col-md-2 ms-5">
                    <label for="inputAddress" className="form-label title">
                      Fees <span className="star">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="authordetails"
                      placeholder="Type here"
                      // style={{ paddingLeft: "10px" }}
                    />
                    {errors.authordetails && (
                      <div className={`invalid-feedback d-block`}>
                        Please provide a Author Name
                      </div>
                    )}
                </div>

                <div className="col-md-3 ms-5">
                    <label for="inputAddress" className="form-label title">
                      Registered Mobile Number <span className="star">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="authordetails"
                      placeholder="Type here"
                      // style={{ paddingLeft: "10px" }}
                    />
                    {errors.authordetails && (
                      <div className={`invalid-feedback d-block`}>
                        Please provide a Author Name
                      </div>
                    )}
                </div>

                <div className="col-md-3 ms-5">
                    <label for="inputAddress" className="form-label title">
                      Registered Email ID <span className="star">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="authordetails"
                      placeholder="Type here"
                      // style={{ paddingLeft: "10px" }}
                    />
                    {errors.authordetails && (
                      <div className={`invalid-feedback d-block`}>
                        Please provide a Author Name
                      </div>
                    )}
                </div>

                </div>

                {/* four */}
                <div className="addnew-form1-autor-detail mt-5 d-flex">
                <div className="col-md-4">
                    <label for="inputAddress" className="form-label title">
                      City / Town 
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="authordetails"
                      placeholder="Type city / town"
                      // style={{ paddingLeft: "10px" }}
                    />
                    {errors.authordetails && (
                      <div className={`invalid-feedback d-block`}>
                        Please provide a Author Name
                      </div>
                    )}
                </div>

                <div className="col-md-3 ms-5">
                    <label for="inputAddress" className="form-label title">
                      Pincode <span className="star">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="authordetails"
                      placeholder="Type pincode"
                      // style={{ paddingLeft: "10px" }}
                    />
                    {errors.authordetails && (
                      <div className={`invalid-feedback d-block`}>
                        Please provide a Author Name
                      </div>
                    )}
                </div>

                <div className="col-md-4 ms-5">
                    <label for="inputAddress" className="form-label title">
                      Registration License No <span className="star">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="authordetails"
                      placeholder="Type registration license no"
                      // style={{ paddingLeft: "10px" }}
                    />
                    {errors.authordetails && (
                      <div className={`invalid-feedback d-block`}>
                        Please provide a Author Name
                      </div>
                    )}
                </div>

                </div>

                {/* five */}
                <div className="addnew-form1-autor-detail mt-5 d-flex">

                <div className="col-md-4">
                    <label for="inputAddress" className="form-label title">
                      Experience <span className="star">*</span>
                    </label>
                    <select className="form-select w-75 car-job-select">
                      <option value="" hidden selected>
                        Select Years
                      </option>
                      <option value="">Genereal Physician</option>
                      <option value="">Allergist</option>
                      <option value="">Cardiologist</option>
                    </select>
                    {errors.authordetails && (
                      <div className={`invalid-feedback d-block`}>
                        Please provide a Author Name
                      </div>
                    )}
                  </div>

                  <div className="col-md-4">
                    <label for="inputAddress" className="form-label title">
                      Associate Council <span className="star">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="authordetails"
                      placeholder="Type Name Educational Institute"
                      // style={{ paddingLeft: "10px" }}
                    />
                    {errors.authordetails && (
                      <div className={`invalid-feedback d-block`}>
                        Please provide a Author Name
                      </div>
                    )}
                </div>

                <div className="col-md-4 ms-5">
                    <label for="inputAddress" className="form-label title">
                      Select Known Languages (Upto 4) <span className="star">*</span>
                    </label>
                    <select className="form-select w-75 car-job-select">
                      <option value="" hidden selected>
                        Select and Add
                      </option>
                      <option value="">Genereal Physician</option>
                      <option value="">Allergist</option>
                      <option value="">Cardiologist</option>
                    </select>
                    {errors.authordetails && (
                      <div className={`invalid-feedback d-block`}>
                        Please provide a Author Name
                      </div>
                    )}
                  </div>

                </div>

                {/* Six */}
                <div className="addnew-form1-autor-detail mt-5 d-flex">
                <div className="col-md-4">
                    <label for="inputAddress" className="form-label title">
                      Educational Qualification 1 <span className="star">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="authordetails"
                      placeholder="Type here"
                      // style={{ paddingLeft: "10px" }}
                    />
                    {errors.authordetails && (
                      <div className={`invalid-feedback d-block`}>
                        Please provide a Author Name
                      </div>
                    )}
                </div>

                <div className="col-md-3 ms-5">
                    <label for="inputAddress" className="form-label title">
                      College / University & Year
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="authordetails"
                      placeholder="Type here"
                      // style={{ paddingLeft: "10px" }}
                    />
                    {errors.authordetails && (
                      <div className={`invalid-feedback d-block`}>
                        Please provide a Author Name
                      </div>
                    )}
                </div>

                <div className="col-md-4 ms-5">
                    <label for="inputAddress" className="form-label title">
                      Name of Hospital / Clinic (Employed / Owned) 
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="authordetails"
                      placeholder="Type here"
                      // style={{ paddingLeft: "10px" }}
                    />
                    {errors.authordetails && (
                      <div className={`invalid-feedback d-block`}>
                        Please provide a Author Name
                      </div>
                    )}
                </div>

                </div>

                {/* Seven */}
                <div className="addnew-form1-autor-detail mt-5 d-flex">
                <div className="col-md-12">
                    <label for="inputAddress" className="form-label title">
                      Address of Service Provider (For Communication) <span className="star">*</span>
                    </label>
                    <br />
                    <textarea 
                      className="doctor-textarea" 
                      name="" 
                      id="" 
                      cols="30" 
                      rows="10"
                      // placeholder="Type"
                    >

                    </textarea>
                    {errors.authordetails && (
                      <div className={`invalid-feedback d-block`}>
                        Please provide a Author Name
                      </div>
                    )}
                  </div>
                </div>

                {/* Eight */}
                <div className="addnew-form1-autor-detail mt-5 d-flex">
                  
                <div className="col-md-3">
                    <label for="inputAddress" className="form-label title">
                      Date of Onboarding (Active Since) <span className="star">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="authordetails"
                      placeholder="Type here"
                      // style={{ paddingLeft: "10px" }}
                    />
                    {errors.authordetails && (
                      <div className={`invalid-feedback d-block`}>
                        Please provide a Author Name
                      </div>
                    )}
                </div>

                <div className="row col-md-6 ms-5">
                    <div className="col">
                      <label className=" small mb-2" htmlFor="">
                        Verified <span className="star">*</span>
                      </label>
                      <div className="d-flex">
                        <div className="form-check form-check-inline me-3 mt-1">
                          <input
                            className="form-check-input me-3"
                            type="radio"
                            name="verified"
                            id="verified"
                            value="Active"
                            checked
                          />
                          <label htmlFor="option-1" className="text-dark mt-1">
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline mt-1">
                          <input
                            className="form-check-input  me-3"
                            type="radio"
                            name="verified"
                            id="verified"
                            value="Inactive"
                          />
                          <label htmlFor="option-2" className="text-dark mt-1">
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                    {errors.status && (
                      <div className={`invalid-feedback d-block`}>
                        Please Select an Option
                      </div>
                    )}
                  </div>

                  <div className="authorUpload ms-0">
                    <label className="title fo-tit fo-tit-top">
                      Upload Profile Picture{" "}
                      <span className="star">*</span>
                    </label>
                    <div id="img-preview-two"></div>
                    <div>
                      <ImageUploadAndPreview
                      //  onChange={files => setAuthorImage(files.length ? files[0] : null)}
                      />
                      {errors.authorImage && (
                        <div className={`invalid-feedback d-block`}>
                          Please Select Author Image
                        </div>
                      )}
                    </div>
                  </div>

                </div>

                {/* Nine */}
                <div className="addnew-form1-autor-detail mt-5 d-flex">
                  <div className="authorUpload ms-0">
                    <label className="title fo-tit fo-tit-top">
                      Upload Header / Cover Video{" "}
                      <span className="star">*</span>
                    </label>
                    <div id="img-preview-two"></div>
                    <div>
                      <ImageUploadAndPreview
                      //  onChange={files => setAuthorImage(files.length ? files[0] : null)}
                      />
                      {errors.authorImage && (
                        <div className={`invalid-feedback d-block`}>
                          Please Select Author Image
                        </div>
                      )}
                    </div>
                  </div>

                </div>

                <div className="addnew-form1-next">
                  <div onClick={handleNext}>
                    <div>
                      <button>NEXT</button>
                    </div>
                    <div>
                      <img src={next} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              :
                <div className="doctor-page2">

                {/* one */}
                <div className="addnew-form1-autor-detail mt-3 d-flex">

                <div className="col-md-12">
                    <label for="inputAddress" className="form-label title">
                    About (Opt of Max 100 words) <span className="star">*</span>
                    </label>
                    <br />
                    <textarea className="doctor-textarea" name="" id="" cols="30" rows="10">

                    </textarea>
                    {errors.authordetails && (
                      <div className={`invalid-feedback d-block`}>
                        Please provide a Author Name
                      </div>
                    )}
                  </div>

                </div>

                {/* two */}
                <div className="addnew-form1-autor-detail mt-3 d-flex">

                  <div className="authorUpload ms-0">
                    <label className="title fo-tit fo-tit-top">
                      Registration License Document(Upload Relevant Document) <span className="star">*</span>
                    </label>
                    <div id="img-preview-two"></div>
                    <div>
                      <ImageUploadAndPreview
                      //  onChange={files => setAuthorImage(files.length ? files[0] : null)}
                      />
                      {errors.authorImage && (
                        <div className={`invalid-feedback d-block`}>
                          Please Select Author Image
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="authorUpload">
                    <label className="title fo-tit fo-tit-top">
                      Upload Cancelled Cheque Leaf <span className="star">*</span>
                    </label>
                    <div id="img-preview-two"></div>
                    <div>
                      <ImageUploadAndPreview
                      //  onChange={files => setAuthorImage(files.length ? files[0] : null)}
                      />
                      {errors.authorImage && (
                        <div className={`invalid-feedback d-block`}>
                          Please Select Author Image
                        </div>
                      )}
                    </div>
                  </div>

                </div>

                {/* three */}
                <div className="addnew-form1-autor-detail mt-3 d-flex">

                <div className="authorUpload ms-0 me-5">
                    <label className="title fo-tit fo-tit-top">
                      Upload a valid Photo ID of Service Provider <span className="star">*</span>
                    </label>
                    <div id="img-preview-two"></div>
                    <div>
                      <ImageUploadAndPreview
                      //  onChange={files => setAuthorImage(files.length ? files[0] : null)}
                      />
                      {errors.authorImage && (
                        <div className={`invalid-feedback d-block`}>
                          Please Select Author Image
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="authorUpload ms-0 me-5">
                    <label className="title fo-tit fo-tit-top">
                      Upload Ownership Document of Clinic / Hospital / Center <span className="star">*</span>
                    </label>
                    <div id="img-preview-two"></div>
                    <div>
                      <ImageUploadAndPreview
                      //  onChange={files => setAuthorImage(files.length ? files[0] : null)}
                      />
                      {errors.authorImage && (
                        <div className={`invalid-feedback d-block`}>
                          Please Select Author Image
                        </div>
                      )}
                    </div>
                  </div>

                </div>

                <div className="addnew1-form1-next">
                  <div className="addnew1-back-next">
                    <div className="addnew1-back">
                      <div 
                        onClick={handleBack}
                      >
                        <div>
                          <img src={back} alt="" />
                        </div>
                        <div>BACK</div>
                      </div>
                    </div>
                    <div className="addnew1-next">
                    <div>
                      <button
                         type="button"
                         onClick={()=>{
                          setSuccess(true)
                         }}
                         className="bg-transparent border-0 text-white"
                         data-bs-toggle="modal"
                         data-bs-target="#exampleModal2"
                      >UPDATE</button>
                    </div>
                    </div>
                  </div>
                </div>
                
                </div>
              }

              

            </form>
          </div>
        </div>
      {/* </Home> */}
      {success && (
        <SucessModal 
          page={"/home-healthcare-service-provider"} 
          name="Home Healthcare Service Provider" 
          post='updated'
        />
      )}
    </>
  )
}

export default EditNewServiceProviderForm