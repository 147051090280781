import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import { FaAngleDown } from "react-icons/fa";

import Breadcrumbs from "../Common/Breadcrumbs/Breadcrumbs";
import { adLandingFormsList } from "../../constants";
import AdLandingBanner from "./AdLandingBanner";
import AssignedTo from "../modal/AssignedTo";
import filter1 from "../../assets/svg/Health Article/filtericon.svg";
import sort1 from "../../assets/svg/Health Article/sortbyicon.svg";
import search from "../../assets/images/searchIcon.png";
import SucessModal from "../modal/SucessModal";
import Deletepopup from "../modal/Deletepopup";
import newdelete from "../../assets/images/HealthSy/delete.png";
import onboard from "../../assets/images/HealthSy/onboard.png";
import LeadStatus from "../modal/LeadStatus";

const AdLandingForms = () => {
  const [doctorsAdList, setDoctorsAdList] = useState([]);
  const [usersAdList, setUsersAdList] = useState([]);
  const [assignModal, setAssignModal] = useState({ id: "", status: false })
  const [leadStatusModal, setLeadStatusModal] = useState({ id: "", status: false })
  const [success, setSuccess] = useState(false);
  const [leadStatussuccess, setLeadStatusSuccess] = useState(false);
  const [pages,] = useState(0);
  const [current_page,] = useState(1);

  const [deleteModal, setDeleteModal] = useState({ id: "", status: false });
  // const { id } = useParams();
  const navigate = useNavigate()

  const pathName = useLocation();

  useEffect(() => {
    getDoctorsAdList()
    getUsersAdList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDoctorsAdList = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}doctors-ad/doctors-ad-list`)
      .then((response) => {
        setDoctorsAdList(response?.data?.rows);
      });
  }

  const getUsersAdList = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}user-general/user-general-list`)
      .then((response) => {
        setUsersAdList(response?.data?.rows);
      });
  }

  const adLandingFormsBreadcrumbs = useMemo(() => {
    return adLandingFormsList?.filter(
      (data) => data?.slugurl === pathName.pathname.split("/")[2]
    ).map((val) => val)
  }, [pathName.pathname])

  const handleDelete = (id) => {
    if (pathName.pathname === "/ad-landing-forms/partnered-doctors") {
      axios
        .delete(`${process.env.REACT_APP_API_URL}doctors-ad/doctors-ad-delete/${id}`)
        .then(() => {
          getDoctorsAdList();
          setDeleteModal({ id: "", status: false });
        });
    } else if (pathName.pathname === "/ad-landing-forms/users-general") {
      axios
        .delete(`${process.env.REACT_APP_API_URL}user-general/user-general-delete/${id}`)
        .then(() => {
          getUsersAdList();
          setDeleteModal({ id: "", status: false });
        });
    }
  };
  

  const handleClose = () => {
    setDeleteModal({ id: "", status: false });
  };

  const successModalClose = () => {
    setSuccess(false)
    setLeadStatusSuccess(false)
    getDoctorsAdList()
    getUsersAdList()
  }

  return (
    <>
      <div className="mt-4 p-4 bg-white">
        <Breadcrumbs
          breadcrumbText="Ad Landing Forms"
          breadcrumbLink="/ad-landing-forms/partnered-doctors"
          nestedText={adLandingFormsBreadcrumbs[0]?.name}
          nestedLink="/ad-landing-forms/partnered-doctors"
        />
        <AdLandingBanner />
        <div className="healtharticle-show m-0 ps-4">
          <div>Show</div>
          <div>
            <select
              className="me-2"
            >
              <option>10</option>
              <option>25</option>
              <option>50</option>
              <option>100</option>
              <option value="0">All</option>
            </select>
          </div>
          <div>entries</div>
          <div>
            <div>
              <img src={search} alt="" />
            </div>
            <div>
              <input
                type="text"
                placeholder="Search"
                className="form-control"
              />
            </div>
          </div>
          <div className="sortandfilter-new">
            <div className="sortByPositionNew">
              <div>
                <div>Sort by</div>
                <div>
                  <img src={sort1} alt="" />
                </div>
              </div>
            </div>
            <div className="sortByDepartment">
              <div>
                <div>
                  <div>
                    Filter
                  </div>
                </div>
                <div>
                  <img src={filter1} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white">
          {doctorsAdList?.length && pathName.pathname === "/ad-landing-forms/partnered-doctors" ? (
            <div className="adLandingFormsTable ms-4">
              <table className="text-center">
                <thead>
                  <tr>
                    <td>S.No</td>
                    <td>Name</td>
                    <td>Mobile No</td>
                    <td>Email id</td>
                    <td>Gender</td>
                    <td>Primary Specilisation</td>
                    <td>Secondary Specilisation</td>
                    <td>Medical Registration No (MCI)</td>
                    <td>Medical Council</td>
                    <td>Year of Registration with Medical Council</td>
                    <td>Experience</td>
                    <td>Degree</td>
                    <td>City</td>
                    <td>Pincode</td>
                    <td>In-Clinic Fee</td>
                    <td>Online-Consultaion-Fee</td>
                    <td>Clinic Name</td>
                    <td>Clinic Address</td>
                    <td>App Download Android Click</td>
                    <td>App Download iOS Click</td>
                    <td>Brochure Icon Click Status (1.0)</td>
                    <td>Assigned to</td>
                    <td>Lead Status</td>
                    <td>Actions</td>
                  </tr>
                </thead>
                <tbody>
                  {doctorsAdList?.map((el, i) => {
                    return (
                      <tr key={el._id}>
                        <td>{i + 1}</td>
                        <td>{el.name}</td>
                        <td>{el.mobile}</td>
                        <td>{el.email}</td>
                        <td>{el.gender}</td>
                        <td>{el.specialization}</td>
                        <td>{el.secondary_specialization ? el.secondary_specialization : "-"}</td>
                        <td>{el.mci}</td>
                        <td>{el.medical_council}</td>
                        <td>{el.registered_year}</td>
                        <td>{el.experience}</td>
                        <td>{el.degree}</td>
                        <td>{el.city}</td>
                        <td>{el.pincode}</td>
                        <td>{el.in_clinic_fee ? el.in_clinic_fee : "-"}</td>
                        <td>{el.online_consultation_fee ? el.online_consultation_fee : "-"}</td>
                        <td>{el.clinic_name}</td>
                        <td>{el.clinic_address ? el.clinic_address : "-"}</td>
                        <td>{el.android_click}</td>
                        <td>{el.ios_click}</td>
                        <td>{el.brochure_click}</td>
                        <td>{el.assigned_to ? el.assigned_to : "-"}</td>
                        <td>{el.lead_status ? el.lead_status : "-"}</td>
                        <td>
                          <span
                            className="pe-1"
                            onClick={() => setAssignModal({ id: el._id, status: true })}
                          >
                            Assigned
                          </span>
                          <span className="pe-1">
                            <FaAngleDown className="drop-down-arrow" />
                          </span>
                          <span
                            className="pe-1"
                            onClick={() => setLeadStatusModal({ id: el._id, status: true })}
                          >
                            Lead Status
                          </span>
                          <span className="pe-1">
                            <FaAngleDown className="drop-down-arrow" />
                          </span>
                          <span
                            className="newdelete"
                            onClick={() => {
                              setDeleteModal({ id: el._id, status: true });
                            }}
                          >
                            Delete
                          </span>
                          <span className="newdelete-logo">
                            <img src={newdelete} alt="delete-logo" className="pe-1" />
                          </span>
                          <span
                            className="doctor-edit"
                            onClick={() => {
                              navigate(`/ad-landing-doctor-edit/${el._id}`);
                            }}
                          >
                            Edit
                          </span>
                          <span className="onboard-logo">
                            <img src={onboard} alt="edit-logo" />
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : usersAdList?.length && pathName.pathname === "/ad-landing-forms/users-general" ? (
            <div className="adLandingFormsTable ms-4">
              <table className="text-center">
                <thead>
                  <tr>
                    <td>S.No</td>
                    <td>Name</td>
                    <td>Email id</td>
                    <td>Mobile No</td>
                    <td>Age</td>
                    <td>Loaction</td>
                    <td>App Download Android Click</td>
                    <td>App Download iOS Click</td>
                    <td>Brochure Icon Click Status (1.0)</td>
                    <td>Assigned to</td>
                    <td>Lead Status</td>
                    <td>Actions</td>
                  </tr>
                </thead>
                <tbody>
                  {usersAdList?.map((el, i) => {
                    return (
                      <tr key={el._id}>
                        <td>{i + 1}</td>
                        <td>{el.name}</td>
                        <td>{el.email}</td>
                        <td>{el.mobile}</td>
                        <td>{el.age}</td>
                        <td>{el.location}</td>
                        <td>{el.android_click}</td>
                        <td>{el.ios_click}</td>
                        <td>{el.brochure_click}</td>
                        <td>{el.assigned_to ? el.assigned_to : "-"}</td>
                        <td>{el.lead_status ? el.lead_status : "-"}</td>
                        <td>
                          <span
                            className="pe-1"
                            onClick={() => setAssignModal({ id: el._id, status: true })}
                          >
                            Assigned
                          </span>
                          <span className="pe-1">
                            <FaAngleDown className="drop-down-arrow" />
                          </span>
                          <span
                            className="pe-1"
                            onClick={() => setLeadStatusModal({ id: el._id, status: true })}
                          >
                            Lead Status
                          </span>
                          <span className="pe-1">
                            <FaAngleDown className="drop-down-arrow" />
                          </span>
                          <span
                            className="newdelete"
                            onClick={() => {
                              setDeleteModal({ id: el._id, status: true });
                            }}
                          >
                            Delete
                          </span>
                          <span className="newdelete-logo">
                            <img src={newdelete} alt="delete-logo" className="pe-1" />
                          </span>
                          {/* <span
                            className="doctor-edit"
                            onClick={() => {
                              // navigate(`/ad-landing-doctor-edit/${el._id}`);
                            }}
                          >
                            Edit
                          </span>
                          <span className="onboard-logo">
                            <img src={onboard} alt="edit-logo" />
                          </span> */}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="mt-4"> No Ad Landing Forms are registered </div>
          )}
        </div>
        <div className="col d-flex pagination admin-pagination justify-content-end">
          <input
            type="button"
            className="page-item page-link"
            value="<<"
            // onClick={(e) => setCurrentPage(1)}
            disabled={current_page === 1}
          />
          <input
            type="button"
            className="page-item page-link"
            value="<"
            // onClick={(e) => setCurrentPage(current_page - 1)}
            disabled={current_page === 1}
          />

          {current_page > 1 && (
            <input
              type="button"
              value={current_page - 1}
              onClick={(e) => {
                // setCurrentPage(current_page - 1);
              }}
            />
          )}
          <input
            className="page-item page-link"
            type="button"
            value={current_page}
          />
          {current_page < pages && (
            <input
              type="button"
              value={current_page + 1}
              onClick={(e) => {
                // setCurrentPage(current_page + 1);
              }}
            />
          )}
          <input
            type="button"
            value=">"
            className="page-item page-link"
            // onClick={(e) => setCurrentPage(current_page + 1)}
            disabled={current_page === pages}
          />
          <input
            type="button"
            value=">>"
            className="page-link"
            // onClick={(e) => setCurrentPage(pages)}
            disabled={current_page === pages}
          />
        </div>
      </div>
      {assignModal.status === true && (
        <AssignedTo
          id={assignModal?.id}
          setAssignModal={setAssignModal}
          setSuccess={setSuccess}
        />
      )}
      {leadStatusModal.status === true && (
        <LeadStatus
          id={leadStatusModal?.id}
          setLeadStatusModal={setLeadStatusModal}
          setLeadStatusSuccess={setLeadStatusSuccess}
        />
      )}
      {success && (
        <SucessModal
          name="BD"
          post="Assigned"
          onClick={() => successModalClose()}
        />
      )}
      {leadStatussuccess && (
        <SucessModal
          name="Lead Status"
          post="Updated"
          onClick={() => successModalClose()}
        />
      )}

      {deleteModal.status === true && (
        <Deletepopup
          handleDelete={handleDelete}
          id={deleteModal?.id}
          isCloseModalEnable={true}
          handleClose={handleClose}
        />
      )}
    </>
  );
};

export default AdLandingForms;