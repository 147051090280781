import { useMemo } from "react";
import { Controller } from "react-hook-form";
import Select from 'react-select';

const MultiSelectField = ({
	labelName = "",
	name = "",
	control,
	id = "",
	errors,
	errorText = "",
	placeholder = "",
	isRequired = true,
	defaultValue = "",
	options = [],
	className = "",
	requiredText = "",
	maxLength = 0,
	selectedDataIdList=[]
}) => {
	// const rules = isRequired ? { required: requiredText } : {}
	const rules = {
		...(isRequired && { required: requiredText }),
		validate: {
			...(maxLength && { maxOptions: (value) => value.length <= maxLength || `Cannot choose more than ${maxLength} options` })
		}
	};

	const memoizedOptions = useMemo(() => {
			return selectedDataIdList?.length ? options.filter(({ active = null, id }) => active === null || active || selectedDataIdList.includes(id)) : options
	}, [options, selectedDataIdList])

	return (
		<>
			<label
				className="form-label title mb-2"
				htmlFor={id}
			>
				{labelName} {isRequired ? <span className="star">*</span> : null}
			</label>
			<Controller
				name={name}
				control={control}
				rules={rules} // Add required rule
				// defaultValue={[]}
				render={({ field }) => (
					<Select
						{...field}
						options={memoizedOptions}
						placeholder={placeholder}
						className={`${className}`}
						isMulti
					/>
				)}
			/>
			{errors[name] && (
				<div className={`invalid-feedback d-block`}>
					{errors[name]?.message}
				</div>
			)}
		</>
	)
}

export default MultiSelectField;