import axios from "axios";
import { debounce } from 'lodash';
import React, { useEffect, useState } from "react";
import Autocomplete from "react-google-autocomplete";
import { AiOutlineBackward, AiOutlineForward } from "react-icons/ai";
import { FiDownload } from "react-icons/fi";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import contact from "../../../assets/images/HealthSy/contact.png";
import notcontact from "../../../assets/images/HealthSy/notcontacted.png";
import { acrossHealthsy, careersFilterOptions, gender } from "../../../constants";
import DoctorsFilterOptions from "../../Common/doctorsFilterOptions";
import ExcelDownload from "../../Common/excelDownload";
import FilterModal from "../../Common/filterModal";
import FiltersRootModal from "../../Common/filtersRootModals";
import ExcelDownloadModal from "../../masters/Common/excelDownloadAccess";
import "./careers.scss";
import Breadcrumbs from "../../Common/Breadcrumbs/Breadcrumbs";

const fileDotExts = {
  "application/msword": ".doc",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    ".docx",
  "application/pdf": ".pdf",
  unknown: ".pdf",
};

const Careers = () => {

  const navigate = useNavigate();

  const [pages, setPages] = useState(0);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState("newest");
  const [sortName, setSortName] = useState("Sort by");
  const [records, setRecords] = useState([]);

  //filter portion
  const [filtershow, setFilterShow] = useState(false);
  const [sortshow, setSortShow] = useState(false);
  const [textcolor, setTextColor] = useState(0);
  const [pageOffset, setPageOffset] = useState(0);

  const [designationdata, setDesignationData] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [acrossHealthsyData, setAcrossHealthsyData] = useState([]);
  const [genderdata, setGenderdata] = useState([]);
  const [location, setLocation] = useState("");
  const [search, setSearch] = useState(0);
  const [filter,] = useState({ name: "" });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const base64ToBlob = async ({
    uploadResume: base64String,
    name,
    location,
  }) => {
    if (base64String.includes("data:application/")) {
      const link = document.createElement("a");
      const ext =
        fileDotExts[
        base64String.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)[1] ??
        "unknown"
        ];
      link.href = base64String;
      link.download = name + " " + location + ext;
      link.click();
    } else {
      axios({
        url: `${process.env.REACT_APP_API_URL}career/download/${base64String}`,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response?.config?.url?.split("-")?.slice(1).join("_")
        );
        document.body.appendChild(link);
        link.click();
      });
    }
  };

  const handleJobPost = () => {
    navigate("/careerjobpost");
  };

  const handleDepartment = () => {
    navigate("/careerdepartment");
  };

  const handlePosition = () => {
    navigate("/careerposition");
  };

  useEffect(() => {
    list_api_call();
  }, [search, limit, filter, sort, skip]);

  const handlePageChange = (event) => {
    setSkip(event.selected * limit);
    setPageOffset(event.selected);
  };

  useEffect(() => {
    handlePageChange({ selected: 0 });
  }, [search, limit]);

  const list_api_call = async (clearFilter = false) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}career/list`,
        {
          key: "job_post",
          filterValues: clearFilter ? {} : {
            gender: genderdata,
            location: location,
            desgination: designationList,
            acrossHealthsy: acrossHealthsyData,
          },
          value: sort,
          skip,
          limit,
          searchData: search,
        }
      );
      setRecords(response.data.careers);
      setPages(response.data.pagination.totalPages);
    } catch (err) {
      console.error(err);
    }
  };

  const filter_call = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}career/list`, {
        filterValues: {
          gender: genderdata,
          location: location,
          desgination: designationList,
          acrossHealthsy: acrossHealthsyData,
        },
        value: sort,
        skip,
        limit,
        searchData: search,
      })
      .then((res) => {
        setRecords(res.data.careers);
        setPages(res.data.pagination.totalPages);
      });
  };

  useEffect(() => {
    getDesgination();
  }, [])

  const getDesgination = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}career-add-job-post/list`)
      .then((res) => {
        setDesignationData(res.data.rows.map((data) => data?.designation));
      });
  };

  useEffect(() => {
    if (location) {
      filter_Location_Search_call()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const filter_Location_Search_call = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}filters/location`, {
        key: "careers",
        values: {
          location: location
        }
      })
      .then((res) => {
        setRecords(res.data)
      })
  }

  const handleContact = (id) => {
    axios
      .patch(`${process.env.REACT_APP_API_URL}career/update/${id}`, {
        contact: true,
        reject: false,
        select: false,
        interview: false,
        notContact: false,
      })
      .then((result) => {
        list_api_call();
      });
  };

  //Not Contact Functionality
  const handleNotContact = (id) => {
    axios
      .patch(`${process.env.REACT_APP_API_URL}career/update/${id}`, {
        contact: false,
        reject: false,
        select: false,
        interview: false,
        notContact: true,
      })
      .then((result) => {
        list_api_call();
      });
  };

  //Reject Functionality
  const handleReject = (id) => {
    axios
      .patch(`${process.env.REACT_APP_API_URL}career/update/${id}`, {
        contact: false,
        reject: true,
        select: false,
        interview: false,
        notContact: false,
      })
      .then((result) => {
        list_api_call();
      });
  };

  //Select Functionality
  const handleSelect = (id) => {
    axios
      .patch(`${process.env.REACT_APP_API_URL}career/update/${id}`, {
        contact: false,
        reject: false,
        select: true,
        interview: false,
        notContact: false,
      })
      .then((result) => {
        list_api_call();
      });
  };

  //Interview Functionality
  const handleInterview = (id) => {
    axios
      .patch(`${process.env.REACT_APP_API_URL}career/update/${id}`, {
        contact: false,
        reject: false,
        select: false,
        interview: true,
        notContact: false,
      })
      .then((result) => {
        list_api_call();
      });
  };

  const debouncedSearch = debounce((value) => {
    handlePageChange({ selected: 0 })
    setSearch(value); // Update the state after debounce
  }, 500);

  const onChangeSearch = (value) => {
    debouncedSearch(value);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const onClickDownload = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}career/list`, {
        skip: 0,
        limit: 3000,
        value: sort,
        filterValues: {
          gender: genderdata,
          location: location,
          desgination: designationList,
          acrossHealthsy: acrossHealthsyData,
        },
        searchData: search,
      });

      if (!response?.data || !Array.isArray(response?.data.careers) || response?.data.careers.length === 0) {
        console.log("No records available to download.");
        return;
      }

      const records = response?.data.careers;

      const formattedRecords = records.map((record, index) => {
        let hyperlinks = "";
        if (record.hyperLink) {
          if (
            !record.hyperLink?.length ||
            (record.hyperLink?.length && record.hyperLink[0]?.id)
          ) {
            hyperlinks = Object.values(record.hyperLink).map(
              (obj) => Object.values(obj)[0]
            ).join(", ");
          } else {
            const hype = Object.values(record.hyperLink).map((i) => Object.values(i));
            let hyperlink;
            Object.keys(hype).forEach((x) => {
              hyperlink = hype[x];
            });
            hyperlinks = Object.values(hyperlink).map(
              (i) => Object.values(i)[0]
            ).join(", ");
          }
        }

        return {
          "S.No": index + 1,
          "Time of Registration": record.createdAt ? new Date(record.createdAt).toLocaleTimeString() : "",
          "Date of Registration": record.createdAt ? new Date(record.createdAt).toLocaleDateString() : "",
          "Name of the Candidate": record.name || "",
          "Mobile Number": record.mobile || "",
          "Email ID": record.email || "",
          "Age": record.age || "",
          "Gender": record.gender || "",
          "Job Position": record.jobPosition || "",
          "Educational Qualification": record.educationQualification || "",
          "City / Town": record.location || "",
          "Hyperlinks": hyperlinks,
          "Across Healthsy": record.acrossHealthsy || "",
        };
      });
      ExcelDownload(formattedRecords, "", "career.xlsx");
      console.log("File downloaded successfully.");

    } catch (err) {
      console.error("Error downloading the file while getting all Doctor registration records", err, "err.message", err?.message);
      alert(err?.message || "An error occurred while downloading the file.");
    }
  };

  return (
    <>
      {/* <Home> */}
      <div className="healtharticle">
        <div className="healtharticle-container">
          <div className="healtharticle-title carrer-job-title ms-4">
            <Breadcrumbs
              nestedText="Careers"
              nestedLink=""
            />
          </div>
          <div
            className="careers-tab careers-car-tab"
            style={{ marginLeft: "2%" }}
          >
            <div className="career-tab-career career-tab">Careers</div>
            <div className="career-tab-jobpost" onClick={handleJobPost}>
              Job Post
            </div>
            <div className="career-post-department" onClick={handleDepartment}>
              Department/Function
            </div>
            <div className="career-post-position" onClick={handlePosition}>
              Position / Designation
            </div>
          </div>
          <DoctorsFilterOptions
            limit={limit}
            sortshow={sortshow}
            sortName={sortName}
            search={search}
            setFilterShow={setFilterShow}
            setSortShow={setSortShow}
            onChange={onChangeSearch}
            setLimit={setLimit}
            downloadExcel={true}
            onClickDownload={showModal}
          />
          <div className="career-main-table">
            <table className="">
              <thead>
                <tr>
                  <td>S.No</td>
                  <td>Name of the Candidate</td>
                  <td>Mobile Number</td>
                  <td>Email ID</td>
                  <td>Age</td>
                  <td>Gender</td>
                  <td>Job Position</td>
                  <td>Educational Qualification</td>
                  <td>City / Town</td>
                  <td>Hyperlinks</td>
                  <td>Resume CV</td>
                  <td>Across Healthsy</td>
                  <td>Actions</td>
                </tr>
              </thead>
              <tbody>
                {records &&
                  records?.map((el, i) => {
                    //-----hyperlink mapping---------
                    if (
                      !el?.hyperLink?.length ||
                      (el?.hyperLink?.length && el?.hyperLink[0]?.id)
                    ) {
                      var res = Object.values(el.hyperLink).map(
                        (obj) => Object.values(obj)[0]
                      );
                    } else {
                      var hype = Object.values(el.hyperLink).map((i) =>
                        Object.values(i)
                      );
                      var hyperlink;
                      Object.keys(hype).forEach((x) => {
                        hyperlink = hype[x];
                      });
                      var res = Object.values(hyperlink).map(
                        (i) => Object.values(i)[0]
                      );
                    }
                    //-----hyperlink mapping---------

                    if (el.show === undefined || el.show === true) {
                      return (
                        <tr key={el._id}>
                          <td>{skip + (i + 1)}</td>
                          <td>{el.name}</td>
                          <td>{el.mobile}</td>
                          <td>{el.email}</td>
                          <td>{el.age}</td>
                          <td>{el.gender}</td>
                          <td>{el.jobPosition}</td>
                          <td>{el.educationQualification}</td>
                          <td>{el.location}</td>
                          <td className="w-100">
                            {res.map((ele, i) => (
                              <React.Fragment key={i}>
                                <a
                                  style={{
                                    textDecoration: "none",
                                    fontSize: "13px",
                                  }}
                                  href={ele}
                                >
                                  {ele}
                                </a>
                                <br />
                              </React.Fragment>
                            ))}
                          </td>
                          <td>
                            <button
                              className="downloadBtn"
                              onClick={() => {
                                base64ToBlob(el);
                              }}
                            >
                              <div className="d-flex align-items-center">
                                Download
                                <FiDownload className="ml-2" />
                              </div>
                            </button>
                          </td>
                          <td>{el.acrossHealthsy ? el.acrossHealthsy : "-"}</td>
                          <td>
                            <span
                              className={`career-contact ${el.contact === true ? "career-contact-bold" : ""
                                }`}
                              onClick={() => {
                                handleContact(el._id);
                              }}
                            >
                              Contact
                            </span>
                            <span className="career-contact-icon">
                              <img src={contact} alt="" />
                            </span>
                            <span
                              className={`career-reject ${el.reject === true ? "career-reject-bold" : ""
                                }`}
                              onClick={() => {
                                handleReject(el._id);
                              }}
                            >
                              Reject
                            </span>
                            <span className="career-reject-icon">
                              <img src="" alt="" />
                            </span>
                            <span
                              className={`career-select ${el.select === true ? "career-select-bold" : ""
                                }`}
                              onClick={() => {
                                handleSelect(el._id);
                              }}
                            >
                              Select
                            </span>
                            <span className="career-selct-icon">
                              <img src="" alt="" />
                            </span>
                            <span
                              className={`career-interview ${el.interview === true
                                ? "career-interview-bold"
                                : ""
                                }`}
                              onClick={() => {
                                handleInterview(el._id);
                              }}
                            >
                              Interview
                            </span>
                            <span className="career-interview-icon">
                              <img src="" alt="" />
                            </span>
                            <span
                              className={`career-not-contact ${el.notContact === true
                                ? "career-not-contact-bold"
                                : ""
                                }`}
                              onClick={() => {
                                handleNotContact(el._id);
                              }}
                            >
                              Not Contacted
                            </span>
                            <span className="career-not-contact-icon">
                              <img src={notcontact} alt="" />
                            </span>
                          </td>
                        </tr>
                      );
                    } else {
                      return <></>
                    }
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div>
        <div className="mb-5 col d-flex pagination justify-content-end admin-pagination">
          <button
            className="page-link"
            onClick={() => handlePageChange({ selected: 0 })}
            disabled={skip === 0}
          >
            <AiOutlineBackward size={20} />
          </button>
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            pageCount={pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName="pagination my-0"
            activeClassName="active"
            forcePage={pageOffset}
          />
          <button
            className="page-link"
            onClick={() => handlePageChange({ selected: pages - 1 })}
            disabled={skip / limit === pages - 1}
          >
            <AiOutlineForward size={20} />
          </button>
        </div>
      </div>
      {/* </Home> */}

      {filtershow ? (
        <FiltersRootModal
          filterOptions={careersFilterOptions}
          onClickClear={() => {
            list_api_call(true)
            setFilterShow(false);
            setGenderdata([]);
            setDesignationList([]);
            setLocation([]);
            setAcrossHealthsyData([]);
          }}
          onClickApply={() => {
            setFilterShow(false);
            filter_call()
          }}
          selectOption={textcolor}
          setSelectOption={setTextColor}
        />
      ) : null}

      {/*---------Gender---------*/}
      {
        textcolor === 1 && filtershow === true
          ?
          <FilterModal
            title={"Gender"}
            data={gender}
            className="filterModelNestedLevel filterModelOptions"
            activeFilters={genderdata}
            onChange={(e) => {
              const selectedGender = e.target.name;
              if (genderdata.includes(selectedGender)) {
                setGenderdata(genderdata.filter((gender) => gender !== selectedGender));
              } else {
                setGenderdata((prev) => [...prev, selectedGender]);
              }
            }}
          />
          : null
      }

      {/*-------Location--------*/}
      {textcolor === 2 && filtershow === true ? (
        <div className="filter-doctor-fee-career">
          <div className="filter-doctor-fee-header">Location</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            <div className="filter-doctor-search">
              <div>
                <div>
                  <img src={search} alt="" />
                </div>
                <div>
                  <input
                    type="text"
                    name=""
                    id="filter-search"
                    placeholder="Search"
                    onChange={(e) => setLocation(e.target.value)}
                  />
                  <Autocomplete
                    apiKey={"AIzaSyBJUZFe3zt4HNVzwdTlmJYMWtXhZ7mfclk"}
                    onPlaceSelected={(place) => {
                      const city = place.address_components.find(component => component.types.includes("locality"));
                      setLocation(city ? city.long_name : place.formatted_address);
                    }}
                    types={["address"]}
                    className="form-control newlocation"
                    defaultValue={location}
                    placeholder=""
                    componentRestrictions={{ country: "us" }}
                    preventDefault
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {/*-------Designation--------*/}
      {
        textcolor === 3 && filtershow === true
          ?
          <FilterModal
            title={"Designation"}
            data={designationdata}
            className="filterModelNestedLevel filterModelOptions"
            activeFilters={designationList}
            onChange={(e) => {
              if (designationList.includes(e.target.name)) {
                setDesignationList(designationList.filter((ele) => ele !== e.target.name))
              } else {
                setDesignationList((pre) => [...pre, e.target.name])
              }
            }}
          />
          : null
      }

      {/*-------AcrossHealthsy--------*/}
      {
        textcolor === 4 && filtershow === true
          ?
          <FilterModal
            title={"AcrossHealthsy"}
            data={acrossHealthsy}
            className="filterModelNestedLevel filterModelOptions"
            activeFilters={acrossHealthsyData}
            onChange={(e) => {
              const selectedAcrossHealthsy = e.target.name;
              if (acrossHealthsyData.includes(selectedAcrossHealthsy)) {
                setAcrossHealthsyData(acrossHealthsyData.filter((data) => data !== selectedAcrossHealthsy));
              } else {
                setAcrossHealthsyData((prev) => [...prev, selectedAcrossHealthsy]);
              }
            }}
          />
          : null
      }

      {sortshow ? (
        <div className="sortby-Common-career-main">
          <div
            onClick={() => {
              setTextColor(11);
              setSortShow(false);
              setSort("oldest");
              setSortName("Oldest");
            }}
            style={{
              color: textcolor === 11 ? "#CB1B5B" : "",
              fontWeight: textcolor === 11 ? "bold" : "",
            }}
          >
            Oldest
          </div>
          <div
            onClick={() => {
              setTextColor(12);
              setSortShow(false);
              setSort("newest");
              setSortName("Newest");
            }}
            style={{
              color: textcolor === 12 ? "#CB1B5B" : "",
              fontWeight: textcolor === 12 ? "bold" : "",
            }}
          >
            Newest
          </div>
          <div
            onClick={() => {
              setTextColor(13);
              setSortShow(false);
              setSort("ascending");
              setSortName("Ascending");
            }}
            style={{
              color: textcolor === 13 ? "#CB1B5B" : "",
              fontWeight: textcolor === 13 ? "bold" : "",
            }}
          >
            Alphabetical ↑
          </div>
          <div
            onClick={() => {
              setTextColor(14);
              setSortShow(false);
              setSort("descending");
              setSortName("Descending");
            }}
            style={{
              color: textcolor === 14 ? "#CB1B5B" : "",
              fontWeight: textcolor === 14 ? "bold" : "",
            }}
          >
            Alphabetical ↓
          </div>
        </div>
      ) : null}
      <ExcelDownloadModal onClickDownload={onClickDownload} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </>
  );
};

export default Careers;