import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";

import Breadcrumbs from "../Common/Breadcrumbs/Breadcrumbs";
import SucessModal from "../modal/SucessModal";

const AddNewPharmacyPartnerID = ({ edit = false }) => {
    const {
        register,
        // getValues,
        setValue,
        formState: { errors },
        handleSubmit,
    } = useForm();

    const [success, setSuccess] = useState(false);
    const [editSuccess, setEditSuccess] = useState(false);
    const [checked, setChecked] = useState(true);
    const [logo, setLogo] = useState(null);

    const { id } = useParams();

    useEffect(() => {
        edit && getPharmacyID()
    }, [])

    const getPharmacyID = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}rp-qr-ratings/pharmacy-id-get/${id}`)
            .then((response) => {
                setValue("pharmacy_id", response?.data?.pharmacy_id);
                setValue("name", response?.data?.name);
                setValue("city", response?.data?.city);
                setLogo(response?.data?.logo)
                setChecked(response?.data?.active || false);
            })
    }

    const getsrcValue = (value) => {
        if (typeof value === "string" && value.length)
            return process.env.REACT_APP_API_URL + "pharmacy-id-images/" + value;
        return typeof value === "object" ? URL.createObjectURL(value) : " ";
    };

    const onSubmit = (fields) => {
        if (!edit && logo) {
            fields["logo"] = logo;
            axios
                .postForm(
                    `${process.env.REACT_APP_API_URL}rp-qr-ratings/pharmacy-id-create`,
                    fields
                )
                .then((response) => {
                    if (response.data?._id) {
                        setSuccess(true);
                    } else {
                        alert("Fail");
                        console.log("failed...");
                    }
                });
        } else if (edit && logo) {
            // let fields = getValues();
            fields["logo"] = logo;
            axios
                .postForm(
                    `${process.env.REACT_APP_API_URL}rp-qr-ratings/pharmacy-id-update/${id}`,
                    fields
                )
                .then((response) => {
                    if (response.data?._id) {
                        setEditSuccess(true);
                    } else {
                        alert("Fail");
                    }
                });
        }
    };

    return (
        <>
            <div className="healtharticle pt-3 ps-5 mb-4 mt-5">
                <Breadcrumbs
                    breadcrumbText="RP QR ratings"
                    breadcrumbLink="/rp-qr-ratings/pharmacies-partner-id"
                    nestedText="Pharmacies Partner ID"
                    nestedLink="/rp-qr-ratings/pharmacies-partner-id"
                    nestedText2="Add New Pharmacy Partner ID"
                    nestedLink2="#"
                />
                <form onSubmit={handleSubmit(onSubmit)} className="carr-dep-form">
                    <div className="row car-dep-form">
                        <div className="col-6">
                            <label
                                className="car-dep-form-txt mb-2"
                                style={{ color: "#4B4853" }}
                                htmlFor=""
                            >
                                Pharmacy ID{" "}<span className="star">*</span>
                            </label>
                            <input
                                className="form-control w-75"
                                type="text"
                                name=""
                                {...register("pharmacy_id", { required: true })}
                                placeholder="Enter Pharmacy ID"
                            />
                            {errors.pharmacy_id && (
                                <div className={`invalid-feedback d-block`}>
                                    Please provide a Pharmacy ID
                                </div>
                            )}
                        </div>

                        <div className="col-6">
                            <label
                                className="car-dep-form-txt mb-2"
                                style={{ color: "#4B4853" }}
                                htmlFor=""
                            >
                                Name{" "}<span className="star">*</span>
                            </label>
                            <input
                                className="form-control w-75"
                                type="text"
                                name=""
                                {...register("name", { required: true })}
                                placeholder="Enter Name"
                            />
                            {errors.name && (
                                <div className={`invalid-feedback d-block`}>
                                    Please provide a Name
                                </div>
                            )}
                        </div>

                        <div className="col-6 mt-5">
                            <label
                                className="car-dep-form-txt mb-2"
                                style={{ color: "#4B4853" }}
                                htmlFor=""
                            >
                                City{" "}<span className="star">*</span>
                            </label>
                            <input
                                className="form-control w-75"
                                type="text"
                                name=""
                                {...register("city", { required: true })}
                                placeholder="Enter Name"
                            />
                            {errors.name && (
                                <div className={`invalid-feedback d-block`}>
                                    Please provide a City
                                </div>
                            )}
                        </div>

                        <div className="col-6 mt-5">
                            <label
                                className="car-dep-form-txt mb-2"
                                style={{ color: "#4B4853" }}
                                htmlFor=""
                            >
                                Upload Logo{" "}<span className="star">*</span>
                            </label>
                            <div>
                                {logo ? (
                                    <img
                                        src={getsrcValue(logo)}
                                        style={{
                                            width: "100px",
                                            height: "100px",
                                            marginTop: "2%",
                                            marginBottom: "5%",
                                        }}
                                        alt="img"
                                    />
                                ) : null}
                            </div>
                            <input
                                className="form-control-file custom-file-input logoFileChoose"
                                type="file"
                                onChange={(e) => setLogo(e.target.files[0])}
                                required={false}
                            />
                            {logo &&
                                <button
                                    className="btn btn-danger ms-2"
                                    onClick={() => setLogo(null)}
                                >Remove</button>}
                            {!logo && (
                                <div className={`invalid-feedback d-block`}>
                                    Please provide a Logo
                                </div>
                            )}
                        </div>

                        <div className="col-6">
                            <label
                                className="car-dep-form-txt mb-2"
                                style={{ color: "#4B4853" }}
                                htmlFor=""
                            >
                                Active <span className="star">*</span>
                            </label>
                            <div className="d-flex">
                                <div className="form-check form-check-inline d-flex align-items-center me-3 mt-1">
                                    <input
                                        className="form-check-input me-3"
                                        type="radio"
                                        name="status"
                                        id="status"
                                        value={true}
                                        checked={checked}
                                        onClick={() => setChecked(true)}
                                        {...register("active", { required: true })}
                                    />
                                    <label htmlFor="option-1" className="text-dark ">
                                        Yes
                                    </label>
                                </div>
                                <div className="form-check form-check-inline d-flex align-items-center mt-1">
                                    <input
                                        className="form-check-input me-3"
                                        type="radio"
                                        name="status"
                                        id="status"
                                        value={false}
                                        checked={!checked}
                                        onClick={() => setChecked(false)}
                                        {...register("active", { required: true })}
                                    />
                                    <label htmlFor="option-2" className="text-dark">
                                        No
                                    </label>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="car-dep-form-button business-button">
                        <button type="submit" className="mb-5">
                            SUBMIT
                        </button>
                    </div>
                </form>
            </div>
            {success && (
                <SucessModal
                    page={"/rp-qr-ratings/pharmacies-partner-id"}
                    name="Pharmacy Partner ID"
                    post="created"
                />
            )}

            {editSuccess && (
                <SucessModal
                    page={"/rp-qr-ratings/pharmacies-partner-id"}
                    name="Pharmacy Partner ID"
                    post="updated"
                />
            )}
        </>
    );
}

export default AddNewPharmacyPartnerID;