import axios from "axios";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import AddButton from "../Common/addButton";
import buttonadd from "../../assets/images/plus.png";
import { AppDownloadLinks } from "../../constants";

const AppDownloadLink = ({
  dataValues = ""
}) => {
  const navigate = useNavigate();
  const pathName = useLocation();
  const [platformRecords, setPlatformRecords] = useState([])

  useEffect(() => {
    list_api_call()
  }, [])

  useEffect(() => {
    const id = localStorage.getItem('selected_platform')
    if (!id && platformRecords[0]?._id) {
      localStorage.setItem('selected_platform', platformRecords[0]._id)
      navigate(`/app-download-link/${platformRecords[0].platform_name.replace(" ", "-").toLowerCase().trim()}`);
    }
  }, [platformRecords, navigate])

  const ctaFormBredcrumbs = () => {
    return AppDownloadLinks?.filter(
      (data) => data?.slugurl === pathName.pathname.split("/")[2]
    ).map((val) => val.name);
  };

  const legalPageFormBredcrumbs = () => {
    const route = pathName.pathname.split("/")[2];
    const platform = platformRecords.find(
      (data) => (data?.platform_name ?? '').replace(/\s+/g, '-').toLowerCase() === route
    );

    return platform ? platform.platform_name : '';
  };

  const handleOrder = (pathName, id) => {
    localStorage.setItem('selected_platform', id)
    navigate(`/app-download-link/${pathName}`);
  };

  const handleMasterLegalPages = () => {
    navigate('/master-legal-pages')
  }

  const list_api_call = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}platform-name/list`, {
      })
      .then((response) => {
        setPlatformRecords(response.data.rows);
      })
      .catch((err) => {
        console.error(err);
        alert("Some error");
      });
  };

  return (
    <div className="faqContainer">
      <div className="faq-title ms-0">
        <span>
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to="/dashboard"
          >
            Dashboard
          </Link>
        </span>
        <span>&#62;</span>
        <span>
          <Link
            style={{ textDecoration: "none", color: "black" }}
          >
            Legal Page
          </Link>
        </span>
        <span style={{ paddingLeft: "0.4%" }}>&#62;</span>
        <span style={{ paddingLeft: "0.4%" }}>{dataValues ?
          legalPageFormBredcrumbs() : ctaFormBredcrumbs()}</span>
      </div>
      <div className="faq-addnew ms-2">
        <div className="addNewfaq">
          <div>
            <img src={buttonadd} alt="" />
          </div>
          <div>
            <button onClick={() => navigate(`/app-download-link/${pathName.pathname.split('/')[2]}/add-new-app-link`)}>Add Legal Page</button>
          </div>
        </div>
        <div className="healtharticle-addnew mt-3">
          <AddButton
            onClick={handleMasterLegalPages}
            btnName="Master Legal Pages"
          />
        </div>
      </div>
      {dataValues ?
        <div className="faq-tab">
          {platformRecords.map((data, idx) => {
            const route = (data?.platform_name ?? '').replace(/\s+/g, '-').toLowerCase().trim()
            return (
              <div
                key={idx}
                className={`${pathName.pathname.split("/")[2] === route
                  ? "rose-line"
                  : ""
                  } ${idx !== 0 ? "ms-5" : ""}`}
                onClick={() => handleOrder(route, data._id)}
              >
                {data?.platform_name}
              </div>
            );
          })}
        </div> :
        <div className="faq-tab">
          {AppDownloadLinks.map((data, idx) => {
            return (
              <div
                key={idx}
                className={`${pathName.pathname.split("/")[2] === data?.slugurl
                  ? "rose-line"
                  : ""
                  } ${idx !== 0 ? "ms-5" : ""}`}
                onClick={() => handleOrder(data?.slugurl)}
              >
                {data?.name}
              </div>
            );
          })}
        </div>
      }
    </div>
  );
};

export default AppDownloadLink;