import React from "react";
import { Link, useNavigate } from "react-router-dom";
import backRed from "../../../../../assets/svg/back-rose-icon.svg";

const InstaDocTrackView = () => {
 
    const navigate = useNavigate()

  return (
    <>
      {/* <Home> */}
        <div
          onClick={() => navigate("/insta-doc-track")}
          className="rose back-button"
        >
          <img src={backRed} alt="" />
          <span className="rose-back">Back</span>
        </div>
        <div className="healtharticle">
          <div className="healtharticle-container ps-3">
            <div className="healtharticle-title ms-0">
              <Link
                to="/dashboard"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                }}
              >
                <span style={{ paddingLeft: "5%" }}>Dashboard</span>
              </Link>
              <span style={{ paddingLeft: "1%" }}>{`>`}</span>
              <span style={{ paddingLeft: "0.4%" }}>
                <Link
                  to="/add-new-doctors"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontSize: "15px",
                  }}
                >
                  Doctors
                </Link>
              </span>
              {/* <span style={{ paddingLeft: "0.4%" }}>{`>`}</span>
              <span style={{ paddingLeft: "0.4%" }}>
                List of Doctors for Online Consultation
              </span> */}
              <span style={{ paddingLeft: "0.4%" }}>{`>`}</span>
              <span style={{ paddingLeft: "0.4%" }}>Track</span>
              <span style={{ paddingLeft: "0.4%" }}>{`>`}</span>
              <span style={{ paddingLeft: "0.4%" }}>View Doctor(24/7)</span>
            </div>

            <div className="doctor-track-header">
              <div>
                <img src="" alt="" />
              </div>
              <div>
                <div>John. K</div>
                <div>General Physician</div>
              </div>
              <div>Edit</div>
            </div>

            <div className="doctor-track-data">
              <div>
                <div>Name of Patient</div>
                <div>John K</div>
              </div>
              <div>
                <div>Online Consultation ID</div>
                <div>2FR21</div>
              </div>
              <div>
                <div>Age</div>
                <div>31</div>
              </div>
            </div>

            <div className="doctor-track-data">
            <div>
                <div>Gender</div>
                <div>Male</div>
              </div>
              <div>
                <div>Patient's Mobile Number</div>
                <div>+91 72964 45263</div>
              </div>
              <div>
                <div>Online Consultation Date</div>
                <div>04-02-2022</div>
              </div>
            </div>

            <div className="doctor-track-data">
            <div>
                <div>Online Consultation Time Slot</div>
                <div>10:30 AM</div>
              </div>
              <div>
                <div>Symtoms</div>
                <div>Fever, Cough & Cold</div>
              </div>
              <div>
                <div>Status</div>
                <div>Upcoming</div>
              </div>
            </div>

            <div className="doctor-track-data">
            <div>
                <div>Rx</div>
                <div>-</div>
              </div>
              <div>
                <div>Online Consultation Fee ( ₹ INR )</div>
                <div>₹ 199</div>
              </div>
              <div>
                <div>Payment Mode</div>
                <div>Online</div>
              </div>
            </div>

            <div className="doctor-track-data">
            <div>
                <div>Payment Status</div>
                <div>Paid</div>
              </div>
              <div>
                <div>Transaction Id</div>
                <div>PK92962</div>
              </div>
              <div>
                <div>Patient Pincode</div>
                <div>110011</div>
              </div>
            </div>

            <div className="doctor-track-data">
            <div>
                <div>Patient Town / City</div>
                <div>New Delhi</div>
              </div>
              <div>
                <div>Follow-up</div>
                <div>-</div>
              </div>
            </div>


          </div>
        </div>
      {/* </Home> */}
    </>
  );
};

export default InstaDocTrackView