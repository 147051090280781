import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";

import { slugUrl } from "../../constants";
import SucessModal from "../modal/SucessModal";
import Breadcrumbs from "../Common/Breadcrumbs/Breadcrumbs";
import { useEffect } from "react";
const AddNewState = ({ edit = false }) => {
	const [success, setSuccess] = useState(false);
	const [checked, setChecked] = useState(true);

	const { id } = useParams();

	const navigate = useNavigate()

	const {
		register,
		handleSubmit,
		setValue,
		// control,
		formState: { errors },
	} = useForm();

	useEffect(() => {
		edit && getState()
	}, [])

	const getState = () => {
		axios
			.get(`${process.env.REACT_APP_API_URL}frp-states/state-get/${id}`)
			.then((response) => {
				setValue("state", response?.data?.state);
				setChecked(response?.data?.active || false);
			})
	}

	const onSubmit = (fields) => {
		fields.slug_url = slugUrl(fields.state)

		if (edit) {

			axios
				.post(`${process.env.REACT_APP_API_URL}frp-states/state-update/${id}`, fields)
				.then((response) => {
					if (response.status === 200) {
						setSuccess(true);
					} else {
						alert("Failed to edit state. Please try again");
						console.log("failed...");
					}
				})
		} else {

			axios
				.post(`${process.env.REACT_APP_API_URL}frp-states/state-create`, fields)
				.then((response) => {
					if (response.status === 200) {
						setSuccess(true);
					} else {
						alert("Failed to add state. Please try again");
						console.log("failed...");
					}
				});

		}
	}

	return (
		<>
			<div className="healtharticle mt-5 pt-3 ps-2">
				<Breadcrumbs
					breadcrumbText="FRP"
					breadcrumbLink="/frp-states"
					nestedText={edit ? "Edit State" : "Add New State"}
					nestedLink="#"
				/>
				<form className="carr-dep-form" onSubmit={handleSubmit(onSubmit)}>
					<div className="row car-dep-form">
						<div className="col-6">
							<label
								className="car-dep-form-txt mb-2"
								style={{ color: "#4B4853" }}
								htmlFor=""
							>
								State <span className="star">*</span>
							</label>
							<input
								className="form-control w-75"
								type="text"
								name=""
								id="type"
								placeholder="Enter State"
								{...register("state", { required: true })}
							/>
							{errors.state && (
								<div className={`invalid-feedback d-block`}>
									Please Enter state
								</div>
							)}
						</div>

						<div className="col-6">
							<label
								className="car-dep-form-txt mb-2"
								style={{ color: "#4B4853" }}
								htmlFor=""
							>
								Active <span className="star">*</span>
							</label>
							<div className="d-flex">
								<div className="form-check form-check-inline d-flex align-items-center me-3 mt-1">
									<input
										className="form-check-input me-3"
										type="radio"
										name="status"
										id="status"
										value={true}
										checked={checked}
										onClick={() => setChecked(true)}
										{...register("active", { required: true })}
									/>
									<label htmlFor="option-1" className="text-dark ">
										Yes
									</label>
								</div>
								<div className="form-check form-check-inline d-flex align-items-center mt-1">
									<input
										className="form-check-input me-3"
										type="radio"
										name="status"
										id="status"
										value={false}
										checked={!checked}
										onClick={() => setChecked(false)}
										{...register("active", { required: true })}
									/>
									<label htmlFor="option-2" className="text-dark">
										No
									</label>
								</div>
							</div>
						</div>
					</div>
					<div className="car-dep-form-button m-0 pt-5">
						<button type="submit">SUBMIT</button>
					</div>
				</form>
			</div>
			{success && (
				<SucessModal
					page="/frp-states"
					name="State"
					post={edit ? 'updated' : 'added'}
					onClick={() => navigate("/frp-states")}
				/>
			)}
		</>
	);
};

export default AddNewState;