import axios from 'axios'
import moment from 'moment/moment'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import backRed from "../../../assets/svg/back-rose-icon.svg"

const ViewComment = () => {

  const [data, setData] = useState([])
  const { id } = useParams()
  const navigate = useNavigate()
  const [blogdata, setBlogData] = useState([])

  useEffect(() => {
    getCommentData()
    getBlogData()
  }, [])

  const getCommentData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}post-a-comment/get/${id}`)
      .then((res) => {
        console.log(res.data);
        setData(res.data)
      })
  }

  const getBlogData = () => {
    axios.post(`${process.env.REACT_APP_API_URL}health-article/list`)
      .then((res) => {
        setBlogData(res.data.rows)
      })
  }

  return (
    <>
      {/* <Home> */}
      <div onClick={() => navigate("/comment")} className="rose back-button">
        <img src={backRed} alt="" />
        <span className="rose-back">Back</span>
      </div>
      <div className="healtharticle-title blog-view-title mb-5">
        <Link
          to="/getnotified"
          style={{
            textDecoration: "none",
            color: "black",
            fontSize: "15px",
            fontWeight: "bold",
            width: "9%"
          }}
        >
          <span style={{ paddingLeft: "5%" }}>Pop-up Data</span>
        </Link>
        <span style={{ paddingLeft: "1%" }}>{`>`}</span>
        <Link
          to="/comment"
          style={{
            textDecoration: "none",
            color: "black",
            fontSize: "15px",
            fontWeight: "bold",
            paddingLeft: "0.4%",
          }}
        >
          <span>Post a Comment</span>
        </Link>
        <span style={{ paddingLeft: "0.4%" }}>{`>`}</span>
        <span style={{ paddingLeft: "0.4%" }}>View Post a Comment</span>
      </div>
      <div className="healthArticleView mb-5">
        <div>

          <div className="row mt-3 ms-2">
            <div className="menu-field col-md-4 me-0">
              <p className="form-label " htmlFor="" style={{ fontWeight: "500tt" }}>
                Blog Name
              </p>
              <div className="blog-view-text-half-bor">
                {blogdata.map((ele) => {
                  if (ele._id === data.id) {
                    return (
                      <span>{ele.title}</span>
                    )
                  } else {
                    return <></>
                  }
                })}
              </div>
            </div>
            <div className="category-field col-md-4">
              <p className="form-label " htmlFor="" style={{ fontWeight: "500tt" }}>
                Name
              </p>
              <div className="blog-view-text-half-bor">
                {data.name}
              </div>
            </div>
          </div>

          <div className="row mt-3 ms-2">
            <div className="menu-field col-md-4 me-0">
              <p className="form-label " htmlFor="" style={{ fontWeight: "500tt" }}>
                Email
              </p>
              <div className="blog-view-text-half-bor">
                {data.email}
              </div>
            </div>
            <div className="category-field col-md-4">
              <p className="form-label " htmlFor="" style={{ fontWeight: "500tt" }}>
                Date & Time
              </p>
              <div className="blog-view-text-half-bor">
                {`${moment(data.createdAt).format('DD-MM-YYYY')} & ${moment(data.createdAt).format('LT')}`}
              </div>
            </div>
          </div>

          <div className="col-md-8 mt-3 ms-3">
            <p htmlFor="videoUrl" className="form-label" style={{ fontWeight: "500tt" }}>
              Description
            </p>
            <div
              className="blog-view-text-full-des"
            >{data.comment}</div>
          </div>
        </div>
      </div>
      {/* </Home> */}
    </>
  )
}

export default ViewComment