import axios from 'axios';
import React from 'react';
import { Controller } from "react-hook-form";
import AsyncSelect from 'react-select/async';

// Simple debounce function implementation
const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

const loadOptions = async (inputValue, callback) => {
    try {
        // const response = await axios.post(`${process.env.REACT_APP_API_URL}health-article/admin-blog-list`);
        if (inputValue?.length > 2) {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}health-article/list`, { title: inputValue });
            const options = response.data?.rows?.map((data) => {
                return ({
                    value: data?._id,
                    label: data?.title,
                    slug: data?.slugurl,
                })
            }) || []; // Assuming your API returns an array of options
            callback(options);
        } else {
            callback([
                // { value: '64146a2934aadbbe0706ba14', label: '005 Post Test', slug: '005-post-test' }
            ]);

        }
    } catch (error) {
        console.error('Error fetching options:', error);
        callback([]);
    }
};

const debouncedLoadOptions = debounce(loadOptions, 300); // Debounce with 300ms delay

const BlogTitle = ({
    labelName = "",
    name = "",
    control,
    id = "",
    errors,
    errorText = "",
    placeholder = "",
    isRequired = true,
    defaultValue = "",
    inputValue = "",
    options = [],
    className = "",
    requiredText = "",
    maxLength = 0,
    defaultOptions = {},
    selectedDataIdList = []
}) => {

    // const rules = {
    //     ...(isRequired && { required: requiredText }),
    //     // validate: {
    //     // 	...(maxLength && { maxOptions: (value) => value.length <= maxLength || `Cannot choose more than ${maxLength} options` })
    //     // }
    // };
    return (
        <>
            <label
                className="form-label title mb-2"
                htmlFor={id}
            >
                {labelName} {isRequired ? <span className="star">*</span> : null}
            </label>
            <Controller
                name={name}
                rules={{
                    required: 'Please choose a Blog'
                }}
                control={control}
                render={({ field }) => (
                    <AsyncSelect
                        {...field}
                        value={field.value}
                        cacheOptions
                        isOptionSelected={true}
                        loadOptions={debouncedLoadOptions}
                        isClearable={true}
                        inputValue={inputValue?.label}
                    // defaultValue={defaultValue?.value}
                    // defaultOptions={[inputValue]}
                    />
                )}
            />
            {errors?.[name] && (
                <div className={`invalid-feedback d-block`}>
                    {errors[name]?.message}
                </div>
            )}
        </>
    );
};


export default BlogTitle