

export function formatDateTime(date, time) {
  if(!date && !time){
    return '-'
  }
  const formattedDate = new Date(date).toLocaleDateString('en-US', {
    day: '2-digit',
    month: 'long',
    year: 'numeric'
  });
  const formattedTime = new Date(time).toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });
  return `${formattedDate} ${formattedTime}`;
}

export const updatedBookingStatus = (selectedValue) => selectedValue === "Booking Confirmed" ? {
  not_intrested: false,
  booking_confirmed: true,
  yet_to_contact: false,
} : selectedValue === "Not Interested" ? {
  not_intrested: true,
  booking_confirmed: false,
  yet_to_contact: false,
} : {
  not_intrested: false,
  booking_confirmed: false,
  yet_to_contact: true,
}