import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";

import axios from "axios";
import JoditEditor from "jodit-react";

import apiCall from "../../api";
import graytick from "../../assets/images/graytick.PNG";
import greentick from "../../assets/images/greentick.PNG";
import next from "../../assets/svg/next-icon.svg";
import BackBtn from "../Common/backBtn";
import Breadcrumbs from "../Common/Breadcrumbs/Breadcrumbs";
import InputTextField from "../Common/inputTextField";
import NextBackBtns from "../Common/nextBackBtns";
import SelectField from "../Common/selectField";
import SucessModal from "../modal/SucessModal";

const StepOne = ({
  errors,
  setStep,
  trigger,
  isValid,
  register,
  pageType,
  getValues,
  setValue,
  draftedBy,
  legalPlatform,
  description,
  setDescription
}) => {

  const handleNext = async () => {
    await trigger();
    if (isValid) {
      setStep(2);
    }
  };

  useEffect(() => {
    if (legalPlatform?.length && getValues()?.platform_name) {
      setValue('platform_name', getValues()?.platform_name);
    }
  }, [legalPlatform, getValues, setValue])

  useEffect(() => {
    if (pageType?.length && getValues()?.page_type) {
      setValue('page_type', getValues()?.page_type);
    }
  }, [pageType, getValues, setValue])

  useEffect(() => {
    if (draftedBy?.length && getValues()?.drafted_by) {
      setValue('drafted_by', getValues()?.drafted_by);
    }
  }, [draftedBy, getValues, setValue])

  const getWordAndCharacterCount = (text) => {
    const stripHtml = (html) => {
      const div = document.createElement('div');
      div.innerHTML = html;
      return div.textContent || div.innerText || '';
    };

    const stringText = String(text || '').trim();
    const plainText = stripHtml(stringText);

    const sanitizedText = plainText.replace(/[^\x20-\x7E.]/g, '').replace(/\s+/g, ' ').trim();
    const wordCount = sanitizedText ? sanitizedText.split(/\s+/).filter(Boolean).length : 0;
    const characterCount = sanitizedText.length;

    return { wordCount, characterCount };
  };

  const { wordCount, characterCount } = getWordAndCharacterCount(description);

  const handleFullscreen = () => {
    const fullsizeButton = document.querySelector('.jodit-toolbar-button_fullsize .jodit-toolbar-button__button');
    if (fullsizeButton) {
      fullsizeButton.click();
    } else {
      console.log('Fullsize button not found');
    }
  };

  return (
    <>

      <div className="addnew-form1-autor-detail mt-5 d-flex">
        <div className="col-md-6">
          <SelectField
            errors={errors}
            defaultOption=""
            id="platform_name"
            register={register}
            name="platform_name"
            isOptionObject={true}
            labelName="Select Platform Name"
            errorText="Please select the Platform Name"
            options={legalPlatform}
            defaultValue=""
          />
        </div>
        <div className="col-md-6">
          <InputTextField
            labelName="Webpage Name"
            name="webpage_name"
            register={register}
            type="text"
            errors={errors}
            placeholder="Enter Webpage Name"
            errorText={"Please provide a Webpage Name"}
          />
        </div>
      </div>
      <div className="addnew-form1-autor-detail mt-5 d-flex">
        <div className="col-md-6">
          <SelectField
            id="page_type"
            errors={errors}
            name="page_type"
            options={pageType}
            register={register}
            labelName="Page Type"
            isOptionObject={true}
            errorText="Please select the Platform Name"
          />
        </div>
        <div className="col-md-6">
          <SelectField
            id="drafted_by"
            defaultValue=""
            errors={errors}
            defaultOption=""
            name="drafted_by"
            register={register}
            options={draftedBy}
            isOptionObject={true}
            labelName="Legal Page Drafted By"
            errorText="Please select the Drafted By"
          />
        </div>
      </div>
      <div className="addnew-form1-desc">
        <label className="title mt-4">
          Legal Description <span className="star">*</span>
        </label>
        <JoditEditor
          value={description}
          onChange={(value) => {
            setDescription(value);
            setValue("description", value);
          }}
        />
        {!description.length && (
          <div className={`invalid-feedback d-block`}>
            Please provide a Description
          </div>
        )}
        <div
          className="primaryColor d-flex justify-content-between pt-3">
          <div>Total Words: {wordCount} | Total Characters: {characterCount}</div>
          <button type="button" className="border-0 bg-white primaryColor" onClick={handleFullscreen}>
            Open Type View
          </button>
        </div>
      </div>
      <div className="addnew-form1-next justify-content-center">
        <div onClick={handleNext}>
          <div>
            <button type="button">NEXT</button>
          </div>
          <div>
            <img src={next} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

const StepTwo = ({
  register,
  errors,
  setStep,
}) => {

  const handleBack = () => {
    setStep(1)
  };

  return (
    <>
      <div className="addnew-form1-autor-detail mt-5 d-flex">
        <div className="col-md-12">
          <InputTextField
            labelName="Meta Title"
            name="meta_title"
            register={register}
            className="w-100"
            errors={errors}
            errorText={"Please provide a Meta Title"}
          />
        </div>
      </div>
      <div className="addnew-form1-autor-detail mt-5 d-flex">
        <div className="col-md-12">
          <InputTextField
            labelName="Meta Description"
            name="meta_description"
            register={register}
            className="w-100"
            errors={errors}
            errorText={"Please provide a Meta Description"}
          />
        </div>
      </div>
      <div className="addnew-form1-autor-detail mt-5 d-flex">
        <div className="col-md-12">
          <InputTextField
            labelName="Meta Keyword"
            name="meta_keyword"
            register={register}
            className="w-100"
            errors={errors}
            errorText={"Please provide a Meta Keyword"}
          />
        </div>
      </div>
      <div className="addnew-form1-autor-detail mt-5 d-flex">
        <div className="col-md-12">
          <InputTextField
            labelName="OG Tag"
            name="og_tag"
            register={register}
            className="w-100"
            errors={errors}
            errorText={"Please provide a OG Tag"}
          />
        </div>
      </div>
      <div className="backSubmitBtns mb-5">
        <NextBackBtns
          next={false}
          handleBack={handleBack}
        />
        <div className="nextBtnStyle mt-5">
          <div>
            <button type="submit">Submit</button>
          </div>
        </div>
      </div>
    </>
  )
}

const Level = ({
  page,
  edit,
}) => {
  return (
    <>
      <Breadcrumbs
        breadcrumbText="Legal Pages"
        breadcrumbLink="#"
        nestedText={edit ? "Edit Legal Page" : "Add New Legal Page"}
        nestedLink={`/app-download-link/website`}
      />
      {
        <div className={page === 1 ? "doctor-page-tick" : page === 2 ? "doctor-page2-tick" : ""}>
          <div>
            <img src={greentick} alt="" />
            <div>Page 1</div>
          </div>
          <div>
            <img src={page === 1 ? graytick : greentick} alt="" />
            <div>Page 2</div>
          </div>
        </div>
      }
    </>
  )
}

const AddDownloadLinks = ({
  edit = false
}) => {

  const { id } = useParams();
  const pathName = useLocation();
  const [step, setStep] = useState(1);
  const [pageType, setPageType] = useState([]);
  const [success, setSuccess] = useState(false);
  const [draftedBy, setDraftedBy] = useState([]);
  const [description, setDescription] = useState("");
  const [legalPlatform, setLegalPlatform] = useState([]);
  const [platformRecords, setPlatformRecords] = useState([]);

  const {
    // watch,
    trigger,
    control,
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();

  useEffect(() => {
    getLegalPlatform();
    getLegalPageType();
    getLegalDraftedBy();
  }, [])

  useEffect(() => {
    edit && getMenuData();
  }, [edit])

  useEffect(() => {
    list_api_call();
  }, [])

  const getLegalPlatform = async () => {
    try {
      const result = await apiCall(`platform-name/list`, "POST", { active: true });
      if (result.data?.rows) {
        setLegalPlatform(result.data?.rows?.map((data) => ({ value: data?._id, label: data?.platform_name })));
      }
    } catch (err) {
      console.error(err);
    }
  }

  const getLegalPageType = async () => {
    try {
      const result = await apiCall(`page-type/list`, "POST", { active: true });
      if (result.data?.rows) {
        setPageType(result.data?.rows?.map((data) => ({ value: data?._id, label: data?.page_name })));
      }
    } catch (err) {
      console.error(err);
    }
  }

  const getLegalDraftedBy = async () => {
    try {
      const result = await apiCall(`drafted-by/list`, "POST", { active: true });
      if (result.data?.rows) {
        setDraftedBy(result.data?.rows.map((data) => ({ value: data?._id, label: data?.name })));
      }
    } catch (err) {
      console.error(err);
    }
  }

  const legalSuccessPage = () => {
    const route = pathName.pathname.split("/")[2];
    const platform = platformRecords.find(
      (data) => (data?.platform_name ?? '')
        .replace(" ", "-")
        .toLowerCase()
        .trim() === route.replace(/%20/g, '')
    );

    return platform ? platform.platform_name.toLowerCase().replace(/\s+/g, '-') : ''
  };

  const list_api_call = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}platform-name/list`, {
      })
      .then((response) => {
        setPlatformRecords(response.data.rows);
      })
      .catch((err) => {
        console.error(err);
        alert("Some error");
      });
  };

  const getMenuData = async () => {
    try {
      const response = await apiCall(`store-link/get/${id}`, "GET",
        {})
      console.log("response", response.data);
      setValue('platform_name', response.data.platform_name);
      setDescription(response?.data?.platform_name)
      setValue('webpage_name', response.data.webpage_name);
      setValue('page_type', response.data.page_type);
      setValue('drafted_by', response.data.drafted_by);
      if (response?.data?.description) {
        setValue("description", response?.data?.description)
        setDescription(response?.data?.description)
      }
      setValue('meta_title', response.data.meta_title);
      setValue('meta_description', response.data.meta_description);
      setValue('meta_keyword', response.data.meta_keyword);
      setValue('og_tag', response.data.og_tag)
    } catch (err) {
      console.error(err);
    }
  }

  const onSubmit = async (fields) => {
    try {
      const url = edit
        ? `${process.env.REACT_APP_API_URL}store-link/update/${id}`
        : `${process.env.REACT_APP_API_URL}store-link/create`;

      const response = edit
        ? await axios.patch(url, fields)
        : await axios.post(url, fields);

      if (response.data?._id) {
        setSuccess(true);
      } else {
        alert("Fail");
        console.log("failed...");
      }
    } catch (error) {
      console.error("Submit failed", error);
    }
  };

  return (

    <div className="healtharticle mt-5 pt-4 ps-3 pb-5 ps-4">
      {
        edit ?
          <BackBtn
            navigatePath={`/app-download-link/user-app`}
          /> :
          <BackBtn
            navigatePath={`/app-download-link/${legalSuccessPage()}`}
          />
      }
      <div>
        <Level
          page={step}
          edit={edit}
        />
      </div>
      <form className="career-jobpost-form clinicProfile mt-2 ms-2" onSubmit={handleSubmit(onSubmit)}>
        {step === 1 ? (
          <StepOne
            register={register}
            errors={errors}
            setValue={setValue}
            trigger={trigger}
            page={step}
            getValues={getValues}
            isValid={isValid}
            setStep={setStep}
            control={control}
            legalPlatform={legalPlatform}
            pageType={pageType}
            draftedBy={draftedBy}
            description={description}
            setDescription={setDescription}
          />
        ) : ""}
        {step === 2 ? (
          <StepTwo
            register={register}
            errors={errors}
            setValue={setValue}
            trigger={trigger}
            page={step}
            getValues={getValues}
            isValid={isValid}
            setStep={setStep}
            control={control}
          />
        ) : ""}
      </form>
      {success && (
        <SucessModal
          page={edit ? `/app-download-link/user-app` : `/app-download-link/${legalSuccessPage()}`}
          name="New Legal Page"
          post={edit ? 'updated' : 'added'}
        />
      )}
    </div>
  )
};

export default AddDownloadLinks;