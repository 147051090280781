import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { convertSlugToNormalString } from "../../../../../constants";
import Breadcrumbs from "../../../../Common/Breadcrumbs/Breadcrumbs";
import BackBtn from "../../../../Common/backBtn";
import InputRadioField from "../../../../Common/inputRadioField";
import InputTextField from "../../../../Common/inputTextField";
import SucessModal from "../../../../modal/SucessModal";

const InclinicHealthConcernForm = ({ edit = false }) => {
    // const navigate = useNavigate();
    const [success, setSuccess] = useState(false);

    const {
        register,
        setValue,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();

    const { inclinic_specialization_id, id } = useParams();

    useEffect(() => {
        edit && getMenuData();
    }, [edit])

    const getMenuData = () => {
        axios.get(`${process.env.REACT_APP_API_URL}inclinic-health-concern/get/${id}`).then(response => {
            setValue('health_concern', response.data.health_concern);
            setValue('active', response.data.active);
            // setValue('meta_title', response.data?.meta_title);
            // setValue('meta_keyword', response.data?.meta_keyword);
            // setValue('meta_description', response.data?.meta_description);
            // setValue('others', response.data?.others);
        }).catch(err => {
            console.error(err);
            alert("Some error");
        })
    }

    const onSubmit = (fields) => {
        if (edit) {
            axios
                .patch(
                    `${process.env.REACT_APP_API_URL}inclinic-health-concern/update/${id}`,
                    fields
                )
                .then((response) => {
                    if (response.data?._id) {
                        setSuccess(true);
                    } else {
                        alert("Fail");
                        console.log("failed...");
                    }
                });
        } else {
            axios
                .post(
                    `${process.env.REACT_APP_API_URL}inclinic-health-concern/create`,
                    {...fields, inClinicSpecialisation: inclinic_specialization_id}
                )
                .then((response) => {
                    if (response.data?._id) {
                        setSuccess(true);
                    } else {
                        alert("Fail");
                        console.log("failed...");
                    }
                });
        }
    };

    return (
        <>
            <BackBtn
                navigatePath={`/inclinic-view-health-concern/${inclinic_specialization_id}`}
            />
            <div className="healtharticle">
                <div className="healtharticle-container">
                    <div className="healtharticle-title">
                        <Breadcrumbs
                            breadcrumbText='Doctor'
                            breadcrumbLink=''
                            nestedText='In-Clinic Specialization'
                            nestedLink='/inclinic-specialization'
                            nestedText2={convertSlugToNormalString(inclinic_specialization_id)}
                            nestedLink2=''
                            nestedText3={`${edit ? "Edit" : "Add New "}  Health Concern`}
                            nestedLink3=''
                        />
                    </div>

                    <form className="career-jobpost-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className="addnew-form1-autor-detail row mt-3">
                            <div className="col-md-4">
                                <InputTextField
                                    labelName="Name of Health Concern "
                                    name="health_concern"
                                    register={register}
                                    errors={errors}
                                    errorText={"Please provide a Name"}
                                />
                            </div>
                            <div className="mt-4 mt-md-0 col-md-4 ">
                                <InputRadioField
                                    register={register}
                                    errors={errors}
                                    setValue={setValue}
                                    watch={watch}
                                    labelName="Active of Health Concern"
                                    yesNo={true}
                                />
                            </div>
                        </div>
                        <div className="car-dep-form-button mt-5 mb-5">
                            <button type="submit" style={{ marginTop: "" }}>
                                SUBMIT
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            {success && (
                <SucessModal
                    page={`/inclinic-view-health-concern/${inclinic_specialization_id}`}
                    name="Health Concern"
                    post={edit ? 'updated' : 'posted'}
                />
            )}
        </>
    );
}

export default InclinicHealthConcernForm 