import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import backRed from "../../../../assets/svg/back-rose-icon.svg";
import SucessModal from "../../../modal/SucessModal";

const CategoryEdit = () => {

  const { id } = useParams()
  const [fields, setFields] = useState({})
  const navigate = useNavigate()
  const [success, setSuccess] = useState(false);

  const handleUpdateData = () => {
    let item = {
      menu: fields.menu,
      category: fields.category,
      active: fields.active
    };
    fetch(`${process.env.REACT_APP_API_URL}health-article-category/update/${id}`, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(item)
    })
      .then((result) => {
        result.json()
          .then((resp) => {
            setSuccess(true);
          })
      });
  }

  const getCategoryData = () => {
    axios.get(`${process.env.REACT_APP_API_URL}health-article-category/get/${id}`)
      .then(response => {
        setFields(response.data);
      }).catch(err => {
        console.error(err);
        alert("Some error");
      })
  }

  useEffect(() => {
    getCategoryData();
  }, []);

  const [menu, setMenu] = useState([])

  useEffect(() => {
    axios.post(`${process.env.REACT_APP_API_URL}health-article-menu/list`)
      .then((res) => {
        setMenu(res.data.rows)
      })
  }, [])

  return (
    <div>
      {/* <Home> */}
      <div
        onClick={() => navigate("/Category")}
        className="rose back-button"
      >
        <img src={backRed} alt="" />
        <span className="rose-back">Back</span>
      </div>
      <div className="healtharticle">
        <div className="healtharticle-container category-container ps-3">
          <div className="healtharticle-title">
            <Link to="/dashboard" style={{ textDecoration: "none", color: "black", fontSize: "15px" }}><span style={{ paddingLeft: "5%" }}>Dashboard</span></Link>
            <span style={{ paddingLeft: "1%" }}>{`>`}</span>
            <Link to="/healtharticlemenu" style={{ textDecoration: "none", color: "black", paddingLeft: "0.4%" }}><span>Manage Menu and Category</span></Link>
            <span style={{ paddingLeft: "0.4%" }}>{`>`}</span>
            <span style={{ paddingLeft: "0.4%" }}>Edit Category</span>
          </div>
          <div className="row mt-5">
            <div className="col-3">
              <label className="form-label " htmlFor="">
                Menu <span className="text-danger ">*</span>
              </label>
              {/* <input className='form-control w-100 ' type="text" name="" id="" placeholder='Enter'/> */}
              <select className="category-select w-100 " name="" id="menu"
                value={fields.menu}
                onChange={(e) => setFields(prevState => {
                  return { ...prevState, menu: e.target.value }
                })}>
                <option selected hidden>Select Menu</option>
                {
                  menu.map((el) => {
                    return (
                      <option value={el.name}>{el.name}</option>
                    )
                  })
                }
              </select>
            </div>
            <div className="col-4 ms-3">
              <label className="form-label " htmlFor="">
                Category <span className="text-danger ">*</span>
              </label>
              <input
                className="form-control w-100 category-input"
                type="text"
                name=""
                id="category"
                value={fields.category}
                onChange={(e) => setFields(prevState => {
                  return { ...prevState, category: e.target.value }
                })}
                placeholder="category"
              />
            </div>
          </div>

          <div className="row">
            <label htmlFor="" className="mb-3 mt-3 ">
              Active Status <span className="text-danger">*</span>
            </label>
            <div className="d-flex p-0">
              <div className="form-check form-check-inline m-0">
                <input
                  className=" me-3"
                  type="radio"
                  name="active"
                  id="active"
                  onChange={(e) => {
                    setFields(prevState => {
                      return { ...prevState, active: true };
                    });
                  }}
                  value={true}
                  checked={fields.active === true ? "checked" : null}
                />
                <label className="" htmlFor="inline1">
                  yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="me-3"
                  type="radio"
                  name="active"
                  id="status"
                  value={false}
                  onChange={(e) => {
                    setFields(prevState => {
                      return { ...prevState, active: false };
                    });
                  }}
                  checked={fields.active === false ? "checked" : null}
                />
                <label className="" htmlFor="inline2">
                  No
                </label>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center category-btn">
            <button onClick={handleUpdateData} className="add-button ">UPDATE</button>
          </div>
        </div>
      </div>
      {/* </Home> */}

      {success && (
        <SucessModal
          page={"/Category"}
          name="Category"
          post='updated'
        />
      )}
    </div>
  );
}


export default CategoryEdit