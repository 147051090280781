import axios from "axios";
import Moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { FaAngleDown } from "react-icons/fa";
import { useLocation } from "react-router";
import { getWebBookingStatus, webBookingProfileStatus } from "../../constants";
import { formatDateTime, updatedBookingStatus } from "../../utils";
import Breadcrumbs from "../Common/Breadcrumbs/Breadcrumbs";
import AssignedTo from "../modal/AssignedTo";
import Deletepopup from "../modal/Deletepopup";
import LeadStatus from "../modal/LeadStatus";
import LeadStatusConfirm from "../modal/LeadStatusConfirm";
import SucessModal from "../modal/SucessModal";
import './webBooking.scss';
import WebBookingbanner from "./webBookingbanner";
import WebBookingOnlineConsultation from "./WebBookingOnlineConsultation";

const WebBookings = () => {
  const pathName = useLocation();

  const [webBookingsList, setWebBookingsList] = useState([]);
  const [assignModal, setAssignModal] = useState({ id: "", status: false })
  const [deleteModal, setDeleteModal] = useState({ id: "", status: false });
  const [leadStatusModal, setLeadStatusModal] = useState({ id: "", status: false })
  const [success, setSuccess] = useState(false);
  const [leadStatussuccess, setLeadStatusSuccess] = useState(false);
  const [serviceList, setServiceList] = useState([])
  const [pages] = useState(0);
  const [current_page] = useState(1);
  const [leadStatusConfirmModal, setLeadStatusConfirmModal] = useState({ leadType: "", status: false, id: "" });

  // const { id } = useParams();
  const location = useLocation();
  // const navigate = useNavigate()

  useEffect(() => {
    getWebBookingsList()
    list_api_call()
  }, []);

  const getWebBookingsList = () => {

    axios
      .post(`${process.env.REACT_APP_API_URL}web-bookings/list`)
      .then((response) => {
        setWebBookingsList(response?.data);
      });
  }

  const list_api_call = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}services/list`)
      .then((response) => {
        setServiceList(response?.data);
      });
  }

  const handleDelete = (id) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}web-bookings/delete/${id}`)
      .then(() => {
        getWebBookingsList()
        setDeleteModal({ id: "", status: false });
      });
  };

  const servicesBreadcrumbs = useMemo(() => {
    return serviceList?.filter(
      (data) => data?.slug_url === pathName.pathname.split("/")[2]
    ).map((val) => val)
  }, [serviceList, pathName.pathname])

  const handleClose = () => {
    setDeleteModal({ id: "", status: false });
  };
  const handleNotInterested = (id) => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}web-bookings/update/${id}`,
        {
          not_intrested: true,
          booking_confirmed: false,
          yet_to_contact: false,
        }
      )
      .then((result) => {
        getWebBookingsList();
      });
  };

  //NotContact Functionality
  const handleBookingConfirmed = (id) => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}web-bookings/update/${id}`,
        {
          not_intrested: false,
          booking_confirmed: true,
          yet_to_contact: false,
        }
      )
      .then((result) => {
        getWebBookingsList();
      });
  };

  //On-Board Functionality
  const handleYetToContact = (id) => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}web-bookings/update/${id}`,
        {
          not_intrested: false,
          booking_confirmed: false,
          yet_to_contact: true,
        }
      )
      .then((result) => {
        getWebBookingsList();
      });
  };

  const successModalClose = () => {
    setSuccess(false)
    setLeadStatusSuccess(false)
    getWebBookingsList()
    list_api_call();
  }

  const handleOptionChange = (e, id) => {
    const selectedValue = e.target.value;
    const updatedStatus = updatedBookingStatus( selectedValue);
    axios
    .patch(
      `${process.env.REACT_APP_API_URL}web-bookings/update/${id}`,
      updatedStatus
    )
    .then((result) => {
      getWebBookingsList();
    });
  }

  return (
    <>
      <div className="mt-4 p-4 bg-white">
        <Breadcrumbs
          breadcrumbText="Web Bookings, Consultations & Appointments"
          breadcrumbLink="/web-bookings/home-healthcare-bookings"
          nestedText={servicesBreadcrumbs[0]?.name}
          nestedLink="/web-bookings/home-healthcare-bookings"
        />
        <WebBookingbanner serviceList={serviceList} />
        <div className="pt-4">
          {webBookingsList?.length && location.pathname === "/web-bookings/home-healthcare-bookings" ? (
            <div className=" webBookingsTable ms-4">
              <table>
                <thead>
                  <tr>
                    <td className="">S.No</td>
                    <td className="">Name</td>
                    <td className="">Mobile No</td>
                    <td className="">Email id</td>
                    <td className="">City</td>
                    <td className="">Location</td>
                    <td className="">Pincode</td>
                    <td className="">Service Category</td>
                    <td className="">Health Conditions</td>
                    <td className="">Received At</td>
                    <td className="">Booking Requested Date & Time</td>
                    <td className="">Assigned To</td>
                    <td className="">Lead Status</td>
                    <td className="">Action</td>
                  </tr>
                </thead>
                <tbody>
                  {webBookingsList.map((el, i) => {
                    return (
                      <tr key={el._id}>
                        <td>{i + 1}</td>
                        <td>{el.name}</td>
                        <td>{el.mobile}</td>
                        <td>{el.email}</td>
                        <td>{el.city}</td>
                        <td>{el.location}</td>
                        <td>{el.pincode}</td>
                        <td>{el.service_category}</td>
                        <td>{el.health_conditions.map((data, inx) => {
                          return (
                            <div key={inx}>{data}</div>
                          )
                        })}</td>
                        <td>
                          {" "}
                          {Moment(el.createdAt).format("MMMM DD, YYYY LT")}
                        </td>
                        <td>{formatDateTime(el?.date, el?.time)}</td>
                        <td>{el.assigned_to ? el.assigned_to : "-"}</td>
                        <td>{el.lead_status ? el.lead_status : "-"}</td>
                        <td className="">
                          <select
                            className="bg-white"
                            onChange={(e) => handleOptionChange(e, el._id)}
                            style={{ color: "#CB1B5B" }}
                            value={getWebBookingStatus(el)}
                          >
                            {webBookingProfileStatus.map((action, inx) => {
                              return <option key={inx} value={action} className="text-dark">{action}</option>
                            })}
                          </select>
                          {/* <div
                            className={`text-warning cursorPointer ${el.not_intrested && "highlight"}`}
                            onClick={() => {
                              handleNotInterested(el._id);
                            }}
                          >
                            Not Interested</div> */}
                          <div
                            className="text-danger cursorPointer"
                            onClick={() => {
                              setDeleteModal({ id: el._id, status: true })
                            }}
                          >
                            Delete</div>
                          {/* <div
                            className={`text-success cursorPointer ${el.booking_confirmed && "highlight"}`}
                            onClick={() => {
                              handleBookingConfirmed(el._id);
                            }}
                          >
                            Booking Confirmed</div>
                          <div
                            className={`text-primary cursorPointer ${el.yet_to_contact && "highlight"}`}
                            onClick={() => {
                              handleYetToContact(el._id);
                            }}
                          >
                            Yet to Contact
                          </div> */}

                          <span
                            className="pe-1 primaryColor"
                            onClick={() => setAssignModal({ id: el._id, status: true })}
                          >
                            Assigned To
                          </span>
                          <span className="pe-1">
                            <FaAngleDown className="drop-down-arrow" />
                          </span>

                          <span
                            className="pe-1 primaryColor"
                            onClick={() => setLeadStatusModal({ id: el._id, status: true })}
                          >
                            Lead Status
                          </span>
                          <span className="pe-1">
                            <FaAngleDown className="drop-down-arrow" />
                          </span>

                          {/* <span
                            className="doctor-edit"
                            onClick={() => {
                              navigate(`/web-booking-update/${el._id}`);
                            }}
                          >
                            Edit
                          </span>
                          <span className="onboard-logo">
                            <img src={onboard} alt="edit-logo" />
                          </span> */}
                        </td>
                      </tr>
                    );
                  })}

                </tbody>
              </table>
            </div>
          ) : (
            <WebBookingOnlineConsultation />
          )}
        </div>
        <div className="col d-flex pagination admin-pagination justify-content-end">
          <input
            type="button"
            className="page-item page-link"
            value="<<"
            // onClick={(e) => setCurrentPage(1)}
            disabled={current_page === 1}
          />
          <input
            type="button"
            className="page-item page-link"
            value="<"
            // onClick={(e) => setCurrentPage(current_page - 1)}
            disabled={current_page === 1}
          />

          {current_page > 1 && (
            <input
              type="button"
              value={current_page - 1}
              onClick={(e) => {
                // setCurrentPage(current_page - 1);
              }}
            />
          )}
          <input
            className="page-item page-link"
            type="button"
            value={current_page}
          />
          {current_page < pages && (
            <input
              type="button"
              value={current_page + 1}
              onClick={(e) => {
                // setCurrentPage(current_page + 1);
              }}
            />
          )}
          <input
            type="button"
            value=">"
            className="page-item page-link"
            // onClick={(e) => setCurrentPage(current_page + 1)}
            disabled={current_page === pages}
          />
          <input
            type="button"
            value=">>"
            className="page-link"
            // onClick={(e) => setCurrentPage(pages)}
            disabled={current_page === pages}
          />
        </div>
      </div>
      {deleteModal.status === true && (
        <Deletepopup
          handleDelete={handleDelete}
          id={deleteModal?.id}
          isCloseModalEnable={true}
          handleClose={handleClose}
        />
      )}

      {assignModal.status === true && (
        <AssignedTo
          id={assignModal?.id}
          setAssignModal={setAssignModal}
          setSuccess={setSuccess}
          pathName="web-bookings"
        />
      )}

      {success && (
        <SucessModal
          name="BD"
          post="Assigned"
          onClick={() => successModalClose()}
        />
      )}

      {leadStatusModal.status === true && (
        <LeadStatus
          id={leadStatusModal?.id}
          setLeadStatusModal={setLeadStatusModal}
          setLeadStatusSuccess={setLeadStatusSuccess}
          setLeadStatusConfirmModal={setLeadStatusConfirmModal}
        />
      )}
      {leadStatusConfirmModal.status === true && (
        <LeadStatusConfirm
          id={leadStatusConfirmModal?.leadType}
          setLeadStatusConfirmModal={setLeadStatusConfirmModal}
          setLeadStatusSuccess={setLeadStatusSuccess}
          leadStatusConfirmModal={leadStatusConfirmModal}
          formType="web-bookings"
        />
      )}
      {leadStatussuccess && (
        <SucessModal
          name="Lead Status"
          post="Updated"
          onClick={() => successModalClose()}
        />
      )}
    </>
  );
};

export default WebBookings;
