import React, { useState } from "react";
import { Link } from "react-router-dom";
import SucessModal from "../../modal/SucessModal";
import "./index.scss";

const EmailSettings = () => {

  // const {
  //     register,
  //     getValues,
  //     setValue,
  //     formState: { errors },
  //     handleSubmit,
  //   } = useForm();

  const [success,] = useState(false);
  const [editSuccess,] = useState(false);
  // const navigate = useNavigate();

  // const [data, setData] = useState([]);
  // const [id, setId] = useState("");
  // const [count, setCount] = useState(0);

  return (
    <>
      <div className="healtharticle">
        <div className="healtharticle-title ms-4">
          <Link
            to="/settings-social-media-link"
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: "15px",
            }}
          >
            <span style={{ paddingLeft: "5%" }}>Settings</span>
          </Link>
          <span style={{ fontSize: "15px", paddingLeft: "1%" }}>{`>`}</span>
          <Link
            to=""
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: "15px",
              paddingLeft: "0.2%",
              width: "20%",
            }}
          >
            <span style={{ paddingLeft: "6%" }}>Email Settings</span>
          </Link>
        </div>

        <form className="carr-dep-form">
          <div className="row car-dep-form">
            <div className="col-6">
              <label
                className="car-dep-form-txt mb-2"
                style={{ color: "#4B4853" }}
                htmlFor=""
              >
                Host
              </label>
              <input
                className="form-control w-75"
                type="text"
                name=""
                id="type"
                placeholder="Type here..."
              />
            </div>

            <div className="col-6">
              <label
                className="car-dep-form-txt mb-2"
                style={{ color: "#4B4853" }}
                htmlFor=""
              >
                Secure
              </label>
              <input
                className="form-control w-75"
                type="text"
                name=""
                id="type"
                placeholder="Type here..."
              />
            </div>
          </div>

          <div className="row car-dep-form">
            <div className="col-6">
              <label
                className="car-dep-form-txt mb-2"
                style={{ color: "#4B4853" }}
                htmlFor=""
              >
                Port
              </label>
              <input
                className="form-control w-75"
                type="text"
                name=""
                id="type"
                placeholder="Type here..."
              />
            </div>

            <div className="col-6">
              <label
                className="car-dep-form-txt mb-2"
                style={{ color: "#4B4853" }}
                htmlFor=""
              >
                User Name
              </label>
              <input
                className="form-control w-75"
                type="text"
                name=""
                id="type"
                placeholder="Type here..."
              />
            </div>
          </div>

          <div className="row car-dep-form">
            <div className="col-6">
              <label
                className="car-dep-form-txt mb-2"
                style={{ color: "#4B4853" }}
                htmlFor=""
              >
                Password
              </label>
              <input
                className="form-control w-75"
                type="text"
                name=""
                id="type"
                placeholder="Type here..."
              />
            </div>
          </div>

          <div className="car-dep-form-button">
            <button type="submit">SUBMIT</button>
          </div>
        </form>


      </div>
      {success && (
        <SucessModal
          page={"/meta-tags"}
          name="Meta tags"
          post="posted"
        />
      )}

      {editSuccess && (
        <SucessModal
          page={"/meta-tags"}
          name="Meta tags"
          post="updated"
        />
      )}
    </>
  );
};

export default EmailSettings