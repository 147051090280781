import axios from "axios";
import JoditEditor from "jodit-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "react-multiple-select-dropdown-lite/dist/index.css";
import { useParams } from "react-router";

import apiCall from "../../../../api";
import graytick from "../../../../assets/images/graytick.PNG";
import greentick from "../../../../assets/images/greentick.PNG";
import { medicalCouncilYears, states } from "../../../../constants";
import Breadcrumbs from "../../../Common/Breadcrumbs/Breadcrumbs";
import LanguageField from "../../../Common/LanguageFieldNew";
import MultipleAddAwards from "../../../Common/MultipleAddAwards";
import MultipleAddInputs from "../../../Common/MultipleAddInputs";
import BackBtn from "../../../Common/backBtn";
import FAQContent from "../../../Common/faqContent";
import InputRadioDynamic from "../../../Common/inputRadioDynamic";
import InputTextField from "../../../Common/inputTextField";
import MultiSelectField from "../../../Common/multiSelectField";
import MultipleAddMembership from "../../../Common/multipleAddMembership";
import MultipleAddTag from "../../../Common/multipleAddTag";
import NextBackBtns from "../../../Common/nextBackBtns";
import SelectField from "../../../Common/selectField";
import SucessModal from "../../../modal/SucessModal";

const StepOne = ({
	register,
	errors,
	watch,
	setValue,
	trigger,
	isValid,
	getValues,
	setStep,
	control,
	clinicProfiles,
	doctorSpecialization,
	inClinicSpecialization,
	doctorRecord,
	docSpecialization,
	incSpecialization
}) => {
	const profile_mode = watch("profile_mode")
	watch("clinic_profiles")
	// const clinic_profile = watch("clinic_profiles")
	const doctor_primary_specialization = watch("doctor_primary_specialization")
	const inclinic_primary_specialization = watch("inclinic_primary_specialization");
	const [doctorHealthConcers, setDoctorHealthConcers] = useState([])
	const [inClinicHealthConcers, setInClinicHealthConcers] = useState([])

	const getDoctorHealthConcers = async () => {
		const result = await apiCall(`doctor-health-concern/get-by-spl/${doctor_primary_specialization}`, "POST");
		if (result?.data) {
			setDoctorHealthConcers(result.data?.map(hc => ({
				value: hc?.health_concern,
				label: hc?.health_concern,
				id: hc?._id,
				active: hc?.active
			})));
			const hcIdList = result.data.map((ele) => ele._id);
			if (!(getValues()?.online_health_concern.map(hc => hc?.id).every(value => hcIdList.includes(value)))) {
				setValue("online_health_concern", "")
			}
		}
	}

	useEffect(() => {
		if (doctorSpecialization?.length && getValues()?.doctor_primary_specialization) {
			getDoctorHealthConcers()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [doctor_primary_specialization, doctorSpecialization])

	useEffect(() => {
		if (getValues()?.doctor_primary_specialization) {
			setValue("doctor_primary_specialization", getValues()?.doctor_primary_specialization);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [doctorSpecialization])

	useEffect(() => {
		if (getValues()?.online_health_concern) {
			setValue("online_health_concern", getValues()?.online_health_concern)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [doctorHealthConcers])


	const getInclinicHealthConcers = async () => {

		const result = await apiCall(`inclinic-health-concern/get-by-spl/${inclinic_primary_specialization}`, "POST");
		if (result?.data) {
			setInClinicHealthConcers(result.data?.map(hc => ({
				value: hc?.health_concern,
				label: hc?.health_concern,
				id: hc?._id,
				active: hc?.active
			})));
			const hcIdList = result.data.map((ele) => ele._id);
			if (!(getValues()?.inclinic_health_concern?.map(hc => hc?.id).every(value => hcIdList.includes(value)))) {
				setValue("inclinic_health_concern", "")
			}
		}
	}

	useEffect(() => {
		if (inClinicSpecialization?.length && getValues()?.inclinic_primary_specialization) {
			getInclinicHealthConcers()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inclinic_primary_specialization, inClinicSpecialization]);

	useEffect(() => {
		if (getValues()?.inclinic_primary_specialization) {
			setValue("inclinic_primary_specialization", getValues()?.inclinic_primary_specialization);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inClinicSpecialization])

	useEffect(() => {
		if (getValues()?.inclinic_specialization) {
			setValue("inclinic_specialization", getValues()?.inclinic_specialization);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [incSpecialization])

	useEffect(() => {
		if (getValues()?.online_specialization) {
			setValue("online_specialization", getValues()?.online_specialization);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [docSpecialization])

	useEffect(() => {
		if (getValues()?.inclinic_health_concern) {
			setValue("inclinic_health_concern", getValues()?.inclinic_health_concern)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inClinicHealthConcers])


	const handleNext = async () => {
		await trigger();
		if (isValid) {
			setStep(2)
		}
	};
	const removeClinicProfile = (clinicProfile) => {
		const filteredClinics = getValues()?.clinic_profiles?.filter((data) => data?.id !== clinicProfile?.id)
		setValue("clinic_profiles", filteredClinics)
	}

	const CheckText = (profile_mode === "Both" || profile_mode === "Online") ? "Online Doctor" : profile_mode === "InClinic" ? "InClinic" : "";

	return (
		<>
			<div className="addnew-form1-autor-detail mt-5 d-flex">
				<div className="col-md-6">
					<InputTextField
						labelName="Name of the Doctor"
						name="doctor_name"
						register={register}
						errors={errors}
						errorText={"Please provide a Doctor Name"}
					/>
				</div>
				<div className="col-md-6">
					<InputRadioDynamic
						register={register}
						errors={errors}
						errorMessage="Please choose the Profile Mode"
						watch={watch}
						setValue={setValue}
						trigger={trigger}
						label="Profile Mode"
						name="profile_mode"
						options={[
							{ name: "Online", },
							{ name: "InClinic", },
							{ name: "Both", }
						]}
					/>
				</div>
			</div>
			<div className="addnew-form1-autor-detail mt-5 d-flex">
				<div className="col-md-6">
					<SelectField
						id="specialization"
						labelName={`${CheckText} Specialization `}
						name={profile_mode !== "InClinic" ? "online_specialization" : "inclinic_specialization"}
						options={profile_mode !== "InClinic" ? docSpecialization : incSpecialization}
						isOptionObject={true}
						register={register}
						errors={errors}
						className="w-75"
						selectedDataId={profile_mode !== "InClinic" ? doctorRecord?.online_specialization : doctorRecord?.inclinic_specialization}
						errorText={`Please choose a ${CheckText} specialization`}
						defaultOption=""
					/>
				</div>
			</div>
			<div className="addnew-form1-autor-detail mt-5 d-flex">
				<div className="col-md-6">
					<SelectField
						id="primarySpecialization"
						labelName={`${CheckText} Specialist`}
						name={profile_mode !== "InClinic" ? "doctor_primary_specialization" : "inclinic_primary_specialization"}
						options={profile_mode !== "InClinic" ? doctorSpecialization : inClinicSpecialization}
						isOptionObject={true}
						register={register}
						errors={errors}
						selectedDataId={profile_mode !== "InClinic" ? doctorRecord?.doctor_primary_specialization : doctorRecord?.inclinic_primary_specialization}
						className="w-75"
						errorText={`Please choose a ${CheckText} Specialist`}
						defaultOption=""
					/>
				</div>
				<div className="col-md-6">
					<InputRadioDynamic
						register={register}
						errors={errors}
						errorMessage="Please choose the Verified"
						watch={watch}
						setValue={setValue}
						trigger={trigger}
						label="Verified"
						name="verified"
						options={[
							{ name: "Yes", },
							{ name: "No", },
						]}
					/>
				</div>
			</div>
			{(profile_mode && profile_mode !== "InClinic") ? <div className="addnew-form1-autor-detail mt-5 d-flex">
				<div className="col-md-6">
					<InputTextField
						labelName="Online Consultation listing Fee"
						name="online_consultation_fee"
						register={register}
						type="number"
						errors={errors}
						errorText={"Please provide a Online Consultation listing Fee"}
					/>
				</div>
				<div className="col-md-6 partneredDoctorLanuages">
					<div className="col-md-8">
						<MultiSelectField
							name="online_health_concern"
							id="online_health_concern"
							labelName="Online Health Concern "
							control={control}
							errors={errors}
							options={doctorHealthConcers}
							selectedDataIdList={doctorRecord?.online_health_concern?.map(hc => hc._id)}
							// maxLength={3}
							placeholder="Select"
							requiredText="Please choose a Online Health Concern"
						// errorText = "Please choose a Online Health Concern"
						/>
					</div>
				</div>
			</div> : null}
			{(profile_mode === "Both") ? (
				<div className="addnew-form1-autor-detail mt-5 d-flex">
					<div className="col-md-6">
						<SelectField
							id="inclinic_specialization"
							labelName="InClinic Specialization"
							name="inclinic_specialization"
							options={incSpecialization}
							isOptionObject={true}
							register={register}
							selectedDataId={doctorRecord?.inclinic_specialization}
							errors={errors}
							className="w-75"
							errorText="Please choose a InClinic Specialization"
							defaultOption=""
						/>
					</div>
				</div>) : null
			}
			{(profile_mode === "Both") ? (
				<div className="addnew-form1-autor-detail mt-5 d-flex">
					<div className="col-md-6">
						<SelectField
							id="primarySpecialization"
							labelName="InClinic Specialist "
							name="inclinic_primary_specialization"
							options={inClinicSpecialization}
							isOptionObject={true}
							register={register}
							selectedDataId={doctorRecord?.inclinic_primary_specialization}
							errors={errors}
							className="w-75"
							errorText="Please choose a InClinic Specialist"
							defaultOption=""
						/>
					</div>
				</div>) : null
			}
			{(profile_mode && profile_mode !== "Online") ? <div className="addnew-form1-autor-detail mt-5 d-flex">
				<div className="col-md-6">
					<InputTextField
						labelName="Inclinic Consultation listing Fee"
						name="inclinic_consultation_fee"
						register={register}
						errors={errors}
						type="number"
						errorText={"Please provide a Inclinic Consultation listing Fee"}
					/>
				</div>
				<div className="col-md-6">
					<MultiSelectField
						name="inclinic_health_concern"
						id="inclinic_health_concern"
						labelName="Inclinic Health Concerns"
						control={control}
						errors={errors}
						options={inClinicHealthConcers}
						placeholder="Select"
						requiredText="Please choose a Inclinic Health Concern"
						selectedDataIdList={doctorRecord?.inclinic_health_concern?.map(hc => hc._id)}
					/>

				</div>
			</div> : null}
			{profile_mode && profile_mode !== 'Online' && (
				<div className="addnew-form1-autor-detail mt-5">
					<div className="col-md-6 mt-3">
						<b>Clinic Related Details</b>
					</div>
					<div className="col-md-6 mt-4">
						<MultiSelectField
							name="clinic_profiles"
							id="clinic_profiles"
							labelName="Clinic Name"
							control={control}
							errors={errors}
							options={clinicProfiles}
							maxLength={3}
							placeholder="Select"
							requiredText="Please choose a Clinic Name"
						/>
					</div>

					{getValues()?.clinic_profiles?.length > 0 ? (
						<div className="row mt-3">{
							getValues()?.clinic_profiles?.map((clinicDetail, inx) => (
								<div className="col-6" key={inx}>
									<div key={clinicDetail.value} className="clinicNameCard">
										<div className="d-flex clinicDetails">
											<img
												src={`${process.env.REACT_APP_API_URL}clinic-profile/${clinicDetail.logo}`}
												width={50}
												height={50}
												className="rounded"
												alt="Clinic Logo"
											/>
											<div className="ms-2">
												<b>{clinicDetail?.value}</b>
												<div>{clinicDetail.address}</div>
											</div>

										</div>
										<b className="removeClinicBtn"
											onClick={() => removeClinicProfile(clinicDetail)}
										>
											Remove
										</b>
									</div>
								</div>
							))}
						</div>
					) : null}

				</div>
			)}
			<NextBackBtns
				back={false}
				handleNext={handleNext}
			/>
		</>
	)
}

const StepTwo = ({
	register,
	errors,
	watch,
	setValue,
	trigger,
	isValid,
	getValues,
	setStep,
	Memberships,
	setMemberships,
	tags,
	setTags,
	doctorRecord,
	genderList,
	languageList,
	degree,
	pgDegree,
	medicalCouncilList,
	awards,
	setAwards,
	experiences,
	setExperiences,
	about,
	setAbout,
	incliniAbout,
	setInclinicAbout,
}) => {
	const [selectedLang,] = useState([]);
	const profileMode = getValues()?.profile_mode;

	useEffect(() => {
		if (doctorRecord) {
			if (doctorRecord?.awards?.length) {
				doctorRecord.awards?.forEach((edu) => {
					const key = Object.keys(edu)[0];
					const index = key.slice(-1); // Extract the index from the key (e.g., 'membership0' -> '0')
					setValue(`awards${index}`, edu[key]);
				});
			}

			if (doctorRecord?.org_experience?.length) {
				doctorRecord.org_experience?.forEach((edu) => {
					const key = Object.keys(edu)[0];
					const index = key.slice(-1); // Extract the index from the key (e.g., 'membership0' -> '0')
					setValue(`org_experience${index}`, edu[key]);
				});
			}

			doctorRecord?.membership?.forEach((edu) => {
				const key = Object.keys(edu)[0];
				const index = key.slice(-1); // Extract the index from the key (e.g., 'membership0' -> '0')
				setValue(`membership${index}`, edu[key]);
			});

			doctorRecord?.practise?.forEach((edu) => {
				const key = Object.keys(edu)[0];
				const index = key.slice(-1); // Extract the index from the key (e.g., 'practise0' -> '0')
				setValue(`practise${index}`, edu[key]);
			});

			doctorRecord?.tag?.forEach((edu) => {
				const key = Object.keys(edu)[0];
				const index = key.slice(-1); // Extract the index from the key (e.g., 'tag0' -> '0')
				setValue(`tag${index}`, edu[key]);
			});
		}

	}, [doctorRecord])

	useEffect(() => {
		setValue("languages_known", doctorRecord.languages_known)
	}, [languageList])

	const handleNext = async () => {
		await trigger();
		if (isValid && (profileMode === "Online" ? about?.length : profileMode === "InClinic" ? incliniAbout?.length : (incliniAbout?.length && about?.length))) {
			setStep(3)
		}
	};
	const handleBack = () => {
		setStep(1)
	}

	return (
		<>
			<div className="addnew-form1-autor-detail mt-5 d-flex">
				<div className="col-md-6">
					<SelectField
						id="degree"
						labelName="UG Degree"
						name="degree"
						options={degree.map((data) => ({ value: data?._id, label: data?.ug_degree }))}
						register={register}
						errors={errors}
						errorText="Please select the degree"
						defaultOption="Degree"
						isOptionObject={true}
					/>
				</div>
				<div className="col-md-6">
					<InputTextField
						id="education"
						labelName="Education"
						name="education"
						isRequired={false}
						type="text"
						register={register}
						errors={errors}
						placeholder="Type college / university attended"
						errorText={"Please provide a Education"}
					/>
				</div>
			</div>
			<div className="addnew-form1-autor-detail mt-5 d-flex">
				<div className="col-md-6">
					<SelectField
						id="pgDegree"
						labelName="PG Degree"
						name="pg_degree"
						options={pgDegree.map((data) => ({ value: data?._id, label: data?.pg_degree }))}
						register={register}
						errors={errors}
						errorText="Please select the PG Degree"
						defaultOption="PG Degree"
						isOptionObject={true}
						isRequired={false}
					/>
				</div>
				<div className="col-md-6">
					<InputTextField
						id="education"
						labelName="Education"
						name="pg_education"
						type="text"
						register={register}
						errors={errors}
						placeholder="Type college / university attended"
						errorText={"Please provide a Education"}
						isRequired={false}
					/>
				</div>
			</div>
			<div className="addnew-form1-autor-detail mt-5 d-flex">
				<div className="col-md-6">
					<InputRadioDynamic
						register={register}
						errors={errors}
						errorMessage="Please choose the Gender"
						watch={watch}
						setValue={setValue}
						trigger={trigger}
						label="Gender"
						name="gender"
						isOptionObject={true}
						options={genderList}
					/>
				</div>
				<div className="col-md-6">
					<InputTextField
						labelName="MCI/SMC/DCI number"
						name="mci_no"
						register={register}
						errors={errors}
						errorText={"Please provide a MCI/SMC/DCI number"}
						placeholder="Enter MCI/SMC/DCI number"
					/>
				</div>
			</div>
			<div className="addnew-form1-autor-detail mt-5 d-flex">
				<div className="col-md-6">
					<SelectField
						id="medicalCouncilList"
						labelName="Select your Medical Council"
						name="medical_council_status"
						options={medicalCouncilList.map((data) => ({ value: data?._id, label: data?.medical_council }))}
						register={register}
						errors={errors}
						className="w-75"
						errorText="Please choose Medical Council"
						defaultOption=""
						isOptionObject={true}
					/>
				</div>
				<div className="col-md-6">
					<SelectField
						id="medical_council"
						labelName="Year of Registration with your Medical Council"
						name="medical_council"
						options={medicalCouncilYears}
						register={register}
						errors={errors}
						className="w-75"
						errorText="Please choose a Experience"
						defaultOption=""
					/>
				</div>
			</div>
			<div className="mt-5 d-flex">
				<div className="col-md-6">
					<MultipleAddAwards
						register={register}
						errors={errors}
						Awards={awards}
						setAwards={setAwards}
					/>
				</div>
				<div className="col-md-6">
					<MultipleAddInputs
						register={register}
						errors={errors}
						inputValues={experiences}
						setInputValues={setExperiences}
						name={"org_experience"}
						isRequired={false}
						labelName={"Experiences (Organisation Worked in) "}
					/>
				</div>
			</div>
			{(profileMode === "Online" || profileMode === "Both") ? <div className=" d-flex">
				<div className="addnew-form1-desc w-100">
					<label className="title mt-4">
						Online Doctor About <span className="star">*</span>
					</label>
					<JoditEditor
						value={`${about}`}
						onChange={(value) => {
							setAbout(value);
							setValue("about", value);
						}}
					/>
					{!about?.length && (
						<div className={`invalid-feedback d-block`}>
							Please provide a About
						</div>
					)}
				</div>
			</div> : null}
			{(profileMode === "InClinic" || profileMode === "Both") ? <div className=" d-flex">
				<div className="addnew-form1-desc w-100">
					<label className="title mt-4">
						Inclinic Doctor About <span className="star">*</span>
					</label>
					<JoditEditor
						value={`${incliniAbout}`}
						onChange={(value) => {
							setInclinicAbout(value);
							setValue("inclinic_about", value);
						}}
					/>
					{!incliniAbout?.length && (
						<div className={`invalid-feedback d-block`}>
							Please provide a Inclinic About
						</div>
					)}
				</div>
			</div> : null}
			<div className="mt-5 d-flex">
				<div className="col-md-6">
					<MultipleAddMembership
						register={register}
						errors={errors}
						Memberships={Memberships}
						setMemberships={setMemberships}
					/>
				</div>
				<div className="col-md-6">
					<label
						className="car-dep-form-txt mb-2"
						style={{ color: "#4B4853" }}
						htmlFor=""
					>
						Mobile Number{" "}
					</label>
					<div className="position-relative">
						<input
							className="form-control w-75 mobileInput"
							type="text"
							name=""
							maxLength={10}
							id="mobile"
							{...register("mobile", { required: true })}
							placeholder="Enter mobile number..."
							onKeyPress={(e) => {
								if (!/[0-9]/.test(e.key)) {
									e.preventDefault();
								}
							}}
						/>
						<span className="c-input-group-prefix">+91</span>

					</div>
					{errors.mobile && (
						<div className={`invalid-feedback d-block`}>
							Please provide a Mobile Number
						</div>
					)}
				</div>
			</div>
			<div className="mt-5 d-flex">
				<div className="mt-4 col-md-6 partneredDoctorLanuages">
					<LanguageField
						errors={errors}
						language={languageList}
						register={register}
						setValue={setValue}
						trigger={trigger}
						getValues={getValues}
						multiSelectWrapperClassName={"col-md-9"}
						multiSelectClassName={"form-control addnew-input !col-12"}
						selectedLang={selectedLang}
						selectedDataIdList={doctorRecord?.languages_known?.map((lan) => lan._id)}
					/>
				</div>
				<div className="col-md-6">
					<InputTextField
						id="fellowship"
						labelName="Fellowship"
						name="fellowship"
						type="text"
						className="w-50"
						register={register}
						errors={errors}
						isRequired={false}
						errorText={"Please provide a Fellowship"}
					/>
				</div>
			</div>
			<div className="mt-5 d-flex">
				<div className="col-md-6">
					<InputTextField
						id="other_certified_courses"
						labelName="Other Certified Courses "
						name="other_certified_courses"
						type="text"
						className="w-50"
						register={register}
						errors={errors}
						isRequired={false}
						errorText={"Please provide a Other Certified Courses "}
					/>
				</div>
				{/* <div className="col-md-6">
					<label
						className="car-dep-form-txt mb-2"
						style={{ color: "#4B4853" }}
						htmlFor=""
					>
						Registration Date{" "} <span className="star">*</span>
					</label>
					<div className="position-relative">

						<input
							{...register("registration_date", { required: true })}
							type="date"
							className={`form-control w-75 ${getValues()?.registration_date ? "hasDate" : ""}`}
							placeholder="Choose Date"
							min={new Date().toISOString().split("T")[0]}
						/>

					</div>
					{errors.registration_date && (
						<div className={`invalid-feedback d-block`}>
							Please provide a Mobile Number
						</div>
					)}
				</div> */}
				<div className="col-md-6">
					<MultipleAddTag
						register={register}
						errors={errors}
						tags={tags}
						setTags={setTags}
					/>
				</div>
			</div>
			<NextBackBtns
				handleNext={handleNext}
				handleBack={handleBack}
			/>
		</>
	)
}

const StepThree = ({
	register,
	errors,
	watch,
	setValue,
	trigger,
	isValid,
	getValues,
	setStep,
	profileImage,
	setprofileImage,
	cityList,
	citySelected,
	selectedlocation
}) => {
	const selectedCity = watch('city');
	const [localitiesList, setLocalitiesList] = useState([]);
	const profileMode = getValues()?.profile_mode;

	const getLocalityList = async () => {
		const result = await apiCall(`locality/list/${selectedCity}`, "GET");
		if (result?.data) {
			setLocalitiesList(result.data.map((ele) => ({ label: ele.locality, value: ele._id })))
			if (!result.data.map((ele) => ele._id).includes(getValues()?.clinic_location)) {
				setValue("clinic_location", "")
			}
		}
	}

	useEffect(() => {
		if (cityList?.length && getValues()?.city) {
			getLocalityList()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cityList, selectedCity])

	useEffect(() => {
		if (getValues()?.city) {
			setValue("city", getValues()?.city);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cityList])

	useEffect(() => {
		if (getValues()?.clinic_location) {
			setValue("clinic_location", getValues()?.clinic_location)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localitiesList])

	const removeImage = async () => {
		// setIsUpload(false);
		setprofileImage("");
		setValue(profileImage, "")

	}

	const handleNext = async () => {
		await trigger();

		// const fields = {fields}
		if (isValid) {
			setStep(4)
		}
	};

	const handleBack = () => {
		setStep(2)
	}

	const handleInputChange = (e) => {
		setprofileImage(e.target.files[0]);
	};
	const getsrcValue = (value) => {
		if (typeof value === "string" && value.length)
			return process.env.REACT_APP_API_URL + "profile-images/" + value;
		return typeof value === "object" ? URL.createObjectURL(value) : " ";
	};

	return (

		<>
			{
				profileMode !== "Online" ? <>
					<div className="mt-5 d-flex">
						<div className="col-md-6 pe-3">
							<SelectField
								id="clinicProfileState"
								labelName="State"
								name="state"
								options={states}
								register={register}
								errors={errors}
								errorText="Please choose a State"
								defaultOption="State"
							/>
						</div>
						<div className="col-md-6 ps-3">
							<SelectField
								id="clinicCity"
								labelName="City / Town "
								name="city"
								isOptionObject={true}
								options={cityList}
								register={register}
								errors={errors}
								errorText="Please choose a City"
								defaultOption="City"
								selectedDataId={citySelected}
							/>
						</div>
					</div>
					<div className="addnew-form1-autor-detail mt-5 d-flex">
						<div className="col-md-6 pe-3">
							<SelectField
								id="clinicLocality"
								labelName="Clinic Location "
								name="clinic_location"
								isOptionObject={true}
								options={localitiesList}
								register={register}
								errors={errors}
								errorText="Please choose a Locality"
								defaultOption="Locality"
								isRequired={false}
							/>
						</div>
						<div className="col-md-6 ps-3">
							<label className="form-label title">
								Pincode <span className="star">*</span>
							</label>
							<input
								className="form-control w-75"
								type="text"
								{...register("pincode", {
									required: true,
									min: 110000,
									pattern: /[0-9]{6}/,
								})}
								placeholder="Enter pincode"
								maxLength="6"
								onKeyPress={(e) => {
									if (!/[0-9]/.test(e.key)) {
										e.preventDefault();
									}
								}}
							/>
							{errors.pincode && (
								<div className={`invalid-feedback d-block`}>
									Please provide a Pincode
								</div>
							)}
						</div>
					</div></> : null}
			<div className="mt-5 d-flex">
				<div className="col-md-6 pe-3">
					<label htmlFor="inputAddress" className="form-label title">
						Email <span className="star">*</span>
					</label>
					<input
						type="text"
						{...register("email", { required: true })}
						className="form-control w-75"
						// id="authordetails"
						placeholder="Enter email id"
						style={{ paddingLeft: "10px" }}
					/>
					{errors.email && (
						<div className={`invalid-feedback d-block`}>
							Please provide a Email
						</div>
					)}
				</div>
				<div className="col-md-6 ps-3">
					<SelectField
						id="plusMembership"
						labelName="Plus Membership"
						name="plus_membership"
						options={["Included", "Not Included"]}
						register={register}
						errors={errors}
						// errorText="Please choose a Signature Type"
						defaultOption=""
						isRequired={false}
					/>
				</div>
				{/* <div className="col-md-6 ps-3">
					<label
						className="car-dep-form-txt mb-2"
						style={{ color: "#4B4853" }}
						htmlFor=""
					>
						Registration Date{" "} <span className="star">*</span>
					</label>
					<div className="position-relative">
						<input
							{...register("lou_signed_date", { required: true })}
							type="date"
							className={`form-control ${getValues()?.registration_date ? "hasDate" : ""}`}
							placeholder="Choose Date"
						// min={new Date().toISOString().split("T")[0]}
						// onChange={(e) => {
						//     setValue("registration_date", e.target.value, { shouldValidate: true });
						// }}
						/>
					</div>
					{errors.lou_signed_date && (
						<div className={`invalid-feedback d-block`}>
							Please choose the LOU Signed Date
						</div>
					)}
				</div> */}

			</div>
			{/* <div className="mt-5 d-flex">
				<div className="col-md-6 pe-3">
					<SelectField
						id="signatureType"
						labelName="Signature Type"
						name="signature_type"
						options={["Signdesk ", "Go-Live"]}
						register={register}
						errors={errors}
						errorText="Please choose a Signature Type"
						defaultOption=""
					/>
				</div>
				<div className="col-md-6 ps-3">
					<InputTextField
						labelName="Bank Account Details"
						name="bank_account_details"
						className="w-100"
						register={register}
						errors={errors}
						errorText={"Please provide a Bank Account Details"}
						placeholder="Enter Bank Account"
					/>
				</div>

			</div> */}
			{/* <div className="mt-5 d-flex">
				<div className="col-md-6 pe-3">
					<InputTextField
						labelName="Partner Id"
						name="partner_id"
						className="w-100"
						register={register}
						isRequired={false}
						errors={errors}
						// errorText={"Please provide a Bank Account Details"}
						placeholder="Enter Partner ID"
					/>
				</div>
				<div className="col-md-6 ps-3">
					<label
						className="car-dep-form-txt mb-2"
						style={{ color: "#4B4853" }}
						htmlFor=""
					>
						Date of Profile Activated{" "}
					</label>
					<div className="position-relative">
						<input
							{...register("profile_activated_date", { required: false })}
							type="date"
							className={`form-control ${getValues()?.registration_date ? "hasDate" : ""}`}
							placeholder="Choose Date"
						/>
					</div>
				</div>
			</div> */}
			{/* <div className="mt-5 d-flex">
				<div className="col-md-6 pe-3">
					<SelectField
						id="appInstallationStatus"
						labelName="App Installation Status"
						name="app_installation_status"
						options={["Installed", "Yet to Install"]}
						register={register}
						errors={errors}
						// errorText="Please choose a Signature Type"
						defaultOption=""
						isRequired={false}
					/>
				</div>
					{profileMode !== "InClinic" ? <div className="col-md-6 ps-3">
					<SelectField
						id="groundBreakingStatus"
						labelName="Ground Breaking Status"
						name="ground_breaking_status"
						options={["Yet to Ground-break", "Ground-break Completed"]}
						register={register}
						errors={errors}
						// errorText="Please choose a Signature Type"
						defaultOption=""
						isRequired={false}

					/>
				</div> : null}
			</div> */}
			{/* <div className="mt-5 d-flex">
				
			</div> */}
			<div className="mt-5 d-flex">
				<div className="authorUpload ms-0">
					<div className="title fo-tit-top">
						Upload Profile Image{" "}
						<span className="star">*</span>
					</div>
					{profileImage ? (
						<div> <img
							src={getsrcValue(profileImage)}
							style={{
								width: "auto",
								height: "200px",
								marginTop: "2%",
								marginBottom: "5%",
							}}
							alt="doctor"
						/></div>
					) : null}
					{(profileImage === "" || typeof (profileImage) !== 'string') ? <input
						className="form-control-file custom-file-input"
						type="file"
						// {...(profileImage ? {} : register("profileImage", { required: true, onChange: handleInputChange }))}
						{...register("profileImage", { required: true })}
						// id={key}
						// name={key}
						style={{
							width: "120px",
						}}
						onChange={handleInputChange}
					// required={false}
					/> : null}
					{profileImage &&
						<button className="btn btn-danger ms-2"
							onClick={() => removeImage()}
						>Remove</button>}
				</div>
				{errors?.profileImage && (
					<div className={`invalid-feedback d-block`}>
						Please provide a Profile Image
					</div>
				)}
			</div>
			<NextBackBtns
				handleNext={handleNext}
				handleBack={handleBack}
			/>
		</>
	)

}

const StepFour = ({
	faqs,
	setFaqs,
	inClinicFaqs,
	setInClinicFaqs,
	getValues,
	setStep,

}) => {
	const profileMode = getValues()?.profile_mode;
	const [isOnlineTab, setIsOnlineTab] = useState(profileMode !== "InClinic");

	const handleBack = () => {
		setStep(3)
	}

	return (
		<>
			{profileMode === "Both" ? <div className="careers-tab" style={{ marginLeft: "2%" }}>
				{["Online Doctor FAQ", "InClinic Doctor FAQ"].map((value, inx) => (
					<div
						key={inx}
						className={`${(("Online Doctor FAQ" === value && isOnlineTab) || ("Online Doctor FAQ" !== value && !isOnlineTab)) ? "career-language" : ""} ${inx === 0 ? "" : "ms-5"} career-tab-jobpost  me-4`}
						onClick={() => setIsOnlineTab(value === "Online Doctor FAQ")}
					>{value}
					</div>
				))}
			</div> : null}
			<FAQContent
				setFaqs={isOnlineTab ? setFaqs : setInClinicFaqs}
				faqs={isOnlineTab ? faqs : inClinicFaqs}
			/>

			<div className="backSubmitBtns">
				<NextBackBtns
					next={false}
					handleBack={handleBack}
				/>
				<div className="nextBtnStyle mt-5">
					<div>
						<button type="submit">Submit</button>
					</div>
					{/* <div>
							<img src={next} alt="" />
						</div> */}
				</div>
			</div>
		</>
	)
}

const Level = ({
	page,
}) => {
	return (
		<>
			<Breadcrumbs
				breadcrumbText="Doctor"
				breadcrumbLink=""
				nestedText="Add Doctors"
			/>
			{
				<div className={page === 1 ? "doctor-page-tick" : page === 2 ? "doctor-page2-tick" : page === 3 ? "doctor-page3-tick" : page === 4 ? "doctor-page4-tick" : ""}>
					<div>
						<img src={greentick} alt="" />
						<div>Page 1</div>
					</div>
					<div>
						<img src={page === 1 ? graytick : greentick} alt="" />
						<div>Page 2</div>
					</div>
					<div>
						<img src={(page === 3 || page === 4) ? greentick : graytick} alt="" />
						<div>Page 3</div>
					</div>
					<div>
						<img src={page === 4 ? greentick : graytick} alt="" />
						<div>Page 4</div>
					</div>
				</div>
			}
		</>
	)
}

const EditPartneredDoctorForm = () => {

	const { id } = useParams()

	const [step, setStep] = useState(1);
	const [success, setSuccess] = useState(false);

	const [profileImage, setprofileImage] = useState("")
	const [faqs, setFaqs] = useState([]);
	const [inClinicFaqs, setInClinicFaqs] = useState([]);
	const [doctorRecord, setDoctorRecord] = useState([]);
	const [clinicProfiles, setClinicProfiles] = useState([])
	const [cityList, setCityList] = useState([]);
	const [genderList, setGenderList] = useState([]);
	const [languageList, setLanguage] = useState([]);
	const [degree, setDegree] = useState([]);
	const [pgDegree, setPGDegree] = useState([]);
	const [medicalCouncilList, setMedicalCouncilList] = useState([]);
	const [docSpecialization, setDocSpecialization] = useState([]);
	const [incSpecialization, setIncSpecialization] = useState([]);
	const [about, setAbout] = useState("");
	const [incliniAbout, setInclinicAbout] = useState("");

	useEffect(() => {
		list_api_call();
		getClinicProfiles();
		getCitiesList();
		getGenderList()
		getLanguageList()
		getDegree();
		getPGDegree();
		getMedicalCouncilList();
		getSpecialization();
		getInclinicSpecialization();
	}, []);

	const getSpecialization = async () => {
		try {
			const result = await apiCall(`specialization/list`, "POST", { active: true });
			if (result.data?.rows) {
				setDocSpecialization(result.data?.rows?.map((data) => ({ value: data?._id, label: data?.online_doc_specialization })));
			}
		} catch (err) {
			console.error(err);
		}
	}

	const getInclinicSpecialization = async () => {
		try {
			const result = await apiCall(`inclinic-specialization/list`, "POST", { active: true });
			if (result.data?.rows) {
				setIncSpecialization(result.data?.rows?.map((data) => ({ value: data?._id, label: data?.inc_specialization })));
			}
		} catch (err) {
			console.error(err);
		}
	}

	const getCitiesList = async () => {
		try {
			const result = await apiCall(`doctor-cities/city-list`, "POST");
			if (result.data) {
				setCityList(result.data?.map((data) => ({ value: data._id, label: data?.city, active: data?.active })))
			}
		} catch (err) {
			console.error(err);
		}
	}

	const getDegree = () => {
		axios
			.post(`${process.env.REACT_APP_API_URL}ug-degree/list`)
			.then((res) => {
				setDegree(res.data?.rows);
			});
	};

	const getPGDegree = () => {
		axios.post(`${process.env.REACT_APP_API_URL}pg-degree/list`)
			.then((res) => {
				setPGDegree(res.data.rows);
			});
	};

	const getGenderList = () => {
		axios
			.post(`${process.env.REACT_APP_API_URL}doctors-gender/list`, {
				active: true
			})
			.then((response) => {
				setGenderList(response.data?.rows?.map(data => ({ id: data._id, name: data?.type })));
			})
			.catch((err) => {
				console.error(err);
				alert("Some error");
			});
	};


	const getLanguageList = () => {
		axios
			.post(`${process.env.REACT_APP_API_URL}doctors-languages/list`,)
			.then((response) => {
				setLanguage(response.data?.rows);
			})
			.catch((err) => {
				console.error(err);
				alert("Some error");
			});
	};

	const getClinicProfiles = () => {
		axios
			.post(`${process.env.REACT_APP_API_URL}doctors-profile/list`, {
				noLimit: true,
			})
			.then((res) => {
				if (res.data?.rows?.length) {
					// todo optimize query
					setClinicProfiles(res.data.rows.map((data) => ({ value: data?.clinic_name, label: data?.clinic_name, id: data?._id, logo: data?.logo, address: data?.address })));
				}
			});
	}

	const getMedicalCouncilList = () => {
		axios
			.post(`${process.env.REACT_APP_API_URL}doctors-medical-council/list`, {
				noLimit: true,
				active: true
			})
			.then((res) => {
				setMedicalCouncilList(res.data.rows);
			});
	};
	const list_api_call = () => {
		axios
			.get(`${process.env.REACT_APP_API_URL}partnered-doctor/get/${id}`, {
				profile_mode: "Online",
			})
			.then((response) => {
				setDoctorRecord(response?.data);
				setMemberships(response?.data?.membership)
				if (response?.data?.tag) {
					setTags(response?.data?.tag)
				}
				setPractise(response?.data?.practise)
				if (response?.data?.awards?.length) {
					setAwards(response?.data?.awards)
				}
				if (response?.data?.org_experience?.length) {
					setExperiences(response?.data?.org_experience);
				}
				setValue("doctor_name", response?.data?.doctor_name);
				setValue("profile_mode", response?.data?.profile_mode);
				setValue("verified", response?.data?.verified ? "Yes" : "No")
				setValue("online_consultation_fee", response?.data?.online_consultation_fee)
				if (response?.data?.inclinic_consultation_fee) {
					setValue("inclinic_consultation_fee", response?.data?.inclinic_consultation_fee)
				}
				if (response?.data?.online_health_concern) {
					setValue("online_health_concern", response?.data?.online_health_concern?.map((hc) => ({ value: hc?.health_concern, label: hc?.health_concern, id: hc._id })))
				}
				if (response?.data?.inclinic_health_concern) {
					setValue("inclinic_health_concern", response?.data?.inclinic_health_concern?.map((hc) => ({ value: hc?.health_concern, label: hc?.health_concern, id: hc._id })))
				}
				setValue("gender", response?.data?.gender)
				setValue("doctor_primary_specialization", response?.data?.doctor_primary_specialization)
				setValue("inclinic_primary_specialization", response?.data?.inclinic_primary_specialization)
				setValue("mci_no", response?.data?.mci_no)
				setValue("medical_council_status", response?.data?.medical_council_status)
				setValue("mobile", response?.data?.mobile)
				setValue("languages_known", response?.data?.languages_known)
				// // setValue("registration_date", response?.data?.registration_date)
				setValue("city", response?.data?.city)
				setValue("state", response?.data?.state)
				setValue("clinic_location", response?.data?.clinic_location)
				setValue("pincode", response?.data?.pincode)
				setValue("email", response?.data?.email)
				// setValue("lou_signed_date", response?.data?.lou_signed_date)
				// setValue("signature_type", response?.data?.signature_type)
				// setValue("bank_account_details", response?.data?.bank_account_details)
				// setValue("partner_id", response?.data?.partner_id)
				// // setValue("profile_activated_date", response?.data?.profile_activated_date)
				// // setValue("app_installation_status", response?.data?.app_installation_status)
				// // setValue("ground_breaking_status", response?.data?.ground_breaking_status)
				setValue("plus_membership", response?.data?.plus_membership)
				setValue("profileImage", response?.data?.profileImage)
				setValue("degree", response?.data?.degree);
				setValue("pg_degree", response?.data?.pg_degree);
				// if (response?.data?.pg_degree) {
				// 	setPGEnable(true)
				// }
				setValue("education", response?.data?.education);
				setValue("pg_education", response?.data?.pg_education);
				setprofileImage(response?.data?.profileImage)
				setValue("medical_council_status", response?.data?.medical_council_status)
				setFaqs(response?.data?.faq ?? []);
				setInClinicFaqs(response?.data?.inclinic_faq ?? []);
				setValue("medical_council", response?.data?.medical_council);
				setValue("online_specialization", response?.data?.online_specialization);
				setValue("inclinic_specialization", response?.data?.inclinic_specialization);
				setValue("fellowship", response?.data?.fellowship);
				setValue("other_certified_courses", response?.data?.other_certified_courses);
				if (response?.data?.clinic_profiles?.length) {
					setValue("clinic_profiles", response?.data?.clinic_profiles.map((data) => ({ value: data?.clinic_name, label: data?.clinic_name, id: data?._id, logo: data?.logo, address: data?.address })));
				}
				if (response?.data?.about) {
					setValue("about", response?.data?.about)
					setAbout(response?.data?.about)
				}

				if (response?.data?.inclinic_about) {
					setValue("inclinic_about", response?.data?.inclinic_about)
					setInclinicAbout(response?.data?.inclinic_about)
				}
			})

			.catch((err) => {
				console.error(err);
				alert("Some error");
			});
	};

	const {
		register,
		formState: { errors, isValid },
		watch,
		setValue,
		control,
		getValues,
		trigger,
		clearErrors,
		handleSubmit,
	} = useForm({ mode: "onChange" });

	const [practices, setPractise] = useState([
		{
			[`practise${0}`]: "",
			id: 0,
		},
	]);

	const [tags, setTags] = useState([
		{
			[`tag${0}`]: "",
			id: 0,
		},
	]);
	const [Memberships, setMemberships] = useState([
		{
			[`membership${0}`]: "",
			id: 0,
		},
	]);

	const [awards, setAwards] = useState([
		{
			[`awards${0}`]: "",
			id: 0,
		},
	]);

	const [experiences, setExperiences] = useState([
		{
			[`org_experience${0}`]: "",
			id: 0,
		},
	]);

	const [doctorSpecialization, setDoctorSpecialization] = useState([])
	const [inClinicSpecialization, setInClinicSpecialization] = useState([]);

	useEffect(() => {
		getDoctorSpecialisation()
		getInClinicSpecialisation()
	}, [])

	const getDoctorSpecialisation = async () => {
		try {
			const response = await apiCall(`doctor-specialisation/list`, "POST");
			if (response.data?.rows) {
				setDoctorSpecialization(response.data?.rows?.map((spl) => ({ value: spl._id, label: spl?.online_doctor_specialization, active: spl.active })))
			}
		} catch (err) {
			console.error(err);
		}
	}
	const getInClinicSpecialisation = async () => {
		try {
			const response = await apiCall(`inclinc-doctor-specialization/list`, "POST");
			if (response.data?.rows) {
				setInClinicSpecialization(response.data?.rows?.map((spl) => ({ value: spl._id, label: spl?.inclinic_doctor_specialization, active: spl.active })))
			}
		} catch (err) {
			console.error(err);
		}
	}

	const onSubmit = (fields) => {
		const tempFields = {};
		const fieldKeys = Object.keys(fields);
		["practise", "membership", "tag", "org_experience", "awards"].forEach((value) => {
			fieldKeys.forEach((key) => {
				if (!key.includes(value)) {
					tempFields[key] = fields[key];
				}
			});
			// tempFields[value] = fieldKeys.map(key => {
			// 	if (!key.includes(value)) {
			// 		return
			// 	}
			// })

		})

		const languages_known = fields?.languages_known?.reduce((acc, data) => {
			if (data) {
				if (typeof (data?._id) === 'string') {
					acc.push(data?._id)
				}
			}
			return acc
		}, [])

		const profileImage = fields.profileImage && (typeof (fields.profileImage) === 'string' ? fields.profileImage : fields.profileImage[0]);
		const healthConcerns = fields?.online_health_concern?.map((option) => option.id) ?? [];
		const inclinicHealthConcerns = fields?.inclinic_health_concern?.map((option) => option.id) ?? [];
		if (!fields?.pg_degree) {
			delete fields.pg_degree;
		}
		if (!fields?.doctor_primary_specialization) {
			delete fields.doctor_primary_specialization;
		}
		if (!fields?.inclinic_primary_specialization) {
			delete fields?.inclinic_primary_specialization;
		}

		if (!fields?.clinic_location) {
			delete fields.clinic_location;
		}
		if (!fields?.online_specialization) {
			delete fields.online_specialization;
		}
		if (!fields?.inclinic_specialization) {
			delete fields?.inclinic_specialization;
		}

		const updatedFields = {
			...fields,
			awards: awards,
			org_experience: experiences,
			faq: faqs,
			inclinic_faq: inClinicFaqs,
			practise: practices,
			membership: Memberships,
			tag: tags,
			profileImage: profileImage,
			verified: fields?.verified === "Yes",
			languages_known,
			online_health_concern: healthConcerns,
			inclinic_health_concern: inclinicHealthConcerns,
			clinicProfiles: fields?.clinicProfiles?.map(data => data?.id)
		}

		axios
			.postForm(`${process.env.REACT_APP_API_URL}partnered-doctor/update/${id}`, updatedFields)
			.then((response) => {
				if (response.data?._id) {
					setSuccess(true);
				} else {
					alert("Fail");
					console.log("failed...");
				}
			})
			.catch((err) => {
				console.error(err);
				alert("Some error");
			});
	}

	return (
		<>
			<BackBtn
				navigatePath="/partnered-doctor"
			/>
			<div className="healtharticle mt-5 pt-4 ps-3 pb-5 ps-4">
				<div>
					<Level
						page={step}
					/>

					<form className="career-jobpost-form clinicProfile mt-2 ms-2" onSubmit={handleSubmit(onSubmit)}>
						{step === 1 ? (
							<StepOne
								register={register}
								errors={errors}
								watch={watch}
								setValue={setValue}
								trigger={trigger}
								step={step}
								getValues={getValues}
								isValid={isValid}
								setStep={setStep}
								control={control}
								doctorRecord={doctorRecord}
								clearErrors={clearErrors}
								clinicProfiles={clinicProfiles}
								doctorSpecialization={doctorSpecialization}
								inClinicSpecialization={inClinicSpecialization}
								docSpecialization={docSpecialization}
								incSpecialization={incSpecialization}
							/>
						) : ""}

						{step === 2 ? (
							<StepTwo
								register={register}
								errors={errors}
								watch={watch}
								setValue={setValue}
								trigger={trigger}
								page={step}
								getValues={getValues}
								isValid={isValid}
								setStep={setStep}
								genderList={genderList}
								languageList={languageList}
								Memberships={Memberships}
								setMemberships={setMemberships}
								tags={tags}
								setTags={setTags}
								practices={practices}
								setPractise={setPractise}
								doctorRecord={doctorRecord}
								degree={degree}
								pgDegree={pgDegree}
								medicalCouncilList={medicalCouncilList}
								awards={awards}
								setAwards={setAwards}
								experiences={experiences}
								setExperiences={setExperiences}
								about={about}
								setAbout={setAbout}
								incliniAbout={incliniAbout}
								setInclinicAbout={setInclinicAbout}
							/>
						) : ""}

						{step === 3 ? (
							<StepThree
								register={register}
								errors={errors}
								watch={watch}
								setValue={setValue}
								trigger={trigger}
								isValid={isValid}
								getValues={getValues}
								setStep={setStep}
								profileImage={profileImage}
								setprofileImage={setprofileImage}
								cityList={cityList}
								citySelected={doctorRecord?.city}
								selectedlocation={doctorRecord?.clinic_location}
								page={step}
							/>
						) : ""}

						{step === 4 ? (
							<StepFour
								faqs={faqs}
								setFaqs={setFaqs}
								setStep={setStep}
								getValues={getValues}
								inClinicFaqs={inClinicFaqs}
								setInClinicFaqs={setInClinicFaqs}
							/>
						) : ""}
					</form>
				</div>
			</div>
			{
				success && (
					<SucessModal
						page={"/partnered-doctor"}
						name="Doctor Profile"
						post='Updated'
					/>
				)
			}
		</>
	)
}

export default EditPartneredDoctorForm