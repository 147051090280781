import React from "react";
import { Link, useNavigate } from "react-router-dom";
import backRed from "../../../assets/svg/back-rose-icon.svg";

const ViewNewPharmacyForm = () => {
  const navigate = useNavigate();

  return (
    <>
      <div
        onClick={() => navigate("/add-new-retail-pharmacy")}
        className="rose back-button"
      >
        <img src={backRed} alt="" />
        <span className="rose-back">Back</span>
      </div>
      <div className="healtharticle-title ms-0">
        <Link
          to="/dashboard"
          style={{
            textDecoration: "none",
            color: "black",
            fontSize: "15px",
          }}
        >
          <span style={{ paddingLeft: "5%" }}>Dashboard</span>
        </Link>
        <span style={{ paddingLeft: "1%" }}>{`>`}</span>
        <span style={{ paddingLeft: "0.4%" }}>
          <Link
            to="/add-new-retail-pharmacy"
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: "15px",
            }}
          >
            Partnered Retail Pharmacies
          </Link>
        </span>
        <span style={{ paddingLeft: "0.4%" }}>{`>`}</span>
        <span style={{ paddingLeft: "0.4%" }}>View Partnered Pharmacy</span>
      </div>

      <div>

        <div className="doctor-track-header common-view-header-portion">
          <div>
            <img src="" alt="" />
          </div>
          <div>
            <div>Nalam Pharmacy</div>
            {/* <div>Nursing Support Service</div> */}
          </div>
          <div><span style={{ fontWeight: "700", cursor: "pointer" }}>Edit</span></div>
        </div>

        {/* One */}
        <div className="addnew-form1-autor-detail mt-3 mb-5 d-flex">
          <div className="menu-field col-md-4 me-5">
            <p className="form-label " htmlFor="">
              Name of Pharmacy / Chemist Store
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>

          <div className="menu-field col-md-3 me-5">
            <p className="form-label " htmlFor="">
              Pharmacy Address
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>

        </div>

        {/* Two */}
        <div className="addnew-form1-autor-detail mt-3 mb-5 d-flex">

          <div className="menu-field col-md-2 me-5">
            <p className="form-label " htmlFor="">
              Locality
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>

          <div className="menu-field col-md-2 me-5">
            <p className="form-label " htmlFor="">
              City / Town
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>

          <div className="menu-field col-md-2 me-5">
            <p className="form-label " htmlFor="">
              State
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>

          <div className="menu-field col-md-3 me-5">
            <p className="form-label " htmlFor="">
              Pincode of Pharmacy Location
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>

        </div>

        {/* Three */}
        <div className="addnew-form1-autor-detail mt-3 mb-5 d-flex">

          <div className="menu-field col-md-3 me-5">
            <p className="form-label " htmlFor="">
              Mobile Number( For ALL Communication)
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>

          <div className="menu-field col-md-3 me-5">
            <p className="form-label " htmlFor="">
              Email ID (For all Communnication)
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>

          <div className="menu-field col-md-3 me-5">
            <p className="form-label " htmlFor="">
              Telephone Number (opt)
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>

        </div>

        {/* Four */}
        <div className="addnew-form1-autor-detail mt-3 mb-5 d-flex">
          <div className="menu-field col-md-3 me-5">
            <p className="form-label " htmlFor="">
              Pharmacist Name
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>

          <div className="menu-field col-md-3 me-5">
            <p className="form-label " htmlFor="">
              Pharmacist License No
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>

          <div className="menu-field col-md-3 me-5">
            <p className="form-label " htmlFor="">
              Pharmacist Mobile No
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>
        </div>

        {/* Five */}
        <div className="addnew-form1-autor-detail mt-3 mb-5 d-flex">
          <div className="menu-field col-md-3 me-5">
            <p className="form-label " htmlFor="">
              Pharmacy GST Number
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>

          <div className="menu-field col-md-3 me-5">
            <p className="form-label " htmlFor="">
              Pharmacy License Number
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>

          <div className="menu-field col-md-4">
            <p className="form-label " htmlFor="">
              Upload Image GST Document
            </p>
            <div>
              <img
                src=""
                alt=""
                style={{
                  borderRadius: "8px",
                  width: "99px",
                  height: "64px",
                  border: "1px solid gray",
                }}
              />
            </div>
          </div>

        </div>

        {/* Six */}
        <div className="addnew-form1-autor-detail mt-3 mb-5 d-flex">

          <div className="menu-field col-md-3 me-5">
            <p className="form-label " htmlFor="">
              FSSAI License Number
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>

          <div className="menu-field col-md-3">
            <p className="form-label " htmlFor="">
              Upload Image of License No
            </p>
            <div>
              <img
                src=""
                alt=""
                style={{
                  borderRadius: "8px",
                  width: "99px",
                  height: "64px",
                  border: "1px solid gray",
                }}
              />
            </div>
          </div>

          <div className="menu-field col-md-3">
            <p className="form-label " htmlFor="">
              Upload Image of FSSAI License
            </p>
            <div>
              <img
                src=""
                alt=""
                style={{
                  borderRadius: "8px",
                  width: "99px",
                  height: "64px",
                  border: "1px solid gray",
                }}
              />
            </div>
          </div>

        </div>

        {/* Seven */}
        <div className="addnew-form1-autor-detail mt-3 mb-5 d-flex">

          <div className="menu-field col-md-3 me-5">
            <p className="form-label " htmlFor="">
              PAN Details
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>

          <div className="menu-field col-md-3">
            <p className="form-label " htmlFor="">
              Upload Pan Card Image
            </p>
            <div>
              <img
                src=""
                alt=""
                style={{
                  borderRadius: "8px",
                  width: "99px",
                  height: "64px",
                  border: "1px solid gray",
                }}
              />
            </div>
          </div>

          <div className="menu-field col-md-4">
            <p className="form-label " htmlFor="">
              Upload Store Images (Indoor & Outdoor)
            </p>
            <div>
              <img
                src=""
                alt=""
                style={{
                  borderRadius: "8px",
                  width: "99px",
                  height: "64px",
                  border: "1px solid gray",
                }}
              />
            </div>
          </div>

        </div>

        {/* Eight */}
        <div className="addnew-form1-autor-detail mt-3 mb-5 d-flex">

          <div className="col-md-4">
            <label htmlFor="" className="mb-3">
              ERP Software Available <span className="text-danger"></span>
            </label>
            <div className="d-flex ">
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  name="erpsoftware"
                  id="status"
                  checked
                />
                <label className="" htmlFor="inline1">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  name="erpsoftware"
                  id="status"
                />
                <label className="" htmlFor="inline2">
                  No
                </label>
              </div>
            </div>
          </div>

          <div className="ms-0 col-md-4">
            <label htmlFor="" className="mb-3">
              Internet Available <span className="text-danger"></span>
            </label>
            <div className="d-flex ">
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  name="internetavailable"
                  id="status"
                  checked
                />
                <label className="" htmlFor="inline1">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  name="internetavailable"
                  id="status"
                />
                <label className="" htmlFor="inline2">
                  No
                </label>
              </div>
            </div>
          </div>

          <div className="ms-0 col-md-4">
            <label htmlFor="" className="mb-3">
              Smartphone / Desktop / Laptop Available <span className="text-danger"></span>
            </label>
            <div className="d-flex ">
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  name="smartphone"
                  id="status"
                  checked
                />
                <label className="" htmlFor="inline1">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  name="smartphone"
                  id="status"
                />
                <label className="" htmlFor="inline2">
                  No
                </label>
              </div>
            </div>
          </div>

        </div>

        {/* Nine */}
        <div className="addnew-form1-autor-detail mt-3 mb-5 d-flex">

          <div className="col-md-3">
            <label htmlFor="" className="mb-3">
              Do you have Delivery Facility <span className="text-danger"></span>
            </label>
            <div className="d-flex ">
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  name="deliveryfacility"
                  id="status"
                  checked
                />
                <label className="" htmlFor="inline1">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  name="deliveryfacility"
                  id="status"
                />
                <label className="" htmlFor="inline2">
                  No
                </label>
              </div>
            </div>
          </div>

          <div className="menu-field col-md-2 me-5">
            <p className="form-label " htmlFor="">
              Medicine %
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>

          <div className="menu-field col-md-2 me-5">
            <p className="form-label " htmlFor="">
              OTC's %
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>

          <div className="menu-field col-md-2 me-5">
            <p className="form-label " htmlFor="">
              Wellness & Healthcare
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>

        </div>

        {/* Ten */}
        <div className="addnew-form1-autor-detail mt-3 mb-5 d-flex">

          <div className="menu-field col-md-2 me-5">
            <p className="form-label " htmlFor="">
              Business Start Day
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>

          <div className="menu-field col-md-2 me-5">
            <p className="form-label " htmlFor="">
              Working Days
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>

          <div className="menu-field col-md-2 me-5">
            <p className="form-label " htmlFor="">
              Working Hours
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>

        </div>

        {/* Eleven */}
        <div className="addnew-form1-autor-detail mt-3 mb-5 d-flex">

          <div className="menu-field col-md-3 me-5">
            <p className="form-label " htmlFor="">
              Total Inventory Value (INR) App
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>

          <div className="menu-field col-md-3 me-5">
            <p className="form-label " htmlFor="">
              Total Medicine (Rx) in Stock
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>

          <div className="menu-field col-md-6">
            <p className="form-label " htmlFor="">
              Medicines Inventory
            </p>
            <div>
              <img
                src=""
                alt=""
                style={{
                  borderRadius: "8px",
                  width: "99px",
                  height: "64px",
                  border: "1px solid gray",
                }}
              />
            </div>
          </div>

        </div>

        {/* Tweleve */}
        <div className="addnew-form1-autor-detail mt-3 mb-5 d-flex">

          <div className="menu-field col-md-2 me-5">
            <p className="form-label " htmlFor="">
              Total OTC's in Stock
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>

          <div className="menu-field col-md-3">
            <p className="form-label " htmlFor="">
              OTCs Inventory
            </p>
            <div>
              <img
                src=""
                alt=""
                style={{
                  borderRadius: "8px",
                  width: "99px",
                  height: "64px",
                  border: "1px solid gray",
                }}
              />
            </div>
          </div>

          <div className="menu-field col-md-2 me-5">
            <p className="form-label " htmlFor="">
              Wellness & Healthcare Products in Stock
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>

          <div className="menu-field col-md-6">
            <p className="form-label " htmlFor="">
              Wellness & Healthcare Products in Stock
            </p>
            <div>
              <img
                src=""
                alt=""
                style={{
                  borderRadius: "8px",
                  width: "99px",
                  height: "64px",
                  border: "1px solid gray",
                }}
              />
            </div>
          </div>

        </div>

        {/* Thirteen */}
        <div className="addnew-form1-autor-detail mt-3 mb-5 d-flex">
          <div className="menu-field col-md-3 me-5">
            <p className="form-label " htmlFor="">
              Account Holder Name
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>

          <div className="menu-field col-md-3 me-5">
            <p className="form-label " htmlFor="">
              Account Number
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>

          <div className="menu-field col-md-3 me-5">
            <p className="form-label " htmlFor="">
              IFSC
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>
        </div>

        {/* Fourteen */}
        <div className="addnew-form1-autor-detail mt-3 mb-5 d-flex">
          <div className="menu-field col-md-3 me-5">
            <p className="form-label " htmlFor="">
              Account Type
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>

          <div className="menu-field col-md-3 me-5">
            <p className="form-label " htmlFor="">
              Bank Name
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>

          <div className="menu-field col-md-3 me-5">
            <p className="form-label " htmlFor="">
              Branch Name
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>
        </div>

        {/* Fifteen */}
        <div className="addnew-form1-autor-detail mt-3 mb-5 d-flex">

          <div className="menu-field col-md-3 me-5">
            <p className="form-label " htmlFor="">
              UPI ID
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewNewPharmacyForm;
